import React, {useEffect, useRef, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
// import { useHistory, useLocation } from "react-router";
import { CustomSkeleton, IModel, IModelAnalyticsAccuracy, IModelAnalyticsTestDataSet, IModelAnalyticsTestStatisticalData, IModelCollectionsAdditionalData, modelCollectionAdditionalDataRequestPayloadForIntegrity, modelCollectionAdditionalDataRequestPayloadForTopPerforming } from '../../../../../../../../../../../../common';
// import Chip from '@material-ui/core/Chip';
import { ModelCollectionAccuracy } from '../ModelCollectionAccuracy';
import { InputFieldState } from '../../../../../../../../../../../../common/constants/interfaces/inputFieldState';
import { Box, Button, IconButton, Popover, Slider, Switch, TableHead, Tooltip, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useURLQuery } from '../../../../../../../../../../../../hooks/useURLQuery';
import useSetUrlSearchParamsOfCurrentRoute from '../../../../../../../../../../../../hooks/useSetUrlSearchParamsOfCurrentRoute';
import { useLocation } from 'react-router-dom';
import { ModelAnalyticsInterityApiResponseData } from '../../../../../../../../../../../../common/constants/interfaces/modelAnalyticsIndividualClassAccuracyApiResponse';
import { DashboardIntegrityFilter } from '../ModelAnalyticsDashboard';
import { DataService } from '../../../../../../../../../../../../services';
import { CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE, IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS } from '../integrity';
import { getCollectionAccuracyFromLocalStorage, keyToSaveCollectionAccuracyInLocalStorage, saveCollectionAccuracyInLocalStorage } from '../../TrainedModelPopup';
import { isNullOrUndefined } from '../../../../../../../../../../../../services/variableHelperService';


const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    topPerformaingModel:{
        height: "140px",
        overflow:'hidden'
    },
    tableCell:{
      // font: "normal normal normal 600 11px/15px Nunito Sans",
      // fontSize: "11px",
        textAlign:'center',
        color: "#141414",
        padding: "8px 4px",
        '@media (min-width:1500px)' : {
          font: "normal normal normal 600 13px/15px Nunito Sans",
        },
        '@media (max-width:1500px) and (min-width:1000px)' : {
          font: "normal normal normal 600 13px/15px Nunito Sans",
        },
        '@media (max-width:1000px)' : {
          font: "normal normal normal 600 12px/15px Nunito Sans",
        },
    },
    tableCellTitle : {
      textAlign: "left",
      // font: "normal normal normal 600 11px/15px Nunito Sans",
      color: "#838C95",
      opacity: 1,
      padding: "0px 4px 0px 12px",
      width: "120px",
      '@media (min-width:1500px)' : {
        font: "normal normal normal 600 12px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal normal 600 13px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal normal 600 12px/15px Nunito Sans",
      },
    },
    heading:{
      height: "40px",
      textAlign: "left",
      font: "normal normal 600 13px/15px Nunito Sans",
      letterSpacing: "0.32px",
      color: "#1A1A1A",
      opacity: 1,
      padding: "0px 0 0px 12px",
      margin: "0px",
      fontWeight: 600,
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    tableContainer: {
      position: "relative",
      height: "calc(100% - 47.5px)"
    },
    table: {
     // height: "100%"
    },
    tr: {
      height: "44px"
    },
    accuracyModelClass: {
      height: "24px",
    width: "50px",
    margin: "0 auto",
    fontSize: "9px",
    color: "#191919",
  
    '& div': {
      // fontSize: "9px",
      // font: "normal normal 600 9px/12px Nunito Sans", 
      color: "#191919",
      opacity:"unset",
      '@media (min-width:1500px)' : {
        font: "normal normal 600 12px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 600 11px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 600 10px/15px Nunito Sans",
      },
      }
    },
    liveAccuracyModelClass: {
      height: "24px",
    width: "50px",
    margin: "0 auto",
    fontSize: "9px",
    color: "#191919",
  
    '& div': {
      // fontSize: "9px",
      // font: "normal normal 600 9px/12px Nunito Sans", 
      // color: "#191919",
      opacity:"unset",
      '@media (min-width:1500px)' : {
        font: "normal normal 600 12px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 600 11px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 600 10px/15px Nunito Sans",
      },
      }
    },
    popOverTableCell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center", 
      gap: 20
    }
   
  }),
);

type Props = {
  modelAnalyticsTopPerformingModel : IModelAnalyticsTestStatisticalData[], 
  modelDetails: IModel | undefined;

  // modelAnalyticsAccuracy: IModelAnalyticsAccuracy[] | any;
  // modelAnalyticsTestDataSet: IModelAnalyticsTestDataSet[];
  // modelVersionsToShow: string[];

  // topPerformingData: ModelAnalyticsInterityApiResponseData;
  isFetchingTopPerformingData: boolean;
  isFetchingModelAnalyticsTestStatisticalData?: boolean;
  // isTopPerformingFilterApplied: boolean;
  // topPerformingFilter: DashboardIntegrityFilter;
  // setTopPerformingFilter: React.Dispatch<React.SetStateAction<DashboardIntegrityFilter>>;
  // modelCollectionsAdditionalDataQueryParamValueForTopPerforming: modelCollectionAdditionalDataRequestPayloadForTopPerforming[];  
  // FetchModelAnalyticsTestStatisticalDataForIntegrityOfModel: (additionalData: IModelCollectionsAdditionalData[]) => Promise<void>, 
  
  // isFetchingModelAnalyticsTestStatisticalData: boolean, 
  // isTestCollectionPresent: boolean
};

interface ITopPerformerSliderData {
  modelCollectionId: string,
  confidenceScoreThreshold: number,
  sliderIsEnable: boolean
}

function TopPerformingModel( props: Props) {
    const classes = useStyles();
    const location = useLocation();
    
    // const [sortedModelAnalyticsTopPerformingModel, setSortedModelAnalyticsTopPerformingModel] = useState<IModelAnalyticsTestStatisticalData[]>([])
    let sortedModelAnalyticsTopPerformingModel: IModelAnalyticsTestStatisticalData[] = props.modelAnalyticsTopPerformingModel.sort((a, b) => (a.testAccuracy?.value && b.testAccuracy?.value && a.testAccuracy?.value >= b.testAccuracy?.value) ? -1 : 1);
    const [isLiveModelCollectionExist, setIsLiveModelCollectionExist] = useState<boolean>(false);
    const [highestAccuracyValue, setHighestAccuracyValue] = useState<Number>(0);
    const [isTopPerformingModelPopOverOpen, setIsTopPerformingModelPopOverOpen] = useState<boolean>(false);
    const [modelCollectionAccuracies, setModelCollectionAccuracies] = useState<{modelCollectionId: string, accuracyValue: number}[]>([])

    const [thresholdApplied, setThresholdApplied] = useState<IModelCollectionsAdditionalData []>()
    const setUrlSearchParamsOfCurrentRoute =
      useSetUrlSearchParamsOfCurrentRoute();
  
    const setUrlSearchParamsOfCurrentRouteRef = useRef(setUrlSearchParamsOfCurrentRoute);
    setUrlSearchParamsOfCurrentRouteRef.current=setUrlSearchParamsOfCurrentRoute;

    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
    const searchParams = new URLSearchParams(location.search);

    const testCollectionId = searchParams.get('testCollectionId') || "";
    
    
    
    useEffect(() => {
      let highestAccuracy = 0
      sortedModelAnalyticsTopPerformingModel.forEach((x: IModelAnalyticsTestStatisticalData) => {
        if(x.status === "Live"){
          setIsLiveModelCollectionExist(true)
        }
        if(x?.testAccuracy?.value && x?.testAccuracy?.value > highestAccuracy){
          highestAccuracy = x?.testAccuracy?.value
        }
      })
      setHighestAccuracyValue(highestAccuracy)
      },[sortedModelAnalyticsTopPerformingModel, highestAccuracyValue])

      const TopPerformingModelPopOver = (props: { 
        open: boolean,
        minValue: number,
        maxValue: number,
        onClose: () => void
        resetFilters: () => void
        isFilterApplied: boolean,
        isFetchingModelAnalyticsTestStatisticalData: boolean,
        onApplyFilterButtonClick: () => void
        // setFilterMinMaxValue: (value: [number, number]) => Promise<void>
    }) => {
      let modelCollectionsAdditionalData = searchParams.get('modelCollectionsAdditionalDataForTopPerforming');

      console.log("thresoldValue", thresholdApplied)
    
      const [sliderFieldState, setSliderFieldState] = useState< ITopPerformerSliderData []>(
        sortedModelAnalyticsTopPerformingModel.map((state) => {
          let thresholdValue = thresholdApplied?.find((prevState) => prevState.modelCollectionId===state.modelCollectionId)?.confidenceScoreThreshold
          if(thresholdValue===undefined){
            return {
              modelCollectionId: state.modelCollectionId,
              confidenceScoreThreshold: 90,
              sliderIsEnable: false
            }
          }
          return {
              modelCollectionId: state.modelCollectionId,
              confidenceScoreThreshold: thresholdValue,
              sliderIsEnable: true
            }
        })
      ); 

      console.log("sliderFieldState", sliderFieldState)

        return (
          <Popover
            id="top-performing-model-popover-menu"
            anchorEl={document.getElementById("topPerformingModelOptions")}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}        
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
              style: {
                width: "450px",
              },
            }}
          >
            {props.open && (
              <Box id="topPeformingModelMenuItemContainer" style={{ padding: 15 }}>
                <div
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>
                            Model
                          </strong>
                        </TableCell>
                        <TableCell>
                          <strong>
                            Confidence Score threshold
                          </strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {!props.isFetchingModelAnalyticsTestStatisticalData &&
                      sortedModelAnalyticsTopPerformingModel?.map((cell, index: number) => {
                        return (
                          <TableRow>
                            <TableCell>{cell.version}</TableCell>
                            <TableCell className={classes.popOverTableCell}>
                              <Switch
                                checked={sliderFieldState.find(thresold => thresold.modelCollectionId === cell.modelCollectionId)?.sliderIsEnable}
                                onChange={() => {
                                  setSliderFieldState((oldState) => {
                                    const currentSliderIsEnable = oldState.find(thresold => thresold.modelCollectionId === cell.modelCollectionId)?.sliderIsEnable
                                    return [...oldState.filter(thresold => thresold.modelCollectionId !== cell.modelCollectionId), {
                                      modelCollectionId: cell.modelCollectionId,
                                      confidenceScoreThreshold: 90,
                                      sliderIsEnable: !currentSliderIsEnable
                                    }];
                                  })
                                }}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />
                              <Slider
                                value={sliderFieldState.find((state)=> state.modelCollectionId===sortedModelAnalyticsTopPerformingModel[index].modelCollectionId)?.confidenceScoreThreshold}
                                min={0}
                                disabled={!sliderFieldState.find(thresold => thresold.modelCollectionId === cell.modelCollectionId)?.sliderIsEnable}
                                max={100}
                                color="primary"
                                step={1}
                                marks={[
                                  { value: 0, label: "0%" },
                                  { value: 50, label: "50%" },
                                  { value: 100, label: "100%" },
                                ]}

                                // @ts-ignore
                                onChange={(e, value: number) => {
                                  setSliderFieldState((oldState) => {
                                    return [...oldState.filter(thresold => thresold.modelCollectionId!==cell.modelCollectionId), {
                                      modelCollectionId: cell.modelCollectionId,
                                      confidenceScoreThreshold: value,
                                      sliderIsEnable: true
                                    }];
                                  })
                                }}

                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </Table>
                </div>                    
                          
                <div id="resetFilterButtonContainer"
                    style={{marginTop: '40px', display: 'flex', justifyContent: 'flex-end', gap: 20}}
                >
                    <div></div>
                    <Button variant="outlined" color="primary"
                        onClick={()=>{
                          props.onClose()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary"
                        onClick={()=>{
                          let urlParams=sliderFieldState
                          .filter((state) => state.sliderIsEnable)
                          .map((state) => {
                            return {
                              modelCollectionId: state.modelCollectionId,
                              confidenceScoreThreshold: state.confidenceScoreThreshold
                            };
                          })
                          if(modelCollectionsAdditionalData===JSON.stringify(urlParams)){
                            props.onClose()
                            return;
                          }
                          if(urlParams.length===0)
                          {
                            setUrlSearchParamsOfCurrentRouteRef.current({
                              "modelCollectionsAdditionalDataForTopPerforming": ""
                            }, "historyPush");
                          }
                          else{
                            setUrlSearchParamsOfCurrentRouteRef.current({
                              "modelCollectionsAdditionalDataForTopPerforming": JSON.stringify(urlParams)
                            }, "historyPush")
                          }
                          props.onClose()
                        }}
                    >
                        Apply
                    </Button>
                </div>            
              </Box>
            )}
          </Popover>
        );
    }

    const handleCloseTopPerformingModelPopOver = () => {
      setIsTopPerformingModelPopOverOpen(false)
    }    

    useEffect(() => {    
      let modelCollectionsAdditionalData = searchParams.get('modelCollectionsAdditionalDataForTopPerforming');
      if(!modelCollectionsAdditionalData){
        setIsFilterApplied(false)
        setThresholdApplied([])
        return;
      } 
      if(modelCollectionsAdditionalData!=="")
      setIsFilterApplied(true)
      setThresholdApplied(Object.values(JSON.parse(modelCollectionsAdditionalData)))        
    }, [location.search]); 
    
    // set accuracy value for top performing models
  useEffect(() => {

    // setSortedModelAnalyticsTopPerformingModelAccuracies(sortedModelAnalyticsTopPerformingModel.map(obj => obj.testAccuracy.value));
    setModelCollectionAccuracies([]);

    const setAccuracies = async () => {
      await Promise.all(
        sortedModelAnalyticsTopPerformingModel.map(async (collection, index) => {
        //check in localStorage first
        // @ts-ignore
        const accuracy = getCollectionAccuracyFromLocalStorage(testCollectionId, collection.modelCollectionId, !isNullOrUndefined(props.modelDetails?.iouThresholdValue) ? props.modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(props.modelDetails?.confidenceScoreThresholdForIntegrity) ? props.modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
        
        if (accuracy) {
          setModelCollectionAccuracies((oldState) => {
            return [...oldState, {modelCollectionId: collection.modelCollectionId || "", accuracyValue: parseFloat(accuracy.toString())}]
          })
        }
        else {   //call API 
          if(accuracy){
            localStorage.removeItem(keyToSaveCollectionAccuracyInLocalStorage(testCollectionId, collection.modelCollectionId))
          }
          if (props.modelDetails?.isSegmentationModelAnalytics) {
            const apiResponse = await DataService.getIntegrity({
              modelCollectionIds: `[\"${collection.modelCollectionId}\"]`,
              modelId: props.modelDetails?._id || "",
              // @ts-ignore
              modelCollectionsAdditionalData: JSON.stringify([{
                modelCollectionId: collection.modelCollectionId,
                iouThreshold: !isNullOrUndefined(props.modelDetails?.iouThresholdValue) ? props.modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
              }]),
              testCollectionId: testCollectionId
            })

            if (apiResponse?.data[0]) {
              let totalGoodDataPoints = 0
              if(apiResponse.data[0].bands?.length){
                  apiResponse.data[0].bands?.map((obj: any) => {
                    totalGoodDataPoints += obj.totalGoodDataPointsCount || 0
                  })
              }

              if(apiResponse.data[0].totalDataSetsCount){
                const accuracyValue = parseFloat(((totalGoodDataPoints / apiResponse.data[0].totalDataSetsCount) * 100).toFixed(2))

                setModelCollectionAccuracies((oldState) => {
                  return [...oldState, {modelCollectionId: collection.modelCollectionId || "", accuracyValue}]
                })

                //@ts-ignore
                saveCollectionAccuracyInLocalStorage(accuracyValue, testCollectionId, collection.modelCollectionId, !isNullOrUndefined(props.modelDetails?.iouThresholdValue) ? props.modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(props.modelDetails?.confidenceScoreThresholdForIntegrity) ? props.modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
              }
            }
          }
          else{
            setModelCollectionAccuracies((oldState) => {
              return [...oldState, {modelCollectionId: collection.modelCollectionId || "", accuracyValue: collection.testAccuracy?.value || 0}]
            })
          }
        }
      })
      )
    }

    // setAccuracies()

  }, [props.modelAnalyticsTopPerformingModel])

  return (
    <div className={classes.topPerformaingModel}>
        <Paper className={classes.topPerformaingModel}>
            <div className={classes.heading}>
              TOP PERFORMING MODEL
          {
            !props.isFetchingTopPerformingData && !props.isFetchingModelAnalyticsTestStatisticalData &&
            !props.modelDetails?.isSegmentationModelAnalytics &&
            <Box
          >
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              id="topPerformingModelOptions"                          
              onClick={() => {
                setIsTopPerformingModelPopOverOpen(true)
              }}
            >
              {
                isFilterApplied
                  ? (
                    <Tooltip title="Confidence score threshold Applied to Analyse the Top Performing Model">
                      <FilterListIcon />
                    </Tooltip>
                  )
                  : <MoreVertIcon />
              }
            </IconButton>
            {
              <TopPerformingModelPopOver isFetchingModelAnalyticsTestStatisticalData={props.isFetchingTopPerformingData} open={isTopPerformingModelPopOverOpen} minValue={0} maxValue={0} onClose={handleCloseTopPerformingModelPopOver} resetFilters={() => {}} isFilterApplied={false} onApplyFilterButtonClick={() => {}}
              //  setFilterMinMaxValue={() => {}} 
               />
            }
          </Box> }
            </div>
            <hr style={{margin:"0px"}}/>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table}>
                    <TableBody>
                        <TableRow className={classes.tr}>
                            <TableCell className={classes.tableCellTitle}>
                                MODELS
                            </TableCell>
                            {
                              (props.isFetchingTopPerformingData || props.isFetchingModelAnalyticsTestStatisticalData) &&
                              new Array(5).fill("").map((item, index) => (
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                              ))
                            }
                            { !props.isFetchingTopPerformingData && !props.isFetchingModelAnalyticsTestStatisticalData &&
                              sortedModelAnalyticsTopPerformingModel?.map((cell, index) => {
                                return (
                                <TableCell className={classes.tableCell} style={{textAlign: 'center'}} key={cell.modelCollectionId}>
                                  {cell?.version}
                                </TableCell>)
                              })
                            }

                        </TableRow>
                        <TableRow className={classes.tr}>
                            <TableCell className={classes.tableCellTitle}>
                                ACCURACY
                            </TableCell>
                            {
                              (props.isFetchingTopPerformingData || props.isFetchingModelAnalyticsTestStatisticalData) &&
                              new Array(5).fill("").map((item, index) => (
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                              ))
                            }
                            {
                              !props.isFetchingTopPerformingData && !props.isFetchingModelAnalyticsTestStatisticalData
                              &&
                              sortedModelAnalyticsTopPerformingModel?.map((cell, index) => {
                                return (
                                <TableCell className={classes.tableCell} key={cell.modelCollectionId}>
                                  {/* <Chip 
                                  key={cell?.accuracy?.value}
                                  label= {cell?.accuracy?.value}
                                  variant= "outlined"
                                  style={{width: "80px", backgroundColor: "primary"}}
                                  /> */}
                                    <ModelCollectionAccuracy 
                                    className={(cell.status === "Live" || (cell?.testAccuracy && (cell?.testAccuracy?.value <=0)))? classes.liveAccuracyModelClass :classes.accuracyModelClass}
                        isLiveModelCollection={cell.status === "Live"}
                        liveCollectionAccuracy={0}
                        accuracyStatus ={(index === 0 || cell?.testAccuracy?.value === highestAccuracyValue) && ((cell?.testAccuracy?.type === "noLiveExists") || !isLiveModelCollectionExist) ? "customHeighestNoLiveExists" : !isLiveModelCollectionExist ? "customNoLiveExists" : cell?.testAccuracy?.type}
                        // accuracyValue={modelCollectionAccuracies.find(obj => obj.modelCollectionId === cell?.modelCollectionId)?.accuracyValue || 0} 
                        accuracyValue={cell?.testAccuracy?.value || 0} 
                        // modelCollectionFiles={[]} 
                        jsonFileData ={undefined}
                      />       
                                </TableCell>)
                              })
                            }
                        </TableRow>
                    </TableBody>
                   
                </Table>

            </TableContainer>
        </Paper>
    </div>
  )
}

export default TopPerformingModel