import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete, Skeleton } from "@material-ui/lab";
// import { ICollection } from "../../../../../../../../../../../common/constants/interfaces/collections";
import { DataService } from "../../../../../../../../../../../services";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import { Chip, CircularProgress, FormControl, FormControlLabel, FormGroup, IconButton, makeStyles, Paper, PaperProps, Popover, Slider, Switch, TextField, Tooltip } from "@material-ui/core";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { IReduxState } from "../../../../../../../../../../../common/constants/interfaces/redux";
import {
  convertDate,
  IData,
  IDataUpdatePayload,
  IModelType,
  ResourceImageAnnotation,
  ResourceImageGroupAnnotation,
  ResourceStatus,
  Status,
} from "../../../../../../../../../../../common";
import { updateResourceInUI } from '../../../../../../../../../../../store/actions';

// import {
//   ImageCoordinate,
// } from "../../../../../../../../../components/ModelData/ImagePlotter";
import { TwoDimensionalImage } from "../../../../../../../../../../../assets/components/react-annotation-tool/src/apps/two-dimensional-image";
import { setShouldBlockDataTableKeyboardEvents, updateData, setResourceDataBoost } from '../../../../../../../../../../../store/actions/dataActions';
import { getImageScaleFactorForImageWidth } from "../../../../../../../../../../../services/imageHelperService";
// import { copyByValue } from '../../../../../../../../../../../services/variableHelperService';
import { AnnotationComponentAnnotationChangeData, AnnotationComponentSaveClickData, AnnotationOption, getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation, setResourceImageAnnotationsForSendingToAnnotationComponent, setResourceImageGroupAnnotationsOfOriginalImageForCompressedImage, setResourceImageGroupAnnotationsForSendingToAnnotationComponent, setResourceImageAnnotationsOfOriginalImageForCompressedImage, setResourceImageAnnotationsOfCompressedImageForOriginalImage, setResourceImageGroupAnnotationsOfCompressedImageForOriginalImage } from '../../../../../../../../../../../services/imageAnnotationHelperService';
import { ReactElement } from 'react';
import { Cancel, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
// import ImageAspectRatioScaler from "../../../../../../../../../../../common/components/ImageAspectRatioScaler";
import ImageAspectRatioScaler from "./imageAspectRatioScalerWithAlignment.component";
import { ICoPilotResource } from "../../../../../../../../../../../common/constants/interfaces/coPilotResource";
import FileNameClipper from "../../../../../../../../../../../common/components/FileNameClipper";
import moment from "moment";
import { ShowLessLink, ShowMoreLink } from "../../../../../../coPilot/features/components/addRemark/addRemark";
import TagEditText from "./TagEditText";
import { useURLQuery } from "../../../../../../../../../../../hooks/useURLQuery";
import LabelEditText from "./LabelEditText";
import { ResourceLimitQueryParam, ResourceOffsetQueryParam } from "./TableContent";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import usePreviousValue from "../../../../../../../../../../../hooks/usePreviousValue";
import ImageService from "../../../../../../../../../../../services/ImageService";
import useImageOnLoad from "../../../../../../../../../../../hooks/useImageOnLoad";
import { themePrimaryMainColor } from "../../../../../../../../../../../theme";
import { AiAssistantMsgWithInfoIcon } from "./VideoAnnotationDialogBox.component";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ImageRelatedModelReferenceData } from "../../../../../../../../../../../common/constants/interfaces/imageRelatedModelReferenceData";
import ReferenceImageDataLink from "./referenceImageDataLink/referenceImageDataLink";
import Draggable from "react-draggable";
import css from "./ImageKeyPointsEditDialog.module.css";
import ViewAsCSVDialog from "./ViewAsCSVDialog";
import { isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";
import { VisitParentResourceLink } from "./visitParentResourceLink/visitParentResourceLink";
import EditIcon from '@material-ui/icons/Edit';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

const imageWidthToSet = 600;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding:'50px',
    paddingTop:'0px',
    margin:'auto',
    display:'flex'
  },
  dialogContainer: {
    overflow: 'visible'
  },
  child:{
    width:'50%',
    display:'grid',
    gridColumn:'1',
    justifyItems:'center',
    margin:'5px'
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: "30px",
  },
  autoCompleteChipRoot: {
    maxWidth: "170px",
  },
  indicators: {
    position: 'absolute',
    bottom: '45%',
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'opacity 0.25s ease-in-out',
    borderRadius: '50%',
    // background: 'rgba(2, 141, 154, 0.4)',
    zIndex:1,
  },
  leftIndicator: {
    left: 18,
  },
  rightIndicator: {
    right: 18,
  },
  contentRowForImageModelScenario: {
    display: 'flex',
    paddingLeft: "87px",
    '@media (max-width:990px)': {
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: "0px"
    },
  },
  contentRowForImageModelScenarioRhsColumn: {
    width: "38%",
    '@media (max-width:990px)': {
      width: "50%"
    },
    maxHeight: "468px",
    overflow: "auto",
    marginBottom: "13px",
    paddingBottom:"15px" 
  },
  imageAnnotation:{
    marginBottom: "-10px",
    paddingBottom: 0
  },
  details: {
    minWidth : '170px', 
    display:'inline-block', 
    marginBottom:'8px',
    // font: "normal normal normal 15px/20px Nunito Sans",
    fontStyle: 'normal',
    fontVariant: 'normal',
    // fontWeight: 'normal',
    //@ts-ignore
    fontWeight: (props) => props?.fontWeight,
    //@ts-ignore
    fontSize: (props) => props?.fontSize,
    //@ts-ignore
    lineHeight: (props) => props?.lineHeight,
    fontFamily: 'Nunito Sans',
    // fontSize: 'smaller'
  },
  headingStylesForAdditionalDataPopupForBookTranslationModel: {
    minWidth : '170px', 
    display:'inline-block', 
    marginBottom:'8px',
    font: 'normal normal normal 16px/22px Nunito Sans',
    letterSpacing: '0px',
  },
  editText: {
    backgroundColor: 'transparent !important',
    textAlign: "left !important" as "left",
    padding:"0px 0px 0px 5px !important",
    marginTop : "0px !important",
    marginLeft : "-6px !important"
},
closeBtn: {
  position: "absolute",
  right: -10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // padding: "2px",
  top: -10,
  backgroundColor: "white",
  borderRadius: "50%",
  // color: "white",
  cursor: "pointer",
  width: "25px",
  height: "25px"
},
}));

interface IProps extends TPropsFromRedux {
  open: boolean;
  onSwitch: (index: 1 | -1)=>void;
  onClose: () => void;
  modelReferenceData: ImageRelatedModelReferenceData[];
  scenario?:string | "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection";
  resource: IData | ICoPilotResource;
  resources: IData[] | ICoPilotResource[];
  totalCount: number;
  confidenceScore?: number;
  resourceRemarksTextState: InputFieldState<string>;
  modelOrCopilotType: IModelType 
  setResourceRemarksTextState: React.Dispatch<React.SetStateAction<InputFieldState<string>>>;
  modelType: IModelType;
  dataSetCollections ?: IData["dataSetCollections"]
  changesMade ?: IData["changesMade"]
  isShowingInSideDrawer ?: boolean;
  inCopilot ?: boolean;
  isFetchingResource: boolean;
  labelsAvailable ?: string[];
  allowUserToSetAnyLabel: boolean;
  // imageCoordinatesState: InputFieldState<ImageCoordinate[]>;
  // setImageCoordinatesState: React.Dispatch<
  //   React.SetStateAction<InputFieldState<ImageCoordinate[]>>
  // >;
  // setIsImageKeyPointsEditDialogOpen: React.Dispatch<
  //   React.SetStateAction<boolean>
  // >;
  // circleRadius: number;
  // imageDifference: number;
  // setImageDifference: React.Dispatch<React.SetStateAction<number>>;
  // defaultImageDifference: number;
  isViewOnlyMode?: boolean
  annotationOption: AnnotationOption;
  ActionsSection: ReactElement;
  fileIdForMultipleImageAnnotationUpdate?:(fileId:string)=>any;
  onImageAnnotationSaveClick: (data: AnnotationComponentSaveClickData) => any;
  onSaveRemarksClick: () => any;
  alert: string;
  alertMessage: string;
  onNextClick?:any;
  onPreviousClick?:any;
  imageExpandFeatureEnabled: boolean;
  isZoomFeatureEnabledForImageAnnotation: boolean;
  multipleImageAnnotationResourceImageIndex: number;
  setCurrentMultipleImageAnnotationResourceImageIndex: (indexToSet: number)=>any;
  allowUserToSetAnyLabelForImageAnnotation?: boolean;
  updateResourceInUI: (resourceData: any) => Promise<void>
}

const csvInitialMaxLengthLimit=10;
const changesMadeInitialMaxLengthLimit=10;

export const RemarksSection = (props:  {
  resourceRemarksTextState: InputFieldState<string>;
  setResourceRemarksTextState: React.Dispatch<React.SetStateAction<InputFieldState<string>>>;
  isResourceRemarksBeingUpdatedInDB: boolean;
  resourceId: string;
  setShouldBlockDataTableKeyboardEvents: Function;
  label?: string,
  // updateResourceRemarks?: Function;
  labelColor?:string;
  resource ?: ICoPilotResource | IData; 
  confidenceScore?: number;
  resourceType ?: IModelType;
  dataSetCollections ?: IData["dataSetCollections"]
  changesMade ?: IData["changesMade"]
  isShowingInSideDrawer ?: boolean;
  status ?: ResourceStatus
  inCopilot ?: boolean;
  labelsAvailable ?: string[];
  allowUserToSetAnyLabel: boolean;
  imageIndexInMultipleImageAnnotation?: number;
  // confidenceScore_IData?: IData["confidence_score"]
  // updateCopilotResourceRemarks?:Function;
  onSaveRemarksClick: () => any;
  updateResourceInUI: (resourceData: any) => Promise<void>;
  scenario?: "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection";
}) => {
  const [isViewAsCSVOpen, setIsViewAsCSVOpen] = useState<boolean>(false)

  const handletoggleCSVDialog = () => {
    setIsViewAsCSVOpen(prevState=>!prevState)
  }

  return (
    <div
    style={{display: "flex", flexDirection: "column"}}
  >

    {
      props.resourceType === "multipleImageAnnotation" && !isNullOrUndefined(props.imageIndexInMultipleImageAnnotation) &&
      (
        <div><span style={{color: "#8e969e"}}>Image No:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{(props.imageIndexInMultipleImageAnnotation || 0)+1}/{props.resource?.files?.length}</div>
      )
    }

    {console.log("Cu r r e n t s t a t u s: " + props.status)}
    {props.isShowingInSideDrawer !== true && <ShowResourceDetails scenario={props.scenario} updateResourceInUI={props.updateResourceInUI} resource={props.resource} allowUserToSetAnyLabel={props.allowUserToSetAnyLabel} LabelsAvailable={props.labelsAvailable} confidenceScore={props.confidenceScore} resourceType={props.resourceType} dataSetCollections={props.dataSetCollections} changesMade={props.changesMade} inCopilot={props.inCopilot} setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents}/>}                           
      <>
      {
              <div>
              <Button color="primary" variant="contained"
              style={{width: "100%"}}
              onClick={handletoggleCSVDialog}
            >
              View As CSV
            </Button>
            {
              isViewAsCSVOpen===true&&
              <ViewAsCSVDialog resources={props.resource?[props.resource]: []} open={isViewAsCSVOpen} setOpen={setIsViewAsCSVOpen} onClose={handletoggleCSVDialog} />
            }
          </div>
            }
        {
           props.scenario !== 'aiMarketPlaceDataCart' && props.scenario !== 'aiMarketplaceDataSelection'
          &&
          <>
        <div style={{marginBottom: "10px", color: `${props.labelColor}`}}>{props.label || "Remarks"}:</div>
        <textarea 
            style={{width: "100%", minHeight: "83px", marginBottom: "10px", fontSize: '15px', fontFamily: 'Nunito Sans', borderRadius: "5px"}}
            value={props.resourceRemarksTextState.value}
            onFocus={()=>props.setShouldBlockDataTableKeyboardEvents(true)}
            onChange={(event)=>{
                props.setResourceRemarksTextState(oldState=>({
                    ...oldState,
                    isDirty: true,
                    value: event.target.value
                }))
            }}
            onBlur={()=>{
                props.setResourceRemarksTextState(oldState=>({
                    ...oldState,
                    isTouched: true,
                }))
                props.setShouldBlockDataTableKeyboardEvents(false)                         
            }}
            disabled={
                props.isResourceRemarksBeingUpdatedInDB
                ? true
                : false
            }
        />  
        <Button color="primary" variant="contained"
            onClick={()=>{
              props.onSaveRemarksClick();
              return;
            }}
            disabled={
                props.isResourceRemarksBeingUpdatedInDB || !props.resourceRemarksTextState.isDirty ? true : false
            }
        >
            Save Remarks
        </Button> 
        </>
        }
      </>
  </div>    
  );
}

export const FileNameComponent = (props: { isShowingInForecastingViewPopup?: boolean, filename?: string, isVideoAnnotationViewPopup: boolean }) => {
  const classes = useStyles();

  return (
    <div>
      <p className={classes.details} style={{ color: "#8e969e", marginBottom: props?.isShowingInForecastingViewPopup ? "12px" : props.isVideoAnnotationViewPopup ? "20px" : "8px" }}>File Name : </p>
      <p className={classes.details}><FileNameClipper fileName={props?.filename} currentPage="viewDetails" /></p>
    </div>
  )
}

export const TagComponent = (props: { setShouldBlockDataTableKeyboardEvents: Function, tag: string | undefined, resource: IData | ICoPilotResource | undefined, isVideoAnnotationViewPopup: boolean | undefined, isShowingInForecastingViewPopup: boolean | undefined, inCopilot: boolean | undefined }) => {
  const classes = useStyles();
  const { setShouldBlockDataTableKeyboardEvents, tag, resource, isShowingInForecastingViewPopup, inCopilot } = props;

  return (
    <div style={{ display: "flex" }}>
      <p className={(resource as IData)?.model?.type === 'bookTranslation' ? classes.headingStylesForAdditionalDataPopupForBookTranslationModel : classes.details} style={{ color: "#8e969e", marginBottom: isShowingInForecastingViewPopup ? "4px" : props.isVideoAnnotationViewPopup ? "20px" : "8px" }}>Tag : </p>
      <p className={classes.details} style={{ marginBottom: "0px" }}>
        {
          resource !== undefined &&
          <TagEditText
            onEditTextClick={() => { setShouldBlockDataTableKeyboardEvents(true) }}
            onEditTextBlurEvent={() => { setShouldBlockDataTableKeyboardEvents(false) }}
            tooltip={tag}
            defaultValue={resource?.tag}
            resource={resource}
            editTextComponentProps={{ readonly: resource?.status === "approved" || inCopilot ? true : false }}
            editTextComponentAdditionalCSSClassNames={`${classes.editText}`}
          />
        }
      </p>
    </div>
  )
}

const shoowResourceDetailsParentContainerStyles = (resourceType: IModelType | undefined) => {
  if (resourceType === "bookTranslation") {
    return {
      paddingTop: '10px',
      height: '350px',
      width: "490px",
      overflow: 'auto',
    }
  }
  else {
    return {
      paddingTop: '10px',
    }
  }
};

export const ShowResourceDetails = (props: {
  resource : IData | ICoPilotResource | undefined
  setShouldBlockDataTableKeyboardEvents: Function;
  confidenceScore ?: number| string
  resourceType ?: IModelType
  dataSetCollections ?: IData["dataSetCollections"]
  changesMade ?: IData["changesMade"]
  inCopilot ?: boolean;
  isShowingInForecastingViewPopup ?: boolean;
  isFetchingResourceMetadataFileUrl?: boolean;
  resourceMetadataFileDownloadUrl?: string;
  forecastDateTime ?: string;
  LabelsAvailable ?: string[];
  selectedSubTab ?: ResourceStatus | string;
  allowUserToSetAnyLabel: boolean,
  isVideoAnnotationViewPopup?: boolean,
  updateResourceInUI: (resourceData: any) => Promise<void>,
  scenario?: "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection"
}) => {
  const {resource, confidenceScore, resourceType, dataSetCollections, changesMade, inCopilot, isVideoAnnotationViewPopup,  isShowingInForecastingViewPopup, forecastDateTime, LabelsAvailable, selectedSubTab, allowUserToSetAnyLabel } = props;

  const classes = useStyles({fontWeight: resourceType === "bookTranslation" ? "600" : "normal", fontSize: resourceType === 'bookTranslation' ? '14px' : '15px', lineHeight: resourceType === 'bookTranslation' ? '19px': '20px'});
  const dispatch = useDispatch();
  const [csvExpandedFormShowing, setCsvExpandedFormShowing] = useState<boolean>(false);
  const [isChangesMadeExpanded, setIsChangesMadeExpanded] = useState<boolean>(false);
  const [selectedDataObject, setSelectedDataObject] = useState<IData>();
  const [dataBoost,setDataBoost]  = useState('1')
  const duplicateDropdownData  =  Array(100).fill(0).map((e,i)=>(i+1).toString())
  const [isEditingMetaData, setIsEditingMetaData] = useState<boolean>(false)
  const [metadataValue, setMetadataValue] = useState<string>(props.resource?.csv||"")
  const [isSavingMetadataValue, setIsSavingMetadataValue] = useState<boolean>(false)

  const handleToggleEditingMetaData = () => {
    setIsEditingMetaData((prevState) => !prevState)
  }

  const handleChangeMetadata = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMetadataValue(event.target.value)
  }

  const handleCancelChangeMetadata = () => {
    setIsEditingMetaData(false)
    setMetadataValue(props.resource?.csv||"")
  }

  const handleSaveChangesMetaData = async () => {
    try {
      if(props.resource?._id) {
        setIsSavingMetadataValue(true)
        const payload: IDataUpdatePayload = {
          id: [props.resource?._id],
          csv: metadataValue
        }
        props.updateResourceInUI({
          _id: props.resource._id,
          csv: metadataValue
        })
        const apiResponse = await DataService.updateData(payload)
        setIsSavingMetadataValue(false)
      }
      setIsEditingMetaData(false)
    } catch (error) {
      setIsSavingMetadataValue(false)
    }
  }

  const onDataBoostChange = async(ele: string) => {
    if(selectedDataObject?._id) {
        updateData({  id: [selectedDataObject?._id],dataBoost: Number(ele) }, { }, {shouldFetchLatestData: false});
        DataService.updateData({ id: [selectedDataObject?._id], dataBoost: Number(ele)})
    }
    
    setDataBoost(ele.toString())
    setSelectedDataObject((prev) => (JSON.parse(JSON.stringify({...prev, dataBoost: ele.toString()}))));
    dispatch(setResourceDataBoost({resourceId: selectedDataObject?._id || "", dataBoost: Number(ele) }))
  }

  useEffect(() => {      
      if (resource) {
        setSelectedDataObject(JSON.parse(JSON.stringify(resource)));
        resource?.dataBoost ?
            setDataBoost(resource?.dataBoost) : setDataBoost('1')
      }
      return;
  }, [resource])

  useEffect(() => {
    setMetadataValue(resource?.csv || "")
  }, [props.resource?.csv])
  
  // console.log("Confidence Score: " + props.confidenceScore)
  // console.log("Resourc Type : ", props.resourceType)
  // console.log("Changes made by : ")

  const {isFetchingResourceMetadataFileUrl, resourceMetadataFileDownloadUrl} = props;

  return(
  <div style={shoowResourceDetailsParentContainerStyles(resourceType)}>
                              {
                                (resourceType !== "imageAnnotation" && resourceType !== 'bookTranslation' && resourceType!== 'textAnnotation' && resourceType!== 'DLP' && resourceType!== 'videoAnnotation' && resourceType !== undefined) && resource?.filename &&
                                <FileNameComponent filename={resource?.filename} isShowingInForecastingViewPopup={isShowingInForecastingViewPopup} isVideoAnnotationViewPopup={isVideoAnnotationViewPopup ?? false} />  
                              }

                              {
                                  (resource?.parentResourceId) &&
                                  <VisitParentResourceLink 
                                      parentResourceId={resource?.parentResourceId || ""}
                                  />
                              }

                              { isShowingInForecastingViewPopup && 
                                <div> 
                                    <p className={classes.details} style={{ color:"#8e969e", marginBottom: isShowingInForecastingViewPopup ?"12px":"8px"}}>Forecasted At   : </p>
                                    <p className={classes.details} >{convertDate(new Date(forecastDateTime || "").toLocaleString('en-US', {timeZone: 'America/New_York', hour12: false}))}</p>
                                </div>
                              }
                              {
                                resourceType === "bookTranslation" &&
                                <div>
                                  <p className={ classes.headingStylesForAdditionalDataPopupForBookTranslationModel} style={{ color:"#8e969e", marginBottom: isShowingInForecastingViewPopup ?"12px": isVideoAnnotationViewPopup ? "20px": "8px"}}>Paragraph Number   : </p>
                                  <p className={classes.details} >{resource?.resource}</p>
                                </div>
                              }
                              {
                                (resourceType=== 'bookTranslation' || resourceType=== 'forecasting' || resourceType === 'audio' || resourceType === 'video' || resourceType === 'videoAnnotation'  || resourceType === 'image' || resourceType === 'imageAnnotation' || resourceType === 'imageAnnotationGroup') &&
                                <div 
                                // className={classes.info} style={{marginTop: "8px"}}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px"
                                  }}
                                >
                                  <p className={resourceType === "bookTranslation" ? classes.headingStylesForAdditionalDataPopupForBookTranslationModel : classes.details} style={{ color:"#8e969e", marginBottom: "0px"}}>Data Boost:</p>
                                  <p className={classes.details} style={{display: "flex", alignItems: "end"}}> 
                                  <div style={{marginBottom: "6px", marginRight: "4px"}}>x</div>
                                  <Autocomplete
                                    onChange={(event: any, newValue: string | null) => {
                                      onDataBoostChange(newValue?.toString()  || '1');
                                    }}
                                    disabled={props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection'}
                                    disableClearable
                                      value={dataBoost.toString()}
                                      // disablePortal
                                      id="combo-box-demo"
                                      options={duplicateDropdownData}
                                      renderInput={(params: any) => <TextField {...params} />}
                                      />
                                  </p>
                                </div>
                              }
                              {  !isShowingInForecastingViewPopup && resourceType !== "bookTranslation" &&
                                // ((resourceType === "audio" || resourceType === 'text') ?
                                <div style={{display: 'flex'}}>
                                  <p className={classes.details} style={{ color:"#8e969e", marginBottom: isShowingInForecastingViewPopup ? "12px" : isVideoAnnotationViewPopup ? "20px": "8px", display:'flex', alignItems: 'center'}}>Label : </p>
                                  <p className={classes.details}>
                                    {/* {resource?.label} */}{ LabelsAvailable ?
                                      <LabelEditText 
                                          readonly={
                                            props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection' ? true :
                                            false && (selectedSubTab === 'approved' || selectedSubTab === 'deleted' || selectedSubTab === 'rejected')
                                              ? true
                                              : false                                                         
                                          }
                                          labelsAvailable={LabelsAvailable}
                                          resource={resource as unknown as IData}
                                          allowUserToSetAnyLabel={allowUserToSetAnyLabel}
                                          onEditTextFocus={()=>{
                                              setShouldBlockDataTableKeyboardEvents(true)
                                          }}
                                          onEditTextBlurEvent={()=>{
                                              setShouldBlockDataTableKeyboardEvents(false)
                                          }}
                                          placeWhereComponentBeingUsed="data-table"
                                      /> : 
                                      resource?.label}
                                    </p>
                                </div>
                              // : 
                              // <div>
                              //     <p className={classes.details} style={{ color:"#8e969e", marginBottom: isShowingInForecastingViewPopup ?"12px":"8px"}}>Label : </p>
                              //     <p className={classes.details}>{resource?.label}</p>
                              // </div>}
                              }
                              {/* // resourceType === "audio" && */}

                              {
                                resourceType === "bookTranslation" &&
                                <div>
                                  <p className={classes.headingStylesForAdditionalDataPopupForBookTranslationModel} style={{ color:"#8e969e", marginBottom: isShowingInForecastingViewPopup ?"12px": isVideoAnnotationViewPopup || resourceType === "bookTranslation" ? "20px": "8px"}}>Language : </p>
                                  <p className={classes.details} >{(props?.resource as IData)?.textOriginalLanguage}<ArrowForwardIcon style={{ height: "12px"}}/>{(props?.resource as IData)?.textTranslatedLanguage}</p>
                                </div>
                              }
                              
                              {
                                resourceType !== 'videoAnnotation'  &&
                                <TagComponent 
                                  isVideoAnnotationViewPopup={isVideoAnnotationViewPopup ?? false}
                                  setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents} 
                                  tag={resource?.tag}
                                  resource={resource}
                                  isShowingInForecastingViewPopup={isShowingInForecastingViewPopup}
                                  inCopilot={inCopilot}
                                />
                              }

                              <div>
                                  <p className={resourceType === 'bookTranslation' ? classes.headingStylesForAdditionalDataPopupForBookTranslationModel : classes.details} style={{ color:"#8e969e", marginBottom: isShowingInForecastingViewPopup ?"12px": isVideoAnnotationViewPopup || resourceType === "bookTranslation" ? "20px": "8px"}}>Created On   : </p>
                                  {/* <p className={classes.details} >{moment(resource?.created_at || "").format('MMMM Do YYYY')}</p> */}
                                  <p className={classes.details} >{convertDate(resource?.created_at || "")}</p>
                              </div>
                              {(resourceType !== "imageAnnotation" && resourceType!== 'textAnnotation' && resourceType!== 'DLP' && resourceType !== 'videoAnnotation' && resourceType !== undefined) && 
                              <div>
                                  <p className={resourceType === 'bookTranslation' ? classes.headingStylesForAdditionalDataPopupForBookTranslationModel : classes.details} style={{ color:"#8e969e", marginBottom: isShowingInForecastingViewPopup ?"12px" : resourceType === "bookTranslation"? "20px" :"8px"}}>Confidence Score : </p>
                                  <p className={classes.details} >{confidenceScore}%</p>
                              </div>
                              }
                              <div>
                                  <p className={resourceType === 'bookTranslation' ? classes.headingStylesForAdditionalDataPopupForBookTranslationModel : classes.details} style={{ color:"#8e969e", marginBottom: isShowingInForecastingViewPopup ?"12px": isVideoAnnotationViewPopup || resourceType === "bookTranslation" ? "20px": "8px"}}>Last Modified : </p>
                                  <p className={classes.details} >{convertDate(resource?.statusLastModifiedAt || "")}</p>
                              </div>

                              <div
                                  style={{
                                      display: "flex",
                                      flexDirection: isEditingMetaData?
                                      "column":"row"
                                  }}
                              >
                                <div className={resourceType === 'bookTranslation' ? 
                                classes.headingStylesForAdditionalDataPopupForBookTranslationModel 
                                : classes.details} 
                                style={{ color:"#8e969e", 
                                marginBottom: isShowingInForecastingViewPopup ?
                                "12px"
                                : isVideoAnnotationViewPopup || 
                                resourceType === "bookTranslation" ? "20px": "8px",
                                display: "flex",
                                flexDirection: "row"
                                }}>
                                  <p style={{ marginBottom: 0}}>Metadata:</p>
                                 {!isEditingMetaData && props.scenario !== 'aiMarketPlaceDataCart' && props.scenario !== 'aiMarketplaceDataSelection'
                                  &&
                                  <IconButton
                                  onClick={handleToggleEditingMetaData}
                                   style={{padding: 0, marginLeft: 10, height: 24}}>
                                    <EditIcon/>
                                  </IconButton>}
                                  </div>
                                  {isEditingMetaData &&
                                  <div>
                                   <textarea 
                                   style={{width: "100%", minHeight: "83px", marginBottom: "10px", fontSize: '15px', fontFamily: 'Nunito Sans', borderRadius: "5px"}}
                                   value={metadataValue}
                                   onChange={handleChangeMetadata}
                               /> 
                               <div style={{ display: "flex", flexDirection: "row", gap: 10}}>
                                <Button onClick={handleCancelChangeMetadata} variant="outlined" color="primary">
                                  Cancel
                                </Button>
                                <Button variant="contained" color="primary"
                                onClick={handleSaveChangesMetaData}
                                disabled = {metadataValue===props.resource?.csv?true:false}
                                >
                                  Save
                                  {
                                    isSavingMetadataValue &&
                                    <CircularProgress style={{ marginLeft: 10 ,width: 16, height: 16, color: "white"}} />
                                  }
                                </Button>
                               </div>
                                </div>}
                                  {!isEditingMetaData&&<p
                                      className={classes.details}
                                      style={{
                                          display: "flex",
                                          flexDirection: csvExpandedFormShowing ? "column" : "row",
                                          width: "auto",
                                          overflow: "hidden",
                                          whiteSpace: "normal",
                                          textOverflow: "ellipsis",
                                          wordWrap: "break-word",
                                          maxWidth: "240px",                          
                                      }}
                                  >
                                      {
                                          resource?.csv?.length || 0 > csvInitialMaxLengthLimit
                                          ? csvExpandedFormShowing
                                              ? <><ShowLessLink color={isVideoAnnotationViewPopup ? themePrimaryMainColor : undefined} onClick={()=>setCsvExpandedFormShowing(false)} /> {" "} <span dangerouslySetInnerHTML={{__html: resource?.csv || ""}} /> {"  "} </>
                                              : <span dangerouslySetInnerHTML={{__html: resource?.csv?.substring(0, csvInitialMaxLengthLimit)+"..."}} />
                                          : <span dangerouslySetInnerHTML={{__html: resource?.csv || ""}} />
                                      }
                                      {
                                          resource?.csv?.length || 0 > csvInitialMaxLengthLimit
                                          ? csvExpandedFormShowing
                                              ?   (
                                                  <ShowLessLink color={isVideoAnnotationViewPopup ? themePrimaryMainColor : undefined} onClick={()=>setCsvExpandedFormShowing(false)} />
                                              )
                                              :   (
                                                  <ShowMoreLink color={isVideoAnnotationViewPopup ? themePrimaryMainColor : undefined} onClick={()=>setCsvExpandedFormShowing(true)} />
                                              )
                                          : null
                                      }                    
                                  </p>}
                              </div>
                                  {
                                  props.resource?.metadataFileName &&
                                  <div
                                    style={{display: "flex"}}
                                  >
                                    <div
                                      className={classes.details}
                                      style={{
                                        color: "rgb(142, 150, 158)",
                                        display: "flex"
                                      }}
                                    >
                                      Metadata File:
                                    </div>
                                    <div
                                      className={classes.details}
                                      style={{
                                        display: "flex"
                                      }}
                                    >
                                      <div>{props.resource?.metadataFileName}</div>
                                      <GetAppRoundedIcon color="primary"
                                        style={{
                                          cursor: "pointer"
                                        }} 
                                        onClick={()=>{
                                          if (isFetchingResourceMetadataFileUrl) {
                                            return;
                                          }
                                          window.open(resourceMetadataFileDownloadUrl, "_blank");
                                        }}
                                      />
                                    </div>
                                  </div>

                                }                                  
                              {resource?.status === "approved" && 
                                <div style={{ display: "flex", marginTop: "2px"}}>
                                  <div>
                                    <p className={classes.details} style={{ marginTop: "6px", color:"#8e969e", marginBottom: isShowingInForecastingViewPopup ?"12px": isVideoAnnotationViewPopup ? "20px": "8px"}} >Data-Set Collections:</p>
                                  </div>
                                  <div>
                                  <p className={classes.details} style={{whiteSpace: 'normal', maxWidth: "193px", maxHeight: "114px", overflow: "auto"}}>
                                      {
                                          dataSetCollections?.map(dataSetCollection=>{
                                              return (
                                                  <Chip
                                                  key={dataSetCollection._id}
                                                  label={dataSetCollection.name}
                                                  style={{ color:"#ffffff", backgroundColor:"#008D9C"}}
                                                />
                                              )
                                          })
                                      }
                                  </p>
                                  </div>
                                </div>
                              }
                              {!inCopilot  && 
                                <div style={{
                                      display: "flex"
                                  }}>
                                  <p className={resourceType === 'bookTranslation' ? classes.headingStylesForAdditionalDataPopupForBookTranslationModel : classes.details} style={{ color:"#8e969e", maxWidth:"100px", marginBottom: isVideoAnnotationViewPopup || resourceType === "bookTranslation" ? "20px" : "0px"}}>Change Log:</p>
                                  <p className={classes.details} 
                                  style={{
                                          display: "flex",
                                          flexDirection:"column",
                                          width: "auto",
                                          overflow: "hidden",
                                          whiteSpace: "normal",
                                          textOverflow: "ellipsis",
                                          wordWrap: "break-word",
                                          maxWidth: "240px",                          
                                      }}>
                                        <div>
                                          { changesMade !== undefined && changesMade.length > 0 ? isChangesMadeExpanded 
                                          ? <> <ShowLessLink color={isVideoAnnotationViewPopup ? themePrimaryMainColor : undefined} onClick={()=>setIsChangesMadeExpanded(false)} />
                                          { changesMade !== undefined &&(
                                              [...changesMade]?.reverse()?.map(changeMade=>{
                                                  return (
                                                      <div style={{paddingBottom:"10px"}}>
                                                          <div> 
                                                              {changeMade?.change} by {changeMade?.initiatedByEntity?.id || changeMade?.initiatedBy?.emailId} {changeMade?.initiatedByEntity?.type ? `having type ${changeMade?.initiatedByEntity?.type} ` : ""}on {moment(changeMade?.created_at).format(`hh:mm A DD-MMM-YYYY`)}
                                                          </div>
                                                      </div>
                                                  )
                                              }))
                                          }
                                          <ShowLessLink color={isVideoAnnotationViewPopup ? themePrimaryMainColor : undefined} onClick={()=>setIsChangesMadeExpanded(false)} />
                                           </>
                                      : <div style={{display: 'flex'}}> <p style={{padding:"0px 5px"}}>{changesMade[0].change.substring(0,changesMadeInitialMaxLengthLimit)}...</p> <ShowMoreLink color={isVideoAnnotationViewPopup ? themePrimaryMainColor : undefined} onClick={()=>setIsChangesMadeExpanded(true)} /></div> : null}
                                      </div>
                                  </p>
                              </div>}
                              </div>
  )
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}


const ReferenceImageHTML = (props: {modelReferenceData: ImageRelatedModelReferenceData[]}) => {

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOpenFullScreenImage = (file: ImageRelatedModelReferenceData) => {
      setAnchorEl(document.getElementById("reference-image-container"));
      setImageSelectedForFullScreenView(file)
      setIsHideViewFullScreenPopOver(false)
  };

  const [imageSelectedForFullScreenView, setImageSelectedForFullScreenView] = useState<ImageRelatedModelReferenceData | undefined>()
  const [imageMagnifyPercentage, setImageMaginfyPercentage] = useState<number>(1)
  const [isHideViewFullScreenPopOver, setIsHideViewFullScreenPopOver] = useState(false)
  const handleCloseFullScreenImage = () => {
    console.log("close popover")
      setIsHideViewFullScreenPopOver(true)
  };

  //   const canvasWidth = 0;
  //   const canvasHeight = 0;
  //   const imageWidthToSet = () => {
  //         return window.innerWidth - 150;
  //   }

  // const imageHeightToSet = () => {
  // return 0.48 * window.innerHeight;
  // }


  //   const imageHeightToSetRef = useRef(imageHeightToSet);
  //   const imageWidthToSetRef = useRef(imageWidthToSet);	


  const open = Boolean(anchorEl);
  const id = open ? 'draggable-dialog-title' : undefined;
  //       useEffect(async () => {
  //         const onloadPromise = new Promise((resolve, reject) => {
  //             const image = new Image();
  //             image.onload = function () {
  //                 resolve(image)
  //             };
  //             image.src = imageSelectedForFullScreenView?.fileUrl || "";
  //         })

  //         const image = await onloadPromise;

  //         imageWidthToSetRef.current = imageWidthToSet;
  //         imageHeightToSetRef.current = imageHeightToSet;	

  //         let differenceBetweenCanvasAndImageToSet = 0;
  //         let canvasHeightToSet = imageHeightToSetRef.current;
  //         let canvasWidthToSet = imageWidthToSetRef.current;	

  //         const imageAspectRatio = image.width/ image.height

  //         if (image.width > image.height)
  //   {
  //     canvasWidthToSet = imageWidthToSetRef.current;
  //     canvasHeightToSet = ImageService.getImageHeight(
  //       canvasWidthToSet,
  //       imageAspectRatio
  //     )
  //     if (canvasHeightToSet > imageHeightToSetRef.current) {
  //       canvasHeightToSet = imageHeightToSetRef.current;
  //       canvasWidthToSet = ImageService.getImageWidth(
  //         canvasHeightToSet,
  //         imageAspectRatio
  //       );
  //     }
  //   }
  //   else if (image.height > image.width) {
  //     canvasHeightToSet = imageHeightToSetRef.current;
  //     canvasWidthToSet = ImageService.getImageWidthHeightFromURL(
  //       canvasHeightToSet,
  //       imageAspectRatio
  //     );
  //     if (canvasWidthToSet > imageWidthToSetRef.current) {
  //       canvasWidthToSet = imageWidthToSetRef.current;
  //       canvasHeightToSet = ImageService.getImageHeight(
  //         canvasWidthToSet,
  //         imageAspectRatio
  //       )
  //     }
  //   }

  //       }, [imageSelectedForFullScreenView])  
  

  
    useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
          if (event.key === 'r' || event.key === 'R') {

            const focusedElement = document.activeElement;
            if (
              focusedElement?.tagName?.toLowerCase() === "textarea" ||
              (focusedElement?.tagName?.toLowerCase() === "input" &&
              // @ts-ignore
              (focusedElement?.type?.toLowerCase() === "number" || focusedElement?.type?.toLowerCase() === "text")))
            {
              return;
            }

            // Your code to handle the "R" key press goes here
            console.log("key pressed")
            if(imageSelectedForFullScreenView)
              setIsHideViewFullScreenPopOver(prevState => !prevState)
          else{
              // window.alert("No Reference Image selected")
          }
          }
        };

      window.addEventListener('keydown', handleKeyPress);
      
  
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [imageSelectedForFullScreenView]);        

    const [contentSize, setContentSize] = useState({ width: 'auto', height: 'auto' });

    console.log("contentSize", contentSize, props.modelReferenceData)

    // Function to measure the content size
    const measureContentSize = () => {
      const content = document.querySelector('.content-measure'); // Replace with your own selector
      if (content instanceof HTMLElement) {
        setContentSize({
          width: `${content.offsetWidth + 100}px`,
          height: `${content.offsetHeight+ 150}px`,
        });
      }
    };
  
    useEffect(() => {
      measureContentSize();
    }, [imageMagnifyPercentage]);
  
  return (
      <div
          id="reference-image-container"
          style={{
              width: "400px",
              height: "420px",
              overflow: "auto"
          }}
      >
          {
              props.modelReferenceData?.map(imageReferenceData => {
                  return (
                      <div
                      style={{ display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      padding: 10,
                      backgroundColor: imageSelectedForFullScreenView===imageReferenceData? "#dfffff": "white"
                  }}
                      >
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "flex-end",
                              }}
                          >
                              <Tooltip title={imageReferenceData.fileName}>
                                  <ImageAspectRatioScaler
                                      imageUrl={imageReferenceData.fileUrl}
                                      maxImageHeightAllowed={290}
                                      maxImageWidthAllowed={290}
                                      doNotShowSkeleton={true}
                                  />
                              </Tooltip>
                              <Tooltip title={"select an image an then press R to open and close FullSreen Mode"}>
                              <Button
                                  size="small"
                                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                      event.preventDefault()
                                      handleOpenFullScreenImage(imageReferenceData)
                                  }}
                                  style={{ 
                                      display:"flex",
                                      flexDirection:"row",
                                      justifyContent: "center",
                                      alignItems: "center"
                                  }}
                              >
                                  <FullscreenIcon /> <p style={{fontSize: "16px", margin: "auto"}}> &#123; R &#125;</p>
                              </Button>
                              </Tooltip>
                          </div>
                      </div>
                  )
              })
          }
              <Dialog
              style={{
                  display: isHideViewFullScreenPopOver?"none":"flex",
              }}
              maxWidth={"lg"}
              fullWidth={true}
                  id={id}
                  open={open}
                  // anchorEl={anchorEl}
                  // PaperComponent={PaperComponent}
                  onClose={handleCloseFullScreenImage}
                  PaperComponent={PaperComponent}
                  PaperProps={{ style: contentSize }}
                  aria-labelledby="draggable-dialog-title"
              >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <div>{imageSelectedForFullScreenView&&imageSelectedForFullScreenView.fileName}</div>
                </DialogTitle>
                <DialogContent>

                  <div
                      style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center"
                      }}
                  >
                      {
                          imageSelectedForFullScreenView &&
                          <>
                              <div className="content-measure"
                              >
                                  <img src={imageSelectedForFullScreenView.fileUrl} width={800 * imageMagnifyPercentage > window.innerWidth-100 ?
                                      window.innerWidth-100 : 800 * imageMagnifyPercentage
                                  } alt="reference image" />
                              </div>
                          </>
                      }
                      <div
                          style={{ width: "200px" }}
                      >
                          <Slider
                              value={imageMagnifyPercentage}
                              min={1}
                              max={1.75}
                              color="primary"
                              step={0.25}
                              marks={[
                                  { value: 1, label: "1x" },
                                  { value: 1.25, label: "1.25x" },
                                  { value: 1.5, label: "1.5x" },
                                  { value: 1.75, label: "1.75x" }
                              ]}

                              // @ts-ignore
                              onChange={(e, value: number) => {
                                  setImageMaginfyPercentage(value)
                              }}

                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                          />
                      </div>
                  </div>
                  </DialogContent>
              </Dialog>
      </div>
  )
}


export function ImageKeyPointsEditDialog(props: IProps) {
  const { open,onSwitch, annotationOption, resourceRemarksTextState, setResourceRemarksTextState: setResourceRemarksTextState, modelOrCopilotType, onSaveRemarksClick, alert, alertMessage , confidenceScore, dataSetCollections, changesMade, isShowingInSideDrawer, inCopilot} = props;
  
  const classes = useStyles();
  const theme = useTheme();

  const [isFocusMode, setIsFocusMode] = useState(false);

  const [isViewingReferenceImage, setIsViewingReferenceImage] = useState(false);

  
 
  const resource = props.resource
 //MultipleImageAnnotation
    // const [currentIndex, setCurrentIndex] = useState(0);

    const currentIndex = props.multipleImageAnnotationResourceImageIndex;
    const setCurrentIndex = props.setCurrentMultipleImageAnnotationResourceImageIndex;

    useEffect(() => {
      setCurrentIndex(0);
    }, []);

    useEffect(() => {
      // pre-downloading next files so that user will not have to wait
      if (!isNullOrUndefined(currentIndex)) {
        const nextFile = props.resource?.files?.[currentIndex+1];
        if (nextFile?.fileUrl) {
          const image = new Image();
          image.src = nextFile.fileUrl;
          image.onload=function(){}
        }
      }
      if (!isNullOrUndefined(currentIndex+1)) {
        const nextFile = props.resource?.files?.[currentIndex+2];
        if (nextFile?.fileUrl) {
          const image = new Image();
          image.src = nextFile.fileUrl;
          image.onload=function(){}
        }
      }
      if (!isNullOrUndefined(currentIndex+2)) {
        const nextFile = props.resource?.files?.[currentIndex+3];
        if (nextFile?.fileUrl) {
          const image = new Image();
          image.src = nextFile.fileUrl;
          image.onload=function(){}
        }
      }
      if (!isNullOrUndefined(currentIndex+3)) {
        const nextFile = props.resource?.files?.[currentIndex+4];
        if (nextFile?.fileUrl) {
          const image = new Image();
          image.src = nextFile.fileUrl;
          image.onload=function(){}
        }
      }
    
    }, [currentIndex, props.resource?.files])
    
    

    const file=resource.files?.[currentIndex];
  
    const handleNavigation = (direction: number) => {
      if (resource?.files && resource?.files.length) {
        const newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < resource?.files.length) {
          setCurrentIndex(newIndex);
        }
       
      }
    };
    const showPrevious = currentIndex > 0;
    const showNext = resource?.files && currentIndex < (resource?.files.length - 1);
    
  // const file=resource?.files;


  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [dataSetCollectionsFieldState, setDataSetCollectionsFieldState] =
    // useState<InputFieldState<string[]>>({ value: [] });
  const [hasImageScalingCompleted, setHasImageScalingCompleted] = useState(false);
  const [resourcesForWhichImageScalingGoingOn, setResourcesForWhichImageScalingGoingOn] = useState<string[]>([])

  // const [resource, setResource] = useState(props.resource);

  const previousResourceId = usePreviousValue(props.resource?._id || "");

  const isResourceIdChanged = (()=>{
    if (props.resource?._id !== previousResourceId) {
      return true;
    }
    return false;
  })();

  useEffect(()=>{
    return ()=>{
      console.log("imageKeyPoints destroyed")
    }
  }, [])

  const isResourceIdChangedRef = useRef(isResourceIdChanged);
  isResourceIdChangedRef.current = isResourceIdChanged;

  const propsResourceRef = useRef(props.resource);
  propsResourceRef.current = props.resource;

  const editedAnnotationsRef = useRef<ResourceImageAnnotation[]>();
  const editedGroupAnnotationsRef = useRef<ResourceImageGroupAnnotation[]>();

  const [imageAnnotationsForTwoDimensionalImage, setImageAnnotationsForTwoDimensionalImage] = useState<ResourceImageAnnotation[]>([]);
  const [multipleImageAnnotationsForTwoDimensionalImage, setMultipleImageAnnotationsForTwoDimensionalImage] = useState<ResourceImageAnnotation[]>([]);
  const [imageGroupAnnotationsForTwoDimensionalImage, setImageGroupAnnotationsForTwoDimensionalImage] = useState<ResourceImageGroupAnnotation[]>([]);


  useEffect(() => {
    editedAnnotationsRef.current = undefined;
    editedGroupAnnotationsRef.current = undefined;
  }, [propsResourceRef.current?._id, currentIndex])
  

  // const [csvExpandedFormShowing, setCsvExpandedFormShowing] = useState<boolean>(false);
  // const confidence_score_IData = resource.;
  // console.log("c o n f i d e n c e s c o r e : ", props.setShouldBlockDataTableKeyboardEvents);
  // const [alert, setAlert] = useState("none");
  // const [alertMessage, setAlertMessge] = useState("none");

  const offsetQueryParamKey = useMemo(()=>{
    if (props.inCopilot) {
      return "offset"
    }
    return ResourceOffsetQueryParam
  }, [props.inCopilot]);

  const limitQueryParamKey = useMemo(()=>{
    if (props.inCopilot) {
      return "limit"
    }
    return ResourceLimitQueryParam
  }, [props.inCopilot]);

  /*
  const [
    confirmButtonClickProcessHappening,
    setConfirmButtonClickProcessHappening,
  ] = useState(false);
  */

  const [showLeftArrow, setShowLeftArrow] = useState(true);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const isWindowMax720PXWidthMatches = useMediaQuery('@media (max-width:720px)');
  const urlQuery = useURLQuery();
  const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);
  const fullScreenModeRef = useRef(fullScreenMode);
  fullScreenModeRef.current = fullScreenMode;
  console.log("fullscreen mode ::: ",fullScreenMode, window.innerWidth, window.innerHeight);  
  const [expandImageAnnotationAspectScreen, setExpandImageAnnotationAspectScreen] = useState<boolean>(false);

  const imageWidthToSet = useMemo(() => {
    if (fullScreenMode && !isFocusMode) {
      if(window.innerWidth > 1280){
        return 1130
      }
      else{
        return window.innerWidth - 150;
      }
    } else if (isFocusMode && !fullScreenMode) {
      return window.innerWidth / 2;
      if(window.innerWidth > 1280){
        return 1120
      } else {
        return window.innerWidth - 150;
      }
    } else if (isFocusMode && fullScreenMode) {
      if(window.innerWidth > 1280){
        return 1130
      }
      else{
        return window.innerWidth - 150;
      }
    }
    return 600;
  }, [fullScreenMode, isFocusMode])

  const imageHeightToSet = useMemo(() => {
    if (fullScreenMode && !isFocusMode) {
      return 0.8 * window.innerHeight
    } else if (!fullScreenMode && isFocusMode) {
      return 0.83 * window.innerHeight
    } else if (fullScreenMode && isFocusMode) {
      return 0.8 * window.innerHeight
    }
    return 0.4 * window.innerHeight;
  }, [fullScreenMode, isFocusMode])
  const imageHeightToSetRef = useRef(imageHeightToSet);
  

  const imageWidthToSetRef = useRef(imageWidthToSet);

  

  // if(fullScreenMode){
  //   imageWidthToSetRef.current = imageWidthToSet;
  //   imageHeightToSetRef.current = imageHeightToSet;
  // }

  // useEffect(() => {
  //   imageWidthToSetRef.current = imageWidthToSet;
  //   imageHeightToSetRef.current = imageHeightToSet;
  // },[isResourceIdChangedRef.current])
  // imageWidthToSetRef.current = imageWidthToSet;

  const fullScreenModePreviousValue = usePreviousValue(fullScreenMode);
  const isFullScreenModeChanged = (() => {
    if (fullScreenMode !== fullScreenModePreviousValue) {
      return true
    }
    return false;
  })();
  const isFullScreenModeChangedRef = useRef(isFullScreenModeChanged);
  isFullScreenModeChangedRef.current = isFullScreenModeChanged;

  const handleClose = () => {
    props.onClose();
  };

  const addOrRemoveArrows = (index: number) => {
    const OffsetValue: string = urlQuery.get(offsetQueryParamKey) as string;;
    const limit: string = urlQuery.get(limitQueryParamKey) as string;;
    const globalCurrentRowValue = ((Number(OffsetValue) - 1) * Number(limit)) + (index + 1);
    if(globalCurrentRowValue === 1){
        setShowLeftArrow(false)
    }
    else{
        setShowLeftArrow(true)
    }
    if(globalCurrentRowValue === props.totalCount){
        setShowRightArrow(false)
    }
    else{
        setShowRightArrow(true)
    }
}

  useEffect(() => {
    if(props.resources.length > 0 && props.scenario !== 'aiMarketPlaceDataCart'){
      for (let i=0 ; i<props.resources.length; i++) {
        if (props.resources[i]._id === props.resource?._id) {
            addOrRemoveArrows(i)
        }
      }
    }
    if(props.scenario === 'aiMarketPlaceDataCart'){
      setShowLeftArrow(false)
      setShowRightArrow(false)
    }
    // setResource(props.resource);
  }, [props.resource, props.scenario]);


   const {image: originalImage, imageLoadStatus: originalImageLoadStatus} = useImageOnLoad(
    props.resource?.resourceMetaData?.width && props.resource?.resourceMetaData?.height && props.resource?.resource500WUrl && props.resource.resource500WMetaData?.width && props.resource.resource500WMetaData?.height
    ? props.resource.resource
    : ""
   );

  const isShowingCompressedImageBecauseOriginalImageIsDownloading = (()=>{
    if (originalImageLoadStatus === "loading") {
      return true;
    }
    return false;
  })();

  const isShowingCompressedImageBecauseOriginalImageIsDownloadingRef = useRef(isShowingCompressedImageBecauseOriginalImageIsDownloading);
  isShowingCompressedImageBecauseOriginalImageIsDownloadingRef.current = isShowingCompressedImageBecauseOriginalImageIsDownloading;

  useEffect(()=>{
  
    const resource = props.resource;
   
    const executeFunction = async () => {
      if (props?.fileIdForMultipleImageAnnotationUpdate) {
        props.fileIdForMultipleImageAnnotationUpdate(file?._id || "");
      }
      setHasImageScalingCompleted(false);
      setResourcesForWhichImageScalingGoingOn(oldState=>[...oldState, resource?._id.toString() || ""])

      let isShowingCompressedImageBecauseOriginalImageIsDownloading = isShowingCompressedImageBecauseOriginalImageIsDownloadingRef.current;
      let image: {
        width: number,
        height: number
      } | undefined = undefined;

      if (isShowingCompressedImageBecauseOriginalImageIsDownloading && resource.resource500WUrl && resource.resource500WMetaData?.width && resource.resource500WMetaData?.height) {
        image = {
          width: resource.resource500WMetaData.width,
          height: resource.resource500WMetaData.height,
        }
      } else if (false && resource.resourceMetaData?.width && resource.resourceMetaData?.height) {
        // @ts-ignore
        image = {
          // @ts-ignore
          width: resource.resourceMetaData.width,
          // @ts-ignore
          height: resource.resourceMetaData.height,
        }
      } else {
        if(modelOrCopilotType==="multipleImageAnnotation")
        {
          image = await ImageService.getImageWidthHeightFromURL(file?.fileUrl || "");
       
        }
        else{
        image = await ImageService.getImageWidthHeightFromURL(resource?.resource || "");
       
        }
        if (resource._id !== propsResourceRef.current._id) {
          /**
           * if selcted resource got changed, then no point of continuing
           */
          return;
        }
      }

      const imageAspectRatio = image.width / image.height;
    
      imageWidthToSetRef.current = imageWidthToSet;
      imageHeightToSetRef.current = imageHeightToSet;

      // let maxWidthAndHeightAllowed = 0;

      // if (imageWidthToSetRef.current > imageHeightToSetRef.current) {
      //   maxWidthAndHeightAllowed = imageHeightToSetRef.current
      // } else {
      //   maxWidthAndHeightAllowed = imageWidthToSetRef.current
      // }      


      let differenceBetweenCanvasAndImageToSet = 0;
      let canvasHeightToSet = imageHeightToSetRef.current;
      let canvasWidthToSet = imageWidthToSetRef.current;

      // if (image.width > image.height)
      // {
      //   differenceBetweenCanvasAndImageToSet = image.width/ imageWidthToSetRef.current ;
      //   canvasHeightToSet = image.height / differenceBetweenCanvasAndImageToSet ;
      //   canvasWidthToSet = imageWidthToSetRef.current;
      // } 
      // else if (image.height > image.width) {
      //   differenceBetweenCanvasAndImageToSet = image.height / imageHeightToSetRef.current;
      //   canvasWidthToSet = image.width / differenceBetweenCanvasAndImageToSet;
      //   canvasHeightToSet = imageHeightToSetRef.current;
      // }

      // if(canvasHeightToSet > imageHeightToSetRef.current){
      //   differenceBetweenCanvasAndImageToSet = canvasHeightToSet / imageHeightToSetRef.current ;
      //   canvasWidthToSet = canvasWidthToSet / differenceBetweenCanvasAndImageToSet ;
      //   canvasHeightToSet = imageHeightToSetRef.current;
      // }


      if (image.width > image.height)
      {
        canvasWidthToSet = imageWidthToSetRef.current;
        canvasHeightToSet = ImageService.getImageHeight(
          canvasWidthToSet,
          imageAspectRatio
        )

        if (canvasHeightToSet > imageHeightToSetRef.current) {
          canvasHeightToSet = imageHeightToSetRef.current;
          canvasWidthToSet = ImageService.getImageWidth(
            canvasHeightToSet,
            imageAspectRatio
          );
        }         
      } 
      else if (image.height > image.width) {
        canvasHeightToSet = imageHeightToSetRef.current;
        canvasWidthToSet = ImageService.getImageWidth(
          canvasHeightToSet,
          imageAspectRatio
        );

        if (canvasWidthToSet > imageWidthToSetRef.current) {
          canvasWidthToSet = imageWidthToSetRef.current;
          canvasHeightToSet = ImageService.getImageHeight(
            canvasWidthToSet,
            imageAspectRatio
          )
        }
      }

      if (isFocusMode) {
        imageWidthToSetRef.current = canvasWidthToSet;
      }

      if(props.imageExpandFeatureEnabled){
        imageWidthToSetRef.current = canvasWidthToSet
      }
      let imageScaleFactor = getImageScaleFactorForImageWidth(
          image.width,
          imageWidthToSetRef.current
      );

      imageWidthToSetRef.current = canvasWidthToSet;

      let resourceImageGroupAnnotations = (()=>{
        // if (editedGroupAnnotationsRef.current || propsResourceRef.current.imageGroupAnnotations) {
        if (resource.imageGroupAnnotations) {
        // if (editedGroupAnnotationsRef.current) {
          return [...resource.imageGroupAnnotations || []];
        }
        return [];
      })();

      if (isShowingCompressedImageBecauseOriginalImageIsDownloading && propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height && propsResourceRef.current?.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {

        /**
         * Converting imageGroupAnnotations vertices as per compressedImage width and height
         */

        resourceImageGroupAnnotations = setResourceImageGroupAnnotationsOfOriginalImageForCompressedImage(
          resourceImageGroupAnnotations,
          propsResourceRef.current?.resourceMetaData?.width,
          propsResourceRef.current?.resourceMetaData?.height,
          propsResourceRef.current?.resource500WMetaData?.width,
          propsResourceRef.current?.resource500WMetaData?.height
        )
      }

      const imageGroupAnnotationsToSet = 
        (modelOrCopilotType === "imageAnnotationGroup")
        ? setResourceImageGroupAnnotationsForSendingToAnnotationComponent(
            resourceImageGroupAnnotations,
            imageScaleFactor
          ) 
        : [];

      let resourceImageAnnotations = (()=>{
        if (resource.imageAnnotations) {
          return [...resource.imageAnnotations];
        }
        return [];
      })();
      
      
      let filesImageAnnotations = (()=>{
        if (file?.imageAnnotations) {
          return [...file.imageAnnotations];
       
        }
        return [];
      })();
  
      if (isShowingCompressedImageBecauseOriginalImageIsDownloading && propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height && propsResourceRef.current?.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {

        /**
         * Converting imageAnnotations vertices as per compressedImage width and height
         */

         resourceImageAnnotations = setResourceImageAnnotationsOfOriginalImageForCompressedImage(
          resourceImageAnnotations,
          propsResourceRef.current?.resourceMetaData?.width,
          propsResourceRef.current?.resourceMetaData?.height,
          propsResourceRef.current?.resource500WMetaData?.width,
          propsResourceRef.current?.resource500WMetaData?.height          
         )
        
      }

      const imageAnnotationsToSet = 
        modelOrCopilotType === "imageAnnotation"
        ? setResourceImageAnnotationsForSendingToAnnotationComponent(
            resourceImageAnnotations,
            imageScaleFactor
          )
        : modelOrCopilotType === "multipleImageAnnotation"
        ? setResourceImageAnnotationsForSendingToAnnotationComponent(
          filesImageAnnotations,
            imageScaleFactor
          ): []

        setImageGroupAnnotationsForTwoDimensionalImage([...imageGroupAnnotationsToSet]);
        setImageAnnotationsForTwoDimensionalImage([...imageAnnotationsToSet]);
        setMultipleImageAnnotationsForTwoDimensionalImage([...imageAnnotationsToSet])
        
        editedAnnotationsRef.current = undefined;
        editedGroupAnnotationsRef.current = undefined;

      // @ts-ignore
      // setResource(oldState=>{return {
      //     ...oldState,
      //     imageGroupAnnotations: [...imageGroupAnnotationsToSet],
      //     imageAnnotations: [...imageAnnotationsToSet]
      // }})

      setResourcesForWhichImageScalingGoingOn(oldState=>{
        const newArray = [...oldState];
        for (let index = 0; index < newArray.length; index++) {
            const resourceId = newArray[index];
            if (resourceId === resource?._id) {
                newArray.splice(index, 1);
                break;
            }
        }
        return [...newArray]
    })        
      setHasImageScalingCompleted(true);      
    }
   
    executeFunction();
  }, [props.resource?.resource, props.resource?._id,currentIndex]) 
  
  useEffect(() => {

    if (isResourceIdChangedRef.current) {
      return;
    }

    const executeFunction = async () => {
      const resource = propsResourceRef.current;

      if (props?.fileIdForMultipleImageAnnotationUpdate) {
        props.fileIdForMultipleImageAnnotationUpdate(file?._id || "");
      }      
  
      let image: {
        width: number,
        height: number
      } | undefined = undefined;   
      
      if (isShowingCompressedImageBecauseOriginalImageIsDownloading && resource.resource500WUrl && resource.resource500WMetaData?.width && resource.resource500WMetaData?.height) {
        image = {
          width: resource.resource500WMetaData.width,
          height: resource.resource500WMetaData.height,
        }
      } else if (false && resource.resourceMetaData?.width && resource.resourceMetaData?.height) {
        // image = {
        //   width: resource.resourceMetaData.width,
        //   height: resource.resourceMetaData.height,
        // }
      } else {
        if(modelOrCopilotType==="multipleImageAnnotation")
        {
          image = await ImageService.getImageWidthHeightFromURL(file?.fileUrl || "");
        }
        else{
          image = await ImageService.getImageWidthHeightFromURL(resource?.resource || "");
        }

        if (resource._id !== propsResourceRef.current._id) {
          return;
        }        
      }      

      const imageAspectRatio = image.width / image.height;      

      imageWidthToSetRef.current = imageWidthToSet;
      console.log('debug executeFunction ~ focusMode imageWidthToSetRef.current:', imageWidthToSetRef.current)
      imageHeightToSetRef.current = imageHeightToSet;
      console.log('debug executeFunction ~ focusMode imageHeightToSetRef.current:', imageHeightToSetRef.current)

      // let maxWidthAndHeightAllowed = 0;

      // if (imageWidthToSetRef.current > imageHeightToSetRef.current) {
      //   maxWidthAndHeightAllowed = imageHeightToSetRef.current
      // } else {
      //   maxWidthAndHeightAllowed = imageWidthToSetRef.current
      // }

      let differenceBetweenCanvasAndImageToSet = 0;
      let canvasHeightToSet = imageHeightToSetRef.current;
      let canvasWidthToSet = imageWidthToSetRef.current;

      // if (image.width > image.height)
      // {
      //   differenceBetweenCanvasAndImageToSet = image.width/ imageWidthToSetRef.current ;
      //   canvasHeightToSet = image.height / differenceBetweenCanvasAndImageToSet ;
      //   canvasWidthToSet = imageWidthToSetRef.current;
      // } 
      // else if (image.height > image.width) {
      //   differenceBetweenCanvasAndImageToSet = image.height / imageHeightToSetRef.current;
      //   canvasWidthToSet = image.width / differenceBetweenCanvasAndImageToSet;
      //   canvasHeightToSet = imageHeightToSetRef.current;
      // }

      // if(canvasHeightToSet > imageHeightToSetRef.current){
      //   differenceBetweenCanvasAndImageToSet = canvasHeightToSet / imageHeightToSetRef.current ;
      //   canvasWidthToSet = canvasWidthToSet / differenceBetweenCanvasAndImageToSet ;
      //   canvasHeightToSet = imageHeightToSetRef.current;
      // }

      if (image.width > image.height)
      {
        canvasWidthToSet = imageWidthToSetRef.current;
        canvasHeightToSet = ImageService.getImageHeight(
          canvasWidthToSet,
          imageAspectRatio
        )

        if (canvasHeightToSet > imageHeightToSetRef.current) {
          canvasHeightToSet = imageHeightToSetRef.current;
          canvasWidthToSet = ImageService.getImageWidth(
            canvasHeightToSet,
            imageAspectRatio
          );
        }         
      } 
      else if (image.height > image.width) {
        canvasHeightToSet = imageHeightToSetRef.current;
        canvasWidthToSet = ImageService.getImageWidth(
          canvasHeightToSet,
          imageAspectRatio
        );

        if (canvasWidthToSet > imageWidthToSetRef.current) {
          canvasWidthToSet = imageWidthToSetRef.current;
          canvasHeightToSet = ImageService.getImageHeight(
            canvasWidthToSet,
            imageAspectRatio
          )
        }
      }

      // if (isFocusMode) {
      //   imageWidthToSetRef.current = canvasWidthToSet;
      // }

      if(props.imageExpandFeatureEnabled){
        imageWidthToSetRef.current = canvasWidthToSet
      }
      let imageScaleFactor = getImageScaleFactorForImageWidth(
          image.width,
          imageWidthToSetRef.current
      );

      let resourceImageGroupAnnotations = (()=>{
        const imageGroupAnnotations = editedGroupAnnotationsRef.current || resource.imageGroupAnnotations;
        // if (editedGroupAnnotationsRef.current || propsResourceRef.current.imageGroupAnnotations) {
        if (imageGroupAnnotations) {
        // if (editedGroupAnnotationsRef.current) {
          return [...imageGroupAnnotations || []];
        }
        return [];
      })();

      if (isShowingCompressedImageBecauseOriginalImageIsDownloading && propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height && propsResourceRef.current?.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {

        /**
         * Converting imageGroupAnnotations vertices as per compressedImage width and height
         */

        resourceImageGroupAnnotations = setResourceImageGroupAnnotationsOfOriginalImageForCompressedImage(
          resourceImageGroupAnnotations,
          propsResourceRef.current?.resourceMetaData?.width,
          propsResourceRef.current?.resourceMetaData?.height,
          propsResourceRef.current?.resource500WMetaData?.width,
          propsResourceRef.current?.resource500WMetaData?.height
        )
      }

      const imageGroupAnnotationsToSet = 
        (modelOrCopilotType === "imageAnnotationGroup")
        ? setResourceImageGroupAnnotationsForSendingToAnnotationComponent(
            resourceImageGroupAnnotations,
            imageScaleFactor
          ) 
        : [];

      let resourceImageAnnotations = (()=>{
        const imageAnnotations = editedAnnotationsRef.current || resource.imageAnnotations;
        if (imageAnnotations) {
          return [...imageAnnotations];
        }
        return [];
      })();
    
      let filesImageAnnotations = (()=>{
        const imageAnnotations = editedAnnotationsRef.current || file?.imageAnnotations || [];
        if (imageAnnotations) {
          return [...imageAnnotations];
        }
        return [];        
        // if (file?.imageAnnotations) {
        //   return [...file.imageAnnotations];
          
        // }
        // return [];
        
      })();

      if (isShowingCompressedImageBecauseOriginalImageIsDownloading && propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height && propsResourceRef.current?.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {

        /**
         * Converting imageAnnotations vertices as per compressedImage width and height
         */

         resourceImageAnnotations = setResourceImageAnnotationsOfOriginalImageForCompressedImage(
          resourceImageAnnotations,
          propsResourceRef.current?.resourceMetaData?.width,
          propsResourceRef.current?.resourceMetaData?.height,
          propsResourceRef.current?.resource500WMetaData?.width,
          propsResourceRef.current?.resource500WMetaData?.height          
         )

      }

      const imageAnnotationsToSet = 
        modelOrCopilotType === "imageAnnotation"
        ? setResourceImageAnnotationsForSendingToAnnotationComponent(
            resourceImageAnnotations,
            imageScaleFactor
          )
        :modelOrCopilotType==="multipleImageAnnotation"?setResourceImageAnnotationsForSendingToAnnotationComponent(
          filesImageAnnotations,
          imageScaleFactor
        ): []

        setImageGroupAnnotationsForTwoDimensionalImage([...imageGroupAnnotationsToSet]);
        setImageAnnotationsForTwoDimensionalImage([...imageAnnotationsToSet]);
        setMultipleImageAnnotationsForTwoDimensionalImage([...imageAnnotationsToSet])
   
      // @ts-ignore
      // setResource(oldState=>{return {
      //     ...oldState,
      //     imageGroupAnnotations: [...imageGroupAnnotationsToSet],
      //     imageAnnotations: [...imageAnnotationsToSet]
      // }})

      setResourcesForWhichImageScalingGoingOn(oldState=>{
        const newArray = [...oldState];
        for (let index = 0; index < newArray.length; index++) {
            const resourceId = newArray[index];
            if (resourceId === resource?._id) {
                newArray.splice(index, 1);
                break;
            }
        }
        return [...newArray]
    })        
      setHasImageScalingCompleted(true);       
    }

    executeFunction();


  }, [isShowingCompressedImageBecauseOriginalImageIsDownloading, fullScreenMode, isFocusMode])
  

  const resizeAnnotationCanvas = (e : React.KeyboardEvent<HTMLDivElement>) => {
    console.log("key pressed", e.key, "   ::  ", e)
    if(e?.key === 'f' || e?.key === 'F'){
      setFullScreenMode(!fullScreenMode)
    }else{
      return
    }
  }

  const ViewReferencesSwitchComponent = useCallback(
    () => {
     return (
      <FormControl component="fieldset">
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch color="primary" checked={isViewingReferenceImage} 
              onChange={(e)=>{
                setIsViewingReferenceImage(e.target.checked)
              }}
            />
          }
          label="View Reference Images"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
     ) 
    },
    [isViewingReferenceImage],
  )
  const FocusModeSwitchComponent = useCallback(
    () => {
     return (
      <FormControl component="fieldset">
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch color="primary" checked={isFocusMode} 
              onChange={(e)=>{
                setIsFocusMode(e.target.checked)
              }}
            />
          }
          label="Focus Mode"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl> 
     ) 
    },
    [isFocusMode],
  )

  console.log("expand mode", props.imageExpandFeatureEnabled)
  console.log("fullscreen mode", fullScreenMode)

  return (
    <div
      className={`
        ${isFocusMode ? css.focusMode : ""}
      `}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        // fullScreen={fullScreenMode}
        fullWidth={true}
        fullScreen={isFocusMode ? true : false}
        aria-labelledby="responsive-dialog-title"
        onKeyPress={(e) => {
          try {
            const focusedElement = document.activeElement;
            if (
                focusedElement?.tagName?.toLowerCase() === "textarea" ||
                (focusedElement?.tagName?.toLowerCase() === "input" &&
                // @ts-ignore
                (focusedElement?.type?.toLowerCase() === "number" || focusedElement?.type?.toLowerCase() === "text")))
            {
                return;
            }            
        } catch (error) {
            
        }

        if (!props.imageExpandFeatureEnabled) {
          return;
        }

          resizeAnnotationCanvas(e)}}
          classes={{ 
            paperScrollPaper: classes.dialogContainer,
            paper: css.dialogPaper
          }}
          className={`
            ${isFocusMode ? css.focusMode : ""}
          `}
      >
        {
          !isFocusMode &&
          <DialogTitle id="responsive-dialog-title" className={clsx({[classes.imageAnnotation] : (props.modelType === 'imageAnnotation' || props.modelType === 'imageAnnotationGroup')})} style={{display: 'flex', justifyContent: 'flex-start', marginLeft: '40px', marginTop:'30px', marginBottom:'5px'}}>
            {
              props.isFetchingResource
              ?
                <Skeleton width="200px" height="32px" />
              :
                (
                  props.modelType === 'imageAnnotation' ||
                  props.modelType === 'imageAnnotationGroup'
                )
                ? 
                <>
                  <AiAssistantMsgWithInfoIcon 
                    aiAssistantLastCalledAt={(props?.resource as IData)?.aiAssistantLastCalledDetails?.calledAt ? true : false}
                    mb="15px"
                  />
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p style={{display: 'flex', marginBottom:0}}>Image Name: <p style={{color:"#008d9c", paddingLeft:"10px", marginBottom:0}}>{props.resource?.filename}</p></p>
                  <div>
                    {/* <>
                    {fullScreenMode ?
                    <FullscreenExitIcon 
                    color="primary"
                    onClick={() => {
                      setFullScreenMode(false)
                    }} /> :
                    <FullscreenIcon
                    color="primary"
                    onClick={() => {
                      setFullScreenMode(true)
                    }} />}
                    </> */}
                    {/* <>
                    <AspectRatioIcon 
                    color = {expandImageAnnotationAspectScreen ? 'primary' : undefined}
                    onClick={() => {
                      setExpandImageAnnotationAspectScreen(!expandImageAnnotationAspectScreen)
                    }}/>
                    </> */}
                  </div>
                </div>
                </>
                : <p style={{display: 'flex', marginLeft: '20px'}}>Image Name: <p style={{color:"#008d9c", paddingLeft:"10px"}}>
                    {
                      modelOrCopilotType === "multipleImageAnnotation"
                      ? file?.filename
                      : props.resource?.filename
                    }
                  </p></p>
            }
            
          </DialogTitle>
        }
        <DialogContent style={{padding: 0}}>
          <div
              className={`${classes.closeBtn} ${css.closeBtn}`}
              onClick={() => {
                  props.onClose();
              }}
          >
              <Cancel style={{ fontSize: "40px", padding: "4px" }} />
          </div> 
          {/* <DialogContentText></DialogContentText> */}

          {
            true &&
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "77px"
              }}
            >

              {
                !(fullScreenMode && isFocusMode) &&
               <FocusModeSwitchComponent />             
              }


              {
                props.modelReferenceData?.length>0 && !(fullScreenMode && isFocusMode) &&
                <ViewReferencesSwitchComponent />
              }
              {/* <ReferenceImageDataLink
                imageReferenceData={props.modelReferenceData}
              /> */}
            </div>
          }
          
          <div>
          {/* {alert==="success" && <Alert severity="success" onClose={()=>{setAlert("")}}>{alertMessage}</Alert>}
          {alert==="failed" && <Alert severity="success" onClose={()=>{setAlert("")}}>{alertMessage}</Alert>} */}
            {/* left and right arrows */}
            {showLeftArrow && <div className={clsx(classes.leftIndicator, classes.indicators)}>
              <IconButton aria-label="previous"
                // disabled={isUpdating || isFetching} 
                onClick={() => onSwitch(-1)}><KeyboardArrowLeft style={{color:"black", fontSize:"xxx-large"}} />
              </IconButton>
            </div>}
            {showRightArrow && <div className={clsx(classes.rightIndicator, classes.indicators)}>
              <IconButton aria-label="previous"
                // disabled={isUpdating || isFetching} 
                onClick={() => onSwitch(1)}><KeyboardArrowRight style={{color:"black", fontSize:"xxx-large"}} />
              </IconButton>
            </div>}


            {
              (props.modelType === 'imageAnnotation' || props.modelType === 'imageAnnotationGroup') && (modelOrCopilotType!=="multipleImageAnnotation") &&
              (
                  // !props.isFetchingResource && resource && hasImageScalingCompleted && resourcesForWhichImageScalingGoingOn.indexOf(resource?._id) === -1 ?
                  !props.isFetchingResource && props.resource ?
                  <TwoDimensionalImage
                    allowUserToSetAnyLabelForImageAnnotation={props.allowUserToSetAnyLabelForImageAnnotation}
                    // key={`${resource._id}${isShowingCompressedImageBecauseOriginalImageIsDownloading.toString()}${JSON.stringify(resource.imageAnnotations || [])}${JSON.stringify(resource.imageGroupAnnotations || [])}`}
                    // key={props.resource._id}
                    modelType={modelOrCopilotType}
                    ActionsSection={ (props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection') ? null : props.ActionsSection}
                    isFocusMode={isFocusMode}
                    focusModeComponent={
                      props.scenario === "dialog" || props.scenario === "aiMarketPlaceDataCart" || props.scenario === "aiMarketplaceDataSelection"
                      ? <FocusModeSwitchComponent />
                      : undefined
                    }
                    referenceDataComponent={isViewingReferenceImage ? <ReferenceImageHTML modelReferenceData={props.modelReferenceData} />: undefined}
                    viewReferencesSwitchComponent={props.modelReferenceData?.length>0 ? <ViewReferencesSwitchComponent /> : undefined}
                    isViewOnlyMode={props.isViewOnlyMode ? true : false}
                    isShowingCompressedImageBecauseOriginalImageIsDownloading={isShowingCompressedImageBecauseOriginalImageIsDownloading}
                    url={
                      isShowingCompressedImageBecauseOriginalImageIsDownloading
                      ? props.resource.resource500WUrl
                      : props.resource?.resource
                    }
                    alert={[alert,alertMessage]}
                    onSaveClick={(data: AnnotationComponentSaveClickData) => {
                      if (isShowingCompressedImageBecauseOriginalImageIsDownloading) {
                        if (data.annotations?.length>0) {
                          data.annotations = setResourceImageAnnotationsOfCompressedImageForOriginalImage(
                            data.annotations,
                            props.resource?.resourceMetaData?.width || 0,
                            props.resource?.resourceMetaData?.height || 0,
                            props.resource?.resource500WMetaData?.width || 0,
                            props.resource?.resource500WMetaData?.height || 0
                          )                          
                        }
                        if (data.imageGroupAnnotations?.length>0) {
                          data.imageGroupAnnotations = setResourceImageGroupAnnotationsOfCompressedImageForOriginalImage(
                            data.imageGroupAnnotations,
                            props.resource?.resourceMetaData?.width || 0,
                            props.resource?.resourceMetaData?.height || 0,
                            props.resource?.resource500WMetaData?.width || 0,
                            props.resource?.resource500WMetaData?.height || 0
                          )
                        }                        
                      }
                      props.onImageAnnotationSaveClick(data);
                    }}
                    isZoomFeatureEnabled={props.isZoomFeatureEnabledForImageAnnotation}
                    shouldShowMouseAnnotationCoordinates={true}
                    hasSaveButton={ (props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection') ? false : true}
                    imageWidth={imageWidthToSetRef.current}
                    originalImageWidth={props.resource?.resourceMetaData?.width || 0}
                    originalImageHeight={props.resource?.resourceMetaData?.height || 0}
                    //settingManuallyCanvasWidth will be applicable when :
                    // imageWidthToSet is our maximum permissible width for an image based on expand or collapse view (fullScreenMode or not) and imageWidthToSetRef refers to the same value
                    // if we have updated imageWidthToSetRef due to image dimension of height >= width
                    // we are checking two scenarios:
                    // if we are in expanded view -> image width less than 885 pixel will let image meta data come in the side of the image and not bottom, so we check if image pixel is less than 890px then we are manually setting canvas width to 900px.
                    // if we are in non expand view and image width is so less (imageWidthToSet - imageWidthToSetRef.current > 200), it will restrict user to see the expanded view. We will be manually setting canvas width as 400px.
                    // settingManuallyCanvasWidth = {imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSet - imageWidthToSetRef.current > 200 && !fullScreenMode && props.imageExpandFeatureEnabled ? 400 : fullScreenMode && props.imageExpandFeatureEnabled && imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSetRef.current < 890 ? 900 :undefined}
                    settingManuallyCanvasWidth = {
                      props.scenario === "dialog" || props.scenario === "copilotImageAnnotationPopUp" || props.scenario === "aiMarketPlaceDataCart" || props.scenario === "aiMarketplaceDataSelection"
                      ? fullScreenMode 
                        ? window.innerWidth/1.3 
                        : window.innerWidth/2
                      : imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSet - imageWidthToSetRef.current > 200 && !fullScreenMode && props.imageExpandFeatureEnabled ? 400 : fullScreenMode && props.imageExpandFeatureEnabled && imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSetRef.current < 890 ? 900 :undefined
                    }
                    
                    // key={hasImageScalingCompleted.toString()}
                    // imageWidth={fullScreenMode ? window.innerWidth > 1280 ? 1100 : window.innerWidth - 128 :imageWidthToSet}
                    fullScreenMode={fullScreenMode}
                    onClickFullScreenMode = {() => {
                      setFullScreenMode(!fullScreenMode);
                    }}
                    // key={hasImageScalingCompleted.toString()}
                    isExpandFeatureEnabled={props.imageExpandFeatureEnabled}
                    options={annotationOption}
                    resourceId={props.resource._id}
                    onAnnotationsChange={(data: AnnotationComponentAnnotationChangeData)=>{
                      if (isShowingCompressedImageBecauseOriginalImageIsDownloading) {
                        if (data.annotations?.length>0) {
                          data.annotations = setResourceImageAnnotationsOfCompressedImageForOriginalImage(
                            data.annotations,
                            props.resource?.resourceMetaData?.width || 0,
                            props.resource?.resourceMetaData?.height || 0,
                            props.resource?.resource500WMetaData?.width || 0,
                            props.resource?.resource500WMetaData?.height || 0
                          )
                        }
                        if (data.imageGroupAnnotations?.length>0) {
                          data.imageGroupAnnotations = setResourceImageGroupAnnotationsOfCompressedImageForOriginalImage(
                            data.imageGroupAnnotations,
                            props.resource?.resourceMetaData?.width || 0,
                            props.resource?.resourceMetaData?.height || 0,
                            props.resource?.resource500WMetaData?.width || 0,
                            props.resource?.resource500WMetaData?.height || 0
                          )
                        }
                      }
                      editedAnnotationsRef.current = data.annotations;
                      editedGroupAnnotationsRef.current = data.imageGroupAnnotations;
                    }}
                    // maxImageHeightAllowed={isFocusMode ? imageHeightToSet : imageHeightToSetRef.current}
                    maxImageHeightAllowed={imageHeightToSetRef.current}
                    // canvasStageHeight={isFocusMode ? imageHeightToSet : 0}
                    canvasStageHeight={imageHeightToSet}
                    onNextClick={props.onNextClick}
                    onPreviousClick={props.onPreviousClick}
                    defaultAnnotations={
                      modelOrCopilotType === 'imageAnnotation'
                        ? imageAnnotationsForTwoDimensionalImage
                        : modelOrCopilotType === 'imageAnnotationGroup'
                          ? getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation(
                              imageGroupAnnotationsForTwoDimensionalImage || []
                            )     
                          : []
                    }
                    defaultAnnotationGroups={
                      imageGroupAnnotationsForTwoDimensionalImage
                      ?.map(imageGroupAnnotation=>{
                          // @ts-ignore
                          // delete imageGroupAnnotation.imageAnnotations;
                          return imageGroupAnnotation;
                      }) || []
                  }                
                    isDynamicOptionsEnable
                    scenario={props.scenario}
                    ResourceRemarksSection={
                      isFocusMode
                      ? <></>
                      : <RemarksSection
                        imageIndexInMultipleImageAnnotation={currentIndex} 
                        resourceRemarksTextState={resourceRemarksTextState}
                        setResourceRemarksTextState={setResourceRemarksTextState}
                        isResourceRemarksBeingUpdatedInDB={resource.isResourceRemarksBeingUpdatedInDB || false}
                        // updateResourceRemarks={props.updateResourceRemarks}
                        resourceId={resource?._id}
                        setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents}
                        resource={resource}
                        allowUserToSetAnyLabel={props.allowUserToSetAnyLabel}
                        confidenceScore={confidenceScore}
                        resourceType={modelOrCopilotType}
                        dataSetCollections={dataSetCollections}
                        changesMade={changesMade}
                        labelColor={"#8e969e"}
                        isShowingInSideDrawer={isShowingInSideDrawer}
                        inCopilot={inCopilot}
                        onSaveRemarksClick={()=>{
                          props.onSaveRemarksClick();
                        }}
                        labelsAvailable={props.labelsAvailable}
                        updateResourceInUI={props.updateResourceInUI}
                        //@ts-ignore
                        scenario={props.scenario}
                      />
                    }
                  /> : 
                  (
                    <div className={classes.root}>
                      <div className={classes.child}>
                        <Skeleton animation="wave"  width={300} height={500}/>
                        <Skeleton width={'90%'}/>
                        <Skeleton width={'90%'}/>
                      </div>
                      <div className={classes.child}>
                        <Skeleton width={'90%'}/>
                        <Skeleton width={'90%'}/>
                      </div>
                    </div>
                  )
                
                )
            }

           
              
              {
              (modelOrCopilotType==="multipleImageAnnotation") &&
              (
                  // !props.isFetchingResource && resource && hasImageScalingCompleted && resourcesForWhichImageScalingGoingOn.indexOf(resource?._id) === -1 ?
                  !props.isFetchingResource && props.resource ?
                  <TwoDimensionalImage
                  showPrevArrow={showPrevious}
                  showNextArrow={showNext}
                  onSwitch={handleNavigation}
                    // key={`${resource._id}${isShowingCompressedImageBecauseOriginalImageIsDownloading.toString()}${JSON.stringify(resource.imageAnnotations || [])}${JSON.stringify(resource.imageGroupAnnotations || [])}`}
                    key={currentIndex}
                    modelType="multipleImageAnnotation"
                    ActionsSection={ (props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection') ? null : props.ActionsSection}

                    isFocusMode={isFocusMode}
                    focusModeComponent={
                      props.scenario === "dialog" || props.scenario === "aiMarketPlaceDataCart" || props.scenario === "aiMarketplaceDataSelection"
                      ? <FocusModeSwitchComponent />
                      : undefined
                    }
                    referenceDataComponent={isViewingReferenceImage ? <ReferenceImageHTML modelReferenceData={props.modelReferenceData} />: undefined}
                    viewReferencesSwitchComponent={props.modelReferenceData?.length>0 ? <ViewReferencesSwitchComponent /> : undefined}
                    isViewOnlyMode={props.isViewOnlyMode ? true : false}
                    isShowingCompressedImageBecauseOriginalImageIsDownloading={isShowingCompressedImageBecauseOriginalImageIsDownloading}
                    url={
                    file?.fileUrl
                    }
                    alert={[alert,alertMessage]}
                    onSaveClick={(data: AnnotationComponentSaveClickData) => {
                      console.log(data,"DATAIMG")
                     props.onImageAnnotationSaveClick(data);
                    }}
                    isZoomFeatureEnabled={props.isZoomFeatureEnabledForImageAnnotation}
                    shouldShowMouseAnnotationCoordinates={true}
                    hasSaveButton={(props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection') ? false : true}
                    imageWidth={imageWidthToSetRef.current}
                    originalImageWidth={props.resource?.resourceMetaData?.width || 0}
                    originalImageHeight={props.resource?.resourceMetaData?.height || 0}
                    //settingManuallyCanvasWidth will be applicable when :
                    // imageWidthToSet is our maximum permissible width for an image based on expand or collapse view (fullScreenMode or not) and imageWidthToSetRef refers to the same value
                    // if we have updated imageWidthToSetRef due to image dimension of height >= width
                    // we are checking two scenarios:
                    // if we are in expanded view -> image width less than 885 pixel will let image meta data come in the side of the image and not bottom, so we check if image pixel is less than 890px then we are manually setting canvas width to 900px.
                    // if we are in non expand view and image width is so less (imageWidthToSet - imageWidthToSetRef.current > 200), it will restrict user to see the expanded view. We will be manually setting canvas width as 400px.
                    // settingManuallyCanvasWidth = {imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSet - imageWidthToSetRef.current > 200 && !fullScreenMode && props.imageExpandFeatureEnabled ? 400 : fullScreenMode && props.imageExpandFeatureEnabled && imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSetRef.current < 890 ? 900 :undefined}
                    settingManuallyCanvasWidth = {
                      props.scenario === "dialog" || props.scenario === "copilotImageAnnotationPopUp" || props.scenario === "aiMarketPlaceDataCart" || props.scenario === "aiMarketplaceDataSelection"
                      ? fullScreenMode 
                        ? window.innerWidth/1.3 
                        : window.innerWidth/2
                      : imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSet - imageWidthToSetRef.current > 200 && !fullScreenMode && props.imageExpandFeatureEnabled ? 400 : fullScreenMode && props.imageExpandFeatureEnabled && imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSetRef.current < 890 ? 900 :undefined
                    }
                    
                    // key={hasImageScalingCompleted.toString()}
                    // imageWidth={fullScreenMode ? window.innerWidth > 1280 ? 1100 : window.innerWidth - 128 :imageWidthToSet}
                    fullScreenMode={fullScreenMode}
                    onClickFullScreenMode = {() => {
                      setFullScreenMode(!fullScreenMode);
                    }}
                    // key={hasImageScalingCompleted.toString()}
                    isExpandFeatureEnabled={props.imageExpandFeatureEnabled}
                    options={annotationOption}
                    resourceId={props.resource._id}
                    onAnnotationsChange={(data: AnnotationComponentAnnotationChangeData)=>{
                      
                      editedAnnotationsRef.current = data.annotations;
                      editedGroupAnnotationsRef.current = data.imageGroupAnnotations;
                    }}
                    // maxImageHeightAllowed={isFocusMode ? imageHeightToSet : imageHeightToSetRef.current}
                    maxImageHeightAllowed={imageHeightToSetRef.current}
                    canvasStageHeight={isFocusMode ? imageHeightToSet : 0}
                    onNextClick={props.onNextClick}
                    onPreviousClick={props.onPreviousClick}
                    defaultAnnotations={
                       multipleImageAnnotationsForTwoDimensionalImage
                    }
                    defaultAnnotationGroups={
                      imageGroupAnnotationsForTwoDimensionalImage
                      ?.map(imageGroupAnnotation=>{
                          // @ts-ignore
                          // delete imageGroupAnnotation.imageAnnotations;
                          return imageGroupAnnotation;
                      }) || []
                  }                
                    isDynamicOptionsEnable
                    scenario={props.scenario}
                    ResourceRemarksSection={
                      isFocusMode
                      ? <></>
                      : <RemarksSection 
                        resourceRemarksTextState={resourceRemarksTextState}
                        imageIndexInMultipleImageAnnotation={currentIndex}
                        setResourceRemarksTextState={setResourceRemarksTextState}
                        isResourceRemarksBeingUpdatedInDB={resource.isResourceRemarksBeingUpdatedInDB || false}
                        // updateResourceRemarks={props.updateResourceRemarks}
                        resourceId={resource?._id}
                        setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents}
                        resource={resource}
                        allowUserToSetAnyLabel={props.allowUserToSetAnyLabel}
                        confidenceScore={confidenceScore}
                        resourceType={modelOrCopilotType}
                        dataSetCollections={dataSetCollections}
                        changesMade={changesMade}
                        labelColor={"#8e969e"}
                        isShowingInSideDrawer={isShowingInSideDrawer}
                        inCopilot={inCopilot}
                        onSaveRemarksClick={()=>{
                          props.onSaveRemarksClick();
                        }}
                        labelsAvailable={props.labelsAvailable}
                        updateResourceInUI={props.updateResourceInUI}
                        //@ts-ignore
                        scenario={props.scenario}
                      />
                    }
                  />  : 
                  (
                    <div className={classes.root}>
                      <div className={classes.child}>
                        <Skeleton animation="wave"  width={300} height={500}/>
                        <Skeleton width={'90%'}/>
                        <Skeleton width={'90%'}/>
                      </div>
                      <div className={classes.child}>
                        <Skeleton width={'90%'}/>
                        <Skeleton width={'90%'}/>
                      </div>
                    </div>
                  )
                
                )
            }

            {
              props.modelType === 'image' &&
              (
                <div
                  className={`
                    ${classes.contentRowForImageModelScenario}
                  `}
                >
                  <div
                    style={{
                      width: "50%"
                    }}
                  >
                    {/* <ImageAspectRatioScaler
                      maxImageWidth={
                        isWindowMax720PXWidthMatches
                        ? 200
                        : 400
                      }
                      maxImageHeight={
                        isWindowMax720PXWidthMatches
                        ? 200
                        : 400
                      }
                      imageUrl={resource.resource}
                      imagePlotterSuffix='ImageKeyPointsEditDialog'
                    /> */}
                    <ImageAspectRatioScaler
                      imageUrl={props.resource?.resource}
                      image500Url={props.resource?.resource500WUrl ?? undefined}
                      imageMetaData={props.resource?.resourceMetaData ?? undefined}
                      image500UrlMetaData={props.resource?.resource500WMetaData ?? undefined}
                      maxImageWidthAllowed={isWindowMax720PXWidthMatches ? 200 : 400}
                      maxImageHeightAllowed={isWindowMax720PXWidthMatches ? 200 : 400}
                    />
                    {props.ActionsSection}
                  </div>
                  <div
                    className={`
                    ${classes.contentRowForImageModelScenarioRhsColumn}
                    `}
                    // style={{
                    //   width: "38%"
                    // }}
                  >
                    {/* <ShowResourceDetails resource={resource} confidenceScore={props.confidenceScore}/> */}
                      <RemarksSection 
                        resourceRemarksTextState={resourceRemarksTextState}
                        imageIndexInMultipleImageAnnotation={currentIndex}
                        setResourceRemarksTextState={setResourceRemarksTextState}
                        isResourceRemarksBeingUpdatedInDB={resource.isResourceRemarksBeingUpdatedInDB || false}
                        // updateResourceRemarks={props.updateResourceRemarks}
                        resourceId={resource?._id}
                        setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents}
                        onSaveRemarksClick={()=>props.onSaveRemarksClick()}
                        resource={resource}
                        allowUserToSetAnyLabel={props.allowUserToSetAnyLabel}
                        confidenceScore={confidenceScore}
                        resourceType={modelOrCopilotType}
                        dataSetCollections={dataSetCollections}
                        isShowingInSideDrawer={isShowingInSideDrawer}
                        changesMade={changesMade}
                        labelColor={"#8e969e"}
                        inCopilot={inCopilot}
                        labelsAvailable={props.labelsAvailable}
                        updateResourceInUI={props.updateResourceInUI}
                      />
                      {/* <ShowResourceDetails resource={resource}/> */}
                  </div>
                </div>
              )
            }

          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Cancel
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapProps = (state: IReduxState) => {
  return {};
};

const connector = connect(mapProps, {
  // updateResourceImageGroupAnnotationsInDB,
  // updateResourceImageAnnotationsInDB,
  // updateResourceRemarks: updateResourceRemarks,
  setShouldBlockDataTableKeyboardEvents
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ImageKeyPointsEditDialog);
