import axios, { AxiosResponse } from 'axios';
import { EGPT_BASE_URL } from '../../../../../services/constants';


// ....................................... Constants start ...........................................

export const LLM_STUDIO_CHAT_WEB_SOCKET_URL = `wss://${EGPT_BASE_URL.replace("https://", "")}/graphql`;
export const LLM_STUDIO_ORG_NAME = "techolution";
export const LLM_STUDIO_CHAT_ASSISTANT_NAME = "trainingpodchat";
export const LLM_STUDIO_SELECTED_AI_MODEL_NAME = "bestai"
export const LLM_STUDIO_USER_ID = 'user-123'
// ........................................ Constants end .................................................

interface IResetChatHistoryResponse {
    success: boolean;
    message?: string;
}

interface IChatMessage {
    id: string,
    role: 'user' | 'assistant',
    content: string,
    timestamp:string,
    // time: number,
    userDetils: {
        userName: string,
        userEmailId: string,
        llm: string
    }
}

const resetChatHistoryService = async (): Promise<IResetChatHistoryResponse> => {
    const URLParameters = new URLSearchParams();
    URLParameters.set('name', LLM_STUDIO_CHAT_ASSISTANT_NAME);
    URLParameters.set('organizationName', LLM_STUDIO_ORG_NAME);
    const url = `${EGPT_BASE_URL}/api/chat/resetHistory?${URLParameters.toString()}`;

    try {
        const response: AxiosResponse<IResetChatHistoryResponse> = await axios.get(url, {
            withCredentials: true // Include cookies
        });

        return response.data;
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        throw new Error('Failed to reset chat history: ' + error);
    }
};

const getChatHistoryAPIService = async (): Promise<Array<IChatMessage> | null> => {
    const getHistoryURL = `${EGPT_BASE_URL}/api/chat/history?name=${LLM_STUDIO_CHAT_ASSISTANT_NAME}&organizationName=${LLM_STUDIO_ORG_NAME}`;

    try {
        const requestOptions = {
            method: 'GET',
            credentials: 'include',
            withCredentials: true // Send cookies along with the request
        };
        // @ts-ignore
        const resp = await axios.get(getHistoryURL, requestOptions)
        return resp.data;

    } catch (error) {
        console.error(error);
        return null;
    }
};

const getChatSessionIdFromCookies = (): string => {
    const sessionId = document.cookie.split('; ').find(row => row.startsWith(`Enterprise-GPT-Maker-${LLM_STUDIO_ORG_NAME}-${LLM_STUDIO_CHAT_ASSISTANT_NAME}=`))?.split('=')[1] || '';
    alert(sessionId)

    return sessionId;
}

export { getChatHistoryAPIService, resetChatHistoryService, getChatSessionIdFromCookies };