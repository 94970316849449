import React, { useState, useRef, useEffect } from 'react';
import { Box, Input, InputAdornment, Grid, Card, CardContent, Typography, Button, IconButton, Chip, Avatar, Link, Breadcrumbs } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch } from "react-redux";
import homepageCss from './RLEF_homepage.module.css';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import AiNewsImage from '../../../../assets/images/AiNews.png'
import { ReactComponent as ProfileSvg } from '../../../../assets/svg/profile.svg'
import { ReactComponent as Trendingicon } from '../../../../assets/svg/trendingicon.svg'
import { ReactComponent as CuboidBox } from '../../../../assets/svg/cuboixBox.svg'
import { ReactComponent as FilterIcon } from '../../../../assets/svg/filterIcon.svg'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {ReactComponent as StarSvg} from '../../../../assets/svg/star.svg'
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory, useLocation } from 'react-router';
import UserDBService from '../../../../services/UserDBService';
import {ReactComponent as BackgroundEllipseSvg} from '../../../../assets/svg/horizon.svg'
import {ReactComponent as RLEFLogo} from '../../../../assets/svg/rlef-logo.svg'
import { ReactComponent as GreenBlurCircle } from '../../../../assets/svg/greenBlurCircle.svg'
import { ReactComponent as BlueBlurCircle } from '../../../../assets/svg/blueBlurCircle.svg'
import  AIProfileIcon from '../../../../assets/images/Animation.png'
import { getLatestModels, getPopularModels, getResultsForUserPrompt, streamResponsePost } from '../projects/features/aiMarketPlace/aiMarketPlaceServices';
import { IGetModelCollectionResponse, ILastestModelQueryParams, ILatestModel, IPopularModel, IPopularModelQueryParams, Message } from '../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces';
import { ShimmerPlaceholder } from './components/ShimmerPlaceHolder';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { dateFormats, formatDate } from '../../../../common';
import { getResourceById } from '../../../../store/actions/coPilotDetailPageActions';
import copilotService from '../../../../services/copilotService';
import DataService from '../../../../services/DataService';
import Skeleton from '@material-ui/lab/Skeleton';
import useDebounce from '../../../../hooks/useDebounce';
import { STREAM_RESPONSE_POST } from '../projects/features/aiMarketPlace/aiMarketPlaceAPIStrings';
import useCheckUserLoginValidity from '../../../../hooks/useCheckUserLoginValidity';
import FilterModel from './components/FilterModel';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HompageModelsCard from './components/HomepageModelsCard'
import { v4 as uuidv4 } from 'uuid';
import { getChatHistoryAPIService, LLM_STUDIO_CHAT_ASSISTANT_NAME, LLM_STUDIO_CHAT_WEB_SOCKET_URL, LLM_STUDIO_ORG_NAME, LLM_STUDIO_SELECTED_AI_MODEL_NAME, LLM_STUDIO_USER_ID, resetChatHistoryService } from '../chat/services/llmStudioChat.service';
import useAuth from '../../../../hooks/useAuth';
import { startChatStream,stopChatStream } from '@e-llm-studio/streaming-response';
import ReactMarkdown from 'react-markdown';
import ReplayIcon from '@mui/icons-material/Replay';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ClearIcon from '@material-ui/icons/Clear';
import AIMarketPlaceViewModel from '../projects/features/aiMarketPlace/components/AIMarketPlaceViewModel';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // marginTop: theme.spacing(4),
        width: '93%'
    },
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    newsImage: {
        width: '80px',
        borderRadius: '10px',
        // height: '113px'
    },
    actionButton: {
        marginTop: theme.spacing(2),
        // width: '100%',
        borderRadius: '20px',
        alignSelf: 'center',
        textTransform: 'capitalize',
        fontSize: '12px',
        color: "#64748B",
        fontWeight: 500
    },
    sampleImage: {
        width: '100%',
        height: 'auto',
        borderRadius: '10px',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'rgba(63, 133, 247, 0.10)',  // Adjust based on your design
    },
    card: {
        display: 'flex',
        border: '1px solid #E0E0E0',
        backgroundColor: '#FFF',
        marginBottom: theme.spacing(2),
        borderRadius: '16px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: 0
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '11px',
        width: '100%',
        '&:last-child': {
            paddingBottom: '11px',
        },
    },
    purposeModelcardContent: {
        padding: '16px',
        '&:last-child': {
            paddingBottom: '16px',
        },
    },
    authorDate: {
        color: '#008D9C',
        fontSize: 12,
        fontFamily: 'Inter, sans-serif',
        fontWeight: 500,
        whiteSpace: 'nowrap'
    },
    newsTitle: {
        color: '#101828',
        fontSize: 14,
        fontFamily: 'Inter, sans-serif',
        fontWeight: 600,
        whiteSpace: 'nowrap'
    },
    newsDescription: {
        color: '#667085',
        fontSize: '11px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '200px',
        
    },
    designChip: {
        background: '#F0F9FF',
        color: '#026AA2',
        marginRight: '1rem',
        transition: 'background 0.3s ease',
        '&:hover': {
            background: '#A1D5E0'
        }
    }
    ,
    researchChip: {
        background: '#FDF2FA',
        color: '#C11574',
        transition: 'background 0.3s ease',
        '&:hover': {
            background: '#F09EB4',
        },
    },
    TrendingText: {
        color: '#0AB257',
        fontSize: '10px',
        fontWeight: 500
    },
    forkContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: "#FBF3D9",
        borderRadius: '8px'
    },
    forkLabel: {
        color: '#F0C808',
        fontWeight: 'bold',
    },
    accuracyLabel: {
        color: '#28a745',
        fontWeight: 'bold',
    },
    integrityLabel: {
        color: '#17a2b8',
        fontWeight: 'bold',
    },
    specificChip: {
        borderRadius: '22px',
        background: '#E9EFFA',
        padding: '3.5px 5.6px',
        color: "#2264CD",
        fontFamily:"Inter",
        fontWeight:600
    },
    generalChip: {
        borderRadius: '22px',
        padding: '3.5px 5.6px',
        border: '0px solid #59BB9E',
        background: 'rgba(89, 187, 158, 0.12)',
        color: '#59BB9E',
        fontFamily:"Inter",
        fontWeight:600
    },
    customLabel:{
        padding: '1px',
    },
    messagesContainer: {
        flex: 1,
        padding: '10px',
        overflowY: 'auto' as 'auto',
        display: 'flex',
        flexDirection: 'column' as 'column',
        gap: '10px',
    },
    message: {
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    // wordWrap: 'break-word' as 'break-word',
    },
    chatRestartButton: {
        display: 'flex',
        color: '#667C7F',
        // padding: '6px 16px',
        alignItems: 'center',
        borderRadius: '32px',
        border: '1px solid #667C7F',
        background: 'white',
        alignSelf:'center',
        cursor:'pointer'
    },
    OpenInNewButton: {
        // borderRadius: var(--8, 8px);
        border: '1px solid #1E293B',
        background: '#FFFFFF',
        color: '#1E293B',
        textTransform :'capitalize',
        alignSelf:'flex-start',
        marginTop:'0.6rem',
        '&:hover': {
            background: '#1E293B',
            border: '1px solid #1E293B',
            color: '#FFFFFF',
        },
        '&:focus': {
            background: '#1E293B',
            border: '1px solid #1E293B',
            color: '#FFFFFF',
        },
        
    }
}));
interface IFilterForPopularAndLatestModels{
    purpose: { all: boolean, general: boolean, specific: boolean },
    problemType: string 
}
const useQueryParams = () => new URLSearchParams(useLocation().search);

const getInitialFilters = (query: URLSearchParams, isPopular: boolean) => {
    const purposeAll = query.get(`${isPopular ? "popularModels" : "latestModels"}FilterByPurposeType`) === null;
    return {
        purpose: {
            all: purposeAll,
            general: purposeAll || query.get(`${isPopular ? "popularModels" : "latestModels"}FilterByPurposeType`) === "general",
            specific: purposeAll || query.get(`${isPopular ? "popularModels" : "latestModels"}FilterByPurposeType`) === "specific"
        },
        problemType: query.get(`${isPopular ? "popularModels" : "latestModels"}FilterByProblemType`) || "ALL"
    };
};
// Function to format current time as HH:MM AM/PM
const formatTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };
const viewModelPageData={
        "purposeModel": {
            "_id": "662260c9adce26dd32365d45",
            "problemType": "Classification",
            "projectId": "64b5362a6310da01ce6afe18",
            "modelId": "64b536e46310da80366b02be",
            hyperParameters: {
                epoch: {
                    "display_name": "Epoch",
                    "value": "1",
                    "example": "Choose from 1 to 200",
                    "_id": "662260e2adce26dd32365ec1"
                },
                batch_size: {
                    "display_name": "Batch Size",
                    "value": "16",
                    "example": "Choose from 2 to 64",
                    "_id": "662260e2adce26dd32365ec2"
                },
                device: {
                    "display_name": "Device",
                    "value": "0",
                    "example": "Choose a value from 0 or 1 or 2 or 3 or 4",
                    "_id": "662260e2adce26dd32365ec3"
                }
            },
            "status": "deployed",
            "deploymentFailedMessage": "Deployment successful",
            "ipAddressIfDeploymentSuccess": "http://35.226.230.163",
            "deploymentCompletionEmailRecepients": [
                "sai.gurudeep@techolution.com"
            ],
            "deploymentCompletionEmailCCRecepients": [],
            "created_at": "2024-04-19T12:17:13.496Z",
            "updated_at": "2024-10-25T10:55:01.176Z",
            "__v": 12,
            "modelDescription": "object-classification-demo",
            "modelName": "object-classification-demo",
            "purposeType": "general",
            "modelWeightsFileName": null,
            "modelWeightsFileDownloadLink": null,
            "librariesRequirementFileName": "requirements.txt",
            "globalPackageFileDownloadLink": null,
            "globalPackageFileName": null,
            "modelinferenceOutput": null,
            "gcpCredentialsJsonForDeploymentFileName": "credentials.json",
            "computivePower": "Medium",
            "deployment_location": "us-central1-c",
            "isUsingRLEFGCP": true,
            "userDeploymentPlatformChoice": "Kubernetes",
            "deployedConsoleUrl": "https://console.cloud.google.com/kubernetes/nodepool/us-central1-c/rlef-64b536e46310da80366b02be-cluster/rlef-662260c9adce26dd32365d45-nodepool?project=faceopen-techolution",
            "deploymentDetails": {
                "POD_NAME": "object-classification-demo-pod",
                "CLUSTER_NAME": "rlef-64b536e46310da80366b02be-cluster",
                "CONTAINER_NAME": "object-classification-demo-container",
                "NODE_POOL_NAME": "rlef-662260c9adce26dd32365d45-nodepool",
                "DEPLOYMENTNAME": "object-classification-demo-deployment",
                "CONTAINER_PORT": "8501",
                "ZONE": "us-central1-c",
                "GPU_TYPE": "nvidia-tesla-t4",
                "GPU_COUNT": "1",
                "CPU": "4",
                "MEMORY": "15360"
            },
            "aiTask": "Computer Vision - Image",
            "monthOfRelease": 9,
            "references": [],
            tags: [
                {
                    "value": "object",
                    "_id": "66f67428d69b91a5bdceaf87"
                },
                {
                    "value": "classification",
                    "_id": "66f67428d69b91a5bdceaf88"
                }
            ],
            "testDataSet": {
                "label": "sample-dataset",
                "link": "https://google.com",
                "_id": "66f67428d69b91a5bdceaf86"
            },
            "yearOfRelease": 2022,
            "clonesCount": 0,
            "inferenceHardware": "nvidia-l4",
            "inferenceHardwareGPUCount": 1
        },
        "checkpointFiles": {
            "files": {
                "analysis": {}
            }
        },
        "project": {
            "_id": "64b5362a6310da01ce6afe18",
            "name": "Ask Alice",
            "created_at": "2023-07-17T12:38:02.824Z",
            "cloudStorageBucketId": "631ae4d2e5dd762dc077b63c"
        },
        "model": {
            "_id": "64b536e46310da80366b02be",
            "aiAssistantLabellingEnabled": true,
            "apiToCallIfAiAssistantLabellingEnabled": "",
            "aiAssistantEnabled": false,
            "apiToCallOnAiAssistantInvocation": "",
            "aiAssistantDescription": "",
            "allowUserToSetAnyLabel": false,
            "project": "64b5362a6310da01ce6afe18",
            "modelGroupId": "64b536526310da015a6aff58",
            "name": "Object Classification",
            "type": "image",
            "created_at": "2023-07-17T12:41:08.096Z",
            "lastUpdatedAt": "2024-11-06T16:39:55.714Z",
            "defaultDataSetCollection": "664e1c092dc18aa88c2546b0",
            "planogramEnabled": false,
            "confidenceScoreThresholdForIntegrity": 57,
            "aiAssistedLabellingId": "664cccb27f10bf0e8793c1c3"
        }
    }
const RLEFHomePage = () => {
    const classes = useStyles();
    const history=useHistory()
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(false);
    const searchContainerRef = useRef<HTMLDivElement>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isHamburgerOpened,setIsHamburgerOpened]=useState<boolean>(false)
    const [showLogout,setShowLogout]=useState<boolean>(false)
    const [availablePopularModelsData, setAvailablePopularModelsData] = useState<IPopularModel[] | []>([])
    const [availableLatestModelsData, setAvailableLatestModelsData] = useState<ILatestModel[] | []>([])
    const [isPopularModelsDataFetching, setIsPopularModelsDataFetching] = useState(false)
    const [isLatestModelsDataFetching, setIsLatestModelsDataFetching] = useState(false)
    const [popularModelFetchStatus, setPopularModelFetchStatus] = useState<Record<string, Record<string, boolean>>>({});
    const [fetchedPopularModelResources, setFetchedPopularModelResources] = useState<Record<string, Record<string, any>>>({});
    const [latestModelFetchStatus, setLatestModelFetchStatus] = useState<Record<string, Record<string, boolean>>>({});
    const [fetchedLatestModelResources, setFetchedLatestModelResources] = useState<Record<string, Record<string, any>>>({})
    const [fetchedSearchResultsResources, setFetchedSearchResultsResources] = useState<Record<string, Record<string, any>>>({});
    const debouncedSearchQuery = useDebounce(searchQuery,1000)
    const [searchApiloading, setSearchApiloading] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<Array<IGetModelCollectionResponse>>([]);
    const [streamedResponse, setStreamedResponse] = useState('');
    const [isPopularModelsFilterModalOpen, setIsPopularModelsFilterModalOpen] = useState(false);
    const [isLatestModelsFilterModalOpen, setIsLatestModelsFilterModalOpen] = useState(false);
    const  { search, pathname} = useLocation();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const query = new URLSearchParams(search)    
    const [selectedPopularModelsFilters, setSelectedPopularModelsFilters] = useState(getInitialFilters(query, true));
    const [selectedLatestModelsFilters, setSelectedLatestModelsFilters] = useState(getInitialFilters(query, false));
    const { userLoginStatus } = useCheckUserLoginValidity({silentlyGenerateNewAccessTokenBeforeExpiryIfValidUser: true});
    const LatestModelsfilterButtonRef = useRef<HTMLButtonElement>(null);
    const PopularModelsfilterButtonRef = useRef<HTMLButtonElement>(null);
    const [homepageSearchChatMessages, setHomepageSearchChatMessages] = useState<Message[]>([]);
    const [isStreaming, setIsStreaming] = useState(false);
    const [loadingDots, setLoadingDots] = useState(''); 
    const [loadingTextIndex, setLoadingTextIndex] = useState(0);
    const [isChatHistoryFetched,setIsChatHistoryFetched] = useState(false)
    const [showMoreAIDetailsWindow,setShowMoreAIDetailsWindow]=useState(false)
    const messageEndRef = useRef<HTMLDivElement | null>(null);
    const [streamingMessageIndex, setStreamingMessageIndex] = useState<number | null>(null);
    const loadingTexts = ["Thinking", "Generating response", "Processing your request"];
    const responseAbortController = useRef<AbortController | null>(null);
    useEffect(()=>{
        if (userLoginStatus === 'invalidUser') {
            // redirecting to login page
            history.push("/login");
        }
    }, [userLoginStatus])
    // useEffect(() => {
    //     const handleClickOutside = (event: MouseEvent) => {
    //         if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
    //             setIsActive(false); // Close modal
    //             setSearchQuery("");
    //         }
    //     };

    //     if (isActive) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [isActive]);
    const fetchPopularModels = async (selectedPopularModelsFilters : { purpose: { all: boolean; general: boolean; specific: boolean }; problemType?: string }) => {
        const params: IPopularModelQueryParams = {
            sortOrder: 'desc',
            bestModelFilter: 'accuracy',
            testDataSetRandomResourceIdsCnt: 3,
            limit: 3,
            ...(selectedPopularModelsFilters.problemType==="ALL"?{}:{problemType : selectedPopularModelsFilters.problemType}),
            ...(selectedPopularModelsFilters.purpose.all ? {} : { purposeType: selectedPopularModelsFilters.purpose.general ? "general" : "specific" })
        };
        const paramsFromTheURL ={
            problemType: query.get("popularModelsFilterByProblemType"),
            purposeType: query.get("popularModelsFilterByPurposeType")
        } 

        const finalParams = {
            ...params,
            ...(paramsFromTheURL.problemType ? { problemType: paramsFromTheURL.problemType } : {}),
            ...(paramsFromTheURL.purposeType ? { purposeType: paramsFromTheURL.purposeType } : {})
        };
        setIsPopularModelsDataFetching(true)
        const data = await getPopularModels(finalParams);
        if (data) setAvailablePopularModelsData(data.result)
        setIsPopularModelsDataFetching(false)
    }
    const fetchLatestModels = async (selectedLatestModelsFilters : { purpose: { all: boolean; general: boolean; specific: boolean }; problemType: string }) => {
        const params: ILastestModelQueryParams = {
            bestModelFilter: 'accuracy',
            testDataSetRandomResourceIdsCnt: 3,
            limit: 3,
            ...(selectedLatestModelsFilters.problemType==="ALL"?{}:{problemType : selectedLatestModelsFilters.problemType}),
            ...(selectedLatestModelsFilters.purpose.all ? {} : { purposeType: selectedLatestModelsFilters.purpose.general ? "general" : "specific" })

        };
        const paramsFromTheURL ={
            problemType: query.get("latestModelsFilterByProblemType"),
            purposeType: query.get("latestModelsFilterByPurposeType")
        } 
        
        const finalParams = {
            ...params,
            ...(paramsFromTheURL.problemType ? { problemType: paramsFromTheURL.problemType } : {}),
            ...(paramsFromTheURL.purposeType ? { purposeType: paramsFromTheURL.purposeType } : {})
        };
        setIsLatestModelsDataFetching(true)
        const data = await getLatestModels(finalParams);
        if (data) setAvailableLatestModelsData(data.result)
        setIsLatestModelsDataFetching(false)
    }
    useEffect(() => {
        fetchPopularModels(selectedPopularModelsFilters)
    }, [selectedPopularModelsFilters])
    useEffect(() => {
        fetchLatestModels(selectedLatestModelsFilters)
    }, [selectedLatestModelsFilters])

    useEffect(() => {
        const fetchPopularModelResources = async () => {
            try {
                // Loop through each popular model
                for (const model of availablePopularModelsData) {
                    if (model.purposeModel?.purposeType === 'specific') {
                        const modelId = model.purposeModel?._id;
                        // Loop through each checkpoint file's analysis resource IDs
                        // @ts-ignore 
                        const { resources } = model.checkpointFiles.files.analysis.dataSetCollection;
                        // Initialize the fetched resources for this model ID if not already present
                        if (!fetchedPopularModelResources[modelId]) {
                            setFetchedPopularModelResources((prevResources) => ({
                                ...prevResources,
                                [modelId]: {},
                            }));
                        }
                        // Fetch each resource one by one
                        for (const resourceId of resources) {
                            try {
                                // Set fetch status to true for this resource
                                setPopularModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: true,
                                    },
                                }));

                                // Make the API call to fetch resource by ID
                                const res = await DataService.getResourceFromDBById({resourceId:resourceId});
                                // Save the fetched resource data in the state
                                setFetchedPopularModelResources((prevResources) => ({
                                    ...prevResources,
                                    [modelId]: {
                                        ...prevResources[modelId],
                                        [resourceId]: res.data,
                                    },
                                }));

                                // After successful fetch, set status to false
                                setPopularModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));

                            } catch (resourceError) {
                                console.error(`Error fetching resource with ID ${resourceId}:`, resourceError);

                                // Ensure that even in case of error, fetching is marked as done
                                setPopularModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));
                            }
                        }
                    }
                }
            } catch (generalError) {
                console.error('Error fetching popular model resources:', generalError);
            }
        };
        setPopularModelFetchStatus({})
        setFetchedPopularModelResources({})
        fetchPopularModelResources();
    }, [availablePopularModelsData]);

    useEffect(() => {
        const fetchLatestModelResources = async () => {
            try {
                // Loop through each latest model
                for (const model of availableLatestModelsData) {
                    if (model.purposeModel?.purposeType === 'specific') {
                        const modelId = model.purposeModel?._id;
                        // Loop through each checkpoint file's analysis resource IDs
                        // @ts-ignore 
                        const { resources } = model.checkpointFiles.files.analysis.dataSetCollection;

                        // Fetch each resource one by one
                        for (const resourceId of resources) {
                            try {
                                // Set fetch status to true for this resource
                                setLatestModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));

                                // Make the API call to fetch resource by ID
                                const res = await DataService.getResourceFromDBById({resourceId:resourceId});

                                // Save the fetched resource data in the state
                                setFetchedLatestModelResources((prevResources) => ({
                                    ...prevResources,
                                    [modelId]: {
                                        ...prevResources[modelId],
                                        [resourceId]: res.data,
                                    },
                                }));

                                // After successful fetch, set status to false
                                setLatestModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));

                            } catch (resourceError) {
                                console.error(`Error fetching resource with ID ${resourceId}:`, resourceError);

                                // Ensure that even in case of error, fetching is marked as done
                                setLatestModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));
                            }
                        }
                    }
                }
            } catch (generalError) {
                console.error('Error fetching latest model resources:', generalError);
            }
        };
        setFetchedLatestModelResources({})
        setLatestModelFetchStatus({})
        fetchLatestModelResources();
    }, [availableLatestModelsData]);


    useEffect(() => {
        const fetchSearchResultsResources = async () => {
            try {
                // Loop through each latest model
                for (const model of searchResults) {
                    if (model.purposeModel?.purposeType === 'specific') {
                        const modelId = model.purposeModel?._id;
                        // @ts-ignore 
                        let { resources } = model.checkpointFiles.files.analysis.dataSetCollection;

                        if(!resources) resources = []

                        // Fetch each resource one by one
                        for (const resourceId of resources) {
                            try {
                                const res = await DataService.getResourceFromDBById({resourceId:resourceId});

                                if(res.data){
                                    setFetchedSearchResultsResources((prevResources) => ({
                                        ...prevResources,
                                        //@ts-ignore
                                        [modelId]: {
                                            //@ts-ignore
                                            ...(prevResources[modelId] || {}),
                                            [resourceId]: res.data,
                                        },
                                    }));
                                }

                            } catch (resourceError) {
                                console.error(`Error fetching resource with ID ${resourceId}:`, resourceError);

                                // Ensure that even in case of error, fetching is marked as done
                                setLatestModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    //@ts-ignore
                                    [modelId]: {
                                        //@ts-ignore
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));
                            }
                        }
                    }
                }
            } catch (generalError) {
                console.error('Error fetching latest model resources:', generalError);
            }
        };
        setFetchedSearchResultsResources({})
        fetchSearchResultsResources();
    }, [searchResults]);

    
    // useEffect(() => {
    //     const handleSearchApiCall = async () => {
    //         try {
    //             setSearchApiloading(true)
    //             const res = await getResultsForUserPrompt(debouncedSearchQuery)
    //             if(res?.result?.length){
    //                 setSearchResults(res.result);
    //             }
    //         }
    //         catch (error) {
    //             console.log(error)
    //         }
    //         finally {
    //             setSearchApiloading(false)
    //         }
    //     }
    //     if (debouncedSearchQuery.trim()) {
    //         handleSearchApiCall()
    //     }
    // }, [debouncedSearchQuery]);

    const handleFocus = () => {
        setIsActive(true);
    };

    const handleBlur = () => {
        setIsActive(false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };
    const handleClickHamburgerOpenClose=(isOpened:boolean)=>{
        setIsHamburgerOpened(isOpened)
    }
    const logout = async () => {
        await UserDBService.logoutUser();
        // localStorage.removeItem('loggedIn');
        history.push('/login');
    }
    const handleOpenLatestModelsFilter = () => {
        setIsLatestModelsFilterModalOpen(true);
    };
    const handleToggleLatestModelsFilter = () =>{
        setIsLatestModelsFilterModalOpen(!isLatestModelsFilterModalOpen);
    }
    const handleCloseLatestModelFilterModal = () => {
        setIsLatestModelsFilterModalOpen(false);
    };
    const handleApplyLatestModelsFilters = (filters: IFilterForPopularAndLatestModels) => {
        if(filters.problemType == "ALL"){
            query.delete("latestModelsFilterByProblemType")
        }
        else{
            query.set("latestModelsFilterByProblemType",filters.problemType)
        }
        if(filters.purpose.all){
            query.delete("latestModelsFilterByPurposeType")
        }
        else if(filters.purpose.general){
            query.set("latestModelsFilterByPurposeType",'general')
        }
        else{
            query.set("latestModelsFilterByPurposeType","specific")
        }
        setSelectedLatestModelsFilters(filters); 
        setIsLatestModelsFilterModalOpen(false);
        history.push(`${pathname}?${query}`)

    };
    const handleTogglePopularModelsFilter = () =>{
        setIsPopularModelsFilterModalOpen(!isPopularModelsFilterModalOpen);
    }
    const handleOpenPopularModelsFilter = () => {
        setIsPopularModelsFilterModalOpen(true);
    };
    const handleClosePopularModelsFilterModal = () => {
        setIsPopularModelsFilterModalOpen(false);
    };
    const handleApplyPopularModelsFilters = (filters: IFilterForPopularAndLatestModels) => {
        if(filters.problemType == "ALL"){
            query.delete("popularModelsFilterByProblemType")
        }
        else{
            query.set("popularModelsFilterByProblemType",filters.problemType)
        }
        if(filters.purpose.all){
            query.delete("popularModelsFilterByPurposeType")
        }
        else if(filters.purpose.general){
            query.set("popularModelsFilterByPurposeType",'general')
        }
        else{
            query.set("popularModelsFilterByPurposeType","specific")
        }
        setSelectedPopularModelsFilters(filters);
        setIsPopularModelsFilterModalOpen(false);
        history.push(`${pathname}?${query}`)
    };
    const { userEmail } = useAuth()
    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
    
    useEffect(() => {
    scrollToBottom();
    }, [homepageSearchChatMessages]);

    useEffect(() => {
    let dotInterval: NodeJS.Timeout;
    let textInterval: NodeJS.Timeout;

    if (isStreaming) {
        dotInterval = setInterval(() => {
        setLoadingDots((prev) => (prev.length < 3 ? prev + '.' : ''));
        }, 500);

        textInterval = setInterval(() => {
        setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
        }, 1500);
    }

    return () => {
        clearInterval(dotInterval);
        clearInterval(textInterval);
        setLoadingTextIndex(0)
    };
    }, [isStreaming]);
    useEffect(() => {
        // When streaming starts, set streamingMessageIndex to the last message index
        if (isStreaming) {
          setStreamingMessageIndex(homepageSearchChatMessages.length - 1);
        } else {
          setStreamingMessageIndex(null); // Clear index when streaming stops
        }
      }, [isStreaming, homepageSearchChatMessages.length]);
    const fetchChatHistory = async () => {
        const chatHistory = await getChatHistoryAPIService();
        if(chatHistory){
          chatHistory.map(msgObj => {
            setHomepageSearchChatMessages(prev => {
              return [...prev, { text: msgObj.content, sender: msgObj.role,timestamp:msgObj.timestamp }]
            })
          })
        }
      }
    
      useEffect(() => {
        if(!isChatHistoryFetched){
            void fetchChatHistory()
            handleClearHistory()
            setIsChatHistoryFetched(true)
        }
      }, [isActive]);
    const handleSendMessage = () => {
        if (!searchQuery.trim() ||!isActive) return;
    
        const userMessage: Message = { text: searchQuery, sender: 'user',timestamp: formatTime(new Date())};
        setHomepageSearchChatMessages((prev) => [...prev, userMessage]);
        setSearchQuery(''); // Clear input field
    
        // Prepare parameters for startChatStream
        const params = {
          WEBSOCKET_URL: LLM_STUDIO_CHAT_WEB_SOCKET_URL, // replace with actual WebSocket URL
          organizationName: LLM_STUDIO_ORG_NAME,
          chatAssistantName: LLM_STUDIO_CHAT_ASSISTANT_NAME,
          selectedAIModel: LLM_STUDIO_SELECTED_AI_MODEL_NAME,
          replyMessage: '', // If there is no previous response, leave empty
          userName: userEmail,
          userEmailId: userEmail,
          userId: LLM_STUDIO_USER_ID,
          query: searchQuery,
          // sessionId: getChatSessionIdFromCookies(), // Generates a unique session ID
          requestId: `requestId-${uuidv4()}`, // Generates a unique request ID
          // customPrompt: '',
          enableForBackend: false,
          // isDocumentRetrieval:false, // To bypass the document retrieval.
          onStreamEvent: (data: any) => {
            const msgContent = data?.chatStream?.continueChatResult?.message?.content;
            if(!msgContent.startsWith("WaitMessage:")){
            setHomepageSearchChatMessages((prev) => {
              const updatedMessages = [...prev];
              if (updatedMessages[updatedMessages.length - 1].sender === 'assistant') {
                  // Regular expression to match the extraMetaData block
                const extraMetaDataMatch = msgContent.match(/```extraMetaData\s+([\s\S]*?)```/);
                // const match = markdownContent.match(extraMetaDataMatch);
                if (extraMetaDataMatch && extraMetaDataMatch.length>1) {
                    try {
                        // Clean the JSON string by removing any newlines or extra spaces
                        const cleanedMetaData = extraMetaDataMatch[1].replace(/\n/g, '').replace(/\s+/g, ' ');
                        // Parse the extracted metadata JSON
                      const metaData = JSON.parse(cleanedMetaData);                      
                      // Remove the extraMetaData from content for display
                      const contentWithoutMetaData = msgContent.replace(extraMetaDataMatch[0], '').trim();
                      updatedMessages[updatedMessages.length - 1].text = contentWithoutMetaData;
                      updatedMessages[updatedMessages.length - 1].metaData = metaData;
                    //   updatedMessages.push({ text: contentWithoutMetaData, sender: 'assistant',timestamp: formatTime(new Date()) });
                    } catch (error) {
                      console.error('Error parsing extraMetaData:', error);
                    }
                  } else {
                    // If no extraMetaData found, store the entire content
                    updatedMessages[updatedMessages.length - 1].text = msgContent;
                  }
              } else {
                  updatedMessages.push({ text: msgContent, sender: 'assistant',timestamp: formatTime(new Date())});

              }
              return updatedMessages;
            });
          }
          },
          onStreamEnd: () => setIsStreaming(false),
          onError: (error: any) => console.error('Stream error:', error),
        };
    
        // Start the streaming process
        setIsStreaming(true);
        responseAbortController.current = new AbortController();
        startChatStream({ ...params, signal: responseAbortController.current.signal });
    
        // Add an empty bot message to indicate loading
        setHomepageSearchChatMessages((prev) => [...prev, { text: '', sender: 'assistant',timestamp: formatTime(new Date()) }]);
        // Simulate delay for bot response
        // setTimeout(() => {
        //   const nextResponse = botResponses[responseIndex];
        //   setHomepageSearchChatMessages((prev) => {
        //     const updatedMessages = [...prev];
        //     updatedMessages[updatedMessages.length - 1].text = nextResponse;
        //     return updatedMessages;
        //   });
    
        //   // Update the response index
        //   setResponseIndex((prevIndex) => (prevIndex + 1) % botResponses.length);
        //   setIsStreaming(false);
        // }, 3000); // Simulate 1 second delay
      };
    const handleClearHistory = async () => {
    await resetChatHistoryService();
    if (responseAbortController.current) {
        responseAbortController.current.abort();
        }
    setHomepageSearchChatMessages([]); 
    setIsStreaming(false);
    // setLoadingDots('');
    // setLoadingTextIndex(0);
    setShowMoreAIDetailsWindow(false)
    };  
    const handleStopStream = () => {
    setIsStreaming(false);
    // stopChatStream(); 
    setHomepageSearchChatMessages((prev) => {
        const updatedMessages = [...prev];
        // Update the last message to reflect that the stream was stopped
        updatedMessages[updatedMessages.length - 1].text = 'Stream stopped.';
        return updatedMessages;
    });
    };
    const handleShowMoreAIDetailsWindow = ()=>{
        setShowMoreAIDetailsWindow(!showMoreAIDetailsWindow)
    }
    return (
        <Box className={`${homepageCss.container} ${isActive ? homepageCss['blur-background'] : ''}`}>
             {isActive && <Box className={homepageCss.blurOverlay}></Box>}
            <Box className={homepageCss.header}>
                <Box className={homepageCss.rlefLogoWrapper}>
                    {isActive && 
                    <IconButton style={{padding:'4px'}}>
                    <ArrowBackIcon onClick={()=>setIsActive(false)} style={{cursor:'pointer',width:'24px',height:'24px'}}/>
                    </IconButton>
                    }
                    <RLEFLogo />
                    </Box>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{textAlign:'center',paddingRight:'9rem'}}>
                <Typography variant='h4' style={{ color: '#64748B', fontWeight: 700,letterSpacing: '-1.2px',fontSize:'2.5rem' }}>AI Marketplace</Typography>
                <Typography style={{ color: '#64748B',fontFamily:'Inter',fontWeight:400}}>Find AI Models to Solve your Problems</Typography>
                </Box>
                <Box className={homepageCss.hamburgerProfileContainer}>
                    <ClickAwayListener onClickAway={() => { setShowLogout(false) }}>
                        <Box className={homepageCss.profileIconWrapper}>
                            <ProfileSvg onClick={() => setShowLogout(prev => !prev)} />
                            {showLogout && <Box className={homepageCss.profileIconDrawer}>
                                <Box className={homepageCss.logoutWrapper} onClick={logout}><Typography color="primary">Logout</Typography></Box>
                            </Box>
                            }
                        </Box>
                    </ClickAwayListener>
                    <Box className={homepageCss.hamburgerIconWrapper}>
                        {
                            isHamburgerOpened ? <IconButton color="primary" style={{ padding: '6px' }} onClick={() => handleClickHamburgerOpenClose(false)} >
                                <CloseIcon style={{fontSize:'32px',color:'#64748B'}}/>
                            </IconButton> : <IconButton color="primary" style={{ padding: '6px' }} onClick={() => handleClickHamburgerOpenClose(true)}>
                                <MenuIcon style={{fontSize:'32px',color:'#64748B'}} />
                            </IconButton>
                        }
                        {isHamburgerOpened && <Box className={homepageCss.navLinks}>
                            <Link href='admin/projects'>Projects</Link>
                            <Link href='#'>AI News</Link>
                            <Link href='admin/ai-market-place'>AI Marketplace</Link>
                            <Link href='https://autoai-backend-exjsxe2nda-uc.a.run.app/api-docs' target="_blank" >API Documentation</Link>
                            <Link href='admin/configurations'>Configurations</Link>
                        </Box>}
                    </Box>
                </Box>  
            </Box>
            <Box display="flex" justifyContent="center" style={{gap:'1rem',width:'95vw'}}>
            <Box className={`${homepageCss.content} ${showMoreAIDetailsWindow &&isActive ? `${homepageCss.slideLeft}` : ""}`}>
                {/* <Typography variant='h3' style={{ color: '#64748B', fontWeight: 700 }}>AI Marketplace</Typography>
                <Typography style={{ color: '#64748B',marginBottom:'64px',fontFamily:'Inter',fontWeight:400 }}>Find AI Models to Solve your Problems</Typography> */}
                <div
                    className={`${homepageCss.searchContainer} ${isActive ? homepageCss.active : ''}`}
                    ref={searchContainerRef}
                    style={{width: isActive && showMoreAIDetailsWindow?'100%':'60%'}}
                >
                    {isActive && <Box className={`${homepageCss.searchBarchatContainer}`}>
                       {homepageSearchChatMessages.length==0 ?<Typography variant="h1" className={homepageCss.chatInterfaceMainHeading}>Ask question to find right AI Models</Typography>:
                        <Box className={`${classes.messagesContainer} ${homepageCss.customScroll}`}>
                            {homepageSearchChatMessages.map((message: Message, index: React.Key | null | undefined) => (
                                <Box
                                    key={index}
                                    // className={classes.message}
                                    style={{
                                        alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
                                        // wordWrap:'break-word'
                                        maxWidth:'70%',
                                        width:'100%'
                                    }}
                                >
                                    {message.sender === 'user' && (
                                        <Box display="flex" justifyContent="end" style={{gap:"12px"}}>
                                            {/* Message content */}
                                            <Box display="flex" flexDirection="column" alignItems="end" style={{gap:'6px'}}>
                                            <Box display="flex" alignItems="center" style={{ fontSize: '0.75rem', color: '#888', textAlign: 'right',gap:'12px'}}>
                                                <Typography variant='body2' style={{color:'#1E293B',fontWeight:700}}>You</Typography>
                                                {message.timestamp}
                                            </Box>
                                            <Box className={classes.message} style={{backgroundColor: '#EFEFEF',maxWidth:'100%'}}>
                                                {message.text}
                                            </Box>
                                            </Box>
                                            {/* User profile icon */}
                                            <Avatar src="/path/to/user/icon.png" alt="User Icon" style={{ width: '30px', height: '30px' }} />
                                        </Box>

                                    )}
                                    {message.sender === 'assistant' &&
                                        (
                                            <Box display="flex" style={{ gap: "12px" }}>
                                                {/* User profile icon */}
                                                <Avatar src={AIProfileIcon} alt="User Icon" style={{ width: '30px', height: '30px' }} />
                                                <Box display="flex" flexDirection="column" alignItems="start" style={{ gap: '6px',flexGrow:1 ,width:'100%'}}>
                                                    {isStreaming && index === streamingMessageIndex ?
                                                        <Box display="flex" flexDirection="column" alignItems="start" style={{flexGrow:1,width:'100%'}}>
                                                            <Skeleton variant="text" width="25%" height={30} animation="wave" style={{marginBottom:'-6px'}}/>
                                                            <Skeleton variant="text" width="70%" height={30} animation="wave" style={{marginBottom:'-6px'}}/>
                                                            <Skeleton variant="text" width="50%" height={30} animation="wave" style={{marginBottom:'-6px'}}/>
                                                        </Box> : <>
                                                            <Box display="flex" alignItems="center" style={{ fontSize: '0.75rem', color: '#888', textAlign: 'right', gap: '12px' }}>
                                                                <Typography variant='body2' style={{ color: '#1E293B', fontWeight: 700 }}>AI</Typography>
                                                                {message.timestamp}
                                                            </Box>
                                                            <Box className={classes.message} display="flex" flexDirection="column" style={{ background: 'linear-gradient(93deg, #D8F1F2 0%, #F2E5F8 100%)', maxWidth: '100%' }}>
                                                                <ReactMarkdown className={homepageCss.markdownContent} components={{ p: ({ node, ...props }) => <p style={{ margin: 0, textAlign: 'left' }} {...props} /> }}>
                                                                    {message.text || (message.sender === 'assistant' && isStreaming ? `${loadingTexts[loadingTextIndex]}${loadingDots}` : '')}
                                                                </ReactMarkdown>
                                                                <Button variant='outlined' size="small" onClick={handleShowMoreAIDetailsWindow} className={classes.OpenInNewButton} endIcon={<OpenInNewIcon/>}>click here to open</Button>
                                                            </Box>
                                                        </>}
                                                </Box>
                                            </Box>
                                        )}
                                </Box>
                            ))}
                            <div ref={messageEndRef} />
                        </Box>
                        }
                        {homepageSearchChatMessages.length>0&& 
                        <Box className={classes.chatRestartButton}>
                            <IconButton style={{padding:'6px 16px',borderRadius:'40%'}} onClick={handleClearHistory}>
                            <ReplayIcon style={{width:'1rem',height:'1rem'}}/>
                            <Typography variant='body2'>Restart chat</Typography>
                            </IconButton>
                        </Box>
                        }
                    </Box>
                    }
                    <Input
                        className={homepageCss.searchBar}
                        placeholder="Ask me anything to find the best AI model for you"
                        startAdornment={
                            <InputAdornment position="start">
                                <StarSvg />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="start">
                                {isStreaming ? <StopCircleIcon style={{cursor:'pointer',color:'#1C1B1F'}} onClick={handleStopStream}/>:
                                <SendIcon style={{ color: searchQuery.trim() !== '' ? '#008D9C' :'#008D9C66',cursor:'pointer' }} onClick={handleSendMessage}/>}
                            </InputAdornment>
                        }
                        disableUnderline
                        onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleInputChange}
                        value={searchQuery}
                        onFocus={handleFocus}
                        // onBlur={handleBlur}
                        style={{fontFamily:'Inter',color:'#838C95',fontSize:'20px'}}
                    />
                    {/* {isActive && searchQuery.trim() !== '' && (
                        <Box className={homepageCss.dropdown}>
                            {searchApiloading && <div>Loading...</div>}
                            <div>{streamedResponse}</div>
                            {
                                !searchApiloading && searchResults?.length>0 &&
                                <Box className={homepageCss.SearchModelsContainer}>
                                    <Box display="flex" style={{ gap: '8px',fontFamily:'Inter',fontWeight:500 }}>
                                        <CuboidBox />
                                        <Typography variant='h6' style={{ color: '#5C6A7F',fontFamily:'Inter',fontWeight:500  }}>Models</Typography>
                                    </Box>
                                    <Box display="flex" style={{ gap: '8px',fontFamily:'Inter' }}>
                                        <Box style={{ border: '1px dashed rgba(92, 106, 127, 0.70)' }} mr="0.8px" ml="5px" />
                                        <Box display="flex" flexDirection="column" style={{gap:'1rem',flexGrow:1 }}>
                                            {
                                                searchResults.map((obj, index) => {
                                                    return (
                                                        <Box display="flex" flexDirection="column" className={homepageCss.roundedDiv} style={{ gap: '16px', flexGrow: 1 }}>
                                                            <Box display="flex" justifyContent="space-between">
                                                                <Typography variant='body1' style={{ color: '#2A2F38', fontWeight: 700, margin: '0' }}>
                                                                    {obj.purposeModel?.modelName}
                                                                </Typography>
                                                                <Chip label={obj.purposeModel?.purposeType} size="small" className={ obj.purposeModel?.purposeType === 'specific' ? classes.specificChip : classes.generalChip} />
                                                            </Box>
                                                            <Box display="flex" justifyContent="space-between">
                                                                <Box display="flex" flexDirection="column" style={{ gap: '9px' }}>
                                                                    <Breadcrumbs aria-label="breadcrumb">
                                                                        <Link color="inherit" href="/" style={{ color: 'rgba(75, 85, 99, 0.50)' }}>
                                                                            project: <Chip
                                                                                variant="default"
                                                                                style={{ borderRadius: '4px', padding: '0', height: '23px', color: '#5C6A7F', fontFamily: 'Inter', fontWeight: 500, backgroundColor: '#F0F2F4' }}
                                                                                label={obj.project?.name}
                                                                                classes={{ label: classes.customLabel }}
                                                                            />
                                                                        </Link>
                                                                        <Link color="inherit" href="/getting-started/installation/" style={{ color: 'rgba(75, 85, 99, 0.50)' }}>
                                                                            <span style={{ color: '#4B5563' }}>task:</span> {obj.model?.name}
                                                                        </Link>
                                                                    </Breadcrumbs>
                                                                    <Typography variant='body2' style={{ textAlign: 'left', color: 'rgba(75, 85, 99, 0.50)' }}>
                                                                        description: <span style={{ color: "#4B5563", fontFamily: "Inter", fontWeight: 400, fontSize: '12px' }}>
                                                                            {obj.purposeModel?.modelDescription}
                                                                        </span>
                                                                    </Typography>
                                                                    <Typography variant='body2' style={{ color: 'rgba(75, 85, 99, 0.50)', textAlign: 'left' }}>
                                                                        Problem Type: <span style={{ color: '#666666', fontWeight: 600 }}>
                                                                            {obj.purposeModel?.aiTask}
                                                                        </span>
                                                                        <span style={{ color: '#666666', fontWeight: 500 }}>
                                                                            &nbsp;({obj.purposeModel?.problemType})
                                                                        </span>
                                                                    </Typography>
                                                                </Box>
                                                                <Box display="flex" style={{ gap: '0.5rem', fontFamily: 'Inter' }}>
                                                                    <Box display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: '#F3F3F3', borderRadius: '8px', padding: '8px', height: 'max-content' }}>
                                                                        <Typography variant='body2' style={{ color: '#666666', textAlign: 'center', fontWeight: 600 }}>
                                                                            {
                                                                                obj.purposeModel?.purposeType === 'general' ?
                                                                                    '-'
                                                                                    // @ts-ignore
                                                                                    : (obj.checkpointFiles?.files?.analysis.cachedMetrics?.accuracy?.accuracy)?.toFixed(2)
                                                                            }
                                                                        </Typography>
                                                                        <Typography variant='body2' style={{ color: "#64748B", textAlign: 'center', fontSize: '8px' }}>
                                                                            Accuracy
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: '#F3F3F3', borderRadius: '8px', padding: '8px', height: 'max-content' }}>
                                                                        <Typography variant='body2' style={{ color: '#666666', textAlign: 'center', fontWeight: 600 }}>
                                                                            {
                                                                                obj.purposeModel?.purposeType === 'general' ?
                                                                                    '-'
                                                                                    // @ts-ignore
                                                                                    : (obj.checkpointFiles?.files?.analysis.cachedMetrics?.integrity?.integrity)?.toFixed(2)
                                                                            }
                                                                        </Typography>
                                                                        <Typography variant='body2' style={{ color: "#64748B", textAlign: 'center', fontSize: '8px' }}>
                                                                            Integrity
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            {
                                                                Object.entries(fetchedSearchResultsResources[obj.purposeModel?._id || ''] || {}).length > 0
                                                                &&
                                                                <Box>
                                                                    <Typography variant='body2' style={{ color: '#3A3A3A', textAlign: 'left', fontFamily: 'Inter', fontWeight: 600, marginBottom: '8px' }}>
                                                                        Test dataset
                                                                    </Typography>
                                                                    {
                                                                        Object.keys(fetchedSearchResultsResources).length === 0 ? (
                                                                            <Typography>No resources available</Typography>
                                                                        )
                                                                            :
                                                                            (
                                                                                <Box display="flex" mt={1} style={{ gap: '0.5rem', backgroundColor: '#F3F3F3', borderRadius: '8px', overflowX: 'hidden' }} p={1}>
                                                                                    {Object.entries(fetchedSearchResultsResources[obj.purposeModel?._id || ''] || {}).map(([resourceId, object]) => (
                                                                                        (
                                                                                            object && (
                                                                                                <img key={resourceId} src={object?.resource} alt="Resource" style={{ width: '50px', height: '50px', borderRadius: '8px' }} />
                                                                                            )
                                                                                        )
                                                                                    ))}
                                                                                </Box>
                                                                            )
                                                                    }
                                                                </Box>
                                                            }
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        </Box>)
                    }  */}
                </div>
                <Box className={homepageCss.backgroundEllipseWrapper}>
                    <BackgroundEllipseSvg className={homepageCss.ellipse}/>
                </Box>
            </Box>
            {isActive && showMoreAIDetailsWindow && 
            <Box className={homepageCss.showMoreAIDetailsWindowCss}>
                <Box className={homepageCss.showMoreAIDetailsWindowHeader}>
                    <Typography variant='h6' style={{color:'#242424CC',fontWeight:700}}>Can Detection Model</Typography>
                    <ClearIcon onClick={()=>setShowMoreAIDetailsWindow(false)} style={{cursor:'pointer'}}/>
                </Box>
                <Box p={2} className={homepageCss.customScroll}>
                <AIMarketPlaceViewModel viewModelPageData={viewModelPageData} isFetching={false} scenario="homepageChatAssistant"/>
                </Box>
                </Box>
                }
            </Box>
            <Box className={classes.root}>
                <Grid container spacing={4}>
                    {/* AI News */}
                    <Grid item xs={12} md={4}>
                        <Box className={homepageCss.aiNewsContainer}>
                            <div className={classes.sectionHeader}>
                                <Typography variant="h6" className={homepageCss.columnSectionText} style={{fontFamily:"Inter",fontWeight:600}}>AI News</Typography>
                                <Box display="flex" style={{ gap: '0.5rem' }}>
                                    <Box className={homepageCss.filterContainer} >
                                        <IconButton color="primary" style={{ padding: '5px' }}>
                                            <ImportExportIcon style={{ fontSize: '19px' }} />
                                        </IconButton>
                                    </Box>
                                    <Box className={homepageCss.filterContainer}>
                                        <IconButton color="primary" style={{ padding: '6px',borderRadius:'30%' }}>
                                            <FilterIcon />
                                            <Typography variant="body2" className={homepageCss.filterText} style={{fontFamily:"Inter",fontWeight:500}}>Filters</Typography>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </div>
                            {/* News Cards */}
                            
                            {
                            // Array.from({ length: 5 }).map((_, index) => (
                            //     <Card key={index} className={classes.card} style={{ width: '100%' }}>
                            //         <CardContent className={classes.cardContent}>
                            //             <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }} >
                            //                 <img src={AiNewsImage} alt="News" className={classes.newsImage} />
                            //                 <Box ml={2} mr={2} style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                            //                     <Box display="flex" justifyContent="space-between" alignItems="center" style={{ gap: '0.5rem', flexWrap: 'wrap' }}>
                            //                         <Typography variant="body2" className={classes.authorDate}>
                            //                             Phoenix Baker • 19 Jan 2022
                            //                         </Typography>
                            //                         {/* <Box display="flex" p={0.5} style={{ borderRadius: '4px', border: '0.8px solid #0AB257', background: '#EFF9F4', gap: '0.2rem' }}>
                            //                             <Trendingicon />
                            //                             <Typography variant="body2" className={classes.TrendingText}>
                            //                                 Trending
                            //                             </Typography>
                            //                         </Box> */}
                            //                     </Box>
                            //                     <Typography variant="h6" className={classes.newsTitle}>
                            //                         Migrating to Linear 101
                            //                     </Typography>
                            //                     <Typography variant="body2" className={classes.newsDescription}>
                            //                         A comprehensive guide on migrating your team to Linear, from setup to best practices.
                            //                     </Typography>
                            //                     <Box mt={1}>
                            //                         <Chip label="Design" size="small" style={{fontFamily:'Inter',fontWeight:500}} className={classes.designChip} clickable />
                            //                         <Chip label="Research" size="small" style={{fontFamily:'Inter',fontWeight:500}} className={classes.researchChip} clickable />
                            //                     </Box>
                            //                 </Box>
                            //             </Box>
                            //         </CardContent>
                            //     </Card>
                            // ))
                            }
                            {/* <Box display="flex" justifyContent="center">
                                <Button variant="outlined" className={classes.actionButton} size='small'>View All</Button>
                            </Box> */}
                            <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: '100%', height: 0, paddingBottom: '100%'}}>
                                <Typography variant='body2'>Coming soon...</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Popular Models */}
                    <Grid item xs={12} md={4} >
                        <Box className={homepageCss.popularModelsContainer}>
                            <div className={classes.sectionHeader}>
                                <Typography variant="h6" className={homepageCss.columnSectionText} style={{fontFamily:"Inter",fontWeight:600}}>Popular Models</Typography>
                                <Box className={homepageCss.filterContainer}>
                                    <IconButton color="primary" style={{ padding: '6px',borderRadius:'30%' }} ref={PopularModelsfilterButtonRef} onClick={handleTogglePopularModelsFilter}>
                                        <FilterIcon />
                                        <Typography variant="body2" className={homepageCss.filterText} style={{fontFamily:"Inter",fontWeight:500}}>Filters</Typography>
                                    </IconButton>
                                </Box>
                                <FilterModel
                                    open={isPopularModelsFilterModalOpen}
                                    onClose={handleClosePopularModelsFilterModal}
                                    onApply={handleApplyPopularModelsFilters}
                                    buttonPosition={PopularModelsfilterButtonRef.current}
                                    // onClearFilters = {handleClearPopularModelsFilters}
                                    //@ts-ignore
                                    params={selectedPopularModelsFilters}
                                />
                            </div>
                            {/* Model Cards */}
                            {isPopularModelsDataFetching ?
                                <ShimmerPlaceholder />
                                :
                                <>
                                    <HompageModelsCard
                                        availableModelsData={availablePopularModelsData}
                                        modelFetchStatus={popularModelFetchStatus}
                                        fetchedModelResources={fetchedPopularModelResources}
                                        scenario="popularModelsData"
                                    />
                                </>
                            }
                        </Box>
                    </Grid>

                    {/* Latest Models */}
                    <Grid item xs={12} md={4}>
                        <Box className={homepageCss.latestModelsContainer}>
                            <div className={classes.sectionHeader}>
                                <Typography variant="h6" className={homepageCss.columnSectionText} style={{fontFamily:"Inter",fontWeight:600}}>Latest Models</Typography>
                                <Box className={homepageCss.filterContainer}>
                                    <IconButton color="primary" style={{ padding: '6px',borderRadius:'30%' }} ref={LatestModelsfilterButtonRef} onClick={handleToggleLatestModelsFilter}>
                                        <FilterIcon />
                                        <Typography variant="body2" className={homepageCss.filterText} style={{fontFamily:"Inter",fontWeight:500}}>Filters</Typography>
                                    </IconButton>
                                </Box>
                                <FilterModel
                                    open={isLatestModelsFilterModalOpen}
                                    onClose={handleCloseLatestModelFilterModal}
                                    onApply={handleApplyLatestModelsFilters}
                                    buttonPosition={LatestModelsfilterButtonRef.current}
                                    //@ts-ignore
                                    params={selectedLatestModelsFilters}
                                />
                            </div>
                            {/* Model Cards */}
                            {isLatestModelsDataFetching ?
                                <ShimmerPlaceholder />
                                : <>
                                <HompageModelsCard
                                    availableModelsData={availableLatestModelsData}
                                    modelFetchStatus={latestModelFetchStatus}
                                    fetchedModelResources={fetchedLatestModelResources}
                                    scenario="latestModelsData"
                                />
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className={homepageCss.backgroundAnimationAbsoluteWrapper}>
                <div className={homepageCss.blobGreen}></div>
                <div className={homepageCss.blobViolet}></div>
            </Box> 
        </Box>
    );
};

export default RLEFHomePage;