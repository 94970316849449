import {
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableSortLabel,
  Box,
  Grid
} from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import css from "./Model-Collection.module.css"
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { ContactsOutlined, Delete } from "@material-ui/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { NoTableData } from "../../data/constants";
import Popover from "@material-ui/core/Popover";
import {
  convertDate,
  CustomSkeleton,
  IDatasetCollectionPageNumberQuery,
  IModelArchitecturesAvailable,
  IModelCollection,
  IReduxState,
  IReTestApiResponse,
  ModelCollectionSortField,
  queryObjectToString,
  SortOrder,
  useQuery,
  ModelCollectionTableFilterParms,
  IModelUniqueTrainingDataSet,
  IModelCollectionsAdditionalData,
  ModelCollectionTableSortParams,
  accuracyStatus,
  IModel,
  IIntegrity,
  IGetIntegrity,
  IAdditionalHandTrackingDataQueryParams,
  AdditionalHandTrackingDataApiResponse,
  CustomButton,
  ICollectionMetrics,
} from "../../../../../../../../../../../common";
import {
  getCollections,
  setSideDrawer,
  setTopActionButton,
} from "../../../../../../../../../../../store/actions";
import { Slider, Switch } from '@material-ui/core';
import ModelAnalytics from "../../../../../components/Analytics";
import { deleteButtonIconColor } from "../../data/constants/constants";
import { useURLQuery } from "../../../../../../../../../../../hooks/useURLQuery";
import { convertURLQueryParamsToJSON } from "../../../../../../../../../../../services/urlQueryParamService";
import CollectionEnhancedTableToolbar from "./CollectionEnhancedTableToolbar";
import AddModelCollection from "./AddModelCollection";
import {
  DataService,
  ModelService,
} from "../../../../../../../../../../../services";

// import ModelCollectionView from "./ModelCollectionView";
import {
  addSelectedModelCollections,
  addSelectedModelCollectionsToCompare,
  deleteModelCollection,
  setEmptyModelCollection,
  // setLiveModelCollection,
  setModelCollection,
  setModelCollectionTotalCount,
  setSelectedModelCollection,
  setSelectedModelCollections,
  setSelectedModelCollectionsToCompare,
  setShouldFetchModelCollections,
  confirmDeleteCollection,
  updateReTestingModelCollectionDetails,
} from "../../../../../../../../../../../store/actions/collectionActions";
import clsx from "clsx";
import {
  tableContainerStyle,
  tablePaperStyle,
} from "../../../constants/tableStyles";
import { useTableHeadCellsCount } from "../hooks/useTableHeadCellsCount";
import AddTestCollection from "./AddTestCollection";
// import DeploySVGIcon from "../../../../../../../../../../../assets/svg/deploy.svg";
import PerformModelCollectionDeploymentDialog from "../../data/components/PerformModelCollectionDeploymentDialog";
import DeleteModelCollectionErrorPopup from "./DeleteModelCollectionErrorPopup";
import ModelStatus from "./ModelStatus";
import { ModelCollectionAccuracy } from "./ModelCollectionAccuracy";
import { TimeDateFormat } from "../../data/components/TimeDateFormat";
import CompareModelCollectionView from "./CompareModelCollectionView";
import WarningIcon from "@material-ui/icons/Warning";
import SubActionMenu from "./SubAction-Menu";
import ModelCollectionPopup from "./ModelCollectionPopup";
import DeleteCollectionConfirmPopup from "./confirmCollectionDelete";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  AccuracyColumnMenu,
  ArchitectureColumnMenu,
  StatusColumnMenu,
  TrainingDataSetCollectionNameColumnMenu,
  VersionColumnMenu,
} from "./ModelCollectionTableFilterComponent";
import { CreatedDateColumnMenu } from "../../data/components/TableContent";
import { sortVersionsInDescendingOrder } from "../../../../../../../../../../../services/versionHelperService";
import { Canceler } from "axios";
import useSetUrlSearchParamsOfCurrentRoute from "../../../../../../../../../../../hooks/useSetUrlSearchParamsOfCurrentRoute";
import { style } from "d3-selection";
import { CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE, IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS } from "./integrity";
import {ReactComponent as StatisticsIcon} from '../../../../../../../../../../../assets/svg/Icon-statistics.svg'
import TrainedModelPopup from "../TrainedModelPopup";
import { isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";
import UserDBService from "../../../../../../../../../../../services/UserDBService";
import { SHOW_TRAINING_POD_SIMPLIFICATION_FEATURE } from '../../../../../../../../../../../services/constants';
import { collectionMetricsFromAiModelTestResultCSVApiResponse } from "../../../../../../../../../../../services/DataService";
import CheckIcon from '@material-ui/icons/Check';
import BrowseModelCollectionsPopupContext from "../../../../../browseModelCollectionsPopupContext/browseModelCollectionsPopup.context";
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';


const tableCellCheckboxWidth = "40px";
const tableCellVersionWidth = "115px";
const tableCellStatusWidth = "160px";
const tableCellActionsWidth = "150px";
const tableCellViewWidth = "100px";

export enum ModelCollectionTableQueryParam {
  OFFSET = "offset",
  LIMIT = "limit",
  MODEL_ID = "model",
  MODEL_TYPE = "type",
  PROJECT_ID = "project",
  HIGHLIGHT_MODEL_COLLECTION_ID = "highlightModelCollectionId",
  STATUS = "status",
}

export enum ModelCollectionTableFilterQueryParams {
  MODEL_COLLECTION_SORT_FIELD_SEARCH_VALUE_QUERY_PARAM = "sortField",
  MODEL_COLLECTION_SORT_ORDER_SEARCH_VALUE_QUERY_PARAM = "sortOrder",
  MODEL_COLLECTION_VERION_SEARCH_VALUE_QUERY_PARAM = "versionSearchValue",
  MODEL_COLLECTION_STATUS_SEARCH_VALUE_QUERY_PARAM = "statusSearchValue",
  MODEL_COLLECTION_TRAINING_DATASET_COLLECTION_NAME_SEARCH_VALUE_QUERY_PARAM = "trainingDataSetCollectionNameSearchValue",
  MODEL_COLLECTION_ARCHITECTURE_SEARCH_VALUE_QUERY_PARAM = "architectureSearchValue",
  MODEL_COLLECTION_ACCURACY_STATUS_SEARCH_VALUE_QUERY_PARAM = "accuracySearchValue",
  MODEL_COLLECTION_ACCURACY_MIN_SEARCH_VALUE_QUERY_PARAM = "accuracyMinValue",
  MODEL_COLLECTION_ACCURACY_MAX_SEARCH_VALUE_QUERY_PARAM = "accuracyMaxValue",
  MODEL_COLLECTION_CREATED_AT_MIN_SEARCH_VALUE_QUERY_PARAM = "createdAtMinValue",
  MODEL_COLLECTION_CREATED_AT_MAX_SEARCH_VALUE_QUERY_PARAM = "createdAtMaxValue",
}

const useStyles = makeStyles((theme) => ({
  paper: tablePaperStyle(theme),
  table: {
    // minWidth: 750,
    tableLayout: "fixed",
  },
  tableRow: {
    width: "100%",
  },
  liveRow: {
    position: "sticky",
    top: "35px",
    zIndex: 5,
    opacity: 1,
    // background: "#CCE8EB 0% 0% no-repeat padding-box",
    backgroundColor: "white",
    border: "2px solid #008D9C",
    borderRadius: "10px",
    margin: "5px",
    boxShadow: `inset 0px 0px 3px 2px ${theme.palette.primary.main}`,
    "& td": {
      borderBottom: "none",
    },
    // "& :selected": {
    //   background: "#CCE8EB 0% 0% no-repeat padding-box !important",
    // }
  },
  liveRowHover: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#f2f2f2  !important",
      opacity: 1,
    },
    opacity: 1,
  },
  rowSelected: {
    backgroundColor: "#CCE8EB !important",
    "&:hover": {
      backgroundColor: "#CCE8EB !important",
      opacity: 1,
    },
  },
  tableCellCheckbox: {
    width: `${tableCellCheckboxWidth} !important`,
  },
  tableCellVersion: {
    width: tableCellVersionWidth,
    textAlign: "center",
  },
  tableCellStatus: {
    width: tableCellStatusWidth,
    textAlign: "center",
    paddingRight: "0px",
    paddingLeft: "0px",
  },
  tableCellLabels: {
    width: "auto",
    // width: `calc(38.3333333333% - (${tableCellTotalFixedWidth}))`
    textAlign: "center",
  },
  tableCellTraningDataDirectory: {
    "@media(min-width:1300px)": {
      width: "auto",
    },
    "@media(min-width:1000px) and (max-width: 1300px)": {
      width: "350px",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "300px",
    },
    "@media(max-width: 700px)": {
      width: "250px",
    },
    // width: `calc(38.3333333333% - (${tableCellTotalFixedWidth}))`
    textAlign: "center",
  },
  tableCellTestDataSet: {
    "@media(min-width:1300px)": {
      width: "auto",
    },
    "@media(min-width:1000px) and (max-width: 1300px)": {
      width: "300px",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "250px",
    },
    "@media(max-width: 700px)": {
      width: "200px",
    },
    textAlign: "center",
  },
  architecture: {
    width: "160px",
    textAlign: "center",
  },
  emptyAccuracyBox: {
    border: "1px solid #ccc",
    color: "#ffffff",
    padding: "1.5px",
    borderRadius: "25px",
    backgroundColor: "#717983",
    textAlign: "center",
  },
  accuracyBox: {
    border: "1px solid #ccc",
    padding: "1px",
    borderRadius: "25px",
    backgroundColor: "white",
    textAlign: "center",
  },
  integrityFrequencyAccuracy: {
    width: "162px",
    textAlign: "center",
  },
  tableCellCreatedAt: {
    "@media(min-width:1300px)": {
      width: "auto",
    },
    "@media(min-width:1000px) and (max-width: 1300px)": {
      width: "260px",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "220px",
    },
    "@media(max-width: 700px)": {
      width: "180px",
    },
    textAlign: "center",
    // width: `calc(23.3333333333% - (${tableCellTotalFixedWidth}))`
  },
  borderBottomWhiteColor: {
    borderBottomColor: "white",
  },
  tableCellActions: {
    width: tableCellActionsWidth,
    textAlign: "center",
    "& button:first-child": {
      paddingLeft: "0",
      "&:focus": {
        outline: "none",
      },
    },
  },
  tableCellView: {
    width: tableCellViewWidth,
    textAlign: "center",
  },
  tableDisabledCell: {
    pointerEvents: "none",
    opacity: "0.5",
  },
  chip: {
    width: "auto",
    backgroundColor: theme.palette.grey[50],
    marginRight: theme.spacing(1),
  },
  deleteButton: {
    color: deleteButtonIconColor,
  },
  deployButton: {
    color: "#008D9C",
  },
  tableContainer: tableContainerStyle,
  root: {
    marginBottom: theme.spacing(2),
    position: "sticky",
    top: "0px",
    backgroundColor: "#f0f5fc",
    zIndex: 1,
  },
  indicator: {
    backgroundColor: "transparent",
  },
  delete: {
    color: deleteButtonIconColor,
  },
  progress: {
    padding: "10px",
  },
  tableCellAccuracy: {
    width: "135px",
    textAlign: "center",
  },
  accuracyChip: {
    backgroundColor: theme.palette.grey[50],
  },
  lastModifiedTime: {
    color: "#008D9C",
    fontSize: "16px",
  },
  highlightRow: {
    background: "#ffffe2",
  },
  viewIcon: {
    margin: 0,
  },
  chipContainer: {
    width: "150px",
  },
  accuracyColumnChipContainer: {
    overflow: "visible",
  },
  sortLabelIcon: {
    display: "none",
  },
  sortLabelRoot: {
    "&:hover .MuiTableSortLabel-icon": {
      display: "flex",
    },
  },
  sortLabelActive: {
    minWidth: "90px",
    color: "inherit !important",
    "& .MuiTableSortLabel-icon": {
      display: "flex",
    },
  },
  columnOptionsIcon: {
    padding: 0,
  },
  modelCollectionTableFilterAutoCompleteChipRoot: {
    maxWidth: "159px",
  },
  modelCollectionTableFilterAutoComplete: {
    zIndex: 100,
    position: "absolute",
    background: "#ffff",
  },
  tableColumnMenuHeading: {
    display: "flex",
    justifyContent: "space-between",
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
}));

// interface IIntegritySliderData {
//   modelCollectionId: string,
//   confidenceScoreThreshold: number
//   isSliderEnable: boolean
// }

export type DatasetCollectionType = "training" | "test";

export type PageToGo = "prevPage" | "nextPage";

const collectionTabs = [
  { label: "Pending Review", path: "/pending-review", status: "pending" },
  { label: "Approved", path: "/approved", status: "approved" },
  { label: "Deleted", path: "/deleted", status: "deleted" },
];

function ModelCollection(props: TPropsFromRedux) {
  const classes = useStyles();
  const history = useHistory();
  const historyPush = history.push;
  const dispatch = useDispatch();
  const location = useLocation();
  const { search, pathname } = location;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isRedirectingToCollection, setIsRedirectingToCollection] =
    useState<string>("");

  const query = useQuery();

  const [openTooltip, setOpenTooltip] = useState<boolean []>([])

  const browseModelCollectionPopupContext = useContext(BrowseModelCollectionsPopupContext)

  // const [modelCollections, setModelCollections] = useState<IModelCollection[]>(
  //   []
  // );
  // const [collectionsTotalCount, setCollectionsTotalCount] = useState<number>(0);

  const [isFetchingModelCollections, setIsFetchingModelCollections] =
    useState<boolean>(false);
  const [isFetchingLiveModelCollection, setIsFetchingLiveModelCollection] =
    useState<boolean>(false);
  const [
    liveModelCollectionAccuracyIfPresent,
    setLiveModelCollectionAccuracyIfPresent,
  ] = useState<number | null>(0);
  const [architecturesAvailable, setArchitecturesAvailable] =
    useState<IModelArchitecturesAvailable>([]);
  /** Array of Collections selected using checkbox */
  const [selectedCollections, setSelectedCollections] = useState<
    IModelCollection[]
  >([]);

  const [collectionsBeingDeleted, setCollectionsBeingDeleted] = useState<
    string[]
  >([]);

  // const [moreActionMenu, setMoreActionMenu] = useState<null | HTMLElement>(null);
  // const actionMenuOpen = Boolean(moreActionMenu)
  const [isReTestApiFetching, setisReTestApiFetching] =
    useState<boolean>(false);
  const [reTestApiResponse, setReTestApiResponse] =
    useState<IReTestApiResponse>();
  const [isForceReTestApiFetching, setForceIsReTestApiFetching] =
    useState<boolean>(false);
  const [dialogPopupState, setDialogPopupState] = useState<{
    isOpen: boolean;
    openForId: string;
  }>({ isOpen: false, openForId: "" });

  const [isModelCollectionPopupOpen, setIsModelCollectionPopupOpen] =
    useState<boolean>(false);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const [modelCollectionUniqueVersions, setModelCollectionUniqueVersions] =
    useState<string[]>(["10.0.1", "10.0.2", "10.0.3", "10.0.4"]);
  const [
    modelCollectionUniqueArchitectures,
    setModelCollectionUniqueArchitectures,
  ] = useState<string[]>([]);
  const [
    modelCollectionUniqueTrainingDataSets,
    setModelCollectionUniqueTrainingDataSets,
  ] = useState<IModelUniqueTrainingDataSet[]>([]);
  const [modelCollectionUniqueStatuses, setModelCollectionUniqueStatuses] =
    useState<string[]>([]);
  const [
    modelCollectionUniqueAccuracyStatuses,
    setModelCollectionAccuracyStatuses,
  ] = useState<string[]>([]);
  // const [indexValue, setIndexValue] = useState<number>(0);

  const [modelDetails, setModelDetails] = useState<IModel | undefined>(undefined);
  const [trainedModelPopupOpen, setTrainedModelPopupOpen] = useState<any>({});

  const [modelCollectionIdsForWhichIntegrityAcrossVariousConfidenceScoreBandsBeingCollected, setModelCollectionIdsForWhichIntegrityAcrossVariousConfidenceScoreBandsBeingCollected] = useState<string[]>([]);
  
  const [modelCollectionsIntegrityDataAcrossVariousConfidenceScoreBands, setModelCollectionsIntegrityDataAcrossVariousConfidenceScoreBands] = useState<IIntegrity[]>([]);

  const modelCollectionFiltersortOrder: SortOrder =
    (query?.get(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_ORDER_SEARCH_VALUE_QUERY_PARAM
    ) as SortOrder) || "descending";
  const modelCollectionFiltersortField: ModelCollectionSortField =
    (query?.get(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_FIELD_SEARCH_VALUE_QUERY_PARAM
    ) as ModelCollectionSortField) || "created_at";
  const modelCollectionFilterversionsSearchValue = useMemo(
    () =>
      query
        ?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_VERION_SEARCH_VALUE_QUERY_PARAM
        )
        ?.split(",") || [],
    [
      query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_VERION_SEARCH_VALUE_QUERY_PARAM
      ) || "",
    ]
  );

  const [collectionMetricsFromAiModelTestResultCSV, setCollectionMetricsFromAiModelTestResult] = useState<ICollectionMetrics[]>([])
  const [isFetchingCollectionMetrics, setIsFetchingCollectionMetrics] = useState<boolean>(false)

  /** ................. Local constants ................ */
  const isTaskTypeAudioTextTranscription = modelDetails?.type === 'audioTextTranscription'

  const fetchModelDetails = async (modelId: string) => {
    const apiResponse = await ModelService.getModelDetails({
        modelId: modelId,
        modelSelectQuery: 'isSegmentationModelAnalytics confidenceScoreThresholdForIntegrity iouThresholdValue'
    })

    return apiResponse.data
  }

    const updateCollectionMetricsState = useCallback((modelCollectionId: string, testCollectionId: string, metrics: collectionMetricsFromAiModelTestResultCSVApiResponse) => {
        // if testCollectionId is passed, it will updata state based on testCollectionId
        setCollectionMetricsFromAiModelTestResult(prevState => {
            const newState = [...prevState]

            let objForCurrentId = newState.find(obj => obj.modelCollectionId === modelCollectionId)

            if (objForCurrentId) {
                objForCurrentId = {
                    ...objForCurrentId,
                    ...metrics
                }
            }
            else newState.push({
                ...metrics,
                modelCollectionId: modelCollectionId
            })

            return newState
        })
    }, [])

    // fetch collection metrics for each collection
    const fetchCollectionMetrics = async () => {
        if (props.modelCollectionsAvailable && props.modelCollectionsAvailable.length > 0) {
            const modelId = query.get('model')

            let modelDetails = await fetchModelDetails(modelId || "")

            if (modelDetails?.isSegmentationModelAnalytics) {
                setIsFetchingCollectionMetrics(true)
                const promises = props.modelCollectionsAvailable.map((collection: IModelCollection) => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            const response = await DataService.getCollectionMetricsFromAiModelTestResultCSV({
                                modelCollectionId: collection._id,
                                testCollectionId: "",
                                confidenceScoreThreshold: modelDetails.confidenceScoreThresholdForIntegrity,
                                iouThreshold: modelDetails.iouThresholdValue
                            })

                            if(response.data){
                                updateCollectionMetricsState(collection._id, "", response.data)
                            }

                            resolve(response.data)
                        } catch (error) {
                            reject(error);
                        }
                    })
                })

                await Promise.all(promises)
            }
            setIsFetchingCollectionMetrics(false)
        }
    }

    const collectionMetricsFromAiModelTestResultCSVRef = useRef(collectionMetricsFromAiModelTestResultCSV);
    collectionMetricsFromAiModelTestResultCSVRef.current = collectionMetricsFromAiModelTestResultCSV;

    useEffect(() => {
        if(!props.modelCollectionsAvailable?.every(model => collectionMetricsFromAiModelTestResultCSVRef.current.find(obj => obj.modelCollectionId === model._id))){
            fetchCollectionMetrics()
        }
    }, [props.modelCollectionsAvailable, query.get('model'), modelDetails?.confidenceScoreThresholdForIntegrity, modelDetails?.iouThresholdValue])

    useEffect(() => {
        const clearTrainingCompletionPercentageDataFromDB = async () => {
            if (props.modelCollectionsAvailable) {
                for (const collection of props.modelCollectionsAvailable) {
                    if (collection.status !== "Training" && collection.trainingCompletionPercentages?.length && collection.trainingCompletionPercentages.find(obj => obj.testDataSetCollectionId === collection.defaultDataSetCollectionId)) {
                        const newArray = collection.trainingCompletionPercentages.filter(obj => obj.testDataSetCollectionId !== collection.defaultDataSetCollectionId);
                        DataService.updateModelCollection({
                            id: collection._id,
                            trainingCompletionPercentages: newArray
                        })
                    }
                }
            }
        }

        clearTrainingCompletionPercentageDataFromDB();
    }, [props.modelCollectionsAvailable])

  const modelCollectionFilterstatusesSearchValue = useMemo(
    () =>
      query
        ?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_STATUS_SEARCH_VALUE_QUERY_PARAM
        )
        ?.split(",") || [],
    [
      query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_STATUS_SEARCH_VALUE_QUERY_PARAM
      ) || "",
    ]
  );
  const modelCollectionFiltertrainingDataSetCollectionNamesSearchValue =
    useMemo(
      () =>
        query
          ?.get(
            ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_TRAINING_DATASET_COLLECTION_NAME_SEARCH_VALUE_QUERY_PARAM
          )
          ?.split(",") || [],
      [
        query?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_TRAINING_DATASET_COLLECTION_NAME_SEARCH_VALUE_QUERY_PARAM
        ) || "",
      ]
    );
  // const modelCollectionFiltertrainingDataSetCollectionNamesSearchValue = query?.get(ModelCollectionTrainingDataSetCollectionNameSearchValueQueryParam)?.split(",") || [];
  // const modelCollectionFiltertestDataSetCollectionIdsSearchValue = query?.get(ModelCollectionTestDataSetCollectionIdSearchValueQueryParam)?.split(",") || [];
  const modelCollectionFilterarchitecturesSearchValue = useMemo(
    () =>
      query
        ?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ARCHITECTURE_SEARCH_VALUE_QUERY_PARAM
        )
        ?.split(",") || [],
    [
      query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ARCHITECTURE_SEARCH_VALUE_QUERY_PARAM
      ) || "",
    ]
  );
  const modelCollectionFiltercollectionAccuracyStatusValue = useMemo(
    () =>
      query
        ?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_STATUS_SEARCH_VALUE_QUERY_PARAM
        )
        ?.split(",") || [],
    [
      query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_STATUS_SEARCH_VALUE_QUERY_PARAM
      ) || "",
    ]
  );
  // const modelCollectionFilterarchitecturesSearchValue = query?.get(ModelCollectionArchitectureSearchValueQueryParam)?.split(",") || [];
  const modelCollectionFiltercollectionAccuracyMinValue: string =
    query?.get(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_MIN_SEARCH_VALUE_QUERY_PARAM
    ) || "";
  const modelCollectionFiltercollectionAccuracyMaxValue: string =
    query?.get(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_MAX_SEARCH_VALUE_QUERY_PARAM
    ) || "";
  const modelCollectionFiltercollectionCreatedDateMinValue: string =
    query?.get(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_CREATED_AT_MIN_SEARCH_VALUE_QUERY_PARAM
    ) || "";
  const modelCollectionFiltercollectionCreatedDateMaxValue: string =
    query?.get(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_CREATED_AT_MAX_SEARCH_VALUE_QUERY_PARAM
    ) || "";
  const modelId: string =
    query?.get(ModelCollectionTableQueryParam.MODEL_ID) || "";
  const getModelCollectionApiCancelFunctionRef = useRef<Canceler | null>(null);
  const getLiveModelCollectionApiCancelFunctionRef = useRef<Canceler | null>(
    null
  );
  const [isApiRequestCancelled, setApiRequestCancelled] =
    useState<boolean>(false);

  const [sortField, setSortField] = useState<ModelCollectionTableSortParams>({
    order: "ascending",
    field: "",
  });
  const [tableFilter, setTableFilter] =
    useState<ModelCollectionTableFilterParms>({
      filter: {
        version: {
          searchValue: [],
          menuOpen: false,
        },
        status: {
          searchValue: [],
          menuOpen: false,
        },
        trainingDataSetCollectionNames: {
          searchValue: [],
          menuOpen: false,
        },
        architectures: {
          searchValue: [],
          menuOpen: false,
        },
        accuracy: {
          accuracyStatus: [],
          minValue: "",
          maxValue: "",
          menuOpen: false,
        },
        createdDate: {
          minValue: "",
          maxValue: "",
          menuOpen: false,
        },
      },
    });

  console.log(
    `model collection selected value is ${JSON.stringify(
      props.modelCollectionsSelected
    )}`,
    tableFilter
  );

  function ModelCollectionPopupOpenButton(props: {
    onClick?: () => void;
    iconButtonStyles?: {};
  }) {
    const FullScreenButton = withStyles({
      root: {
        textTransform: "none",
      },
    })(Button);

    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <FullScreenButton
          startIcon={<FullscreenIcon />}
          onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
          color="primary"
          variant="contained"
        >
          View
        </FullScreenButton>
      </div>
    );
  }
  const getSortAndFilterValues = (query: URLSearchParams) => {
    const sortOrder: SortOrder =
      (query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_ORDER_SEARCH_VALUE_QUERY_PARAM
      ) as SortOrder) || "descending";
    const sortField: ModelCollectionSortField =
      (query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_FIELD_SEARCH_VALUE_QUERY_PARAM
      ) as ModelCollectionSortField) || "created_at";
    const versionsSearchValue =
      query
        ?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_VERION_SEARCH_VALUE_QUERY_PARAM
        )
        ?.split(",") || [];
    const statusesSearchValue =
      query
        ?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_STATUS_SEARCH_VALUE_QUERY_PARAM
        )
        ?.split(",") || [];
    const trainingDataSetCollectionNamesSearchValue =
      query
        ?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_TRAINING_DATASET_COLLECTION_NAME_SEARCH_VALUE_QUERY_PARAM
        )
        ?.split(",") || [];
    const architecturesSearchValue =
      query
        ?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ARCHITECTURE_SEARCH_VALUE_QUERY_PARAM
        )
        ?.split(",") || [];
    const collectionAccuracyStatusValue: accuracyStatus[] | [] =
      (query
        ?.get(
          ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_STATUS_SEARCH_VALUE_QUERY_PARAM
        )
        ?.split(",") as accuracyStatus[]) || [];
    const collectionAccuracyMinValue: string =
      query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_MIN_SEARCH_VALUE_QUERY_PARAM
      ) || "";
    const collectionAccuracyMaxValue: string =
      query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_MAX_SEARCH_VALUE_QUERY_PARAM
      ) || "";
    const collectionCreatedDateMinValue: string =
      query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_CREATED_AT_MIN_SEARCH_VALUE_QUERY_PARAM
      ) || "";
    const collectionCreatedDateMaxValue: string =
      query?.get(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_CREATED_AT_MAX_SEARCH_VALUE_QUERY_PARAM
      ) || "";

    return {
      sortOrder,
      sortField,
      versionsSearchValue,
      statusesSearchValue,
      trainingDataSetCollectionNamesSearchValue,
      architecturesSearchValue,
      collectionAccuracyStatusValue,
      collectionAccuracyMinValue,
      collectionAccuracyMaxValue,
      collectionCreatedDateMaxValue,
      collectionCreatedDateMinValue,
    };
  };

  const getUniqueModelCollectionVersions = async (modelId: string) => {
    const apiResponse = await ModelService.getUniqueModelCollectionVersions({
      modelId: modelId,
    });
    if (apiResponse.data) {
      const updatedResponse = sortVersionsInDescendingOrder(
        apiResponse.data.uniqueModelCollectionVersions.map((uniqueVersion) =>
          JSON.parse(`{"version" : "${uniqueVersion}"}`)
        )
      ).map((response) => response.version);
      setModelCollectionUniqueVersions(updatedResponse);
    }
  };

  const getUniqueModelCollectionArchitectures = async (modelId: string) => {
    const apiResponse =
      await ModelService.getUniqueModelCollectionArchitectures({
        modelId: modelId,
      });
    if (apiResponse.data) {
      setModelCollectionUniqueArchitectures(
        apiResponse.data.uniqueModelCollectionArchitectures
      );
    }
  };

  const getUniqueModelCollectionTrainingDataSets = async (modelId: string) => {
    const apiResponse =
      await ModelService.getUniqueModelCollectionTrainingDataSets({
        modelId: modelId,
      });
    if (apiResponse.data) {
      setModelCollectionUniqueTrainingDataSets(
        apiResponse.data.uniqueModelCollectionTrainingDataSets
      );
    }
  };

  const getUniqueModelCollectionStatuses = async (modelId: string) => {
    const apiResponse = await ModelService.getUniqueModelCollectionStatuses({
      modelId: modelId,
    });
    if (apiResponse.data) {
      setModelCollectionUniqueStatuses(
        apiResponse.data.uniqueModelCollectionStatuses
      );
    }
  };

  const getUniqueModelCollectionAccuracyStatuses = async (modelId: string) => {
    const apiResponse =
      await ModelService.getUniqueModelCollectionAccuracyStatuses({
        modelId: modelId,
      });
    if (apiResponse.data) {
      setModelCollectionAccuracyStatuses(
        apiResponse.data.uniqueModelCollectionAccuracyStatuses
      );
    }
  };

  const handleSort = (labelFieldName: ModelCollectionSortField) => {
    const urlQuery = new URLSearchParams(location?.search);
    let previousSortOrder: SortOrder = sortField.order;
    let sortOrderToSet: SortOrder = "ascending";
    if (sortField.field !== labelFieldName) {
      sortOrderToSet = "ascending";
    } else {
      if (previousSortOrder === "ascending") {
        sortOrderToSet = "descending";
      } else {
        sortOrderToSet = "ascending";
      }
    }
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_ORDER_SEARCH_VALUE_QUERY_PARAM,
      sortOrderToSet
    );
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_FIELD_SEARCH_VALUE_QUERY_PARAM,
      labelFieldName
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
    setSortField({ field: labelFieldName, order: sortOrderToSet });
    // fetchModelCollection(undefined, undefined, undefined, undefined, tableFilter, {field: labelFieldName, order: sortOrderToSet})
  };

  const styleClass: any = classes;

  async function setVersionFilterSearchValue(value: string[]): Promise<void> {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          version: {
            ...oldState.filter.version,
            menuOpen: false,
            searchValue: value,
          },
        },
      };
    });
  }

  function resetVersionFilter() {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          version: {
            ...oldState.filter.version,
            searchValue: [],
            menuOpen: false,
          },
        },
      };
    });
    updateUrlForVersion([]);
  }

  function deleteParamsOnCloseTrainedModelPopup () {
    const urlQuery = new URLSearchParams(location?.search);
    const viewIntegrity = urlQuery.get("viewIntegrity");
    if(viewIntegrity === "true") {
      urlQuery.delete("viewIntegrity");
    }
    history.replace(`${location?.pathname}?${urlQuery.toString()}`);
  }

  const updateUrlForVersion = (search: string[]) => {
    const urlQuery = new URLSearchParams(location?.search);
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_VERION_SEARCH_VALUE_QUERY_PARAM,
      search.join(",")
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
  };

  async function setStatusFilterSearchValue(value: string[]): Promise<void> {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          status: {
            ...oldState.filter.status,
            menuOpen: false,
            searchValue: value,
          },
        },
      };
    });
  }

  function resetStatusFilter() {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          status: {
            ...oldState.filter.status,
            searchValue: [],
            menuOpen: false,
          },
        },
      };
    });
    updateUrlForStatus([]);
  }

  const updateUrlForStatus = (search: string[]) => {
    const urlQuery = new URLSearchParams(location?.search);
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_STATUS_SEARCH_VALUE_QUERY_PARAM,
      search.join(",")
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
  };

  async function setTrainingDataSetFilterSearchValue(
    value: string[]
  ): Promise<void> {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          trainingDataSetCollectionNames: {
            ...oldState.filter.trainingDataSetCollectionNames,
            menuOpen: false,
            searchValue: value,
          },
        },
      };
    });
  }

  function resetTrainingDataSetFilter() {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          trainingDataSetCollectionNames: {
            ...oldState.filter.trainingDataSetCollectionNames,
            searchValue: [],
            menuOpen: false,
          },
        },
      };
    });
    updateUrlForTrainingDataSet([]);
  }

  const updateUrlForTrainingDataSet = (search: string[]) => {
    const urlQuery = new URLSearchParams(location?.search);
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_TRAINING_DATASET_COLLECTION_NAME_SEARCH_VALUE_QUERY_PARAM,
      search.join(",")
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
  };

  async function setArchitectureFilterSearchValue(
    value: string[]
  ): Promise<void> {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          architectures: {
            ...oldState.filter.architectures,
            menuOpen: false,
            searchValue: value,
          },
        },
      };
    });
  }

  function resetArchitectureFilter() {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          architectures: {
            ...oldState.filter.architectures,
            searchValue: [],
            menuOpen: false,
          },
        },
      };
    });
    updateUrlForArchitecture([]);
  }

  const updateUrlForArchitecture = (search: string[]) => {
    const urlQuery = new URLSearchParams(location?.search);
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ARCHITECTURE_SEARCH_VALUE_QUERY_PARAM,
      search.join(",")
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
  };

  function handleAccuracyApplyFilterButtonClick(
    value: [number, number],
    accuracyStatus: string[]
  ) {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          accuracy: {
            ...oldState.filter.accuracy,
            menuOpen: false,
            minValue: value[0]?.toString() || "",
            maxValue: value[1]?.toString() || "",
            accuracyStatus: accuracyStatus,
          },
        },
      };
    });
    updateUrlForAccuracy(
      value[0]?.toString() || "",
      value[1]?.toString() || "",
      accuracyStatus
    );
  }

  function resetAccuracyFilter() {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          accuracy: {
            ...oldState.filter.accuracy,
            maxValue: "",
            minValue: "",
            accuracyStatus: [],
            menuOpen: false,
          },
        },
      };
    });
    updateUrlForAccuracy("", "", []);
  }

  const updateUrlForAccuracy = (
    minValue: string,
    maxValue: string,
    accuracyStatus: string[]
  ) => {
    const urlQuery = new URLSearchParams(location?.search);
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_MIN_SEARCH_VALUE_QUERY_PARAM,
      minValue
    );
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_MAX_SEARCH_VALUE_QUERY_PARAM,
      maxValue
    );
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_STATUS_SEARCH_VALUE_QUERY_PARAM,
      accuracyStatus.join(",")
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
  };

  function handleResourceCreatedAtDateApplyFilterButtonClick(
    value: [string, string]
  ) {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          createdDate: {
            ...oldState.filter.createdDate,
            menuOpen: false,
            minValue: value[0],
            maxValue: value[1],
          },
        },
      };
    });
    updateUrlForDate(value[0], value[1]);
  }

  function resetDateFilter() {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          createdDate: {
            ...oldState.filter.createdDate,
            maxValue: "",
            minValue: "",
            menuOpen: false,
          },
        },
      };
    });
    updateUrlForDate("", "");
  }

  const updateUrlForDate = (minValue: string, maxValue: string) => {
    const urlQuery = new URLSearchParams(location?.search);
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_CREATED_AT_MIN_SEARCH_VALUE_QUERY_PARAM,
      minValue
    );
    urlQuery.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_CREATED_AT_MAX_SEARCH_VALUE_QUERY_PARAM,
      maxValue
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
  };

  // This empty function is created as it is a mandatory paramter to the component CreatedDateColumnMenu which is imported from TableComponent.tsx .
  async function setCreatedDateFilterMinMaxValue(
    value: [string, string]
  ): Promise<void> { }

  // async function setAccuracyFilterMinMaxValue(value: [number, number]): Promise<void> {
  // }

  function iterateOverResources(direction: 1 | -1) {
    // direction === 1 indicates moving to the next resource and direction === -1 indicates moving to the previous resource
    if (props.modelCollectionsAvailable) {
      for (var i = 0; i < props.modelCollectionsAvailable.length; i++) {
        if (
          props.modelCollectionsAvailable[i]._id ===
          props.modelCollectionSelected
        ) {
          if (direction === 1) {
            if (i + 1 < props.modelCollectionsAvailable.length) {
              // dispatch(setSelectedModelCollection(props.modelCollectionsAvailable[i+1]._id))
              setSelectedModelCollection(
                props.modelCollectionsAvailable[i + 1]._id
              );
              // setIndexValue(i+1)
              // setSelectedResource(props.resourcelist[i+1])
            } else if (
              rowsPerPage * (page + 1) <=
              props.totalCountOfModelCollections
            ) {
              handleChangePage(null, page + 1, "nextPage");
            }
          } else if (direction === -1) {
            if (i - 1 >= 0) {
              // dispatch(setSelectedModelCollection(props.modelCollectionsAvailable[i-1]._id))
              setSelectedModelCollection(
                props.modelCollectionsAvailable[i - 1]._id
              );
              // setIndexValue(i-1)
              // setSelectedResource(props.resourcelist[i-1])
            } else if (page > 0) {
              handleChangePage(null, page - 1, "prevPage");
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    if (props.modelCollectionsAvailable)
      console.log(props.modelCollectionsAvailable);

    if (
      props.modelCollectionsAvailable &&
      props.modelCollectionsAvailable.length > 0
    ) {
      for (let i = 0; i < props.modelCollectionsAvailable.length; i++) {
        if (
          props.modelCollectionsAvailable[i]._id ===
          props.modelCollectionSelected
        ) {
          addOrRemoveArrows(i);
        }
      }
    }
  }, [props.modelCollectionSelected]);
  const addOrRemoveArrows = (index: number) => {
    const OffsetValue: string = urlQuery.get("offset") as string;
    const limit: string = urlQuery.get("limit") as string;
    let addLiveIndex = 0;
    if (isLiveCollectionInFirstRow) {
      addLiveIndex = 1;
    }
    const globalCurrentRowValue =
      (Number(OffsetValue) - 1) * Number(limit) + (index + 1);
    if (globalCurrentRowValue === 1) {
      setShowLeftArrow(false);
    } else {
      setShowLeftArrow(true);
    }
    if (
      globalCurrentRowValue - addLiveIndex ===
      props.totalCountOfModelCollections
    ) {
      setShowRightArrow(false);
    } else {
      setShowRightArrow(true);
    }
  };

  const urlQuery = useURLQuery();
  const {
    // selected,
    setSideDrawer,
    setSelectedModelCollection,
    setTopActionButton,
    shouldFetchModelCollections,
    setShouldFetchModelCollections,
    modelCollectionsAvailable,
    // totalCountOfModelCollections,
    isSideDrawerOpen,
  } = props;

  const tableId = "table";
  const tableHeadCellsCount = useTableHeadCellsCount(tableId, [
    isSideDrawerOpen,
  ]);
  const [isBestModelCollectionAvailable, setIsBestModelCollectionAvailable] =
    useState(false);
  const [selectedModelCollectionAccuracyValue, setSelectedModelCollectionAccuracyValue] = useState<number>(0);

  // const downloadCSV = (path: string) => {
  //   window.open(path, "_self");
  // };

  const highlightModelCollectionRowForFewSeconds = useCallback(
    (modelCollectionIdOfRowToHighlight: string) => {
      const tableRowElement = document.getElementById(
        modelCollectionIdOfRowToHighlight
      );
      if (tableRowElement) {
        tableRowElement.scrollIntoView({ behavior: "smooth", block: "center" });
        tableRowElement.classList.add(`${classes.highlightRow}`);
        setTimeout(() => {
          tableRowElement.classList.remove(`${classes.highlightRow}`);
        }, 3000);
      }
    },
    [classes.highlightRow]
  );

  const removeHighlightModelCollectionIdFromUrlQueryParams = useCallback(() => {
    const updatedUrlSearchParams = new URLSearchParams(window.location.search);
    if (
      updatedUrlSearchParams.get(
        ModelCollectionTableQueryParam.HIGHLIGHT_MODEL_COLLECTION_ID
      )
    ) {
      updatedUrlSearchParams.delete(
        ModelCollectionTableQueryParam.HIGHLIGHT_MODEL_COLLECTION_ID
      );
      let queryParamsJson = convertURLQueryParamsToJSON(
        updatedUrlSearchParams.entries()
      );
      const queryString = `?${queryObjectToString(queryParamsJson)}`;
      historyPush(`${location.pathname}${queryString}`);
    }
  }, [historyPush, location.pathname]);

  const removeSelectedModelCollectionIfItGotDeleted = useCallback(() => {
    if (!props.modelCollectionsAvailable) {
      return;
    }

    for (const collection of props.modelCollectionsAvailable) {
      if (collection._id === props.modelCollectionSelected) {
        return;
      }
    }
    // setSelectedModelCollection(null);
    // setIndexValue(0)
    // dispatch(setSelectedModelCollection(null))
  }, [props.modelCollectionsAvailable, props.modelCollectionSelected]);

  const handleChangePage = async (
    event: unknown,
    newPage: number,
    pageToGo?: PageToGo
  ) => {
    if (!isFetchingModelCollections && !isFetchingLiveModelCollection) {
      let queryParamsJson = convertURLQueryParamsToJSON(urlQuery.entries());
      queryParamsJson = {
        ...queryParamsJson,
        offset: newPage + 1,
      };
      const queryString = `?${queryObjectToString(queryParamsJson)}`;

      history.push(`${location.pathname}${queryString}`);
      setIsBestModelBtnClicked(false);

      // setSelectedCollections([]);
      // dispatch(setSelectedModelCollections([]));
      // await fetchLiveCollections("", String(queryParamsJson.offset), pageToGo, props.modelCollectionSelected);
      await fetchModelCollection(
        "",
        String(queryParamsJson.offset),
        pageToGo,
        props.modelCollectionSelected
      );

      // fetchCollections("", String(queryParamsJson.offset));
    }
  };
  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isFetchingModelCollections && !isFetchingLiveModelCollection) {
      let queryParamsJson = convertURLQueryParamsToJSON(urlQuery.entries());
      queryParamsJson = {
        ...queryParamsJson,
        limit: Number(event.target.value),
        offset: 1,
      };
      setRowsPerPage(Number(event.target.value));
      setIsBestModelBtnClicked(false);
      const queryString = `?${queryObjectToString(queryParamsJson)}`;
      history.push(`${location.pathname}${queryString}`);

      // setSelectedCollections([]);
      // dispatch(setSelectedModelCollections([]));

      await fetchModelCollection(event.target.value, String(1));
      // fetchCollections(event.target.value, String(1));
    }
  };
  // const handleClick = (data: string) => {
  //   if(isSideDrawerOpen && data === props.modelCollectionSelected){
  //     setSideDrawer({isOpen: false});
  //   }else{
  //     setSideDrawer({ type: "modelCollectionView", component: ModelCollectionView, isOpen: true });
  //     setSelectedModelCollection(data);
  //   }
  // };

  const fetchModelCollection = useCallback(
    (
      limitValue: string = "",
      offsetValue: string = "",
      pageToGo?: PageToGo,
      selectedModelCollection?: string,
      onGettingCancelLiveModelCollectionApiFunction?:
        | ((cancelApiFunction: Canceler) => void)
        | undefined,
      onGettingCancelModelCollectionApiFunction?:
        | ((cancelApiFunction: Canceler) => void)
        | undefined
    ): Promise<void> => {
      return new Promise<void>(async (resolve, reject) => {
        const query = new URLSearchParams(location.search);
        const limit = limitValue || query.get("limit") || "10";
        const model = query.get("model") || "";
        const offset = offsetValue || query.get("offset") || "1";
        const sortAndFilterValues = getSortAndFilterValues(query);
        const viewIntegrity = query.get("viewIntegrity");
        setRowsPerPage(parseInt(limit, 10));
        setPage(parseInt(offset, 10) - 1);

        try {
          dispatch(setEmptyModelCollection(true));
          setIsFetchingModelCollections(true);
          setIsFetchingLiveModelCollection(true);
          setSortField({
            field: sortAndFilterValues.sortField,
            order: sortAndFilterValues.sortOrder,
          });
          setTableFilter({
            filter: {
              version: {
                searchValue: sortAndFilterValues.versionsSearchValue,
                menuOpen: false,
              },
              status: {
                searchValue: sortAndFilterValues.statusesSearchValue,
                menuOpen: false,
              },
              trainingDataSetCollectionNames: {
                searchValue:
                  sortAndFilterValues.trainingDataSetCollectionNamesSearchValue,
                menuOpen: false,
              },
              architectures: {
                searchValue: sortAndFilterValues.architecturesSearchValue,
                menuOpen: false,
              },
              accuracy: {
                accuracyStatus:
                  sortAndFilterValues.collectionAccuracyStatusValue,
                minValue: sortAndFilterValues.collectionAccuracyMinValue,
                maxValue: sortAndFilterValues.collectionAccuracyMaxValue,
                menuOpen: false,
              },
              createdDate: {
                minValue: sortAndFilterValues.collectionCreatedDateMinValue,
                maxValue: sortAndFilterValues.collectionCreatedDateMaxValue,
                menuOpen: false,
              },
            },
          });
          // fetching live model collection and model collections
          const [liveModelCollectionApiResponse, modelCollectionApiResponse] =
            await Promise.all([
              DataService.fetchModelCollectionsFromDB(
                {
                  limit: 1,
                  model,
                  offset: 1,
                  shouldFetchLiveModelCollection: true,
                  jsonFileData: "true",
                  versions: sortAndFilterValues?.versionsSearchValue || [],
                  statuses: sortAndFilterValues?.statusesSearchValue || [],
                  trainingDataSetCollectionNames:
                    sortAndFilterValues?.trainingDataSetCollectionNamesSearchValue ||
                    [],
                  accuracyStatus:
                    sortAndFilterValues?.collectionAccuracyStatusValue || [],
                  accuracyMinValue:
                    sortAndFilterValues?.collectionAccuracyMinValue || "",
                  accuracyMaxValue:
                    sortAndFilterValues?.collectionAccuracyMaxValue || "",
                  architectures:
                    sortAndFilterValues?.architecturesSearchValue || [],
                  createdAtMinValue:
                    sortAndFilterValues?.collectionCreatedDateMinValue || "",
                  createdAtMaxValue:
                    sortAndFilterValues?.collectionCreatedDateMaxValue || "",
                  sortField: sortAndFilterValues?.sortField || "created_at",
                  sortOrder: sortAndFilterValues?.sortOrder || "descending",
                  shouldFetchModelName: true,
                  shouldFetchProjectName: true
                },
                {
                  onGettingCancelLiveModelCollectionApiFunction:
                    onGettingCancelLiveModelCollectionApiFunction,
                }
              ),
              DataService.fetchModelCollectionsFromDB(
                {
                  limit: Number(limit),
                  // limit: 1,
                  model,
                  jsonFileData: "true",
                  offset: Number(offset),
                  shouldFetchLiveModelCollection: false,
                  versions: sortAndFilterValues?.versionsSearchValue || [],
                  statuses: sortAndFilterValues?.statusesSearchValue || [],
                  trainingDataSetCollectionNames:
                    sortAndFilterValues?.trainingDataSetCollectionNamesSearchValue ||
                    [],
                  accuracyStatus:
                    sortAndFilterValues?.collectionAccuracyStatusValue,
                  accuracyMinValue:
                    sortAndFilterValues?.collectionAccuracyMinValue || "",
                  accuracyMaxValue:
                    sortAndFilterValues?.collectionAccuracyMaxValue || "",
                  architectures:
                    sortAndFilterValues?.architecturesSearchValue || [],
                  createdAtMinValue:
                    sortAndFilterValues?.collectionCreatedDateMinValue || "",
                  createdAtMaxValue:
                    sortAndFilterValues?.collectionCreatedDateMaxValue || "",
                  sortField: sortAndFilterValues?.sortField || "created_at",
                  sortOrder: sortAndFilterValues?.sortOrder || "descending",
                  shouldFetchModelName: true,
                  shouldFetchProjectName: true
                },
                {
                  onGettingCancelModelCollectionApiFunction:
                    onGettingCancelModelCollectionApiFunction,
                }
              ),
            ]);
            if(modelCollectionApiResponse?.data?.collections.length === 1 && viewIntegrity === "true") {
              setTrainedModelPopupOpen((prevState: any) => (
                {
                  ...prevState,
                  [0]: true
                }
              ))
            }

          if (liveModelCollectionApiResponse.data.totalCount > 0) {
            if (liveModelCollectionApiResponse.data?.collections[0]?.accuracy) {
              setLiveModelCollectionAccuracyIfPresent(
                liveModelCollectionApiResponse.data?.collections[0]?.accuracy
              );
            }
            fetchBestModelCollection(
              liveModelCollectionApiResponse.data.collections[0]
            );
          } else {
            setLiveModelCollectionAccuracyIfPresent(0);
            setIsBestModelCollectionAvailable(false);
          }

          // Dispatch action for setting model collection and total count to the redux action
          if (
            liveModelCollectionApiResponse &&
            liveModelCollectionApiResponse.data.collections.length > 0
          ) {
            const newModelCollectionArray =
              liveModelCollectionApiResponse.data.collections.concat(
                modelCollectionApiResponse.data.collections
              );
            dispatch(setModelCollection(newModelCollectionArray));
          } else {
            dispatch(
              setModelCollection(modelCollectionApiResponse.data.collections)
            );
          }

          dispatch(
            setModelCollectionTotalCount(
              modelCollectionApiResponse.data.totalCount
            )
          );

          // setting the next or previous model collection as selected on navigation if any model collection was selected earlier

          if (
            selectedModelCollection &&
            liveModelCollectionApiResponse.data.collections.length > 0
          ) {
            if (pageToGo === "nextPage") {
              setSelectedModelCollection(
                liveModelCollectionApiResponse.data.collections[0]._id
              );
            }
          }
          if (
            selectedModelCollection &&
            modelCollectionApiResponse.data.collections.length > 0
          ) {
            if (
              liveModelCollectionApiResponse.data.collections.length === 0 &&
              pageToGo === "prevPage"
            ) {
              setSelectedModelCollection(
                modelCollectionApiResponse.data.collections[
                  modelCollectionApiResponse.data.collections.length - 1
                ]._id
              );
            } else if (
              liveModelCollectionApiResponse.data.collections.length === 0 &&
              pageToGo === "nextPage"
            ) {
              setSelectedModelCollection(
                modelCollectionApiResponse.data.collections[0]._id
              );
            } else if (
              liveModelCollectionApiResponse.data.collections.length === 1 &&
              pageToGo === "prevPage"
            ) {
              setSelectedModelCollection(
                modelCollectionApiResponse.data.collections[
                  modelCollectionApiResponse.data.collections.length - 1
                ]._id
              );
            }
          }

          const highlightModelCollectionId =
            query.get(
              ModelCollectionTableQueryParam.HIGHLIGHT_MODEL_COLLECTION_ID
            ) || "";

          if (highlightModelCollectionId) {
            setTimeout(() => {
              // Waiting for modelCollectionRow to render in DOM

              highlightModelCollectionRowForFewSeconds(
                highlightModelCollectionId
              );
            }, 1000);

            removeHighlightModelCollectionIdFromUrlQueryParams();
          }
          setApiRequestCancelled(false);

          // fetchLiveCollections(liveModelCollectionApiResponse.data, pageToGo, selectedModelCollection);
          // fetchCollections(modelCollectionApiResponse.data, pageToGo, liveModelCollectionApiResponse.data.collections.length, selectedModelCollection);
        } catch (error) {
          if (error.constructor.name === "Cancel") {
            setApiRequestCancelled(true);
          }
        }
        setIsFetchingModelCollections(false);
        setIsFetchingLiveModelCollection(false);
      });
    },
    [
      location.search,
      highlightModelCollectionRowForFewSeconds,
      removeHighlightModelCollectionIdFromUrlQueryParams,
    ]
  );

  useEffect(() => {
    if (getModelCollectionApiCancelFunctionRef.current) {
      getModelCollectionApiCancelFunctionRef.current();
      getModelCollectionApiCancelFunctionRef.current = null;
    }
    if (getLiveModelCollectionApiCancelFunctionRef.current) {
      getLiveModelCollectionApiCancelFunctionRef.current();
      getLiveModelCollectionApiCancelFunctionRef.current = null;
    }
    fetchModelCollection(
      undefined,
      undefined,
      undefined,
      undefined,
      (cancelApiFunction) => {
        getLiveModelCollectionApiCancelFunctionRef.current = cancelApiFunction;
      },
      (cancelApiFunction) => {
        getModelCollectionApiCancelFunctionRef.current = cancelApiFunction;
      }
    );

    console.log(
      modelCollectionFiltersortOrder,
      modelCollectionFiltersortField,
      modelCollectionFilterversionsSearchValue,
      modelCollectionFilterstatusesSearchValue,
      modelCollectionFiltertrainingDataSetCollectionNamesSearchValue,
      modelCollectionFilterarchitecturesSearchValue,
      modelCollectionFiltercollectionAccuracyMinValue,
      modelCollectionFiltercollectionAccuracyStatusValue,
      modelCollectionFiltercollectionAccuracyMaxValue,
      modelCollectionFiltercollectionCreatedDateMinValue,
      modelCollectionFiltercollectionCreatedDateMaxValue
    );
  }, [
    modelCollectionFiltersortOrder,
    modelCollectionFiltersortField,
    modelCollectionFilterversionsSearchValue,
    modelCollectionFilterstatusesSearchValue,
    modelCollectionFiltertrainingDataSetCollectionNamesSearchValue,
    modelCollectionFilterarchitecturesSearchValue,
    modelCollectionFiltercollectionAccuracyMinValue,
    modelCollectionFiltercollectionAccuracyStatusValue,
    modelCollectionFiltercollectionAccuracyMaxValue,
    modelCollectionFiltercollectionCreatedDateMinValue,
    modelCollectionFiltercollectionCreatedDateMaxValue,
  ]);

  useEffect(() => {
    // const query = new
    setTopActionButton({
      type: "addCollection",
      onClickComponentToRender: AddModelCollectionComponentWrapper,
    });
    setSideDrawer({
      type: "projectAnalytics",
      component: ModelAnalytics,
      isOpen: false,
    });

    // fetchModelCollection();
    setModelConfigurations();
    dispatch(setSelectedModelCollections([]));
    dispatch(setSelectedModelCollectionsToCompare([]));
  }, []);

  console.log("testing reload", modelDetails)
  const [accuracyLoader,setAccuracyLoader]=useState<boolean>(false);
  useEffect(() => {
    if (modelId) {
      getUniqueModelCollectionVersions(modelId);
      getUniqueModelCollectionArchitectures(modelId);
      getUniqueModelCollectionTrainingDataSets(modelId);
      getUniqueModelCollectionStatuses(modelId);
      getUniqueModelCollectionAccuracyStatuses(modelId);
    }
  }, [modelId]);

  const setModelConfigurations = async () => {
    const modelId: string = urlQuery.get("model") || "";
    const apiResponse = await ModelService.getModelDetails({
      modelId: modelId,
      modelSelectQuery: "architecturesAvailable isSegmentationModelAnalytics segmentationModelAnalyticsAccuracyMetric iouThresholdValue predictionStatusLogic maximumMisPredictionDeviationAllowedInNumber confidenceScoreThresholdForIntegrity isAnalyticsBasedOnWordErrorRateEnabled wordErrorRateThreshold type",
    });
    if (apiResponse.data) {
      setArchitecturesAvailable(apiResponse.data.architecturesAvailable);
    }
    setModelDetails(apiResponse.data)
  };

  const isSegmentationModelAnalyticsEnabled = modelDetails?.isSegmentationModelAnalytics;
  const isWordErrorRateModelAnalyticsEnabled = modelDetails?.isAnalyticsBasedOnWordErrorRateEnabled || false;

  useEffect(() => {
    const executeFunction = async () => {
      const integrityApiRequestPayload: IGetIntegrity = {
        modelCollectionIds: "",
        modelId: modelId,
        modelCollectionsAdditionalData: [],
        testCollectionId: ""
      }

      const modelCollectionIdsForWhichWeNeedtoFetchIntegrityData: string[] = (() => {
        let modelCollectionIdsForWhichWeNeedtoFetchIntegrityData: string[] = [];
        if (props.modelCollectionsAvailable && props.modelCollectionsAvailable?.length>0) {
          for (let modelCollectionIndex = 0; modelCollectionIndex < props.modelCollectionsAvailable?.length || 0; modelCollectionIndex++) {
            const modelCollectionShowingInUI = props.modelCollectionsAvailable?.[modelCollectionIndex];
            const isModelCollectionIntegrityAcrossVariousConfidenceScoreBandsDataAlreadyPresent: boolean = (()=>{
              const isPresent = modelCollectionsIntegrityDataAcrossVariousConfidenceScoreBands.find(modelCollection=>modelCollection._id === modelCollectionShowingInUI._id);
              if (isPresent) {
                return true
              } else {
                return false
              }
            })();
            if (!isModelCollectionIntegrityAcrossVariousConfidenceScoreBandsDataAlreadyPresent) {
              modelCollectionIdsForWhichWeNeedtoFetchIntegrityData = [...modelCollectionIdsForWhichWeNeedtoFetchIntegrityData, modelCollectionShowingInUI._id]
            }
          }
        }

        return modelCollectionIdsForWhichWeNeedtoFetchIntegrityData;
      })();

      if (!modelCollectionIdsForWhichWeNeedtoFetchIntegrityData || !modelCollectionIdsForWhichWeNeedtoFetchIntegrityData.length) {
        return;
      }

      integrityApiRequestPayload.modelCollectionIds = JSON.stringify(modelCollectionIdsForWhichWeNeedtoFetchIntegrityData)
      if (isSegmentationModelAnalyticsEnabled) {
        // @ts-ignore
        integrityApiRequestPayload.modelCollectionsAdditionalData = JSON.stringify(modelCollectionIdsForWhichWeNeedtoFetchIntegrityData
        .map(modelCollectionId=>{
          return {
            modelCollectionId: modelCollectionId,
            iouThreshold: !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
          }
        }))
      }

      if(isTaskTypeAudioTextTranscription){
        integrityApiRequestPayload['fetchWordErrorRateBands'] = true
      }

      setModelCollectionIdsForWhichIntegrityAcrossVariousConfidenceScoreBandsBeingCollected(oldState=>[...oldState, ...modelCollectionIdsForWhichWeNeedtoFetchIntegrityData])

      try {
        setAccuracyLoader(true);
        const doCacheApiResponse: boolean=modelDetails?.predictionStatusLogic==="labelAndErrorDeviation"?false:true
        const integrityApiResponse = await DataService.getIntegrity(integrityApiRequestPayload, doCacheApiResponse)
        if (integrityApiResponse?.data?.length>0) {
          setModelCollectionsIntegrityDataAcrossVariousConfidenceScoreBands(oldState=>{
            return [...oldState, ...integrityApiResponse?.data]
          })
        }
        setAccuracyLoader(false)
      } catch (error) {
        // @ts-ignore
        setAccuracyLoader(false)
        alert(error?.response.data || error?.message)
      }

      /**
       * Removing modelCollectionIds for which we have fetched data
       */
      setModelCollectionIdsForWhichIntegrityAcrossVariousConfidenceScoreBandsBeingCollected(oldState=>{
        for (let modelCollectionIdIndex = 0; modelCollectionIdIndex < oldState.length; modelCollectionIdIndex++) {
          const modelCollectionIdForWhichWeNeedtoFetchIntegrityData = oldState[modelCollectionIdIndex];
          if (modelCollectionIdsForWhichWeNeedtoFetchIntegrityData.includes(modelCollectionIdForWhichWeNeedtoFetchIntegrityData)) {
            oldState.splice(modelCollectionIdIndex, 1);
            modelCollectionIdIndex = modelCollectionIdIndex - 1;
          }
        }
        return [...oldState]
      })
    }

    if (props.modelCollectionsAvailable && props.modelCollectionsAvailable?.length>0 && modelId && modelDetails) {
      executeFunction();
    }
  }, [isSegmentationModelAnalyticsEnabled, modelCollectionsIntegrityDataAcrossVariousConfidenceScoreBands, modelDetails, modelId, props.modelCollectionsAvailable])

  useEffect(() => {
    removeSelectedModelCollectionIfItGotDeleted();
  }, [modelCollectionsAvailable, removeSelectedModelCollectionIfItGotDeleted]);

  useEffect(() => {
    if (shouldFetchModelCollections === true) {
      // fetchLiveCollections();
      setShouldFetchModelCollections({ shouldFetchModelCollections: false });
    }
  }, [
    shouldFetchModelCollections,
    // fetchLiveCollections,
    setShouldFetchModelCollections,
  ]);

  useEffect(() => {
    if (props.deleteModelCollectionTotalCount > 0) {
      fetchModelCollection();
    }
  }, [props.deleteModelCollectionTotalCount]);

  function handleTestButtonClick(collection: IModelCollection) {
    setSideDrawer({
      type: "addCollection",
      component: () =>
        AddTestCollectionComponentWrapperForTrainingScenario(collection),
      isOpen: true,
    });
  }
  function AddTestCollectionComponentWrapperForTrainingScenario(
    collection?: IModelCollection
  ) {
    return (
      <AddTestCollection
        scenario={"testModelCollections"}
        selectedModelCollection={
          props?.modelCollectionsSelected?.length > 0
            ? props?.modelCollectionsSelected
            : collection
              ? [collection]
              : []
        }
      />
    );
  }

  // useEffect(() => {
  //     const query = new URLSearchParams(location.search);
  //     const limit = query.get('limit') || '10';
  //     const model = query.get('model') || '';
  //     const offset = isCollectionAddSuccess ? '1' : query.get('offset') || '1';
  //     setRowsPerPage(parseInt(limit, 10));
  //     setPage(parseInt(offset, 10) - 1);
  //     getCollections({ offset, limit, model });
  // }, [location, getCollections, isCollectionAddSuccess, isCollectionDeleteSuccess]);

  const isCollectionCheckboxChecked = (collectionId: string) => {
    for (const collection of props.modelCollectionsSelected) {
      if (collection._id === collectionId) {
        return true;
      }
    }
    return false;
  };

  const isLiveCollectionInFirstRow = useMemo(() => {
    if (isFetchingModelCollections) {
      return false;
    }
    if (modelCollectionsAvailable && modelCollectionsAvailable?.length > 0) {
      for (let index = 0; index < modelCollectionsAvailable.length; index++) {
        const modelCollection = modelCollectionsAvailable[index];
        if (modelCollection.status === "Live" && index === 0) {
          return true;
        }
      }
    }
    return false;
  }, [modelCollectionsAvailable, isFetchingModelCollections]);

  const handleCollectionRowCheckBoxClick = (
    collectionClicked: IModelCollection
  ) => {
    for (
      let index = 0;
      index < props.modelCollectionsSelected.length;
      index++
    ) {
      const selectedCollection = props.modelCollectionsSelected[index];
      if (selectedCollection._id === collectionClicked._id) {
        const oldSelectedCollections = props.modelCollectionsSelected;
        oldSelectedCollections.splice(index, 1);
        // setSelectedCollections([...oldSelectedCollections]);
        dispatch(setSelectedModelCollections([...oldSelectedCollections]));
        if (
          (selectedCollection.status === "Trained" || "Live" || "Re-Trained") &&
          selectedCollection.accuracy &&
          selectedCollection.accuracy > 0
        ) {
          const oldSelectedCollectionsToCompare =
            props.modelCollectionsSelectedToCompare.filter(
              (selectedModelCollection) => {
                return selectedModelCollection !== selectedCollection._id;
              }
            );
          // oldSelectedCollectionsToCompare.splice(index, 1);
          dispatch(
            setSelectedModelCollectionsToCompare([
              ...oldSelectedCollectionsToCompare,
            ])
          );
        }
        return;
      }
    }

    setSelectedCollections((oldData) => [...oldData, collectionClicked]);
    // const updateModelCollectionsSelected = [...props.modelCollectionsSelected, collectionClicked]
    dispatch(addSelectedModelCollections(collectionClicked));
    if (
      (collectionClicked.status === "Trained" || "Live" || "Re-Trained") &&
      collectionClicked.accuracy &&
      collectionClicked.accuracy > 0
    ) {
      dispatch(addSelectedModelCollectionsToCompare(collectionClicked._id));
    }
  };

  const handleSelectAllCollectionCheckboxClick = (isChecked: boolean) => {
    if (isChecked) {
      if (props.modelCollectionsAvailable) {
        // setSelectedCollections([...props.modelCollectionsAvailable]);
        dispatch(
          setSelectedModelCollections([...props.modelCollectionsAvailable])
        );
        const allModelCollectionsToCompare =
          props?.modelCollectionsAvailable?.filter((modelCollections) => {
            return (
              modelCollections.accuracy &&
              modelCollections.status &&
              modelCollections.accuracy > 0 &&
              (modelCollections.status === "Live" || "Trained" || "Re-Trained")
            );
          });
        const allModelCollectionsIdsToCompare =
          allModelCollectionsToCompare.map((modelCollection) => {
            return modelCollection._id;
          });
        allModelCollectionsIdsToCompare &&
          dispatch(
            setSelectedModelCollectionsToCompare([
              ...allModelCollectionsIdsToCompare,
            ])
          );
      }
    } else {
      // setSelectedCollections([]);
      dispatch(setSelectedModelCollections([]));
      dispatch(setSelectedModelCollectionsToCompare([]));
    }
  };

  const updatedModelCollectionsSelectedAfterDeleting = (
    collectionToDelete: string
  ) => {
    for (
      let index = 0;
      index < props.modelCollectionsSelected.length;
      index++
    ) {
      const collection = props.modelCollectionsSelected[index];
      if (collection._id === collectionToDelete) {
        props.modelCollectionsSelected.splice(index, 1);
        break;
      }
    }
    return [...props.modelCollectionsSelected];
  };
  const updatedModelCollectionsToCompareSelectedAfterDeleting = (
    collectionToDelete: string
  ) => {
    for (
      let index = 0;
      index < props.modelCollectionsSelectedToCompare.length;
      index++
    ) {
      const collection = props.modelCollectionsSelectedToCompare[index];
      if (collection === collectionToDelete) {
        props.modelCollectionsSelectedToCompare.splice(index, 1);
        break;
      }
    }
    return [...props.modelCollectionsSelectedToCompare];
  };
  const handleCollectionDeleteButtonClick = async (
    collectionIdToDelete: string
  ) => {
    setCollectionsBeingDeleted((oldValue) => {
      if (oldValue.indexOf(collectionIdToDelete) === -1) {
        oldValue.push(collectionIdToDelete);
      }
      return [...oldValue];
    });
    try {
      // const apiResponse = await DataService.deleteModelCollections({
      //   collections: [collectionIdToDelete],
      // });
      dispatch(deleteModelCollection({ collections: [collectionIdToDelete] }));

      // if (props.deleteModelCollectionTotalCount > 0) {
      //   fetchCollections();
      // }
      setSideDrawer({ isOpen: false });
    } catch (error: any) {
      alert(error.response.data);
    }
    setCollectionsBeingDeleted((oldValue) => {
      for (let index = 0; index < oldValue.length; index++) {
        const element = oldValue[index];
        if (element === collectionIdToDelete) {
          oldValue.splice(index, 1);
          break;
        }
      }
      return [...oldValue];
    });

    // setSelectedCollections((oldValue) => {
    //   for (let index = 0; index < oldValue.length; index++) {
    //     const collection = oldValue[index];
    //     if (collection._id === collectionIdToDelete) {
    //       oldValue.splice(index, 1);
    //       break;
    //     }
    //   }
    //   return [...oldValue];
    // });
    dispatch(
      setSelectedModelCollections(
        updatedModelCollectionsSelectedAfterDeleting(collectionIdToDelete)
      )
    );
    dispatch(
      setSelectedModelCollectionsToCompare(
        updatedModelCollectionsToCompareSelectedAfterDeleting(
          collectionIdToDelete
        )
      )
    );
  };

  useEffect(() => {
    if (
      props.sideDraweType &&
      props.sideDraweType === "addTestCollection" &&
      isSideDrawerOpen
    ) {
      setSideDrawer({
        type: "addTestCollection",
        component: () => AddTestCollectionComponentWrapperForTrainingScenario(),
        isOpen: true,
      });
    }
  }, [props.modelCollectionsSelected]);

  /**
   * Test button click handler for
   * multiple model selection
   */
  const testSelectedCollectionHandler = () => {
    setSideDrawer({
      type: "addTestCollection",
      component: () => AddTestCollectionComponentWrapperForTrainingScenario(),
      isOpen: true,
    });
  };

  const handleDeleteSelectedCollectionsButtonClick = async () => {
    let collectionIdsToDelete: string[] = props.modelCollectionsSelected.map(
      (selectedCollection) => selectedCollection._id
    );

    setCollectionsBeingDeleted((oldValue) => {
      for (const collectionIdToDelete of collectionIdsToDelete) {
        if (oldValue.indexOf(collectionIdToDelete) === -1) {
          oldValue.push(collectionIdToDelete);
        }
      }
      return [...oldValue];
    });

    if (collectionIdsToDelete.length > 0) {
      try {
        // const apiResponse = await DataService.deleteModelCollections({
        //   collections: [...collectionIdsToDelete],
        // });
        dispatch(deleteModelCollection({ collections: collectionIdsToDelete }));

        // if (props.deleteModelCollectionTotalCount > 0) {
        //   fetchCollections();
        // }
        setSideDrawer({ isOpen: false });
      } catch (error: any) {
        alert(error.response.data);
      }

      setCollectionsBeingDeleted((oldValue) => {
        for (let index = 0; index < oldValue.length; index++) {
          const element = oldValue[index];
          if (collectionIdsToDelete.includes(element)) {
            oldValue.splice(index, 1);
            index = index - 1;
          }
        }
        return [...oldValue];
      });

      // setSelectedCollections([]);
      dispatch(setSelectedModelCollections([]));
      dispatch(setSelectedModelCollectionsToCompare([]));
    }
  };

  const [isBestModelBtnClicked, setIsBestModelBtnClicked] = useState(false);
  const bestModelBtnClickHandler = async () => {
    const query = new URLSearchParams(location.search);
    // const model = query.get("model") || "";
    // const limit = query.get("limit") || "10";
    // const bestModelPageApiResponse = await DataService.getPageNumberOfBestModel({
    //   model,
    //   sortField: "created_at",
    //   // sortField.field || "created_at",
    //   sortOrder: "descending",
    //   // sortField.order || "descending",
    //   limit: parseInt(limit)
    // });
    query.set("offset", "1");
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_VERION_SEARCH_VALUE_QUERY_PARAM,
      ""
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_STATUS_SEARCH_VALUE_QUERY_PARAM,
      ""
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_TRAINING_DATASET_COLLECTION_NAME_SEARCH_VALUE_QUERY_PARAM,
      ""
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ARCHITECTURE_SEARCH_VALUE_QUERY_PARAM,
      ""
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_STATUS_SEARCH_VALUE_QUERY_PARAM,
      ""
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_MIN_SEARCH_VALUE_QUERY_PARAM,
      ""
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ACCURACY_MAX_SEARCH_VALUE_QUERY_PARAM,
      ""
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_CREATED_AT_MIN_SEARCH_VALUE_QUERY_PARAM,
      ""
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_CREATED_AT_MAX_SEARCH_VALUE_QUERY_PARAM,
      ""
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_FIELD_SEARCH_VALUE_QUERY_PARAM,
      "accuracy"
    );
    query.set(
      ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_ORDER_SEARCH_VALUE_QUERY_PARAM,
      "descending"
    );
    location.search = query.toString();
    history.push(location.pathname + "?" + query.toString());

    dispatch(setEmptyModelCollection(true));
    if (!isBestModelBtnClicked) {
      fetchModelCollection();
      setIsBestModelBtnClicked(true);
    }
    // setSelectedCollections([]);
    dispatch(setSelectedModelCollections([]));
    dispatch(setSelectedModelCollectionsToCompare([]));
  };

  const compareButtonClickHandler = () => {
    console.log("clicked compare");
    setSideDrawer({
      type: "addCollection",
      component: () => <CompareModelCollectionView />,
      isOpen: true,
    });
  };

  const [bestModel, setBestModel] = useState<IModelCollection>();
  useEffect(() => {
    if (
      !isFetchingModelCollections &&
      !isFetchingLiveModelCollection &&
      bestModel?._id &&
      isBestModelBtnClicked
    ) {
      document
        .getElementById(bestModel?._id || "")
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(bestModel?._id || "")
        ?.classList.add(`${classes.highlightRow}`);
      setTimeout(() => {
        document
          .getElementById(bestModel?._id || "")
          ?.classList.remove(`${classes.highlightRow}`);
        setIsBestModelBtnClicked(false);
      }, 2500);
    }
  }, [
    isFetchingModelCollections,
    bestModel,
    isFetchingLiveModelCollection,
    isBestModelBtnClicked,
  ]);
  const fetchBestModelCollection = async (
    availableLiveCollection: IModelCollection
  ) => {
    const query = new URLSearchParams(location.search);
    const model = query.get("model") || "";
    const apiResponseForBestModelCollection =
      await DataService.fetchModelCollectionsFromDB({
        model,
        sortOrder: "descending",
        sortField: "accuracy",
        limit: 1,
        jsonFileData: "true",
        shouldFetchLiveModelCollection: false,
      });

    const dataForBestModelCollection =
      apiResponseForBestModelCollection.data.collections;
    if (
      dataForBestModelCollection.length > 0 &&
      dataForBestModelCollection[0].accuracy &&
      availableLiveCollection.accuracy &&
      dataForBestModelCollection[0].accuracy > availableLiveCollection.accuracy
    ) {
      setBestModel(dataForBestModelCollection[0]);
      setIsBestModelCollectionAvailable(true);
    } else {
      setIsBestModelCollectionAvailable(false);
    }
  };
  // const selectedTab = collectionTabs.find((item) =>
  //   pathname.includes(item.path)
  // )?.path;
  // const rootPath = pathname.split(selectedTab || "")[0];

  const [
    performModelCollectionDeploymentPopupState,
    setPerformModelCollectionDeploymentPopupState,
  ] = useState<{ isOpen: boolean; selected: IModelCollection | undefined }>({
    isOpen: false,
    selected: undefined,
  });

  /**
   * To handle the click operation on
   * training and test data set collection column
   * @param index index of the selected dataset
   * @param case check the click on training or test data set
   */
  const dataSetCollectionColumnClickHandler = async (
    index: number,
    clickCase: DatasetCollectionType
  ) => {
    if (props?.modelCollectionsAvailable) {
      setIsRedirectingToCollection(`${index}-${clickCase}`);
      const collection = props.modelCollectionsAvailable[index];
      const queryObject: IDatasetCollectionPageNumberQuery = {
        pageRowsLimit: 10,
        sortField: "created_at",
        sortOrder: "descending",
      };
      const datasetName =
        clickCase === "training"
          ? collection.trainingDataDirectory[0]
          : collection.defaultDataSetCollectionName || "";
      const response = await DataService.getDatasetCollectionPageNumber({
        ...queryObject,
        modelId: collection.model._id,
        dataSetCollectionName: datasetName,
      });

      const model = query.get(ModelCollectionTableQueryParam.MODEL_ID) || "";
      const type = query.get(ModelCollectionTableQueryParam.MODEL_TYPE) || "";
      const project =
        query.get(ModelCollectionTableQueryParam.PROJECT_ID) || "";
      const newQuery = new URLSearchParams();
      newQuery.set(ModelCollectionTableQueryParam.MODEL_ID, model);
      newQuery.set(ModelCollectionTableQueryParam.MODEL_TYPE, type);
      newQuery.set(ModelCollectionTableQueryParam.PROJECT_ID, project);
      newQuery.set(
        ModelCollectionTableQueryParam.OFFSET,
        response.data.pageNumber.toString()
      );
      newQuery.set(
        ModelCollectionTableQueryParam.LIMIT,
        queryObject.pageRowsLimit.toString()
      );
      newQuery.set(ModelCollectionTableQueryParam.STATUS, "pending");
      newQuery.set(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_FIELD_SEARCH_VALUE_QUERY_PARAM,
        queryObject.sortField
      );
      newQuery.set(
        ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_SORT_ORDER_SEARCH_VALUE_QUERY_PARAM,
        queryObject.sortOrder
      );
      newQuery.set(
        ModelCollectionTableQueryParam.HIGHLIGHT_MODEL_COLLECTION_ID,
        response.data.dataSetCollectionId
      );
      setIsRedirectingToCollection("");
      const basePath = window.location.pathname;
      const updatedPath = basePath.replace('/collections', '/data');
            window.open(
            updatedPath.substring(0, updatedPath.lastIndexOf("/")) +
        "/data-set?" +
        newQuery.toString(),
        "_blank"
      );
    }
  };

  /*
  const handleClickMoreActions = (event: React.MouseEvent<HTMLElement>) => {
    setMoreActionMenu(event.currentTarget)
  }

  const hancleCloseMoreActions = () => {
    setMoreActionMenu(null)
  }
*/

  const handlePopupViewClick = (data: string, accuracyValue: number) => {
    setSelectedModelCollection(data);
    setSelectedModelCollectionAccuracyValue(accuracyValue);
    setIsModelCollectionPopupOpen(true);
  };

  /**
   * To handle Re-test button click
   * @param collection Model collection type
   */
  const handleReTestButtonClick = async (collection: IModelCollection) => {
    try {
      setisReTestApiFetching(true);
      const apiResponse = await DataService.modelCollectionReTest({
        modelCollectionId: collection._id,
        forceReTest: false,
      });
      setReTestApiResponse(apiResponse.data);
      if (apiResponse.data.AlreadyInProgress) {
        setDialogPopupState({ isOpen: true, openForId: collection._id });
      }
      dispatch(
        updateReTestingModelCollectionDetails({
          id: collection._id,
          reTestingDetail: apiResponse.data.reTestingDetails,
        })
      );
      setisReTestApiFetching(false);
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * To force the re-testing
   * @param modelCollectionId selected model id
   */
  const forceReTest = async (modelCollectionId: string) => {
    try {
      setForceIsReTestApiFetching(true);
      const apiResponse = await DataService.modelCollectionReTest({
        modelCollectionId: modelCollectionId,
        forceReTest: true,
      });
      setReTestApiResponse(apiResponse.data);
      dispatch(
        updateReTestingModelCollectionDetails({
          id: modelCollectionId,
          reTestingDetail: apiResponse.data.reTestingDetails,
        })
      );
      setForceIsReTestApiFetching(false);
      setDialogPopupState({ isOpen: false, openForId: "" });
    } catch (error) {
      console.log(error);
    }
  };

  const [integrityAccuracy, setIntegrityAccuracy] = useState(null);
  // const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [fetchingDataforIntegrityAccuracy, setFetchingDataforIntegrityAccuracy] = useState<boolean>()

  // const openPopover = (
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   setPopoverOpen(true);
  // };

  // const closePopover = () => {
  //   setPopoverOpen(false);
  // };

  const [confidenceScoreThresholdForIntegrity, setConfidenceScoreThresholdForIntegrity] = useState<number | undefined>()
  const [iouScoreThresholdForIntegrity, setIouScoreThresholdForIntegrity] = useState<number | undefined>()

  useEffect(() => {
    const iouAndConfidenceScoreThresholdPassingPercentageFunc = async () => {
      try {
        const modelId: string = urlQuery.get("model") || "";
        const apiResponse = await ModelService.getModelDetails({
          modelId: modelId,
          modelSelectQuery: "confidenceScoreThresholdForIntegrity iouThresholdValue",
        })
        setConfidenceScoreThresholdForIntegrity(apiResponse?.data?.confidenceScoreThresholdForIntegrity)
        setIouScoreThresholdForIntegrity(apiResponse?.data?.iouThresholdValue ||  IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS)
      } catch (e) {
        console.log("error", e)
      }
    }
    iouAndConfidenceScoreThresholdPassingPercentageFunc()
  }, [integrityAccuracy])

  useEffect(() => {
    const callToGetAccuracy = async () => {
      try {
        if (!props.modelCollectionsAvailable || props.modelCollectionsAvailable.length === 0) {
          return;
        }

        setIsFetchingCollectionMetrics(true);

        const collectionIds: any = props.modelCollectionsAvailable?.map((item) => item._id) || [];
        setFetchingDataforIntegrityAccuracy(true)
        // console.log("Collection IDs", collectionIds, modelId);
        console.log("thresholdApplied", thresholdApplied)
        const doCacheApiResponse: boolean=modelDetails?.predictionStatusLogic==="labelAndErrorDeviation"?false:true
        const modelCollectionsAdditionalData = [
          ...(isSegmentationModelAnalyticsEnabled || isWordErrorRateModelAnalyticsEnabled ? [] : (thresholdApplied?.length===0 ? (initialstate || []): (thresholdApplied || []))),
          ...(
            isSegmentationModelAnalyticsEnabled ?
              collectionIds?.map((id: string) => {
                return {
                  modelCollectionId: id,
                  //@ts-ignore
                  iouThreshold: !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS,
                  confidenceScoreThreshold: !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE
                }
              })
              :
              []
          ),
          ...(
            isWordErrorRateModelAnalyticsEnabled
              ?
                collectionIds?.map((id: string) => {
                  return {
                    modelCollectionId: id,
                    //@ts-ignore
                    confidenceScoreThreshold: !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE,
                    wordErrorRateThreshold: modelDetails?.wordErrorRateThreshold || 0
                  }
                })  
              : []              
          )
          
        ]

        const res = await DataService.getModelAnalyticsTestStatisticalData({
          modelId,
          getIntegrityFrequency: true,
          modelCollectionIds: JSON.stringify(collectionIds),
          getIntegrityFrequencyAccuracyPercent: true,
          modelCollectionsAdditionalData: JSON.stringify(modelCollectionsAdditionalData),
        }, doCacheApiResponse);

        //DataService.getModelAnalyticsTestStatisticalData
        let parsedData: Record<string, string> = {};
        res.data.forEach((item) => {
          // @ts-ignore
          const accuracyValue = item?.integrityFrequencyAccuracy?.value;
          const accuracyWithPercentage =
            accuracyValue !== undefined ? parseFloat(accuracyValue.toFixed(2)) + "%" : "N A";
          parsedData[item.modelCollectionId] = accuracyWithPercentage;
        });
        // @ts-ignore
        setIntegrityAccuracy(parsedData);
        setFetchingDataforIntegrityAccuracy(false)
      } catch (error) {
        console.log(error);
        return error;
      }
      finally {
        setFetchingDataforIntegrityAccuracy(false);
      }
    };
    callToGetAccuracy();
  }, [props.modelCollectionsAvailable, isSegmentationModelAnalyticsEnabled, modelDetails?.maximumMisPredictionDeviationAllowedInNumber, isWordErrorRateModelAnalyticsEnabled]);


  const setUrlSearchParamsOfCurrentRoute =
    useSetUrlSearchParamsOfCurrentRoute();
  const setUrlSearchParamsOfCurrentRouteRef = useRef(setUrlSearchParamsOfCurrentRoute);

  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  
  const [thresholdApplied, setThresholdApplied] =
    useState<IModelCollectionsAdditionalData[]>();

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {

    let modelCollectionsAdditionalData = searchParams.get('modelCollectionsAdditionalDataForIntegrity');

    if (!modelCollectionsAdditionalData) {
      setIsFilterApplied(false)
      setThresholdApplied([])
      return;
    }
    if (modelCollectionsAdditionalData !== "")
      setIsFilterApplied(true)

    setThresholdApplied(Object.values(JSON.parse(modelCollectionsAdditionalData)))
    //props.FetchModelAnalyticsTestStatisticalDataForIntegrityOfModel(JSON.parse(JSON.stringify(modelCollectionsAdditionalData)))


  }, [location.search]);

  const initialstate: IModelCollectionsAdditionalData[] | undefined = props?.modelCollectionsAvailable?.map((state) => {
    // let thresholdValue = thresholdApplied?.find((prevState) => prevState.modelCollectionId === state._id)?.confidenceScoreThreshold
    if (confidenceScoreThresholdForIntegrity === undefined) {
      return {
        modelCollectionId: state._id,
        confidenceScoreThreshold: 100,
      }
    }
    return {
      modelCollectionId: state._id,
      confidenceScoreThreshold: confidenceScoreThresholdForIntegrity,
    }
  })
  // const [sliderFieldState, setSliderFieldState] = useState<
  //   IModelCollectionsAdditionalData[]
  // >(
  //   []
  // );


  // useEffect(() => {

  //   const initialSliderisEnabled: boolean[] | undefined = props?.modelCollectionsAvailable?.map((state) => {
  //     let thresholdValue = thresholdApplied?.find((prevState) => prevState.modelCollectionId === state._id)?.confidenceScoreThreshold

  //     if (thresholdValue === undefined) {
  //       return false
  //     }
  //     return true
  //   })
  //   if (initialstate !== undefined)
  //     setSliderFieldState(initialstate);
  // }, [props.modelCollectionsAvailable])


  const [integrityAccuracyPassingPercentage, setIntegrityAccuracyPassingPercentage] = useState<number | undefined>()

  useEffect(() => {
    const setModelIntegrityAccuracyPassingPercentage = async () => {
      try {
        const modelId: string = urlQuery.get("model") || "";
        const apiResponse = await ModelService.getModelDetails({
          modelId: modelId,
          modelSelectQuery: "modelIntegrityPassingPercentage",
        })
        setIntegrityAccuracyPassingPercentage(apiResponse?.data?.modelIntegrityPassingPercentage)
        console.log("apiresss", apiResponse)
      } catch (e) {
        console.log("error", e)
      }
    }
    setModelIntegrityAccuracyPassingPercentage()
  }, [integrityAccuracy])

  useEffect(() => {
    if (!isFetchingModelCollections && !isFetchingLiveModelCollection) {
      setTimeout(() => {
        const element = document.getElementById(
          props.modelCollectionSelected || ""
        );
        if (element && !isBestModelBtnClicked) {
          element.scrollIntoView({
            block: "center",
            inline: "nearest",
            behavior: "smooth",
          });
        }
      }, 200);
    }
  }, [
    isFetchingModelCollections,
    isFetchingLiveModelCollection,
    props.modelCollectionSelected,
  ]);

  const [handstrackingData, setHandstrackingData] = useState<AdditionalHandTrackingDataApiResponse>([]);
  const doWeNeedToSHowHandsTrackingData = modelId === "656f5e0e29b391c0646cc554" ? true : false;
  const [ishandtrackingdatafetching, setIsHandTrackingDatafetching] = useState<boolean>(true);

  useEffect(() => {
    if (modelId === "656f5e0e29b391c0646cc554") {
      const collectionIds: string[] = props.modelCollectionsAvailable?.map((item) => item._id) || [];

      const queryParams: IAdditionalHandTrackingDataQueryParams = {
        modelId: modelId,
        modelCollectionIds:collectionIds,
      };
      const fetchData = async () => {
        try {
      
          const response = await DataService.getAdditionalHandTrackingData(queryParams);
          setHandstrackingData(response.data);     
          setIsHandTrackingDatafetching(false)
        } catch (error) {
          console.error('Error fetching data:', error);
          // setIsHandTrackingDatafetching(true)
      
        }
    
      };
      fetchData();
    }
  }, [modelId,props.modelCollectionsAvailable]); 

  const [finalPath, setFinalPath] = useState('');

  useEffect(() => {
    const modelParam = searchParams.get('model');
    const pathSplit=pathname.split('/')
    pathSplit.pop();
    pathSplit.pop();
    setFinalPath(pathSplit.join('/')+'/addNewModel/aboutModel/problemType');
  },[])

  useEffect(() => {
    const falseArray = Array(props.modelCollectionsAvailable?.length).fill(false);
    setOpenTooltip([...falseArray])
  }, [props.modelCollectionsAvailable])

  return (
    <Paper className={classes.paper} variant="outlined">
      <Grid container justify="space-between">
      { props.scenario === "browseModelCollectionsPopup" ? 
      <h4 style={{ padding: 20 }}>Models</h4>
      : <CollectionEnhancedTableToolbar
        onDeleteClick={() => {
          let collectionIdsToDelete: string[] = selectedCollections.map(
            (selectedCollection) => selectedCollection._id
          );

          props.confirmDeleteCollection({
            multiDelete: true,
            collectionToDelete: null,
            showConfirmDeleteModal: true,
            selectedCollectionIds: collectionIdsToDelete,
            collectionType: "model",
          });
        }}
        onTestClick={() => testSelectedCollectionHandler()}
        status={["deleted", "test"]}
        resources={props.modelCollectionsAvailable || []}
        selected={props.modelCollectionsSelected}
        isDeleteHappening={collectionsBeingDeleted.length > 0}
        shouldShowBestModel={isBestModelCollectionAvailable}
        onBestButtonClick={() => bestModelBtnClickHandler()}
        shouldShowCompareButton={true}
        onCompareButtonClick={() => compareButtonClickHandler()}
        isFetchingModelCollections={isFetchingModelCollections}
      />}
        {
          SHOW_TRAINING_POD_SIMPLIFICATION_FEATURE && props.modelCollectionsSelected.length <1 &&
          props.scenario !== "browseModelCollectionsPopup" &&
          <CustomButton
            variant="outlined"
            style={{margin:'10px', padding:'20px', border:'1px solid #008D9C', fontWeight:'bold', boxShadow:'none', height:'20px'}}
            onClick={() => {
              history.push(`${finalPath}?${searchParams}`)
            }}
          >
            {`Add Model >`}
          </CustomButton>
        }
      </Grid>
      {isModelCollectionPopupOpen && (
        <ModelCollectionPopup
          architecturesAvailable={architecturesAvailable}
          // disabledDeployButton={
          //   integrityAccuracyPassingPercentage !== undefined
          //   && integrityAccuracyPassingPercentage !== null
          //   && integrityAccuracy !== null
          //   && integrityAccuracy !== undefined
          //   && integrityAccuracy?.[props?.modelCollectionSelected] !== undefined
          //   && integrityAccuracy?.[props?.modelCollectionSelected] !== null
          //   && integrityAccuracyPassingPercentage <= parseInt(`${integrityAccuracy?.[props?.modelCollectionSelected]}`, 10) ? true : false
          // }
          disabledDeployButton={
            false
          }
          open={isModelCollectionPopupOpen}
          handleClose={() => setIsModelCollectionPopupOpen(false)}
          modelCollectionId={props.modelCollectionSelected}
          handleReTestButtonClick={handleReTestButtonClick}
          isReTestApiFetching={isReTestApiFetching}
          isForceReTestApiFetching={isForceReTestApiFetching}
          onMoveToLeft={() => {
            iterateOverResources(-1);
          }}
          onMoveToRight={() => {
            iterateOverResources(1);
          }}
          isResourceExistBefore={showLeftArrow}
          isResourceExistAfter={showRightArrow}
          // onDeleteClick={() => handleDeleteSelectedCollectionsButtonClick()}
          onTestClick={() => testSelectedCollectionHandler()}
          isFetching={
            isFetchingLiveModelCollection || isFetchingModelCollections
          }
          collectionMetrics={collectionMetricsFromAiModelTestResultCSV}
          isFetchingCollectionMetrics={isFetchingCollectionMetrics}
          accuracyValue={selectedModelCollectionAccuracyValue}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
          id={tableId}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          stickyHeader
        >
          <TableHead>
            <TableRow
              classes={{ root: classes.tableRow }}
              style={{ fontSize: " 12px" }}
            >
              {props.scenario !== "browseModelCollectionsPopup" && <TableCell
                padding="checkbox"
                classes={{ root: classes.tableCellCheckbox }}
                className={`header
                ${isLiveCollectionInFirstRow
                    ? classes.borderBottomWhiteColor
                    : ""
                  }
              `}
              >
                <Checkbox
                  indeterminate={
                    props?.modelCollectionsSelected?.length > 0 &&
                    props?.modelCollectionsSelected?.length <
                    (props?.modelCollectionsAvailable?.length || 0)
                  }
                  checked={
                    (props?.modelCollectionsAvailable?.length || 0) > 0 &&
                    props?.modelCollectionsSelected?.length ===
                    props?.modelCollectionsAvailable?.length
                  }
                  onChange={(e) =>
                    handleSelectAllCollectionCheckboxClick(e.target.checked)
                  }
                  inputProps={{ "aria-label": "select all desserts" }}
                />
              </TableCell>}
              <TableCell
                classes={{ root: classes.tableCellVersion }}
                style={{
                  color: "#98A6B3",
                  font: "normal normal normal 14px/22px Nunito Sans",
                }}
              >
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  <TableSortLabel
                    active={sortField.field === "version"}
                    direction={
                      sortField.field === "version"
                        ? sortField.order === "ascending"
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={(e) => {
                      handleSort("version");
                    }}
                    classes={{
                      icon: classes.sortLabelIcon,
                      root: classes.sortLabelRoot,
                      active: classes.sortLabelActive,
                    }}
                    style={{
                      paddingLeft: sortField.field === "version" ? "" : "10px",
                    }}
                  >
                    Version
                  </TableSortLabel>
                  <div>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      id="versionColumnOptions"
                      classes={{
                        root: classes.columnOptionsIcon,
                      }}
                      onClick={() => {
                        setTableFilter((oldState) => {
                          console.log("filter state, ", oldState);
                          return {
                            ...oldState,
                            filter: {
                              ...oldState.filter,
                              version: {
                                ...oldState.filter.version,
                                menuOpen: true,
                              },
                            },
                          };
                        });
                      }}
                    >
                      {tableFilter.filter.version.searchValue.length > 0 &&
                        tableFilter.filter.version.searchValue[0] !== "" ? (
                        <Tooltip title="Filter applied on version">
                          <FilterListIcon />
                        </Tooltip>
                      ) : (
                        <MoreVertIcon />
                      )}
                    </IconButton>
                    {tableFilter.filter.version.menuOpen && (
                      //  <span>
                      //   hello
                      // </span>
                      <VersionColumnMenu
                        open={tableFilter.filter.version.menuOpen}
                        onClose={() =>
                          setTableFilter((oldState) => {
                            return {
                              ...oldState,
                              filter: {
                                ...oldState.filter,
                                version: {
                                  ...oldState.filter.version,
                                  menuOpen: false,
                                },
                              },
                            };
                          })
                        }
                        versionSearchValue={
                          tableFilter.filter.version.searchValue
                        }
                        resetVersionFilters={() => resetVersionFilter()}
                        classes={styleClass}
                        uniqueVersionsAvailable={modelCollectionUniqueVersions}
                        // searchOperator={tableFilter.filter.version.searchOperator}
                        isVersionFilterApplied={
                          tableFilter.filter.version.searchValue.length > 0
                        }
                        onVersionApplyFilterButtonClick={(value: string[]) =>
                          updateUrlForVersion(value)
                        }
                        setVersionFilterSearchValue={(value: string[]) =>
                          setVersionFilterSearchValue(value)
                        }
                      />
                    )}
                  </div>
                </Box>
              </TableCell>
              <TableCell
                classes={{ root: classes.tableCellStatus }}
                style={{
                  color: "#98A6B3",
                  font: "normal normal normal 14px/22px Nunito Sans",
                }}
              >
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  <TableSortLabel
                    active={sortField.field === "status"}
                    direction={
                      sortField.field === "status"
                        ? sortField.order === "ascending"
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={(e) => {
                      handleSort("status");
                    }}
                    classes={{
                      icon: classes.sortLabelIcon,
                      root: classes.sortLabelRoot,
                      active: classes.sortLabelActive,
                    }}
                  >
                    Status
                  </TableSortLabel>
                  <div>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      id="statusColumnOptions"
                      classes={{
                        root: classes.columnOptionsIcon,
                      }}
                      onClick={() => {
                        setTableFilter((oldState) => {
                          console.log("filter state, ", oldState);
                          return {
                            ...oldState,
                            filter: {
                              ...oldState.filter,
                              status: {
                                ...oldState.filter.status,
                                menuOpen: true,
                              },
                            },
                          };
                        });
                      }}
                    >
                      {tableFilter.filter.status.searchValue.length > 0 &&
                        tableFilter.filter.status.searchValue[0] !== "" ? (
                        <Tooltip title="Filter applied on status">
                          <FilterListIcon />
                        </Tooltip>
                      ) : (
                        <MoreVertIcon />
                      )}
                    </IconButton>
                    {tableFilter.filter.status.menuOpen && (
                      //  <span>
                      //   hello
                      // </span>
                      <StatusColumnMenu
                        open={tableFilter.filter.status.menuOpen}
                        onClose={() =>
                          setTableFilter((oldState) => {
                            return {
                              ...oldState,
                              filter: {
                                ...oldState.filter,
                                status: {
                                  ...oldState.filter.status,
                                  menuOpen: false,
                                },
                              },
                            };
                          })
                        }
                        statusSearchValue={
                          tableFilter.filter.status.searchValue
                        }
                        resetStatusFilters={() => resetStatusFilter()}
                        classes={styleClass}
                        uniqueStatusesAvailable={modelCollectionUniqueStatuses}
                        isStatusFilterApplied={
                          tableFilter.filter.status.searchValue.length > 0
                        }
                        onStatusApplyFilterButtonClick={(value: string[]) =>
                          updateUrlForStatus(value)
                        }
                        setStatusFilterSearchValue={(value: string[]) =>
                          setStatusFilterSearchValue(value)
                        }
                      />
                    )}
                  </div>
                </Box>
              </TableCell>
              {/* <TableCell classes={{root: classes.tableCellLabels}}>Labels</TableCell>               */}
              <TableCell
                classes={{ root: classes.tableCellTraningDataDirectory }}
                style={{
                  width: "142px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: "#98A6B3",
                  font: "normal normal normal 14px/22px Nunito Sans",
                }}
              >
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  <Tooltip title="Training Data Sets">
                    <span>Training Data Set</span>
                  </Tooltip>
                  <div>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      id="trainingDataSetCollectionNameColumnOptions"
                      classes={{
                        root: classes.columnOptionsIcon,
                      }}
                      onClick={() => {
                        setTableFilter((oldState) => {
                          console.log("filter state, ", oldState);
                          return {
                            ...oldState,
                            filter: {
                              ...oldState.filter,
                              trainingDataSetCollectionNames: {
                                ...oldState.filter
                                  .trainingDataSetCollectionNames,
                                menuOpen: true,
                              },
                            },
                          };
                        });
                      }}
                    >
                      {tableFilter.filter.trainingDataSetCollectionNames
                        .searchValue.length > 0 &&
                        tableFilter.filter.trainingDataSetCollectionNames
                          .searchValue[0] !== "" ? (
                        <Tooltip title="Filter applied on training data sets">
                          <FilterListIcon />
                        </Tooltip>
                      ) : (
                        <MoreVertIcon />
                      )}
                    </IconButton>
                    {tableFilter.filter.trainingDataSetCollectionNames
                      .menuOpen && (
                        //  <span>
                        //   hello
                        // </span>
                        <TrainingDataSetCollectionNameColumnMenu
                          open={
                            tableFilter.filter.trainingDataSetCollectionNames
                              .menuOpen
                          }
                          onClose={() =>
                            setTableFilter((oldState) => {
                              return {
                                ...oldState,
                                filter: {
                                  ...oldState.filter,
                                  trainingDataSetCollectionNames: {
                                    ...oldState.filter
                                      .trainingDataSetCollectionNames,
                                    menuOpen: false,
                                  },
                                },
                              };
                            })
                          }
                          trainingDataSetCollectionNameSearchValue={
                            tableFilter.filter.trainingDataSetCollectionNames
                              .searchValue
                          }
                          resetTrainingDataSetCollectionNameFilters={() =>
                            resetTrainingDataSetFilter()
                          }
                          classes={styleClass}
                          uniqueTrainingDataSetCollectionNamesAvailable={
                            modelCollectionUniqueTrainingDataSets
                          }
                          isTrainingDataSetCollectionNameFilterApplied={
                            tableFilter.filter.trainingDataSetCollectionNames
                              .searchValue.length > 0
                          }
                          onTrainingDataSetCollectionNameApplyFilterButtonClick={(
                            value: string[]
                          ) => updateUrlForTrainingDataSet(value)}
                          setTrainingDataSetCollectionNameFilterSearchValue={(
                            value: string[]
                          ) => setTrainingDataSetFilterSearchValue(value)}
                        />
                      )}
                  </div>
                </Box>
              </TableCell>
              <TableCell
                classes={{ root: classes.tableCellAccuracy }}
                style={{
                  color: "#98A6B3",
                  font: "normal normal normal 14px/22px Nunito Sans",
                }}
              >
                Accuracy
                {
                  false &&
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  {
                    false &&
                  <TableSortLabel
                    active={sortField.field === "accuracy"}
                    disabled={isSegmentationModelAnalyticsEnabled ? true : false}
                    direction={
                      sortField.field === "accuracy"
                        ? sortField.order === "ascending"
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={(e) => {
                      handleSort("accuracy");
                    }}
                    classes={{
                      icon: classes.sortLabelIcon,
                      root: classes.sortLabelRoot,
                      active: classes.sortLabelActive,
                    }}
                    style={{
                      paddingLeft: sortField.field === "accuracy" ? "" : "12px",
                    }}
                  >
                    Accuracy
                  </TableSortLabel>
                  }
                  <Box>
                    {
                      !isSegmentationModelAnalyticsEnabled &&
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      id="accuracyColumnOptions"
                      classes={{
                        root: classes.columnOptionsIcon,
                      }}
                      onClick={() => {
                        setTableFilter((oldState) => {
                          return {
                            ...oldState,
                            filter: {
                              ...oldState.filter,
                              accuracy: {
                                ...oldState.filter.accuracy,
                                menuOpen: true,
                              },
                            },
                          };
                        });
                      }}
                    >
                      {tableFilter.filter.accuracy.minValue !== "" ||
                        tableFilter.filter.accuracy.maxValue !== "" ||
                        (tableFilter.filter.accuracy.accuracyStatus.length > 0 &&
                          tableFilter.filter.accuracy.accuracyStatus[0] !==
                          "") ? (
                        <Tooltip title="Filter applied on accuracy">
                          <FilterListIcon />
                        </Tooltip>
                      ) : (
                        <MoreVertIcon />
                      )}
                    </IconButton>
                    }
                    {tableFilter.filter.accuracy.menuOpen && (
                      <AccuracyColumnMenu
                        open={tableFilter.filter.accuracy.menuOpen}
                        onClose={() =>
                          setTableFilter((oldState) => {
                            return {
                              ...oldState,
                              filter: {
                                ...oldState.filter,
                                accuracy: {
                                  ...oldState.filter.accuracy,
                                  menuOpen: false,
                                },
                              },
                            };
                          })
                        }
                        minValue={Number(tableFilter.filter.accuracy.minValue)}
                        maxValue={Number(tableFilter.filter.accuracy.maxValue)}
                        resetFilters={() => resetAccuracyFilter()}
                        classes={styleClass}
                        isFilterApplied={
                          tableFilter.filter.accuracy.minValue !== "" ||
                          tableFilter.filter.accuracy.maxValue !== "" ||
                          (tableFilter.filter.accuracy.accuracyStatus.length >
                            0 &&
                            tableFilter.filter.accuracy.accuracyStatus[0] !==
                            "")
                        }
                        onApplyFilterButtonClick={(filter, value) =>
                          handleAccuracyApplyFilterButtonClick(filter, value)
                        }
                        // setFilterMinMaxValue={(value)=> setAccuracyFilterMinMaxValue(value)}
                        accuracyStatusSearchValue={
                          tableFilter.filter.accuracy.accuracyStatus
                        }
                        uniqueAccuracyStatusesAvailable={
                          modelCollectionUniqueAccuracyStatuses
                        }
                      />
                    )}
                  </Box>
                </Box>
                }
              </TableCell>
              <TableCell
                classes={{ root: classes.integrityFrequencyAccuracy }}
                style={{
                  color: "#98A6B3",
                  font: "normal normal normal 14px/22px Nunito Sans",
                  width: "201px"

                }}
              >
                <div></div>
                <Tooltip title="Integrity">
                  <span>Integrity</span>
                </Tooltip>
                {/* {
                  !isSegmentationModelAnalyticsEnabled &&
                  <IconButton
                    onClick={(event) => openPopover(event)}
                    id="integrity-accuracy-button"
                  >
                    {
                      props.modelCollectionsAvailable?.every(
                        (model) =>
                          !thresholdApplied?.some((threshold) => threshold.modelCollectionId === model._id)
                      ) ? (
                        <MoreVertIcon />
                      ) : (
                        <Tooltip title="Confidence score threshold Applied on Integrity Accuracy">
                          <FilterListIcon />
                        </Tooltip>
                      )
                    }
                  </IconButton>
                } */}
              </TableCell>
              {/* <Popover
                open={isPopoverOpen}
                anchorEl={document.getElementById("integrity-accuracy-button")}
                onClose={closePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  style: {
                    width: '600px',
                  },
                }}
              >
                <div>
                  <TableContainer className={css.integrityFilterTableContainer}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Model</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Confidence Score threshold</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.modelCollectionsAvailable &&
                          props.modelCollectionsAvailable.map((cell: any, index: number) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{cell.version}</TableCell>
                                <TableCell>
                                  <div style={{ display: "flex", gap: 50, marginRight: "50px", alignItems: "center" }}>
                                  // <Switch
                                      //checked={sliderFieldState.find(thresold => thresold.modelCollectionId === cell._id)?.isSliderEnable}
                                      //onChange={() => {
                                        //setSliderFieldState((oldState) => {
                                          //const currentSliderIsEnable = oldState.find(thresold => thresold.modelCollectionId === cell._id)?.isSliderEnable
                                          //return [...oldState.filter(thresold => thresold.modelCollectionId !== cell._id), {
                                            //modelCollectionId: cell._id,
                                            //confidenceScoreThreshold: 90,
                                            //isSliderEnable: !currentSliderIsEnable
                                          //}];
                                        //})

                                      //}}
                                      //name="checkedA"
                                      //inputProps={{
                                        //"aria-label": "secondary checkbox",
                                      //}}
                                    />
                                    <Slider
                                      value={
                                        sliderFieldState?.find(
                                          (state) =>
                                            state.modelCollectionId === cell._id
                                        )?.confidenceScoreThreshold
                                      }
                                      min={0}
                                      // disabled={!sliderFieldState.find(thresold => {
                                      //   return thresold.modelCollectionId === cell._id
                                      // })?.isSliderEnable}
                                      max={100}
                                      color="primary"
                                      step={1}
                                      marks={[
                                        { value: 0, label: "0%" },
                                        { value: 50, label: "50%" },
                                        { value: 100, label: "100%" },
                                      ]}
                                      classes={{
                                        thumb: css.integrityConfidenceScoreThresholdSliderThumb
                                      }}
                                      // @ts-ignore
                                      onChange={(e, value: any) => {
                                        setSliderFieldState((oldState) => {
                                          return [...oldState.filter(thresold => thresold.modelCollectionId !== cell._id), {
                                            modelCollectionId: cell._id,
                                            confidenceScoreThreshold: value,
                                          }];
                                        })
                                        // setSliderFieldState((oldState) => {
                                        //   return oldState?.map(
                                        //     (threshold) => (threshold.modelCollectionId === cell._id) ?
                                        //       {
                                        //         ...threshold,
                                        //         confidenceScoreThreshold: value
                                        //       } : threshold

                                        //   )


                                        // });

                                      }}

                                      valueLabelDisplay="auto"
                                      aria-labelledby="range-slider"
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div id="resetFilterButtonContainer"
                  style={{ marginTop: '20px', marginBottom: "20px", marginRight: "20px", display: 'flex', justifyContent: 'flex-end', gap: 20 }}
                >
                  <div></div>
                  <Button variant="outlined" color="primary"
                    onClick={() => {
                      const initialSliderisEnabled: boolean[] | undefined = props?.modelCollectionsAvailable?.map((state) => {
                        let thresholdValue = thresholdApplied?.find((prevState) => prevState.modelCollectionId === state._id)?.confidenceScoreThreshold

                        if (thresholdValue === undefined) {
                          return false
                        }
                        return true
                      })
                      if (initialstate)
                        setSliderFieldState(initialstate);
                      closePopover()

                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary"
                    onClick={async () => {
                      console.log("statee", sliderFieldState)
                      try {
                        setIsFilterApplied(true);
                        let checkIfAllDefault = true;
                        let urlParams = sliderFieldState
                          .map((state, index) => {
                            if(state.confidenceScoreThreshold!==100)
                            checkIfAllDefault=false
                            return {
                              modelCollectionId: state.modelCollectionId,
                              confidenceScoreThreshold: state.confidenceScoreThreshold
                            }
                          })
                        if(checkIfAllDefault)
                        urlParams=[];
                        if (urlParams?.length === 0) {
                          setUrlSearchParamsOfCurrentRouteRef.current({
                            "modelCollectionsAdditionalDataForIntegrity": ""
                          }, "historyPush");
                        }
                        else {
                          setUrlSearchParamsOfCurrentRouteRef.current({
                            "modelCollectionsAdditionalDataForIntegrity": JSON.stringify(urlParams)
                          }, "historyPush")
                        }
                        const modelCollectionIds: string[] | undefined = sliderFieldState?.map((state) => {
                          return state?.modelCollectionId
                        })

                        setFetchingDataforIntegrityAccuracy(true)

                        closePopover()
                        const res = await DataService.getModelAnalyticsTestStatisticalData({
                          modelId,
                          modelCollectionIds: JSON.stringify(modelCollectionIds),
                          getIntegrityFrequency: true,
                          modelCollectionsAdditionalData: isSegmentationModelAnalyticsEnabled ? JSON.stringify([]) : JSON.stringify(sliderFieldState),
                          getIntegrityFrequencyAccuracyPercentComparisonWithLiveCollection: true,
                          getIntegrityFrequencyAccuracyPercent: true,
                        });
                        if (res) {
                          let parsedData: Record<string, string> = {};
                          res.data.forEach((item) => {
                            // @ts-ignore
                            const accuracyValue = item?.integrityFrequencyAccuracy?.value;
                            const accuracyWithPercentage =
                              accuracyValue !== undefined ? parseFloat(accuracyValue.toFixed(2)) + "%" : "N A";
                            parsedData[item.modelCollectionId] = accuracyWithPercentage;
                          });
                          setFetchingDataforIntegrityAccuracy(false)
                          // @ts-ignore
                          setIntegrityAccuracy(parsedData);
                        }

                      } catch (e) {
                        console.log("erorro is there ", e)
                      }
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Popover> */}

              <TableCell
                classes={{ root: classes.tableCellTestDataSet }}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: "#98A6B3",
                  font: "normal normal normal 14px/22px Nunito Sans",
                }}
              >
                <Tooltip
                  title="Test Data Set"
                  style={{
                    color: "#98A6B3",
                    font: "normal normal normal 14px/22px Nunito Sans",
                  }}
                >
                  <span>Test Data Set</span>
                </Tooltip>
              </TableCell>
              <TableCell
                classes={{ root: classes.architecture }}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: "#98A6B3",
                  font: "normal normal normal 14px/22px Nunito Sans",
                }}
              >
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  <TableSortLabel
                    active={sortField.field === "modelArchitecture"}
                    direction={
                      sortField.field === "modelArchitecture"
                        ? sortField.order === "ascending"
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={(e) => {
                      handleSort("modelArchitecture");
                    }}
                    classes={{
                      icon: classes.sortLabelIcon,
                      root: classes.sortLabelRoot,
                      active: classes.sortLabelActive,
                    }}
                  >
                    Architecture
                  </TableSortLabel>
                  <div>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      id="architectureColumnOptions"
                      classes={{
                        root: classes.columnOptionsIcon,
                      }}
                      onClick={() => {
                        setTableFilter((oldState) => {
                          return {
                            ...oldState,
                            filter: {
                              ...oldState.filter,
                              architectures: {
                                ...oldState.filter.architectures,
                                menuOpen: true,
                              },
                            },
                          };
                        });
                      }}
                    >
                      {tableFilter.filter.architectures.searchValue.length >
                        0 &&
                        tableFilter.filter.architectures.searchValue[0] !== "" ? (
                        <Tooltip title="Filter applied on architecture">
                          <FilterListIcon />
                        </Tooltip>
                      ) : (
                        <MoreVertIcon />
                      )}
                    </IconButton>
                    {tableFilter.filter.architectures.menuOpen && (
                      //  <span>
                      //   hello
                      // </span>
                      <ArchitectureColumnMenu
                        open={tableFilter.filter.architectures.menuOpen}
                        onClose={() =>
                          setTableFilter((oldState) => {
                            return {
                              ...oldState,
                              filter: {
                                ...oldState.filter,
                                architectures: {
                                  ...oldState.filter.architectures,
                                  menuOpen: false,
                                },
                              },
                            };
                          })
                        }
                        architectureSearchValue={
                          tableFilter.filter.architectures.searchValue
                        }
                        resetArchitectureFilters={() =>
                          resetArchitectureFilter()
                        }
                        classes={styleClass}
                        uniqueArchitecturesAvailable={
                          modelCollectionUniqueArchitectures
                        }
                        isArchitectureFilterApplied={
                          tableFilter.filter.architectures.searchValue.length >
                          0
                        }
                        onArchitectureApplyFilterButtonClick={(
                          value: string[]
                        ) => updateUrlForArchitecture(value)}
                        setArchitectureFilterSearchValue={(value: string[]) =>
                          setArchitectureFilterSearchValue(value)
                        }
                      />
                    )}
                  </div>
                </Box>
              </TableCell>

              {!isSideDrawerOpen && (
                <TableCell
                  classes={{ root: `${classes.tableCellCreatedAt}` }}
                  style={{
                    color: "#98A6B3",
                    font: "normal normal normal 14px/22px Nunito Sans",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {/* <Tooltip title="Created At"
                style={{color: "#98A6B3", font : "normal normal normal 14px/22px Nunito Sans"}}>
                  <span>
                    Created At
                  </span>
              </Tooltip>                  */}
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div></div>
                    <TableSortLabel
                      active={sortField.field === "created_at"}
                      direction={
                        sortField.field === "created_at"
                          ? sortField.order === "ascending"
                            ? "asc"
                            : "desc"
                          : "asc"
                      }
                      onClick={(e) => {
                        handleSort("created_at");
                      }}
                      classes={{
                        icon: classes.sortLabelIcon,
                        root: classes.sortLabelRoot,
                        active: classes.sortLabelActive,
                      }}
                    >
                      Created On
                    </TableSortLabel>
                    <Box>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        id="createdDateColumnOptions"
                        classes={{
                          root: classes.columnOptionsIcon,
                        }}
                        onClick={() => {
                          setTableFilter((oldState) => {
                            return {
                              ...oldState,
                              filter: {
                                ...oldState.filter,
                                createdDate: {
                                  ...oldState.filter.createdDate,
                                  menuOpen: true,
                                },
                              },
                            };
                          });
                        }}
                      >
                        {tableFilter.filter.createdDate.minValue !== "" ||
                          tableFilter.filter.createdDate.maxValue !== "" ? (
                          <Tooltip title="Filter applied on date">
                            <FilterListIcon />
                          </Tooltip>
                        ) : (
                          <MoreVertIcon />
                        )}
                      </IconButton>
                      {tableFilter.filter.createdDate.menuOpen && (
                        <CreatedDateColumnMenu
                          open={tableFilter.filter.createdDate.menuOpen}
                          onClose={() =>
                            setTableFilter((oldState) => {
                              return {
                                ...oldState,
                                filter: {
                                  ...oldState.filter,
                                  createdDate: {
                                    ...oldState.filter.createdDate,
                                    menuOpen: false,
                                  },
                                },
                              };
                            })
                          }
                          minValue={tableFilter.filter.createdDate.minValue}
                          maxValue={tableFilter.filter.createdDate.maxValue}
                          resetFilters={() => resetDateFilter()}
                          classes={styleClass}
                          isFilterApplied={
                            tableFilter.filter.createdDate.minValue !== "" ||
                            tableFilter.filter.createdDate.maxValue !== ""
                          }
                          onApplyFilterButtonClick={(value) =>
                            handleResourceCreatedAtDateApplyFilterButtonClick(
                              value
                            )
                          }
                          setFilterMinMaxValue={(value) =>
                            setCreatedDateFilterMinMaxValue(value)
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </TableCell>
              )}
              {!isSideDrawerOpen && (
                <TableCell
                  classes={{ root: classes.tableCellActions }}
                  style={{
                    color: "#98A6B3",
                    font: "normal normal normal 14px/22px Nunito Sans",
                  }}
                >
                  Actions
                </TableCell>
              )}
              <TableCell
                classes={{ root: classes.tableCellView }}
                className={`
                  ${isLiveCollectionInFirstRow
                    ? classes.borderBottomWhiteColor
                    : ""
                  }
                `}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(isFetchingModelCollections ||
              isFetchingLiveModelCollection ||
              isApiRequestCancelled) &&
              new Array(10).fill("").map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                </TableRow>
              ))}
            {!isFetchingModelCollections &&
              !isFetchingLiveModelCollection &&
              props.modelCollectionsAvailable?.map((row, index) => {          
                const isCheckboxChecked = isCollectionCheckboxChecked(row._id);
                const modelCollectionConfidenceScoreBandsIntegrityData = modelCollectionsIntegrityDataAcrossVariousConfidenceScoreBands.find(modelcollection=>modelcollection._id === row._id)            
                const isModelCollectionConfidenceScoreBandsIntegrityDataBeingFetched = modelCollectionIdsForWhichIntegrityAcrossVariousConfidenceScoreBandsBeingCollected.includes(modelCollectionConfidenceScoreBandsIntegrityData?._id || "") ? true : false;
                let totalGoodDataPoints = 0;
                if ( modelCollectionConfidenceScoreBandsIntegrityData?.bands?.length) {
                  modelCollectionConfidenceScoreBandsIntegrityData?.bands?.forEach(obj => {
                    totalGoodDataPoints += obj.totalGoodDataPointsCount || 0;
                  });
                }
                let CalculatedAccuracy=0

                if(modelCollectionConfidenceScoreBandsIntegrityData)
                {
                    CalculatedAccuracy = (totalGoodDataPoints /modelCollectionConfidenceScoreBandsIntegrityData?.totalDataSetsCount) * 100;
                    if (isNaN(CalculatedAccuracy)) {
                    
                      CalculatedAccuracy = 0;
                    }     
                }
                const SystemAccuracy = CalculatedAccuracy.toFixed(2);               
   
                return (
                  <>
                    <TableRow
                      hover
                      id={row._id}
                      key={row._id}
                      // selected={selected?._id === row._id}
                      selected={props.modelCollectionSelected === row._id}
                      classes={{
                        root: `${classes.tableRow} ${row.status === "Live" && classes.liveRow
                          }`,
                        hover: classes.liveRowHover,
                        selected: classes.rowSelected,
                      }}
                      style={{ fontSize: " 12px" }}
                    >
                     { props.scenario !== "browseModelCollectionsPopup" && <TableCell
                        padding="checkbox"
                        classes={{ root: `${classes.tableCellCheckbox}` }}
                      >
                        <Checkbox
                          inputProps={{ "aria-labelledby": row._id }}
                          checked={isCheckboxChecked}
                          onChange={() => handleCollectionRowCheckBoxClick(row)}
                        />
                      </TableCell>}
                      <TableCell
                        classes={{ root: `${classes.tableCellVersion}` }}
                      // onClick={() => handleClick(row._id)}
                      >
                        <Typography component="p" variant="subtitle2" noWrap>
                          <Tooltip title={row.version}>
                            <span style={{ fontSize: "14px" }}>
                              {row.version}
                            </span>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                      <TableCell
                        classes={{ root: `${classes.tableCellStatus}` }}
                      // onClick={() => handleClick(row._id)}
                      >
                        <div
                          style={{ display: "flex" }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ModelStatus status={row.status} trainingTestingCompletionPercentage={row.trainingCompletionPercentages?.find(obj => obj.testDataSetCollectionId === row.defaultDataSetCollectionId)?.completionPercentage}/>
                            {
                              (row.status === "Trained" || row.status === "Re-Trained" || row.status === "Tested" || row.status === "Live")
                              &&
                              <IconButton
                                onClick={() => {
                                  setTrainedModelPopupOpen((prevState: any) => (
                                    {
                                      ...prevState,
                                      [index]: true
                                    }
                                  ))
                                }}
                                style={{ marginLeft: '12px', width: '50px', height: '50px' }}
                              >
                                <StatisticsIcon />
                              </IconButton>
                            }
                          </div>
                          <TrainedModelPopup
                            scenario={"trainedModelPopup"}
                            isHandsTrackingDataFetching={ishandtrackingdatafetching}
                            hands_tracking_data={ doWeNeedToSHowHandsTrackingData ? handstrackingData :[]}
                            modelCollection={row}
                            isOpen={trainedModelPopupOpen[index]}
                            onClose={() => {
                              deleteParamsOnCloseTrainedModelPopup()
                              setTrainedModelPopupOpen((prevState: any) => ({
                                ...prevState,
                                [index]: false
                              }))
                            }}
                            accuracy={
                              isSegmentationModelAnalyticsEnabled
                                ? parseFloat(SystemAccuracy)
                                // : row.accuracy || 0
                                : parseFloat(SystemAccuracy) || 0
                            }
                            
                            integrity={integrityAccuracy?.[row._id] || -1}
                            confidenceScoreBandsIntegrityData={modelCollectionConfidenceScoreBandsIntegrityData}
                            // isConfidenceScoreBandsIntegrityDataBeingFetched={isModelCollectionConfidenceScoreBandsIntegrityDataBeingFetched}
                            isConfidenceScoreBandsIntegrityDataBeingFetched={modelCollectionConfidenceScoreBandsIntegrityData ? false : true}
                            versionSearchValue={row.version}
                            architectureSearchValue={row.modelArchitecture}
                            iouThresholdValueForSegmentationAnalytics={!isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS}
                            collectionMetrics={collectionMetricsFromAiModelTestResultCSV}
                            isFetchingCollectionMetrics={isFetchingCollectionMetrics}
                            isSegmentationModelAnalytics={isSegmentationModelAnalyticsEnabled}
                            confidenceScoreThresholdValue={modelDetails?.confidenceScoreThresholdForIntegrity}
                          />
                        </div>
                      </TableCell>
                      {/* <TableCell
                      classes={{root: `${classes.tableCellLabels}`}}
                    >
                      <Tooltip title={row.labels?.join(", ")}>
                        <Typography
                          component="p"
                          noWrap
                        >
                          {row.labels?.map((label) => (
                            <Chip
                              key={label}
                              label={label}
                              classes={{ root: classes.chip }}
                            />
                          ))}
                        </Typography>
                      </Tooltip>
                    </TableCell> */}
                      <TableCell
                        classes={{
                          root: `${classes.tableCellTraningDataDirectory}`,
                        }}
                      // onClick={() => handleClick(row._id)}
                      >
                        <Tooltip title={row.trainingDataDirectory?.join(", ")}>
                          <Typography component="p" noWrap>
                            {row.trainingDataDirectory?.map(
                              (trainingDataDirectory) => (
                                <Chip
                                  key={trainingDataDirectory}
                                  label={
                                    !isRedirectingToCollection ||
                                      parseInt(
                                        isRedirectingToCollection.split("-")[0]
                                      ) !== index ||
                                      isRedirectingToCollection.split("-")[1] !==
                                      "training"
                                      ? trainingDataDirectory
                                      : undefined
                                  }
                                  icon={
                                    isRedirectingToCollection &&
                                      parseInt(
                                        isRedirectingToCollection.split("-")[0]
                                      ) === index &&
                                      isRedirectingToCollection.split("-")[1] ===
                                      "training" ? (
                                      <CircularProgress size={18} />
                                    ) : undefined
                                  }
                                  clickable
                                  onClick={(e) => {
                                    dataSetCollectionColumnClickHandler(
                                      index,
                                      "training"
                                    );
                                  }}
                                  classes={{ root: classes.chip }}
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%",
                                  }}
                                />
                              )
                            )}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableCellAccuracy }} 
                      // onClick={() => handleClick(row._id)}
                      >
                        {row?.reTestingDetail ? (
                          <Tooltip
                            title={
                              row?.reTestingDetail.status === "preparingRequest"
                                ? "Preparing Request"
                                : row?.reTestingDetail.status === "queued"
                                  ? " Re-Testing"
                                  : row?.reTestingDetail.status === "failed"
                                    ? " Failed "
                                    : " NA "
                            }
                          >
                            <Typography
                              component="p"
                              noWrap
                              classes={{
                                root: `${classes.accuracyColumnChipContainer}`,
                              }}
                            >
                              <Chip
                                style={{ maxWidth: "116%", height: "24px" }}
                                label={
                                  row?.reTestingDetail.status ===
                                    "preparingRequest"
                                    ? "Preparing Request"
                                    : row?.reTestingDetail.status === "queued"
                                      ? " Re-Testing"
                                      : row?.reTestingDetail.status === "failed"
                                        ? " Failed "
                                        : " NA "
                                }
                                classes={{
                                  root: `${classes.chip} ${classes.chipContainer}`,
                                }}
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                         accuracyLoader) ? (
                            <TableCell>
                              <CustomSkeleton height={40} width={100} />
                            </TableCell>
                          ) :  <ModelCollectionAccuracy
                          showTotalInTooltip={modelDetails?.predictionStatusLogic==="labelAndErrorDeviation"?false:true}
                            isLiveModelCollection={
                              row?.status === "Live" ? true : false
                            }
                            liveCollectionAccuracy={
                              props.modelCollectionsAvailable?.filter(
                                (collection: IModelCollection) =>
                                  collection.status === "Live"
                                  // @ts-ignore
                              )[0]?.testAccuracy ||
                              props.modelCollectionsAvailable?.filter(
                                (collection: IModelCollection) =>
                                  collection.status === "Live"
                              )[0]?.accuracy || 0
                            }
                            accuracyValue={  isSegmentationModelAnalyticsEnabled
                              ? parseFloat(SystemAccuracy)
                              // : row.accuracy || 0}
                              : parseFloat(SystemAccuracy) || 0}
                            
                            jsonFileData={
                              row.jsonFileDataForAccuracy || undefined
                            }
                          />
                        }
                      </TableCell>

                      <TableCell style={{ textAlign: "center" }}>
                        <Tooltip
                          // title={
                          //   !isSegmentationModelAnalyticsEnabled ? 
                          //   (sliderFieldState?.find((threshold) => threshold.modelCollectionId === row._id)
                          //     ? <div style={{whiteSpace: 'pre-line', textAlign: 'center'}}>{`Confidence score threshold is set as ${sliderFieldState?.find((threshold) => threshold.modelCollectionId === row._id)?.confidenceScoreThreshold}%\n Click for more details`}</div>
                          //     : "")
                          //     : (`Click for more details`)
                          // }
                          title={
                            !isSegmentationModelAnalyticsEnabled ? 
                              <div>{`Confidence score threshold is set as ${confidenceScoreThresholdForIntegrity} from configuration`}</div> :
                              <div>{`Integrity is calculated using iou threshold ${iouScoreThresholdForIntegrity} and confidence score threshold ${confidenceScoreThresholdForIntegrity} defined in Task Configuration`}</div>
                          }
                        >
                          {
                            (fetchingDataforIntegrityAccuracy) ? (
                              <TableCell>
                                <CustomSkeleton height={40} width={100} />
                              </TableCell>
                            ) : (
                              integrityAccuracy?.[row._id] !== "N A" ? (
                                // <Link to={row.model?.isSegmentationModelAnalytics === true ? `/admin/projects/${row.projectName}/${row.modelName}/collections/modelAnalytics/integrity?model=${row.model?._id}&type=${row.model.type}&project=${row.model?.project}&status=dashboard&modelCollectionIdsToCompare=${row._id}&modelCollectionsAdditionalData=[{"modelCollectionId":"${row._id}","iouThreshold":${!isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS}}]`
                                //       : `/admin/projects/${row.projectName}/${row.modelName}/collections/modelAnalytics/integrity?model=${row.model?._id}&type=${row.model.type}&project=${row.model?.project}&status=dashboard&modelCollectionIdsToCompare=${row._id}`}
                                      // style={{ color: "inherit", textDecoration: 'none' }}>
                                  <div className={classes.accuracyBox}
                                    style={{
                                      borderColor: `${integrityAccuracyPassingPercentage !== undefined
                                        && integrityAccuracyPassingPercentage !== null
                                        && integrityAccuracy !== null
                                        && integrityAccuracy !== undefined
                                        && integrityAccuracy?.[row._id] !== undefined
                                        && integrityAccuracy?.[row._id] !== null
                                        && integrityAccuracyPassingPercentage <= parseInt(`${integrityAccuracy?.[row._id]}`, 10) ? "green" : "red"
                                        }`
                                    }}>
                                    {integrityAccuracy?.[row._id]}
                                  </div>
                                // </Link>
                              ) : (
                                <div className={classes.emptyAccuracyBox}>N A</div>
                              )
                            )
                          }
                        </Tooltip>

                      </TableCell>

                      <TableCell
                        classes={{
                          root: `${classes.tableCellTraningDataDirectory}`,
                        }}
                      // onClick={() => handleClick(row._id)}
                      >
                        {/* <Tooltip title={(row?.populatedDefaultDataSetCollection?.name || row?.defaultDataSetCollection?.name || "")}>
                        <Typography
                          component="p"
                          noWrap
                        > */}
                        {!row?.defaultDataSetCollectionName && (
                          <Tooltip title={"NA"}>
                            <Typography component="p" noWrap>
                              <Chip
                                style={{ maxWidth: "100%" }}
                                disabled
                                label={"NA"}
                                clickable
                                classes={{ root: classes.chip }}
                              />
                            </Typography>
                          </Tooltip>
                        )}
                        {row?.defaultDataSetCollectionName && (
                          <Tooltip
                            title={row?.defaultDataSetCollectionName || ""}
                          >
                            <Typography component="p" noWrap>
                              <Chip
                                key={row?.defaultDataSetCollectionName || ""}
                                label={
                                  !isRedirectingToCollection ||
                                    parseInt(
                                      isRedirectingToCollection.split("-")[0]
                                    ) !== index ||
                                    isRedirectingToCollection.split("-")[1] !==
                                    "test"
                                    ? row?.defaultDataSetCollectionName || ""
                                    : undefined
                                }
                                icon={
                                  isRedirectingToCollection &&
                                    parseInt(
                                      isRedirectingToCollection.split("-")[0]
                                    ) === index &&
                                    isRedirectingToCollection.split("-")[1] ===
                                    "test" ? (
                                    <CircularProgress size={18} />
                                  ) : undefined
                                }
                                classes={{ root: classes.chip }}
                                clickable
                                onClick={() =>
                                  dataSetCollectionColumnClickHandler(
                                    index,
                                    "test"
                                  )
                                }
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                }}
                              />
                            </Typography>
                          </Tooltip>
                        )}
                        {/* </Typography>
                      </Tooltip> */}
                      </TableCell>
                      {/* {
                      !isSideDrawerOpen &&
                      <TableCell
                        classes={{root: `${classes.tableCellDescription}`}}
                        // onClick={() => handleClick(row._id)}
                      >
                        <Tooltip title={row.modelDescription}>
                          <Typography
                            component="p"
                            variant="subtitle2"
                            noWrap
                          >
                            {row.modelDescription}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    } */}
                      <TableCell
                        classes={{ root: `${classes.architecture}` }}
                      // onClick={() => handleClick(row._id)}
                      >
                        <Tooltip title={row?.modelArchitecture}>
                          <Typography component="p" noWrap>
                            <Chip
                              key={row?.modelArchitecture}
                              label={row?.modelArchitecture}
                              classes={{ root: classes.chip }}
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      </TableCell>

                      {!isSideDrawerOpen && (
                        <TableCell
                          classes={{ root: `${classes.tableCellCreatedAt}` }}
                        >
                          <div
                            style={{
                              maxWidth: "140px",
                              margin: "auto",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <TimeDateFormat
                              timeString={row.created_at}
                              timeStyleClass={classes.lastModifiedTime}
                              textAlign={"left"}
                            />
                          </div>
                        </TableCell>
                      )}
                      {/* <TableCell>
                                            <Typography component="p" variant="subtitle2" noWrap>{convertDate(row.created_at)}</Typography>
                                        </TableCell> */}
                      {!isSideDrawerOpen && (
                        <TableCell
                          classes={{ root: `${classes.tableCellActions}` }}
                        >
                          {/* <IconButton aria-label="export" onClick={() => downloadCSV(row.path_to_csv)}>
                                                <GetApp />
                                            </IconButton> */}
                          {
                            props.scenario === "browseModelCollectionsPopup"?
                            browseModelCollectionPopupContext.selectedModelCollection?._id === row._id ?
                            <>
                            <Tooltip open={openTooltip[index] || false} title={"Select this AI Model for model File"}
                              onOpen={() => {
                                setOpenTooltip((prevState) => {
                                  prevState[index] = true
                                  return [
                                    ...prevState
                                  ]
                                })
                              }} 
                              onClose={() => {
                                setOpenTooltip((prevState) => {
                                  prevState[index] = false
                                  return [
                                    ...prevState
                                  ]
                                })
                              }}>
                                    <CheckBoxRoundedIcon color="primary"
                                      onClick={() => {
                                        /**
                                         * Remove data selected by user which will be used for testing AI Assisted labelling code on
                                         */
                                        setOpenTooltip((prevState) => {
                                          prevState[index] = false
                                          return [
                                            ...prevState
                                          ]
                                        })
                                        browseModelCollectionPopupContext.setSelectedModelCollection(null)
                                      }}
                                      style={{
                                        cursor: "pointer"
                                      }}
                                    />
                                    </Tooltip>
                                </>
                            :<>
                                <Tooltip open={openTooltip[index] || false} title={"Select this AI Model for model File"}
                                onOpen={() => {
                                  setOpenTooltip((prevState) => {
                                    prevState[index] = true
                                    return [
                                      ...prevState
                                    ]
                                  })
                                }} 
                                onClose={() => {
                                  setOpenTooltip((prevState) => {
                                    prevState[index] = false
                                    return [
                                      ...prevState
                                    ]
                                  })
                                }}
                                >
                                  <CheckIcon color="primary"
                                    onClick={() => {
                                      /**
                                       * Set data selected by user which will be used for testing AI Assisted labelling code on
                                       */

                                      setOpenTooltip((prevState) => {
                                        prevState[index] = false
                                        return [
                                          ...prevState
                                        ]
                                      })
                                      if(props.selectModelCollection) {
                                        props.selectModelCollection(row)
                                      }
                                    }}
                                    style={{
                                      cursor: "pointer"
                                    }}
                                  />
                              </Tooltip>
                            </>
                          : (collectionsBeingDeleted.indexOf(row._id) >= 0 ? (
                            <CircularProgress
                              classes={{
                                root: clsx(classes.progress, classes.delete),
                              }}
                              size={48}
                            />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Tooltip title={"Delete the collection"}>
                                <IconButton
                                  classes={{ root: classes.deleteButton }}
                                  aria-label="export"
                                  onClick={() =>
                                    props.confirmDeleteCollection({
                                      multiDelete: false,
                                      collectionToDelete: row,
                                      showConfirmDeleteModal: true,
                                      selectedCollectionIds: [],
                                      collectionType: "model",
                                    })
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"More Actions"}>
                                <SubActionMenu
                                  // disabledDeployButton={integrityAccuracyPassingPercentage !== undefined
                                  //   && integrityAccuracyPassingPercentage !== null
                                  //   && integrityAccuracy !== null
                                  //   && integrityAccuracy !== undefined
                                  //   && integrityAccuracy?.[row._id] !== undefined
                                  //   && integrityAccuracy?.[row._id] !== null
                                  //   && integrityAccuracyPassingPercentage <= parseInt(`${integrityAccuracy?.[row._id]}`, 10) ? true : false
                                  // }
                                  disabledDeployButton={false}
                                  modelRow={row}
                                  architecturesAvailable={
                                    architecturesAvailable
                                  }
                                  handleTestButtonClick={handleTestButtonClick}
                                  setPerformModelCollectionDeploymentPopupState={
                                    setPerformModelCollectionDeploymentPopupState
                                  }
                                  collectionType="modelCollection"
                                  handleReTestButtonClick={
                                    handleReTestButtonClick
                                  }
                                />
                                {/* <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClickMoreActions}>
                              {/* More Actions */}
                                {/* <MenuOpenIcon/>
                            </Button> */}
                              </Tooltip>

                              {/* <Menu
                              id="fade-menu"
                              anchorEl={moreActionMenu}
                              getContentAnchorEl={null}
                              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                              transformOrigin={{ vertical: "top", horizontal: "center" }}
                              keepMounted
                              open={actionMenuOpen}
                              onClose={hancleCloseMoreActions}
                              TransitionComponent={Fade}
                              elevation={2}
                            >
                            <MenuItem onClick={hancleCloseMoreActions}>{
                            row?.checkpointFiles?.length !== 0 ? 
                            <Tooltip title={"Test the collection"}>
                              <IconButton onClick={() => { handleTestButtonClick(row) }}  style={{width:"55px"}}>
                                <img src="https://cdn-icons-png.flaticon.com/512/1581/1581884.png" height={24} width={24} />
                              </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title={"Collection does not contain any checkpoint file!"}>
                              <div>
                              <IconButton  style={{pointerEvents: "none", opacity:"0.5", padding:"15px 12px 12px", width:"68px"}} disabled>
                                <img src="https://cdn-icons-png.flaticon.com/512/1581/1581884.png" height={24} width={24} />
                              </IconButton>
                              </div>
                            </Tooltip>
                            }
                            </MenuItem>
                          <MenuItem onClick={hancleCloseMoreActions}>
                          <Tooltip title={""}>
                            <div>
                            <IconButton
                              classes={{ root: `${classes.deployButton} ${(row?.checkpointFiles?.length > 0) && (architecturesAvailable.find(architecture => architecture.label === row.modelArchitecture && architecture.apiUrlToCallForDeployment !== "" && architecture.isDeploymentFeatureEnabled)) ? "" : classes.tableDisabledCell}` }}
                              onClick={()=>{
                                setPerformModelCollectionDeploymentPopupState(oldState=>({...oldState, isOpen: true, selected: row}))
                              }}
                            >
                              <img style={{width: "30px", transform: "scale(1.5)"}} src={DeploySVGIcon}/>
                            </IconButton>
                            </div>
                          </Tooltip>
                          </MenuItem>
                          </Menu> */}
                            </div>
                          ))}
                        </TableCell>
                      )}
                      <TableCell
                        classes={{ root: `${classes.tableCellView}` }}
                        style={{ height: "67px" }}
                      >
                        {/* <Tooltip title="View Details">
                      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                          <Button 
                          startIcon={<FullscreenIcon/>}
                          onClick={() => {
                            handleClick(row._id)
                          // if (props.onClick) {
                          //   props.onClick();
                          // }
                        }}
                        color='primary' variant='contained'
                        classes={{startIcon : `${classes.viewIcon}`}}
                        style={{textTransform: 'none', padding: "8px 0px", minWidth: "50px"}}></Button>
                      </div>
                      </Tooltip> */}

                        {/* <Tooltip title="View Details Popup"> */}
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <ModelCollectionPopupOpenButton
                            onClick={() => {
                              handlePopupViewClick(row._id, isSegmentationModelAnalyticsEnabled ? parseFloat(SystemAccuracy) : row.accuracy || 0);
                            }}
                          />
                        </div>
                        {/* </Tooltip> */}
                      </TableCell>
                    </TableRow>
                    {dialogPopupState && (
                      <div>
                        <Dialog
                          open={
                            dialogPopupState.isOpen &&
                            row._id === dialogPopupState.openForId
                          }
                          onClose={() =>
                            setDialogPopupState({
                              isOpen: false,
                              openForId: "",
                            })
                          }
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle
                            id="alert-dialog-title"
                            style={{ paddingBottom: "9px" }}
                          >
                            <WarningIcon
                              style={{ marginBottom: "7px", color: "#f5c609" }}
                            />
                            {/* {"Re-Testing is already going on..."} */}
                            <span style={{ marginLeft: "5px" }}>Warning!</span>
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Re-Testing for Model Version <b>{row?.version}</b>{" "}
                              for default dataset collection was started at{" "}
                              <b>
                                {row?.reTestingDetail &&
                                  convertDate(row.reTestingDetail.created_at)}
                              </b>{" "}
                              and is still in progress. Are you sure to perform
                              Re-Testing again?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              style={{
                                width: "18%",
                                outline: "none",
                                color: "red",
                              }}
                              onClick={() =>
                                setDialogPopupState({
                                  isOpen: false,
                                  openForId: "",
                                })
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              style={{ width: "18%", outline: "none" }}
                              onClick={() => {
                                forceReTest(row?._id || "");
                              }}
                              color="primary"
                            >
                              {isForceReTestApiFetching ? (
                                <CircularProgress
                                  // classes={{
                                  //   root: clsx(classes.progress),
                                  // }}
                                  size={24}
                                />
                              ) : (
                                "Proceed"
                              )}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    )}
                  </>
                );
              })}
            {!isFetchingModelCollections &&
              !isFetchingLiveModelCollection &&
              !props.modelCollectionsAvailable?.length && (
                <NoTableData colSpan={tableHeadCellsCount} />
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {props?.modelCollectionsAvailable !== null &&
        (props?.totalCountOfModelCollections || 0) > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={props.totalCountOfModelCollections || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      {performModelCollectionDeploymentPopupState.isOpen && (
        <PerformModelCollectionDeploymentDialog
          open={true}
          modelCollection={
            performModelCollectionDeploymentPopupState.selected || ({} as any)
          }
          onClose={() => {
            setPerformModelCollectionDeploymentPopupState((oldState) => ({
              ...oldState,
              isOpen: false,
            }));
          }}
          fetchCollections={fetchModelCollection}
        />
      )}
      <DeleteModelCollectionErrorPopup />
      <DeleteCollectionConfirmPopup
        singleDeleteCallBack={handleCollectionDeleteButtonClick}
        multiDeleteCallBack={handleDeleteSelectedCollectionsButtonClick}
      />
    </Paper>
  );
}

function AddModelCollectionComponentWrapper() {
  return <AddModelCollection scenario={"addModelCollection"} />;
}

const mapProps = (state: IReduxState, ownProps: {scenario?: "browseCollectionsPopup" | "browseModelCollectionsPopup", selectModelCollection: (modelCollection: IModelCollection) => void}) => {
  return {
    collections: state.collection.collections,
    isFetching: state.collection.isFetching,
    selectedType: state.optionsDrawer.type,
    selected: state.collection.selected,
    isCollectionAddSuccess: state.collection.isAddSuccess,
    isCollectionDeleteSuccess: state.collection.isDeleteSuccess,
    shouldFetchModelCollections:
      state.collection.modelCollection.shouldFetchModelCollections,
    modelCollectionSelected:
      state.collection.modelCollection.modelCollectionSelected,
    modelCollectionsAvailable:
      state.collection.modelCollection.modelCollections,
    totalCountOfModelCollections:
      state.collection.modelCollection.totalModelCollectionsTableCount,
    isSideDrawerOpen: state.optionsDrawer.isOpen,
    sideDraweType: state.optionsDrawer.type,
    deleteModelCollectionErrorMessages:
      state.collection.modelCollection
        .errorMessagesDueToModelCollectionLinkedSomewhereElse,
    deleteModelCollectionTotalCount:
      state.collection.modelCollection.deletedModelCollectionCount,
    modelCollectionsSelected:
      state.collection.modelCollection.modelCollectionsSelected,
    modelCollectionsSelectedToCompare:
      state.collection.modelCollection.modelCollectionsSelectedToCompare,
    scenario: ownProps.scenario,
    selectModelCollection: ownProps.selectModelCollection
  };
};

const connector = connect(mapProps, {
  getCollections,
  setSideDrawer,
  setSelectedModelCollection,
  setTopActionButton,
  setShouldFetchModelCollections,
  deleteModelCollection,
  confirmDeleteCollection,
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModelCollection);
// function setModelCollections(collections: any) {
//   throw new Error("Function not implemented.");
// }
