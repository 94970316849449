import React from 'react';
import { Box, Grid, Card, CardContent, Typography, Button, IconButton, Chip, Avatar, Link, Breadcrumbs } from '@material-ui/core';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import Skeleton from '@material-ui/lab/Skeleton';
import { ILatestModel, IPopularModel } from '../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces';
import homepageCss from '../RLEF_homepage.module.css'
import { makeStyles } from '@material-ui/core/styles';
import { dateFormats, formatDate } from '../../../../../common';

// Define the styles
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // marginTop: theme.spacing(4),
        width: '93%'
    },
    actionButton: {
        marginTop: theme.spacing(2),
        // width: '100%',
        borderRadius: '20px',
        alignSelf: 'center',
        textTransform: 'capitalize',
        fontSize: '12px',
        color: "#64748B",
        fontWeight: 500
    },
    card: {
        display: 'flex',
        border: '1px solid #E0E0E0',
        backgroundColor: '#FFF',
        marginBottom: theme.spacing(2),
        borderRadius: '16px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: 0
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '11px',
        width: '100%',
        '&:last-child': {
            paddingBottom: '11px',
        },
    },
    purposeModelcardContent: {
        padding: '16px',
        '&:last-child': {
            paddingBottom: '16px',
        },
    },
    specificChip: {
        borderRadius: '22px',
        background: '#E9EFFA',
        padding: '3.5px 5.6px',
        color: "#2264CD",
        fontFamily:"Inter",
        fontWeight:600
    },
    generalChip: {
        borderRadius: '22px',
        padding: '3.5px 5.6px',
        border: '0px solid #59BB9E',
        background: 'rgba(89, 187, 158, 0.12)',
        color: '#59BB9E',
        fontFamily:"Inter",
        fontWeight:600
    }
}));

// Define the props interface
interface HomepageModelsCardProps {
    availableModelsData: Array<IPopularModel>;
    modelFetchStatus: Record<string, any>;
    fetchedModelResources: Record<string, any>;
    scenario?: "latestModelsData"|"popularModelsData" | "homePageChat"
}

const HomepageModelsCard: React.FC<HomepageModelsCardProps> = ({
    availableModelsData,
    modelFetchStatus,
    fetchedModelResources,
    scenario
}) => {
    const classes = useStyles();
    const generateModelAnalyticsLink = (TrainingPodDetails: ILatestModel | IPopularModel) => {
        // @ts-ignore
        let accuracy = TrainingPodDetails?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy
        const isSegmentationModelEnabled = TrainingPodDetails?.model?.isSegmentationModelAnalytics
        let url = `/admin/projects/${TrainingPodDetails?.project?.name}/${TrainingPodDetails?.model?.name}/collections/modelAnalytics/resources?model=${TrainingPodDetails?.model?._id}&project=${TrainingPodDetails?.project?._id}&limit=10&offset=1&type=${TrainingPodDetails?.model?.type}&status=dashboard&modelCollectionIdFilterSelected=${TrainingPodDetails?._id}&modelCollectionIdsToCompare=${TrainingPodDetails?._id}&scenario=integrity&accuracy=${accuracy}&sortField=predicted_status&sortOrder=ascending&bandFilterSelected=ALL&`
        if (isSegmentationModelEnabled) {
            url += `&iouThresholdValue=${TrainingPodDetails?.model?.iouThresholdValue}`
        }
        return url
    }
    return (
        <>
            {availableModelsData && availableModelsData.map((eachModelData: IPopularModel | ILatestModel) => (
                <Card className={`${classes.card} ${homepageCss[scenario === "latestModelsData" ? "latestModelCard" : "popularModelCard"]}`} style={{ width: '100%' }} key={eachModelData?.purposeModel._id}>
                    <CardContent className={`${classes.cardContent} ${classes.purposeModelcardContent}`} >
                        <Box display="flex" flexDirection="column" style={{ width: '100%', gap: '14px' }}>
                            <Box>
                                <Box display="flex" justifyContent="space-between" mb={1}>
                                    <Typography variant='body2' style={{ color: "#191D23", fontFamily: "Inter", fontWeight: 600 }}>{eachModelData?.purposeModel.modelName}</Typography>
                                    <Box display="flex" justifyContent="space-between" style={{ gap: '12px' }}>
                                        {eachModelData?.purposeModel?.purposeType === 'specific' ?
                                            <Chip label="Specific" size="small" className={classes.specificChip} />
                                            : <Chip label="General" size="small" className={classes.generalChip} />
                                        }
                                        <BookmarkBorderIcon />
                                    </Box>
                                </Box>
                                <Typography variant='body2' style={{ fontSize: '12px', color: '#64748B', fontFamily: 'Inter', fontWeight: 400 }}>{eachModelData?.project?.name} . <span style={{ color: scenario === "latestModelsData" ? '#CFA100': '#7cd5a7' , fontWeight: 600 }}>{formatDate(dateFormats.dd_mmm_yyyy, eachModelData?.purposeModel?.created_at)}</span></Typography>
                            </Box>
                            {eachModelData?.purposeModel?.createdByUser?.emailId && <Typography variant='body2' style={{ color: '#64748B' }}>Author: <span style={{ color: '#666666', fontWeight: 600 }}>{eachModelData?.purposeModel?.createdByUser?.emailId || "NA"}</span></Typography>}
                            <Typography variant='body2' style={{ color: '#64748B' }}>Problem Type: <span style={{ color: '#666666', fontWeight: 600 }}>{eachModelData?.purposeModel?.aiTask || "NA"} </span><span style={{ color: '#666666', fontWeight: 500 }}>({eachModelData?.purposeModel?.problemType || "NA"})</span></Typography>

                            <Box display="flex" justifyContent="space-between" style={{ fontFamily: 'Inter' }}>
                                {scenario!=="latestModelsData" && <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: '30%', backgroundColor: '#FBF3D9', borderRadius: '8px', padding: '4px 0px' }}>
                                    <Typography variant='body2' style={{ color: "#CFA100", textAlign: 'center', fontWeight: 600 }}>{eachModelData?.purposeModel?.clonesCount || "0"}</Typography>
                                    <Typography variant='body2' style={{ color: "#64748B", textAlign: 'center' }}>Forks</Typography>

                                </Box>}
                                <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: scenario === "latestModelsData" ? '40%' :'30%', backgroundColor: '#F3F3F3', borderRadius: '8px', padding: '4px 0px' }}>
                                    {eachModelData?.purposeModel?.purposeType === 'specific' ?
                                        // @ts-ignore 
                                        <Typography variant='body2' style={{ color: '#666666', textAlign: 'center', fontWeight: 600 }}>{Number.isInteger(eachModelData?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy) ? eachModelData.checkpointFiles.files.analysis.cachedMetrics.accuracy.accuracy : eachModelData.checkpointFiles.files.analysis.cachedMetrics.accuracy.accuracy.toFixed(2)}%</Typography>
                                        :
                                        <Typography variant='body2' style={{ color: '#666666', textAlign: 'center', fontWeight: 600 }}>-</Typography>
                                    }
                                    <Typography variant='body2' style={{ color: "#64748B", textAlign: 'center' }}>Accuracy</Typography>

                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: scenario === "latestModelsData" ? '40%' :'30%', backgroundColor: '#F3F3F3', borderRadius: '8px', padding: '4px 0px' }}>
                                    {eachModelData?.purposeModel?.purposeType === 'specific' ?
                                        <Typography variant='body2' style={{ color: '#666666', textAlign: 'center', fontWeight: 600 }}>
                                            {// @ts-ignore 
                                                eachModelData?.checkpointFiles?.files?.analysis?.cachedMetrics?.integrity?.integrity !== undefined ? (Number.isInteger(eachModelData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity) ? eachModelData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity : eachModelData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity.toFixed(2)) + "%" : "NA"}
                                        </Typography>
                                        :
                                        <Typography variant='body2' style={{ color: '#666666', textAlign: 'center', fontWeight: 600 }}>-</Typography>
                                    }
                                    <Typography variant='body2' style={{ color: "#64748B", textAlign: 'center' }}>Integrity</Typography>

                                </Box>
                            </Box>

                            <Typography variant='body2' className={homepageCss.ellipsisText} style={{ fontFamily: 'Inter', color: '#666' }}>{eachModelData?.purposeModel?.modelDescription}</Typography>

                            {eachModelData?.purposeModel?.purposeType === 'specific' && <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="body2" style={{ color: "#838C95", fontSize: '12px' }}>
                                        Test dataset
                                    </Typography>
                                    <Typography variant="body2" color="primary" style={{ fontSize: '12px', fontWeight: 600, textAlign: 'right' }}>
                                        <Link href={generateModelAnalyticsLink(eachModelData)} target="_blank" rel="noopener" >
                                            View more
                                        </Link>
                                    </Typography>
                                </Box>
                                {Object.keys(modelFetchStatus[eachModelData.purposeModel?._id] || {}).length === 0 && Object.keys(fetchedModelResources[eachModelData.purposeModel?._id] || {}).length === 0 ? (
                                    <Typography>No resources available</Typography>
                                ) : (
                                    <Box display="flex" mt={1} style={{ gap: '0.5rem', backgroundColor: '#F3F3F3', overflow: 'hidden', borderRadius: '8px' }} p={1}>
                                        {/* Display loader or images */}
                                        {Object.entries(modelFetchStatus[eachModelData.purposeModel?._id] || {}).map(([resourceId, isFetching]) => (
                                            isFetching ? (
                                                <Skeleton key={resourceId} variant="rect" width="20%" height={50} />
                                            ) : (
                                                fetchedModelResources[eachModelData.purposeModel?._id]?.[resourceId] && (
                                                    <img key={resourceId} src={fetchedModelResources[eachModelData.purposeModel?._id][resourceId].resource} alt="Resource" style={{ width: '50px', height: '50px', borderRadius: "8px" }} />
                                                )
                                            )
                                        ))}
                                    </Box>
                                )}
                            </Box>}
                        </Box>
                    </CardContent>
                </Card>
            ))}
            {availableModelsData && availableModelsData.length > 0 && <Box display="flex" justifyContent="center">
                <Button variant="outlined" className={classes.actionButton} size='small'>View All</Button>
            </Box>}
            {availableModelsData && availableModelsData.length == 0 && <Typography variant='body2'>No Data</Typography>}
        </>
    );
};

export default HomepageModelsCard;
