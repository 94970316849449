import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
} from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import {
  setCollectionResourceLabelCount,
  setDefaultDatasetCollection,
  updateDataSetCollection,
  confirmDeleteCollection,
  setDataSetCollectionDataBoost,
} from "../../../../../../../../../../../store/actions";
import {
  Chip,
  makeStyles,
  Tooltip,
  Typography,
  Checkbox,
  CircularProgress,
  IconButton,
  Icon,
  TextField,
  Button,
  Dialog,
  ButtonGroup,
  InputAdornment,
} from "@material-ui/core";
import {
  convertDate,
  IDataResponse,
  IReduxState,
  UpdateModelApiRequestPayload,
} from "../../../../../../../../../../../common";
import {
  DataService,
  ModelService,
} from "../../../../../../../../../../../services";
import { Delete, FileCopy, GetApp } from "@material-ui/icons";
import TrainDataSetCollectionButton from "./trainDataSetCollectionButton.component";
import ReTrainDataSetCollectionButton from "./reTrainDataSetCollectionButton.component";
import {
  aiTestForDatasetCollection,
  copyDataSetToAnotherModelButtonTooltipText,
  deleteButtonIconColor,
  shuffleDataSetToAnotherModelButtonTooltipText,
  splitDataSetToAnotherModelButtonTooltipText,
} from "../../data/constants/constants";
import { AddModelCollectionScenario } from "./AddModelCollection";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import dataSetCopyingToAnotherModelProcessService, {
  getProcessProgressApiResponse,
} from "../../../../../../../../../../../services/dataSetCopyingToAnotherModelProcessService";
import { Alert } from "@material-ui/lab";
import usePreviousValue from "../../../../../../../../../../../hooks/usePreviousValue";
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import { Cancel } from "@material-ui/icons";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DataSetCollectionResourcesTable from "./dataSetCollectionResourcesTable";
import DeleteCollectionConfirmPopup from "./confirmCollectionDelete";
import AssistantIcon from "@material-ui/icons/Assistant";
import { removeDuplicateElementsFromArray } from "../../../../../../../../../../../services/arrayHelperService";
import { isNullOrUndefined, isNullOrUndefinedOrEmpString } from "../../../../../../../../../../../services/variableHelperService";
import { NumberService } from "../../../../../../../../../../../services/numberService";
import { useIsDataSetCollectionNameUniqueInATask } from "../../../../../../../../../../../hooks/useIsDataSetCollectionNameUniqueInATask";
import ApplyResourceLabelvalueToAllPopup from "./applyResourceLabelvalueToAllPopup/applyResourceLabelvalueToAllPopup";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import BrowseCollectionsPopupContext from "../../../../../browseCollectionsPopupContext/browseCollectionsPopup.context";

export type ResourceLabelViewType = "percentage" | "number";

const duplicateDropdownData = Array(100)
  .fill(0)
  .map((e, i) => (i + 1).toString());
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    padding: "20px 0px",
    "&p": {
      padding: "0px",
    },
  },
  body: {
    padding: "10px",
    // width: "95vw",
    // height: "95vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(min-width:1300px)": {
      width: "90vw",
      height: "80vh",
    },
    "@media(min-width:1000px) and (max-width: 1300px)": {
      width: "88vw",
      height: "80vh",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "90vw",
      height: "80vh",
    },
    "@media(max-width: 700px)": {
      width: "85vw",
      height: "80vh",
    },
  },
  dialogContainer: {
    overflow: "visible",
  },
  dialogContainerWidth: {
    maxWidth: "calc(100% - 64px)",
  },
  closeBtn: {
    position: "absolute",
    right: -10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: "2px",
    top: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    // color: "white",
    cursor: "pointer",
    width: "25px",
    height: "25px",
  },
  navigateButton: {
    padding: "0px",
    "&:hover": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  showResource: {
    width: "100%",
    height: "100%",
    "@media(min-width:1300px)": {
      display: "flex",
      jsutifyContent: "space-between",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      display: "flex",
      jsutifyContent: "space-between",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      // display: "flex",
    },
    "@media(max-width: 700px)": {
      // display: "flex",
    },
  },
  checkpointFiles: {
    // width: '58%',
    padding: "0px 30px 20px 0px",
    "@media(min-width:1300px)": {
      width: "69%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      width: "69%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      display: "flex",
      justifyContent: "center",
      width: "90%",
    },
    "@media(max-width: 700px)": {
      display: "flex",
      justifyContent: "center",
      width: "90%",
    },
    // width: '60%'
  },
  detailsSection: {
    position: 'relative',
    "@media(min-width:1300px)": {
      width: "30%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      width: "30%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "65%",
    },
    "@media(max-width: 700px)": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "65%",
    },
  },
  actoinButtonWidth: {
    "@media(min-width:1300px)": {
      position: "sticky",
      top: "0px",
      width: "95%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      position: "sticky",
      top: "0px",
      width: "95%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      position: "fixed",
      bottom: "7vh",
      display: "flex",
      justifyContent: "center",
      width: "60%",
    },
    "@media(max-width: 700px)": {
      position: "fixed",
      bottom: "7vh",
      display: "flex",
      justifyContent: "center",
      width: "60%",
    },
  },
  resourceDetails: {
    margin: "10px 4px 60px",
    maxHeight: "92%",
    overflow: "auto",
    "@media(min-width:1300px)": {
      // maxHeight: "80%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      // maxHeight: "80%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      // maxHeight: "60%",
      width: "360px",
    },
    "@media(max-width: 700px)": {
      // maxHeight: "60%",
      width: "360px",
    },
  },
  title: {
    font: "600 16px/24px Nunito Sans",
    color: "#000",
    letterSpacing: "0",
    marginLeft: "30px",
  },
  infoBlock: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3.75),
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    width: "200px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  info: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  infoLabel: {
    margin: 0,
    width: "38%",
    font: "normal normal normal 13px/22px Nunito Sans",
    letterSpacing: "0px",
    color: "#98A6B3",
  },
  infoValue: {
    margin: 0,
    overflow: "auto",
    textOverflow: "inherit",
    whiteSpace: "normal",
    maxWidth: "70%",
    font: "normal normal normal 13px/22px Nunito Sans",
    letterSpacing: "0px",
    color: "#212121",
  },
  labelBlock: {
    width: "300px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  indicators: {
    position: "absolute",
    bottom: "40px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "opacity 0.25s ease-in-out",
    borderRadius: "50%",
    background: "rgba(2, 141, 154, 0.1)",
  },
  leftIndicator: {
    left: 10,
  },
  rightIndicator: {
    right: 10,
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
  status: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  delete: {
    color: "#EF8A8A",
  },
  chip: {
    minWidth: "75px",
    marginBottom: 5,
    backgroundColor: theme.palette.grey[50],
    marginRight: theme.spacing(1),
  },
  checkboxRoot: {
    padding: 0,
  },
  deleteButton: {
    color: deleteButtonIconColor,
  },
  disabledDeleteBtn: {
    opacity: "0.6",
    color: "#EF8A8A !important",
  },
  addDescriptionBtns: {
    justifyContent: "flex-end",
  },
  flex: {
    display: "flex",
  },
  flexColumn: {
    flexDirection: "column",
  },
  flexCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
  labelCountSpinnerContainer: {
    width: "100%",
    minHeight: "250px",
  },
  labelsTitle: {
    width: "100%",
    marginLeft: "0 !important",
  },
  labelsValue: {
    marginLeft: "1.5rem",
  },
  lookAsDisabled: {
    opacity: 0.5,
  },
  pointerEventsNone: {
    pointerEvents: "none",
  },
  resourceLabelColumnHeading: {
    fontStyle: "italic",
    color: "black",
  },
}));

export interface IAction {
  downloadHandler: Function;
  trainHandler: Function;
  reTrainHandler: Function;
  copyCollectionHandler?: Function;
  aiTestForDatasetCollectionHandler?: Function;
  shuffleHandler : Function;
  splitHandler : Function;
}
export interface DataSetPropType extends TPropsFromRedux {
  open: boolean;
  handleClose: () => void;
  onMoveToRight: () => void;
  onMoveToLeft: () => void;
  isResourceExistBefore: boolean;
  isResourceExistAfter: boolean;
  defaultCollectionId: string;
  modelId: string;
  updateDefaultCollectionId: Function;
  isFetchDefaultId: boolean;
  setFetchingDefaultId: Function;
  actions: IAction;
  modelCollectionScenario: AddModelCollectionScenario;
  loadingAiTest: boolean;
  setIsImageAnnotationsToBeCopied: Function;
  setIsModelTypeImage: Function;
  scenario?: "browseCollectionsPopup" | "aiMarketplaceDataSelection" | "aiMarketplaceSelectedDataList";
  isUsingDataCartForAiAssistedlabellingCodeTesting: boolean;
}

type EditedResourceLabel = {
  action: "remove" | "editCount";
  label: string;
  countEditedValue?: number;
  errorMessage?: string;
};

function convertPercentageToNumeric(percentage: number, maxValue: number): number {
  const numericValue = (percentage * maxValue) / 100;
  return numericValue;
}

export const DataSetCollectionPopup = (props: DataSetPropType) => {
  const classes = useStyles();
  const resourcesState = useSelector((state: IReduxState) => state.data)
  const dataCartContext = useContext(BrowseCollectionsPopupContext)

  const {
    open,
    handleClose,
    onMoveToRight,
    onMoveToLeft,
    isResourceExistBefore,
    isResourceExistAfter,
    selected,
    defaultCollectionId,
    modelId,
    updateDefaultCollectionId,
    isFetchDefaultId,
    setFetchingDefaultId,
    actions,
    modelCollectionScenario,
    confirmDeleteCollection,
    loadingAiTest,
  } = props;
  console.log("DATTA", selected);
  console.log("DATTA", modelId);
  console.log("DATTA Testing RERENDER");
  const dispatch = useDispatch();
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isRemarksBeingUpdatedInDB, setIsRemarksBeingUpdatedInDB] =
    useState(false);
  const descriptionText = selected?.description || "";
  const [descriptionValue, setDescriptionValue] = useState(
    descriptionText || ""
  );
  const [isFetchingResourceLabels, setIsFetchingResourceLabels] =
    useState(false);

  const { setCollectionResourceLabelCount } = props;

  const [isEditingResourceLabels, setIsEditingResourceLabels] = useState(false);
  const [editedResourceLabels, setEditedResourceLabels] = useState<
    EditedResourceLabel[]
  >([]);

  const [resourceLabelsView, setResourceLabelsView] = useState<ResourceLabelViewType>("number");
  const [resourceLabelsApplyAllPopup, setResourceLabelsApplyAllPopup] = useState<{
    open: boolean,
  }>({
    open: false,
  })
  const [dataSetCollectionNameOfClonedCollectionDuringResourceLabelsCloning, setDataSetCollectionNameOfClonedCollectionDuringResourceLabelsCloning] = useState("");

  const {isUnique: isDataSetResourceLabelsClonedCollectionNameUnique,} = useIsDataSetCollectionNameUniqueInATask({
    dataSetCollectionNameToCheck: dataSetCollectionNameOfClonedCollectionDuringResourceLabelsCloning,
    taskId: modelId,
    debounceDelayInMs: 500
  })


  useEffect(()=>{
    const executeFunction = async () => {
      const uniqueDatSetCollectionName =
      await DataService.getDataSetCollectionUniqueNameIfAlreadySameNameExistInModel(
        {
          modelId: modelId,
          datasetCollectionName:
            selected?.name || "",
        }
      );
      setDataSetCollectionNameOfClonedCollectionDuringResourceLabelsCloning(uniqueDatSetCollectionName);
    }
    if (selected?.name && modelId) {
      executeFunction();
    } else {
      setDataSetCollectionNameOfClonedCollectionDuringResourceLabelsCloning("");
    }
  }, [selected?.name, modelId])

  const remarksText = selected?.remarks?.text || "";
  const [remarksTextState, setRemarksTextState] = useState<
    InputFieldState<string>
  >({ value: remarksText || "" });
  const dialogRef = useRef<any>(null);
  const [aiTestDescription, setAiTestDescription] = useState("");
  const [aiTestEnabled, setAiTestEnabled] = useState(false);

  const [
    cloningResourceLabelsToNewDataSetCollectionInDbDetails,
    setCloningResourceLabelsToNewDataSetCollectionInDbDetails,
  ] = useState({
    isCloningHappeningInDB: false,
    newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning: "",
  });

  // tags
  const [isModelBeingUpdatedInDB, setIsModelBeingUpdatedInDB] = useState(false);
  const [tagsState, setTagsState] = useState<InputFieldState<string[]>>({
    value: [],
  });
  useEffect(() => {
    if (selected && selected.tags && selected.tags.length > 0) {
      setTagsState((prevData) => {
        const updatedData = { ...prevData };
        updatedData.value = selected.tags.map((tag) => tag.value);
        return updatedData;
      });
    } else {
      setTagsState((prevData) => {
        const updatedData = { ...prevData };
        updatedData.value = [];
        return updatedData;
      });
    }
  }, [selected?._id]);
  useEffect(() => {
    setRemarksTextState((prevValue) => ({
      value: remarksText,
      isDirty: false,
      isTouched: false,
    }));
  }, [remarksText, selected?._id]);

  useEffect(() => {
    setDescriptionValue(descriptionText);
  }, [descriptionText]);

  const dataBoostValue = selected?.dataBoost
    ? selected.dataBoost.toString()
    : "1";
  const [dataBoost, setDataBoost] = useState(dataBoostValue || "1");

  //   useEffect(() => {
  //     setDataBoost(dataBoostValue);
  //   },[dataBoostValue])

  useEffect(() => {
    const exectueFunction = async () => {
      const apiResponse = await ModelService.getModelDetails({
        modelId: modelId,
        modelSelectQuery: `aiTestEnabled aiTestDescription`,
      });
      setAiTestDescription(apiResponse.data?.aiTestDescription || "");
      setAiTestEnabled(apiResponse.data?.aiTestEnabled || false);
    };
    exectueFunction();
  }, [modelId, selected?._id]);

  const doesAnyResourceLabelHasError = useMemo(() => {
    if (editedResourceLabels?.length > 0) {
      for (const editedResourceLabel of editedResourceLabels) {
        if (editedResourceLabel.errorMessage) {
          return true;
        }
      }
    }

    return false;
  }, [editedResourceLabels]);

  const onClose = () => {
    handleClose();
  };
  const arrowClicked = (direction: "left" | "right") => {
    if (direction === "left") {
      onMoveToLeft();
    } else {
      onMoveToRight();
    }
  };

  const handleDefaultCollectionChange = async (event: any) => {
    const modelPayload: UpdateModelApiRequestPayload = event.target.checked
      ? {
          id: [modelId],
          defaultDataSetCollection: selected?._id,
        }
      : { id: [modelId], defaultDataSetCollection: null };
    setFetchingDefaultId(true);
    await ModelService.updateModel(modelPayload);
    if (modelPayload.defaultDataSetCollection && selected) {
      props.setDefaultDatasetCollection({
        name: selected?.name,
        id: selected?._id,
      });
    } else {
      props.setDefaultDatasetCollection(null);
    }
    updateDefaultCollectionId(modelId);
  };

  const onDataBoostChange = async (ele: string) => {
    // if(selectedDataObject?._id) {
    //     updateData({  id: [selectedDataObject?._id],dataBoost: ele }, { }, {shouldFetchLatestData: false});
    // }
    if (selected) {
      DataService.setDataSetCollectionDataBoost({
        id: selected._id,
        dataBoost: Number(ele),
      });
    }
    setDataBoost(ele.toString());
    dispatch(
      setDataSetCollectionDataBoost({
        modelCollectionId: selected?._id || "",
        dataBoost: Number(ele),
      })
    );
    // setSelectedDataObject((prev) => (JSON.parse(JSON.stringify({...prev, dataBoost: ele.toString()}))))
  };

  /**
   * To fetch the resources labels and count for specific collection
   */
  const fetchResourceLabelsData = useCallback(async () => {
    if (selected) {
      try {
        const data = await DataService.getResourceLabelsAndCountForCollection(
          selected._id
        );
        const labelsData = data.data;
        const modifiedLabels = Object.entries(labelsData).length
          ? Object.entries(labelsData).map((entry) => {
              return {
                label: entry[0],
                count: entry[1],
              };
            })
          : [];
        const updatedLabel = {
          ...selected,
          dataSetResourcesLabelCount: [...modifiedLabels],
        };
        setCollectionResourceLabelCount(updatedLabel);
      } catch (err) {
        console.error(err);
      }
      setIsFetchingResourceLabels(false);
    }
  }, [selected?._id, setCollectionResourceLabelCount]);

  const handleOnDataSetCopyToAnotherModelProcessCompleted = useCallback(() => {
    fetchResourceLabelsData();
  }, [fetchResourceLabelsData]);

  useEffect(() => {
    // if (selected && !selected.dataSetResourcesLabelCount) {
    if (selected?._id) {
      setIsFetchingResourceLabels(true);
      fetchResourceLabelsData();
    }
  }, [fetchResourceLabelsData, selected?._id]);

  useEffect(() => {
    if (props.shouldFetchLatestResourceLabels) {
      setIsFetchingResourceLabels(true);
      fetchResourceLabelsData();
    }
  }, [fetchResourceLabelsData, props.shouldFetchLatestResourceLabels]);

  /**
   * To update the dataset collection description
   * @param value description value
   */
  const updateDatasetCollectionDescription = async (value: string) => {
    if (selected) {
      try {
        const data = await DataService.updateDatasetCollection({
          id: selected._id,
          description: value,
        });
        if (data.status === 200) {
          props.updateDataSetCollection({
            ...selected,
            description: data.data.description,
            updated_at: data.data.updated_at,
          });
        }
      } catch (e) {
        alert(e);
      }
      setIsEditingDescription(false);
    }
  };

  useEffect(() => {
    setDataBoost(dataBoostValue || "1");
  }, [dataBoostValue]);

  const handleOnKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.shiftKey) {
        return;
      }
      const keyBoardEvent: any = event;
      /**
       * keyBoardEvent.path[0].nodeName !== "DIV"
       * Above condition to prevent arrow press inside a text field
       */
      if (
        keyBoardEvent.path?.length &&
        keyBoardEvent.path[0].nodeName !== "DIV"
      ) {
        return;
      }
      if (isFetchDefaultId) {
        return;
      }
      if (event.key === "ArrowLeft" && isResourceExistBefore) {
        onMoveToLeft();
      } else if (event.key === "ArrowRight" && isResourceExistAfter) {
        onMoveToRight();
      }
    },
    [
      isResourceExistBefore,
      isResourceExistAfter,
      onMoveToLeft,
      onMoveToRight,
      isFetchDefaultId,
    ]
  );

  useEffect(() => {
    if (dialogRef && dialogRef.current) {
      dialogRef.current.addEventListener("keyup", handleOnKeyUp);
    }
    return () => {
      if (dialogRef && dialogRef.current) {
        dialogRef.current.removeEventListener("keyup", handleOnKeyUp);
      }
    };
  }, [dialogRef.current, handleOnKeyUp]);
  function handleTagsInputChangeEvent(eventValue: string) {
    if (eventValue.includes(",")) {
      const values: string[] = eventValue
        .split(",")
        .map((value) => value.trim())
        .filter((value: string) => {
          if (!value) {
            return false;
          }
          return true;
        });

      if (values?.length > 0) {
        const oldValue = tagsState.value;
        for (const value of values) {
          if (!oldValue.includes(value)) {
            oldValue.push(value);
          }
        }

        const tagsToSave = removeDuplicateElementsFromArray(oldValue);

        setTagsState({
          ...tagsState,
          isDirty: true,
          isTouched: true,
          value: [...tagsToSave],
        });
      }
    }
  }
  const onSaveRemarksClick = async () => {
    if (selected) {
      setIsRemarksBeingUpdatedInDB(true);
      try {
        const data = await DataService.updateDatasetCollection({
          id: selected._id,
          remarks: remarksTextState.value,
        });
        if (data.status === 200) {
          props.updateDataSetCollection({
            ...selected,
            remarks: data.data.remarks,
            updated_at: data.data.updated_at,
          });
          setRemarksTextState((oldState) => {
            return {
              ...oldState,
              isDirty: false,
              isTouched: false,
            };
          });
          setIsRemarksBeingUpdatedInDB(false);
        }
      } catch (e) {
        setIsRemarksBeingUpdatedInDB(false);
        alert(e);
      }
    }
  };
  function updateTags() {
    if (!selected) return;
    if (tagsState.value.length === 0) {
      setTagsState({
        ...tagsState,
        isTouched: true,
        errorMessage: "At least one tag is required",
      });
      return;
    }
    setIsModelBeingUpdatedInDB(true);
    DataService.updateDatasetCollection({
      id: selected._id,
      tags: tagsState.value,
    })
      .catch((err) => console.log(err))
      .finally(() => setIsModelBeingUpdatedInDB(false));
  }
  useEffect(() => {
    if (tagsState.value.length === 0) {
      setTagsState((prevState) => {
        const updatedState = { ...prevState };
        updatedState.errorMessage = "Atleast one tag is required";
        return updatedState;
      });
    } else {
      setTagsState((prevState) => {
        const updatedState = { ...prevState };
        updatedState.errorMessage = "";
        return updatedState;
      });
    }
  }, [tagsState.value]);

  /**
   * adds the currently selected data points in the ai marketplace datacart
   */
  const onClickSelectDataPoints = () => {
    if (resourcesState.selectedItems.length) {
      dataCartContext.addResourcesToAiMarketplaceDataCart(resourcesState.selectedItems);
    }
    onClose();
  }


  return (
    <Dialog
      ref={dialogRef}
      onClose={onClose}
      open={open}
      maxWidth="lg"
      fullWidth={false}
      classes={{
        paperScrollPaper: classes.dialogContainer,
        paperWidthLg: classes.dialogContainerWidth,
      }}
    >
      <div className={classes.root}>
        <div
          className={classes.closeBtn}
          onClick={() => {
            onClose();
          }}
        >
          <Cancel style={{ fontSize: "40px", padding: "4px" }} />
        </div>

        {!isFetchDefaultId ? (
          <div className={classes.body}>
            {/* left arrow  */}
            {
              <IconButton
                onClick={() => arrowClicked("left")}
                className={classes.navigateButton}
                style={{
                  visibility: isResourceExistBefore ? "visible" : "hidden",
                }}
              >
                <NavigateBeforeIcon
                  style={{ color: "black", fontSize: "xxx-large" }}
                />
              </IconButton>
            }

            {/* model collection resource details */}
            <div className={classes.showResource}>
              <div className={classes.checkpointFiles}>
                {selected?._id && (
                  <>
                    <DataSetCollectionResourcesTable
                      dataSetCollectionId={selected?._id}
                      scenario={props.scenario}
                      isUsingDataCartForAiAssistedlabellingCodeTesting={
                        props.isUsingDataCartForAiAssistedlabellingCodeTesting
                      }
                    />
                  </>
                )}
              </div>

              <div className={classes.detailsSection}>
                {/* Action Button section */}
                {
                  props.scenario !== 'aiMarketplaceDataSelection'
                  &&
                <div
                  style={{
                    backgroundColor: "white",
                    opacity: 1,
                    zIndex: 5,
                    marginLeft: "4px",
                  }}
                  className={classes.actoinButtonWidth}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p
                        style={{
                          marginBottom: "10px",
                          color: `${"#8e969e"}`,
                          marginRight: "30px",
                          width: "100px",
                        }}
                      >
                        Actions :{" "}
                      </p>
                    </div>
                    <div className={classes.infoValue} style={{ width: "90%" }}>
                      {selected?.isResourcesFileDownloadLinkBeingFetched ? (
                        <Icon
                          style={{ verticalAlign: "middle", padding: "12px" }}
                        >
                          <CircularProgress size="20px" color="primary" />
                        </Icon>
                      ) : (
                        <Tooltip
                          title={
                            !(
                              selected?.totalNoOfResources &&
                              selected?.totalNoOfResources > 0
                            )
                              ? "Collection does not have any resources to download"
                              : "Download collection's resources details in a csv file"
                          }
                        >
                          <IconButton
                            color="primary"
                            disabled={
                              !(
                                selected?.totalNoOfResources &&
                                selected?.totalNoOfResources > 0
                              )
                                ? true
                                : false
                            }
                            onClick={() => {
                              actions.downloadHandler(selected);
                            }}
                          >
                            <GetApp />
                          </IconButton>
                        </Tooltip>
                      )}
                      { // @ts-ignore
                      props.scenario !== "browseCollectionsPopup" && props.scenario !== 'aiMarketplaceDataSelection' && props.scenario !== "aiMarketplaceSelectedDataList" && (
                        <>
                          {!selected?.isDeleteHappening ? (
                            <Tooltip title={"Delete the collection"}>
                              <IconButton
                                id={"deleteBtn_sideDrawer" + selected?._id}
                                classes={{
                                  root: classes.deleteButton,
                                  disabled: classes.disabledDeleteBtn,
                                }}
                                aria-label="export"
                                disabled={selected?.isDeleteHappening}
                                onClick={() => {
                                  confirmDeleteCollection({
                                    multiDelete: false,
                                    collectionToDelete: selected || null,
                                    showConfirmDeleteModal: true,
                                    selectedCollectionIds: [],
                                    collectionType: "dataSet",
                                    deletedCollectionFromPopup: true,
                                  });
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Deleting data-set collection">
                              <div
                                style={{
                                  marginLeft: "12px",
                                  marginRight: "12px",
                                  display: "inline",
                                }}
                              >
                                <CircularProgress
                                  style={{
                                    color: "#EF8A8A",
                                    alignSelf: "center",
                                    marginBottom: "-7px",
                                  }}
                                  size="21px"
                                  color="primary"
                                />
                              </div>
                            </Tooltip>
                          )}
                          <TrainDataSetCollectionButton
                            onClick={() => {
                              actions.trainHandler(selected);
                            }}
                            isSelected={
                              modelCollectionScenario ===
                                "trainingDataSetCollections" &&
                              props.trainingDataDirectoriesSelectedStateForAddModelCollection.value.indexOf(
                                selected?.name || ""
                              ) !== -1
                                ? true
                                : false
                            }
                            isDefaultDataSetCollectionExist={
                              (props.defaultDatasetCollection &&
                                props.defaultDatasetCollection.id.length > 0) ||
                              false
                            }
                          />
                          <ReTrainDataSetCollectionButton
                            onClick={() => {
                              actions.reTrainHandler(selected);
                            }}
                            isSelected={
                              modelCollectionScenario ===
                                "reTrainingDataSetCollections" &&
                              props.trainingDataDirectoriesSelectedStateForAddModelCollection.value.indexOf(
                                selected?.name || ""
                              ) !== -1
                                ? true
                                : false
                            }
                            isDefaultDataSetCollectionExist={
                              (props.defaultDatasetCollection &&
                                props.defaultDatasetCollection.id.length > 0) ||
                              false
                            }
                          />
                          <Tooltip
                            title={copyDataSetToAnotherModelButtonTooltipText}
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                props.setIsModelTypeImage(false);
                                props.setIsImageAnnotationsToBeCopied(true);
                                props.actions.copyCollectionHandler &&
                                  props.actions.copyCollectionHandler(selected);
                              }}
                            >
                              <FileCopy />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={shuffleDataSetToAnotherModelButtonTooltipText}
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                props.actions.shuffleHandler &&
                                  props.actions.shuffleHandler(selected);
                              }}
                            >
                              <ShuffleIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={splitDataSetToAnotherModelButtonTooltipText}
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                props.actions.splitHandler &&
                                  props.actions.splitHandler(selected);
                              }}
                            >
                              <CallSplitIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      {aiTestEnabled && (
                        <Tooltip title={aiTestDescription}>
                          <IconButton
                            // color="primary"
                            disabled={loadingAiTest}
                            style={{ color: "#DAA520" }}
                            onClick={() => {
                              props.actions.aiTestForDatasetCollectionHandler &&
                                props.actions.aiTestForDatasetCollectionHandler(
                                  selected
                                );
                            }}
                          >
                            {loadingAiTest ? (
                              <CircularProgress
                                style={{ width: "25px", height: "25px" }}
                              />
                            ) : (
                              <AssistantIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
                }
                <div style={{ overflow: "auto", height: "85%" }}>
                  <div className={classes.info}>
                    <p className={classes.infoLabel}>Name:</p>
                    <p className={classes.infoValue}>
                      <Tooltip title={selected?.name as string}>
                        <span>{selected?.name}</span>
                      </Tooltip>
                    </p>
                  </div>

                  <div style={{ marginTop: "20px" }}></div>
                  <DataSetCopyProcessInfoSection
                    dataSetCollectionId={selected?._id || ""}
                    onDataSetCopyToAnotherModelProcessCompleted={
                      handleOnDataSetCopyToAnotherModelProcessCompleted
                    }
                  />

                  <div className={classes.info}>
                    <p className={classes.infoLabel}>Created At:</p>
                    <p className={classes.infoValue}>
                      {selected && convertDate(selected.created_at)}
                    </p>
                  </div>
                  <div className={classes.info} style={{ marginTop: "8px" }}>
                    <p className={classes.infoLabel}>Data Boost:</p>
                    <p
                      className={classes.infoValue}
                      style={{
                        width: "59%",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "end",
                      }}
                    >
                      <div style={{ marginBottom: "4px", marginRight: "4px" }}>
                        x
                      </div>
                      <Autocomplete
                        onChange={(event: any, newValue: string | null) => {
                          onDataBoostChange(newValue?.toString() || "1");
                        }}
                        disableClearable
                        value={dataBoost.toString()}
                        disablePortal
                        id="combo-box-demo"
                        options={duplicateDropdownData}
                        renderInput={(params: any) => (
                          <TextField {...params} style={{ width: "auto" }} />
                        )}
                        disabled={props.scenario === 'aiMarketplaceDataSelection'}
                      />
                    </p>
                  </div>
                  {selected?.created_by && (
                    <div className={classes.info}>
                      <p className={classes.infoLabel}>Created By:</p>
                      <p
                        className={classes.infoValue}
                        style={{
                          maxWidth: "200px",
                          wordWrap: "break-word",
                          whiteSpace: "unset",
                        }}
                      >
                        {selected?.created_by}
                      </p>
                    </div>
                  )}
                  <div className={classes.info}>
                    <p className={classes.infoLabel}>Last Updated At:</p>
                    <p className={classes.infoValue}>
                      {selected && convertDate(selected.updated_at)}
                    </p>
                  </div>
                  {selected?.parentCollections &&
                    selected?.parentCollections.length >= 1 && (
                      <div
                        className={classes.info}
                        style={{ justifyContent: "flex-start" }}
                      >
                        <p className={classes.infoLabel}>
                          Parent Data-Set Collections:
                        </p>
                        <p
                          className={classes.infoValue}
                          // style={{width: '65%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                        >
                          {/* <Tooltip title={selected?.parentCollections.map(parentCollections => parentCollections.name).join('  ,  ')}> */}
                          <div
                            style={{
                              width: "100%",
                              overflow: "auto",
                              maxHeight: "200px",
                            }}
                          >
                            {selected?.parentCollections?.map(
                              (parentDataSetCollection) => {
                                return (
                                  <Tooltip title={parentDataSetCollection.name}>
                                    <Chip
                                      key={parentDataSetCollection._id}
                                      label={parentDataSetCollection.name}
                                      classes={{ root: classes.chip }}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    />
                                  </Tooltip>
                                );
                              }
                            )}
                          </div>
                          {/* </Tooltip> */}
                        </p>
                      </div>
                    )}
                  <div className={classes.info}>
                    {!isEditingDescription && (
                      <p className={classes.infoLabel}>
                        Description:
                        {
                          props.scenario !== 'aiMarketplaceDataSelection'
                          &&
                        <Tooltip title={"Edit Description"}>
                          <IconButton
                            id={"editButton-desciption-btn"}
                            color="primary"
                            aria-label="export"
                            size="small"
                            onClick={() => {
                              setIsEditingDescription(true);
                            }}
                          >
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                        }
                      </p>
                    )}
                    {!isEditingDescription && (
                      <p
                        className={classes.infoValue}
                        style={{
                          overflow: "auto",
                          textOverflow: "inherit",
                          whiteSpace: "normal",
                          maxWidth: 200,
                        }}
                      >
                        {descriptionValue || selected?.description}
                      </p>
                    )}
                  </div>
                  {isEditingDescription && selected && (
                    <div>
                      <TextField
                        label="Description"
                        id="description-input"
                        classes={{ root: classes.flex }}
                        value={descriptionValue}
                        onChange={(e) => {
                          setDescriptionValue(e.target.value);
                        }}
                        variant="outlined"
                        size="small"
                      />
                      <div
                        className={`${classes.addDescriptionBtns} ${classes.flex}`}
                      >
                        <Tooltip title={"Cancel"}>
                          <IconButton
                            id={"cancel-description-btn"}
                            color="primary"
                            classes={{ root: classes.deleteButton }}
                            aria-label="export"
                            onClick={() => {
                              setDescriptionValue(selected.description);
                              setIsEditingDescription(false);
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Save"}>
                          <IconButton
                            id={"save-desciption-btn"}
                            color="primary"
                            aria-label="export"
                            onClick={() => {
                              updateDatasetCollectionDescription(
                                descriptionValue
                              );
                            }}
                          >
                            <CheckIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                  {
                    props.scenario !== 'aiMarketplaceDataSelection'
                    &&
                    <div className={classes.info}>
                      <p className={classes.infoLabel}>Default Collection:</p>
                      <p className={classes.infoValue}>
                        {!isFetchDefaultId && (
                          <Checkbox
                            checked={defaultCollectionId === selected?._id}
                            classes={{ root: classes.checkboxRoot }}
                            onChange={(e) => handleDefaultCollectionChange(e)}
                          />
                        )}
                        {isFetchDefaultId && <CircularProgress size={20} />}
                      </p>
                    </div>
                  }

              {selected?.dataSetResourcesLabelCount &&
                    !isFetchingResourceLabels && (
                      <div className={classes.info + " " + classes.flexColumn}>
                        {
                          resourceLabelsApplyAllPopup.open &&
                          <ApplyResourceLabelvalueToAllPopup 
                            onClose={()=>{
                              setResourceLabelsApplyAllPopup(oldState=>{
                                return {
                                  ...oldState,
                                  open: false
                                }
                              })
                            }} 
                          onApplyClick={(numericValue, labelView)=>{

                            setEditedResourceLabels(OldEditedResourceLabels=>{

                              // Adding all labels to editedResourceLabels
                              for (let resourceLabelIndex = 0; resourceLabelIndex < (selected.dataSetResourcesLabelCount || []).length; resourceLabelIndex++) {
                                const label = (selected.dataSetResourcesLabelCount || [])[resourceLabelIndex];
                                const labelPresentInEditedResourceLabels = OldEditedResourceLabels.find(editedResourceLabel=>editedResourceLabel.label === label.label)
                                if (!labelPresentInEditedResourceLabels) {
                                  OldEditedResourceLabels.push({
                                    action: "editCount",
                                    label: label.label,
                                    countEditedValue: 0,
                                  })
                                }
                              }

                              // Changing count of all editedResourceLabelsWhichAreNotDeletedByUser
                              for (const editedResourceLabel of OldEditedResourceLabels) {
                                if (editedResourceLabel.action === "remove") {
                                  continue;
                                }

                                const maxValueOfLabel: number = (()=>{
                                  const resourceLabel = (selected?.dataSetResourcesLabelCount || []).find(label=>label.label === editedResourceLabel.label)
                                  return resourceLabel?.count || 0;
                                })();

                                if (labelView === "number") {
                                  editedResourceLabel.countEditedValue = 
                                    Number(numericValue) > maxValueOfLabel
                                    ? NumberService.convertDecimalToInteger(Number(maxValueOfLabel) || 0)
                                    : NumberService.convertDecimalToInteger(Number(numericValue) || 0);
                                  continue;
                                }
                                if (labelView === "percentage") {
                                  const countToSet = (()=>{
                                    if (Number(numericValue) > 100) {
                                      return 100;
                                    }
                                    return convertPercentageToNumeric(Number(numericValue), maxValueOfLabel)
                                  })(); 
                                  editedResourceLabel.countEditedValue = countToSet;
                                }
                                
                              }
                              
                              return [...OldEditedResourceLabels]
                            })

                            setResourceLabelsApplyAllPopup(oldState=>{
                              return {
                                ...oldState,
                                open: false
                              }
                            })
                          }} 
                          labelViewDefaultValue={resourceLabelsView}                          
                          />
                        }
                        {selected.dataSetResourcesLabelCount.length ? (
                          <>
                            <Typography
                              className={
                                classes.labelsTitle + " " + classes.title
                              }
                              style={{ marginBottom: 10 }}
                            >
                              Resource Labels:
                              {!isEditingResourceLabels && props.scenario !== 'aiMarketplaceDataSelection' && (
                                <Tooltip
                                  title={
                                    "Clone resource labels to a new data-set collection"
                                  }
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="export"
                                    size="small"
                                    onClick={() => {
                                      setIsEditingResourceLabels(true);
                                    }}
                                  >
                                    <FileCopyIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Typography>

                            {
                              isEditingResourceLabels &&
                                  <TextField size="small" 
                                  variant="standard"
                                  style={{
                                    width: "100%",
                                    marginBottom: "20px"
                                  }}
                                  inputProps={{
                                    style:{
                                      fontSize: "12px"
                                    }
                                  }}
                                  label="New data-set collection name"
                                  value={dataSetCollectionNameOfClonedCollectionDuringResourceLabelsCloning}
                                  onChange={(e)=>{
                                    setDataSetCollectionNameOfClonedCollectionDuringResourceLabelsCloning(e.target.value)
                                  }}
                                  error={!isDataSetResourceLabelsClonedCollectionNameUnique}
                                  helperText={
                                    !isDataSetResourceLabelsClonedCollectionNameUnique
                                    ? "Data-set collection needs to be unique"
                                    : ""
                                  }
                                />                              
                            }

                              {
                                isEditingResourceLabels &&
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      alignItems: "baseline"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        gap: "12px",
                                        alignItems: "baseline",
                                        marginBottom: "12px"
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "14px"
                                        }}
                                      >View</div>
                                      <div>
                                        <ButtonGroup size="small">
                                            <Button color="primary" size="small" variant={resourceLabelsView === "percentage" ? "contained" : "outlined"}
                                              onClick={()=>{
                                                setResourceLabelsView("percentage")
                                              }}
                                            >
                                              <Tooltip title="See label count as percentage">
                                              <span>%</span>
                                              </Tooltip>
                                            </Button>
                                            <Button color="primary" size="small" variant={resourceLabelsView === "number" ? "contained" : "outlined"}
                                              onClick={()=>{
                                                setResourceLabelsView("number")
                                              }}                                        
                                            >
                                              <Tooltip title="See label count as numeric">
                                              <span>123</span>
                                              </Tooltip>
                                            </Button>
                                        </ButtonGroup>
                                      </div>
                                    </div>
                                      <Button 
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        style={{
                                          width: "117%"
                                        }}
                                        onClick={()=>{
                                          setResourceLabelsApplyAllPopup({
                                            open: true
                                          })
                                        }}
                                      >
                                        Apply Count to all Labels
                                      </Button>
                                  </div>
                                </>
                              }

                            <div
                              className={`${classes.flex}`}
                              style={{
                                width: "100%",
                              }}
                            >
                              <div
                                className={`${classes.infoLabel} ${classes.resourceLabelColumnHeading}`}
                              >
                                Label
                              </div>
                              <div
                                className={`${classes.infoValue} ${classes.resourceLabelColumnHeading}`}
                              >
                                Count
                              </div>
                            </div>
                            {selected.dataSetResourcesLabelCount.map(
                              (resourceValue) => {
                                const resourceEditedLabel =
                                  editedResourceLabels?.find(
                                    (editedResourceLabel) => {
                                      if (
                                        editedResourceLabel.label ===
                                        resourceValue.label
                                      ) {
                                        return true;
                                      }
                                      return false;
                                    }
                                  );

                                const isResourceLabelActionSetAsRemovedInEditedResourceLabels =
                                  resourceEditedLabel
                                    ? resourceEditedLabel.action === "remove"
                                      ? true
                                      : false
                                    : false;

                                  const resourceLabelCountTextFieldValue = (()=>{
                                    if (!resourceEditedLabel) {
                                      if (resourceLabelsView === "percentage") {
                                        return 100
                                      } else {
                                        return resourceValue.count
                                      }
                                    }
                                    if (isNullOrUndefinedOrEmpString(resourceEditedLabel?.countEditedValue)) {
                                      return resourceEditedLabel?.countEditedValue;
                                    }
                                    if (resourceLabelsView === "percentage") {
                                      const currentNumValue = (()=>{
                                        return resourceEditedLabel?.countEditedValue                                       
                                      })();
                                      const maxValue = resourceValue.count;
                                      const percentageValue = ((currentNumValue || 0)/maxValue) * 100
                                      return percentageValue;
                                    }

                                    return resourceEditedLabel?.countEditedValue || 0;
                                  })();
                                  const resourceLabelCountTextFieldDefaultValue = (()=>{
                                    if (resourceLabelsView === "percentage") {
                                      const currentNumValue = resourceValue.count || 0;
                                      const maxValue = resourceValue.count;
                                      const percentageValue = (currentNumValue/maxValue) * 100
                                      return percentageValue;
                                    }
                                    
                                    return resourceValue.count
                                  })();
                                  console.log('resourceLabelCountTextFieldValue ~ resourceLabelCountTextFieldValue:', resourceLabelCountTextFieldValue)

                                return (
                                  <div
                                    className={`
                                                            ${classes.flex}
                                                            ${
                                                              isResourceLabelActionSetAsRemovedInEditedResourceLabels
                                                                ? `${classes.lookAsDisabled} ${classes.pointerEventsNone}`
                                                                : ""
                                                            }
                                                        `}
                                    style={{ width: "100%" }}
                                  >
                                    <p className={classes.infoLabel}>
                                      {resourceValue.label || ""}:
                                    </p>
                                    {isEditingResourceLabels ? (
                                      <TextField
                                        // label="Count to set for cloning"
                                        // defaultValue={resourceValue.count}
                                        defaultValue={resourceLabelCountTextFieldDefaultValue}
                                        value={
                                          isNullOrUndefinedOrEmpString(resourceLabelCountTextFieldValue)
                                          ? ""
                                          
                                          : NumberService.convertDecimalToInteger(resourceLabelCountTextFieldValue || 0)
                                        }
                                        // resourceEditedLabel?.countEditedValue
                                        inputProps={{
                                          type: "number",
                                          max: 
                                            resourceLabelsView === "percentage"
                                            ? 100
                                            : resourceValue.count,
                                          min: 0,
                                        }}
                                        InputProps={{
                                          ...(
                                            resourceLabelsView === "percentage"
                                            ? {endAdornment: <InputAdornment position="end">%</InputAdornment>}
                                            : {}
                                          )
                                        }}
                                        size="small"
                                        style={{
                                          width: "100px",
                                        }}
                                        error={
                                          resourceEditedLabel?.errorMessage
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          resourceEditedLabel?.errorMessage ||
                                          ""
                                        }
                                        onChange={(event) => {
                                          let countToSet: number | undefined;
                                          if (!isNullOrUndefinedOrEmpString(event.target.value)) {
                                            countToSet = Number(
                                              event.target.value
                                            );
                                          } else {
                                            // @ts-ignore
                                            countToSet = event.target.value
                                          }

                                          if (resourceLabelsView === "percentage" && !isNullOrUndefined(countToSet)) {
                                            if (!isNullOrUndefinedOrEmpString(countToSet)) {
                                              countToSet = convertPercentageToNumeric(
                                                countToSet || 0, resourceValue.count
                                              )
                                            }
                                          }

                                          setEditedResourceLabels(
                                            (oldEditedResourceLabels) => {
                                              const editedResourceLabelIndex =
                                                oldEditedResourceLabels.findIndex(
                                                  (editedResourceLabel) =>
                                                    editedResourceLabel.label ===
                                                    resourceValue.label
                                                );

                                              const editedResourceLabelAlreadyExist =
                                                editedResourceLabelIndex !== -1
                                                  ? true
                                                  : false;

                                              const errorMessage: string =
                                                isNullOrUndefinedOrEmpString(countToSet)
                                                ? ""
                                                // @ts-ignore
                                                : countToSet > resourceValue.count
                                                ? `Count cannot be set more than ${resourceLabelsView === 'percentage' ? 100 : resourceValue.count}`
                                                // @ts-ignore
                                                  : countToSet < 0
                                                    ? `Count cannot be set less than 0`
                                                    : ``;

                                              if (
                                                !editedResourceLabelAlreadyExist
                                              ) {
                                                if (
                                                  countToSet !==
                                                  resourceValue.count
                                                ) {
                                                  oldEditedResourceLabels.push({
                                                    action: "editCount",
                                                    label: resourceValue.label,
                                                    countEditedValue:
                                                      countToSet,
                                                    errorMessage: errorMessage,
                                                  });
                                                }
                                              } else {
                                                if (
                                                  countToSet !==
                                                  resourceValue.count
                                                ) {
                                                  oldEditedResourceLabels[
                                                    editedResourceLabelIndex
                                                  ] = {
                                                    action: "editCount",
                                                    label: resourceValue.label,
                                                    countEditedValue:
                                                      countToSet,
                                                    errorMessage: errorMessage,
                                                  };
                                                } else {
                                                  oldEditedResourceLabels.splice(
                                                    editedResourceLabelIndex,
                                                    1
                                                  );
                                                }
                                              }

                                              return [
                                                ...oldEditedResourceLabels,
                                              ];
                                            }
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p
                                        className={
                                          classes.infoValue +
                                          " " +
                                          classes.labelsValue
                                        }
                                      >
                                        {resourceValue.count || "NA"}
                                      </p>
                                    )}
                                    {isEditingResourceLabels && (
                                      <p
                                        style={{
                                          marginLeft: "15px",
                                        }}
                                      >
                                        <IconButton
                                          aria-label="export"
                                          style={{
                                            color: deleteButtonIconColor,
                                            width: "1px",
                                            height: "1px",
                                          }}
                                          size="small"
                                          onClick={() => {
                                            setEditedResourceLabels(
                                              (oldEditedResourceLabels) => {
                                                const editedResourceLabelIndex =
                                                  oldEditedResourceLabels.findIndex(
                                                    (editedResourceLabel) =>
                                                      editedResourceLabel.label ===
                                                      resourceValue.label
                                                  );

                                                const editedResourceLabelAlreadyExist =
                                                  editedResourceLabelIndex !==
                                                  -1
                                                    ? true
                                                    : false;

                                                if (
                                                  !editedResourceLabelAlreadyExist
                                                ) {
                                                  oldEditedResourceLabels.push({
                                                    action: "remove",
                                                    label: resourceValue.label,
                                                  });
                                                } else {
                                                  oldEditedResourceLabels[
                                                    editedResourceLabelIndex
                                                  ] = {
                                                    action: "remove",
                                                    label: resourceValue.label,
                                                  };
                                                }

                                                return [
                                                  ...oldEditedResourceLabels,
                                                ];
                                              }
                                            );
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </p>
                                    )}
                                  </div>
                                );
                              }
                            )}

                            {isEditingResourceLabels && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    width: "100%",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      setEditedResourceLabels([]);
                                      setIsEditingResourceLabels(false);
                                      setCloningResourceLabelsToNewDataSetCollectionInDbDetails(
                                        (oldState) => ({
                                          ...oldState,
                                          newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning:
                                            "",
                                        })
                                      );
                                    }}
                                    disabled={
                                      cloningResourceLabelsToNewDataSetCollectionInDbDetails.isCloningHappeningInDB
                                    }
                                    variant="contained"
                                    size="small"
                                    style={{
                                      backgroundColor: "white",
                                    }}
                                  >
                                    Cancel
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    disabled={
                                      editedResourceLabels?.length === 0 ||
                                      cloningResourceLabelsToNewDataSetCollectionInDbDetails.isCloningHappeningInDB ||
                                      doesAnyResourceLabelHasError === true ||
                                      !isDataSetResourceLabelsClonedCollectionNameUnique
                                    }
                                    onClick={async () => {
                                      setCloningResourceLabelsToNewDataSetCollectionInDbDetails(
                                        (oldState) => ({
                                          ...oldState,
                                          isCloningHappeningInDB: true,
                                        })
                                      );

                                      // const uniqueDatSetCollectionName =
                                      //   await DataService.getDataSetCollectionUniqueNameIfAlreadySameNameExistInModel(
                                      //     {
                                      //       modelId: modelId,
                                      //       datasetCollectionName:
                                      //         selected.name,
                                      //     }
                                      //   );

                                      setCloningResourceLabelsToNewDataSetCollectionInDbDetails(
                                        (oldState) => ({
                                          ...oldState,
                                          newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning:
                                            dataSetCollectionNameOfClonedCollectionDuringResourceLabelsCloning,
                                        })
                                      );

                                      await DataService.addCollection({
                                        name: dataSetCollectionNameOfClonedCollectionDuringResourceLabelsCloning,
                                        description: `DataSet Collection created from ${selected.name}`,
                                        model: modelId,
                                        copyResourceLabelsFrom: {
                                          dataSetCollectionId:
                                            selected._id.toString(),
                                          labelsToNotIncludeInResources:
                                            editedResourceLabels
                                              ?.filter(
                                                (editedResourceLabel) => {
                                                  if (
                                                    editedResourceLabel.action ===
                                                    "remove"
                                                  ) {
                                                    return true;
                                                  }
                                                  return false;
                                                }
                                              )
                                              ?.map(
                                                (editedResourceLabel) =>
                                                  editedResourceLabel.label
                                              ),
                                          labelsCountToSetInRandomOrder:
                                            editedResourceLabels
                                              ?.filter(
                                                (editedResourceLabel) => {
                                                  if (
                                                    editedResourceLabel.action ===
                                                    "editCount"
                                                  ) {
                                                    return true;
                                                  }
                                                  return false;
                                                }
                                              )
                                              ?.map((editedResourceLabel) => ({
                                                label:
                                                  editedResourceLabel.label,
                                                count:
                                                  NumberService.convertDecimalToInteger(editedResourceLabel.countEditedValue ||
                                                  0) || 0,
                                              })),
                                        },
                                      });

                                      setCloningResourceLabelsToNewDataSetCollectionInDbDetails(
                                        (oldState) => ({
                                          ...oldState,
                                          isCloningHappeningInDB: false,
                                        })
                                      );
                                    }}
                                  >
                                    {cloningResourceLabelsToNewDataSetCollectionInDbDetails.isCloningHappeningInDB ? (
                                      <CircularProgress size={20} />
                                    ) : (
                                      <>Clone</>
                                    )}
                                  </Button>
                                </div>

                                {cloningResourceLabelsToNewDataSetCollectionInDbDetails.newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning && (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontStyle: "italic",
                                      color: "#868585",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {cloningResourceLabelsToNewDataSetCollectionInDbDetails.isCloningHappeningInDB ? (
                                      <>
                                        Resource Labels are being cloned in{" "}
                                        <b>
                                          {
                                            cloningResourceLabelsToNewDataSetCollectionInDbDetails.newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning
                                          }
                                        </b>{" "}
                                        data-set collection
                                      </>
                                    ) : (
                                      <>
                                        Resource Labels are cloned in{" "}
                                        <b>
                                          {
                                            cloningResourceLabelsToNewDataSetCollectionInDbDetails.newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning
                                          }
                                        </b>{" "}
                                        data-set collection
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  {isFetchingResourceLabels && (
                    <div
                      className={`${classes.flex} ${classes.flexColumn} ${classes.infoLabel} ${classes.flexCenter} ${classes.labelCountSpinnerContainer}`}
                    >
                      <CircularProgress className="m-3" />
                      <span>Fetching resources label...</span>
                    </div>
                  )}

              {
                props.scenario !== 'aiMarketplaceDataSelection'
                &&
                <>
                  <Autocomplete
                    multiple
                    id="Tags Available"
                    options={[]}
                    freeSolo
                    disabled={isModelBeingUpdatedInDB}
                    value={tagsState.value}
                    getOptionSelected={() => false}
                    onChange={(event, value) => {
                      setTagsState((oldState) => {
                        const tagsToSave = removeDuplicateElementsFromArray(
                          value as string[]
                        );

                        return {
                          ...oldState,
                          isDirty: true,
                          isTouched: true,
                          value: [...tagsToSave],
                        };
                      });
                    }}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          // className={`${classes.chip}`}
                          // classes={{ root: classes.autoCompleteChipRoot }}
                          label={
                            <Tooltip title={option}>
                              <span>{option}</span>
                            </Tooltip>
                          }
                          {...getTagProps({ index })}
                        />
                      ));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        className={classes.floating}
                        fullWidth
                        label="Tags"
                        placeholder="Add Tags"
                        onChange={(e) => {
                          handleTagsInputChangeEvent(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleTagsInputChangeEvent(e.target.value + ",");
                        }}
                        error={
                          tagsState.isTouched && tagsState.errorMessage
                            ? true
                            : false
                        }
                        helperText={
                          tagsState.isTouched && tagsState.errorMessage
                            ? tagsState.errorMessage
                            : "Add multiple tag with <enter> or <comma>"
                        }
                      />
                    )}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={updateTags}
                    disabled={
                      !tagsState.isTouched ||
                      tagsState.value.length === 0 ||
                      isModelBeingUpdatedInDB
                        ? true
                        : false
                    }
                    style={{ marginBottom: "20px", minWidth: 150 }}
                  >
                    {isModelBeingUpdatedInDB ? (
                      <CircularProgress size={"1.5rem"} />
                    ) : (
                      "Update Tags"
                    )}
                  </Button>
                </>
              }
                  {/* Remarks Sections */}
              {   props.scenario !== 'aiMarketplaceDataSelection'
                  &&
                  <>
                    <p
                      style={{ marginBottom: "10px" }}
                      className={classes.infoLabel}
                    >
                      Add Remarks:
                    </p>
                    <textarea
                      style={{
                        width: "100%",
                        minHeight: "83px",
                        marginBottom: "10px",
                        fontSize: "13px",
                        fontFamily: "Nunito Sans",
                        borderRadius: "5px",
                      }}
                      value={remarksTextState.value}
                      onChange={(event) => {
                        setRemarksTextState((oldState) => ({
                          ...oldState,
                          isDirty: true,
                          value: event.target.value,
                        }));
                      }}
                      disabled={isRemarksBeingUpdatedInDB}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        onSaveRemarksClick();
                        return;
                      }}
                      disabled={
                        isRemarksBeingUpdatedInDB || !remarksTextState.isDirty
                          ? true
                          : false
                      }
                      style={{ marginBottom: "20px" }}
                    >
                      Save Remarks
                    </Button>
                  </>
              }
                </div>
                {
                  props.scenario === 'aiMarketplaceDataSelection'
                  &&
                  <div style={{ position: 'absolute', bottom: 0, left: 0, borderTop: '1px solid lightgrey', width: '100%' }}>
                    <Button size="small" variant="contained" color="primary" style={{ textTransform: 'none', margin: '6px 6px 6px auto', display: 'block' }}
                      disabled={resourcesState.selectedItems.length === 0}
                      onClick={onClickSelectDataPoints}
                    >
                      Add to cart
                    </Button>
                  </div>
                }
              </div>
            </div>

            {/* right arrow  */}
            {
              <IconButton
                onClick={() => arrowClicked("right")}
                className={classes.navigateButton}
                style={{
                  visibility: isResourceExistAfter ? "visible" : "hidden",
                }}
              >
                <NavigateNextIcon
                  style={{ color: "black", fontSize: "xxx-large" }}
                />
              </IconButton>
            }
          </div>
        ) : (
          <div className={classes.body} style={{ justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
      {/* </div> */}
      <DeleteCollectionConfirmPopup
        isParentPopup={true}
        iterateResource={
          isResourceExistAfter
            ? onMoveToRight
            : isResourceExistBefore
            ? onMoveToLeft
            : onClose
        }
      />
    </Dialog>
  );
};

const mapProps = (state: IReduxState) => {
  return {
    isAdding: state.collection.isAdding,
    selected: state.collection.selected,
    trainingDataDirectoriesSelectedStateForAddModelCollection:
      state.collection.modelCollection
        .trainingDataDirectoriesSelectedStateForAddModelCollection,
    shouldFetchLatestResourceLabels:
      state.collection
        .shouldFetchLatestDataSetCollectionResourceLabelsIfCurrentlyShowingInScreen,
    defaultDatasetCollection: state.collection.defaultDataSetCollection,
  };
};

const connector = connect(mapProps, {
  setDefaultDatasetCollection,
  updateDataSetCollection,
  setCollectionResourceLabelCount,
  confirmDeleteCollection,

});

export type TPropsFromRedux = ConnectedProps<typeof connector>;
export default connector(DataSetCollectionPopup);

const useDataSetCopyProcessInfoSectionStyles = makeStyles((theme) => ({
  statusFailure: {
    color: "red",
  },
}));
function DataSetCopyProcessInfoSection(props: {
  dataSetCollectionId: string;
  onDataSetCopyToAnotherModelProcessCompleted: () => void;
}) {
  const classes = useDataSetCopyProcessInfoSectionStyles();

  const [
    dataSetCopyProcessProgressDetails,
    setDataSetCopyProcessProgressDetails,
  ] = useState<getProcessProgressApiResponse>();
  const previousValueOfDataSetCopyProcessProgressDetails = usePreviousValue(
    dataSetCopyProcessProgressDetails
  );

  const dataSetCollectionId = props.dataSetCollectionId;

  const parentDataSetCollectionResourcesCount: number =
    dataSetCopyProcessProgressDetails?.dataSetCollectionToCopyFrom
      ?.resourcesCount || 0;
  const noOfParentResourcesCopied: number =
    dataSetCopyProcessProgressDetails?.noOfParentDataSetResourcesCopied || 0;

  const { onDataSetCopyToAnotherModelProcessCompleted } = props;

  const copyProcessPercentCompleted: number = useMemo(() => {
    if (
      noOfParentResourcesCopied === 0 &&
      parentDataSetCollectionResourcesCount === 0
    ) {
      return 100;
    }
    return (
      (noOfParentResourcesCopied / parentDataSetCollectionResourcesCount) * 100
    );
  }, [noOfParentResourcesCopied, parentDataSetCollectionResourcesCount]);

  useEffect(() => {
    const executeFunction = async () => {
      const response =
        await dataSetCopyingToAnotherModelProcessService.getProcessProgress({
          dataSetCollectionIdWhereResourcesBeingCopiedIn: dataSetCollectionId,
        });
      setDataSetCopyProcessProgressDetails(response);
    };
    if (dataSetCollectionId) {
      executeFunction();
    }
  }, [dataSetCollectionId]);

  useEffect(() => {
    /**
     * fetching progress details after some time interval
     */

    let timeIntervalId: any;
    if (
      dataSetCollectionId &&
      dataSetCopyProcessProgressDetails &&
      !dataSetCopyProcessProgressDetails.failureMessage
    ) {
      timeIntervalId = setInterval(async () => {
        const response =
          await dataSetCopyingToAnotherModelProcessService.getProcessProgress({
            dataSetCollectionIdWhereResourcesBeingCopiedIn: dataSetCollectionId,
          });
        setDataSetCopyProcessProgressDetails(response);
      }, 3000);
    }
    return () => {
      if (timeIntervalId !== null && timeIntervalId !== undefined) {
        clearInterval(timeIntervalId);
      }
    };
  }, [dataSetCollectionId, dataSetCopyProcessProgressDetails]);

  useEffect(() => {
    if (
      !dataSetCopyProcessProgressDetails &&
      previousValueOfDataSetCopyProcessProgressDetails
    ) {
      /**
       * data-set copy process has been completed
       */

      onDataSetCopyToAnotherModelProcessCompleted();
    }
  }, [
    dataSetCopyProcessProgressDetails,
    previousValueOfDataSetCopyProcessProgressDetails,
    onDataSetCopyToAnotherModelProcessCompleted,
  ]);

  return (
    <>
      {dataSetCopyProcessProgressDetails && (
        <section>
          <Alert severity="info" style={{ fontSize: "13px " }}>
            <strong>
              {
                dataSetCopyProcessProgressDetails.dataSetCollectionToCopyFrom
                  .name
              }
            </strong>{" "}
            from{" "}
            <strong>
              {
                dataSetCopyProcessProgressDetails.dataSetCollectionToCopyFrom
                  .model.name
              }
            </strong>{" "}
            task is being copied in this collection <br />
            <strong>Status:</strong>&nbsp;
            <span
              className={`
                ${
                  dataSetCopyProcessProgressDetails.failureMessage
                    ? classes.statusFailure
                    : ""
                }
              `}
            >
              {dataSetCopyProcessProgressDetails.failureMessage
                ? "Failed"
                : "In-progress"}
            </span>
            <br />
            {dataSetCopyProcessProgressDetails?.failureMessage && (
              <>
                <div>
                  <strong>Error:</strong>{" "}
                  {dataSetCopyProcessProgressDetails?.failureMessage}
                </div>
              </>
            )}
            <strong>Completed:</strong>{" "}
            {Math.round(copyProcessPercentCompleted)}%
          </Alert>
        </section>
      )}
    </>
  );
}
