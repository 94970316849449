import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormLabel, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, TextField, TextareaAutosize, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { AdditionalHandTrackingDataApiResponse, CustomSkeleton, FeatherEditIcon, ICollectionMetrics, IIntegrity, IModelCollection, IModelCollectionsAdditionalData, ITestCollection, IntegrityBand } from '../../../../../../../../../../common';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import CloseIcon from '@material-ui/icons/Close';
import { Theme } from '../../../../../../../../../../theme';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory, useLocation } from 'react-router-dom';
import css from "./TrainedModelPopup.module.css"
import { SortField, SortOrder } from './components/AnalyticsTable';
import { CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE, IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS } from './components/integrity';
import CloudStorageService from '../../../../../../../../../../services/CloudStorageService';
import { useCallback, useEffect, useState } from 'react';
import { isNullOrUndefined } from '../../../../../../../../../../services/variableHelperService';
import DataService from '../../../../../../../../../../services/DataService';
import { MODEL_TRAINED_QNA_NOTIFICATION_DEFAULT_QNA, TTL_FOR_ACCURACY_VALUE_IN_LOCALSTORAGE } from './constants/constants';
import { getItemWithExpiryFromLocalStorage, saveItemWithExpiryInLocalStorage } from '../../../../../../../../../../common/utils/localstorage';
import { IPurposeModel } from '../../../../../../../../../../common/constants/interfaces/purposeModels';
import { AnalyticsDashboardQueryParams } from './components/ModelAnalyticsDashboard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type TrainedModelPopupPropTypes = {
    selectedTestCollectionId?: string;
    scenario?:string | 'addingNewModelForTrainingPod' | 'isTestModelCollection';
    isHandsTrackingDataFetching?:boolean,
    hands_tracking_data?:AdditionalHandTrackingDataApiResponse,
    modelCollection?: IModelCollection,
    testCollection?: ITestCollection,
    isOpen: boolean,
    onClose: any,
    accuracy: number,
    integrity: any,
    confidenceScoreBandsIntegrityData: IIntegrity | undefined ; 
    isConfidenceScoreBandsIntegrityDataBeingFetched: boolean
    testModelCollection?:Array<IIntegrity> |[];
    testModelCollectionIds?:string[]
    testModelIntegrityValues?:any[]
    fullscreen?: boolean;
    autoTriggered?: boolean;    // true means the popup was opened auomatically based on some logics and user didn't open it manually
    // versionSearchValue?: string,
    architectureSearchValue?: string,
    versionSearchValue?: string | undefined | number,
    iouThresholdValueForSegmentationAnalytics?: number,
    purposeModel?: IPurposeModel,
    confidenceScoreThresholdValue?: number,
    isSegmentationModelAnalytics?: boolean,
    collectionMetrics?: ICollectionMetrics[],
    isFetchingCollectionMetrics?: boolean,
}


const useStyles = makeStyles({
    dialogPaper: {
        borderRadius: '8px',
        padding: '22px',
        // width: '100%',
        maxWidth: '100%',
        zIndex: 10
    },
    dialogRibbon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tableCell_key: {
        borderBottom: 'none',
        padding: '10px 4px 10px 4px',
        color: Theme.palette.grey[300],
        minWidth: "150px"
    },
    tableCell_value: {
        borderBottom: 'none',
        padding: '10px 4px 10px 4px'
    },
    NotesDialogPaper: {
        borderRadius: '12px',
        padding: '12px'
    },
    analysisAccordionRoot: {
        backgroundColor: 'white'
    },
    tabsRoot: {
        backgroundColor: 'white !important',
        marginBottom: 16,
    },
    tabRoot: {
        fontWeight: 'bold',
        padding: 0,
        fontSize: '1rem'
    }
})

export const keyToSaveCollectionAccuracyInLocalStorage = (testCollectionId: string, modelCollectionId: string) : string => {
    // if test-collection id passed as empty string it returns key for model collection
    return `accuracy${testCollectionId ? '_testCollection_' + testCollectionId : ''}_modelCollection_${modelCollectionId}`
}


export const getCollectionAccuracyFromLocalStorage = (testCollectionId: string, modelCollectionId: string, iouThreshold: number, confidenceScoreThreshold: number) : number | null => {
    // searches for model-class accuracy if testCollection id was provided as empty string
    let value = getItemWithExpiryFromLocalStorage(keyToSaveCollectionAccuracyInLocalStorage(testCollectionId, modelCollectionId))

    if (isNullOrUndefined(value)) {
        return null
    }
    else {
        value = JSON.stringify(value)
        try{
            value = JSON.parse(value)
        }
        catch(error) {
            console.error("error parsing accuracy values from local storage", error)
            return null
        }
        if(value.iouThreshold !== iouThreshold || value.confidenceScoreThreshold !== confidenceScoreThreshold){
            return null
        }
    }

    return value.accuracyValue
}

export const saveCollectionAccuracyInLocalStorage = (accuracyValue: number, testCollectionId: string, modelCollectionId: string, iouThreshold: number, confidenceScoreThreshold: number) : void => {
    const valueToSave = {
        iouThreshold,
        confidenceScoreThreshold,
        accuracyValue
    }

    const key = keyToSaveCollectionAccuracyInLocalStorage(testCollectionId, modelCollectionId)

    saveItemWithExpiryInLocalStorage(key, valueToSave, TTL_FOR_ACCURACY_VALUE_IN_LOCALSTORAGE)
}


const TrainedModelPopup = (props: TrainedModelPopupPropTypes) => {
    const { modelCollection,
        isOpen,
        onClose,
        accuracy,
        integrity,
        scenario,
        versionSearchValue,
        architectureSearchValue,
        iouThresholdValueForSegmentationAnalytics = IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS,
        confidenceScoreThresholdValue = CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE,
        isSegmentationModelAnalytics,
        collectionMetrics,
        testCollection,
        isFetchingCollectionMetrics
    } = props;

        const history = useHistory();

    const classes = useStyles();
    const [fullScreenEnabled, setFullScreenEnabled] = useState<boolean>(true)
    const [totalGoodDataPoints, setTotalGoodDataPoints] = useState<number>(0)
    const [testCollectionIndex,setTestCollectionIndex]=useState<number>(0)
    const [actualIntegrityData, setActualIntegrityData]=useState<IIntegrity>()
    const [showNotesDialog, setShowNotesDialog] = useState<boolean>(false)
    const[calculatedSystemAccuracy,setCalculatedSystemAccuracy]=useState<number>(-1)
    const [modelCollectionFetchIntegrityAdditionalData, setModelCollectionFetchIntegrityAdditionalData] = useState<IModelCollectionsAdditionalData[]>([])
    const [selectedBandsTabIndex, setSelectedBandsTabIndex] = useState<number>(0)

    const handleNext = () => {
        if (testCollectionIndex < (props?.testModelCollection?.length || 0) - 1) {
          setTestCollectionIndex((prevIndex) => prevIndex + 1);
          setActualIntegrityData(props?.testModelCollection?.[testCollectionIndex + 1]);
        }
      };
    
      const handlePrevious = () => {
        if (testCollectionIndex >= 0) {
          setTestCollectionIndex((prevIndex) => prevIndex - 1);
          setActualIntegrityData(props?.testModelCollection?.[testCollectionIndex - 1]);
        }
      };

      useEffect(() => {
        console.log(modelCollection,)
        if (actualIntegrityData && actualIntegrityData.totalDataSetsCount) {
            const SystemAccuracy = (totalGoodDataPoints / actualIntegrityData.totalDataSetsCount) * 100;
            const roundedAccuracy = SystemAccuracy.toFixed(2);
    
            setCalculatedSystemAccuracy(parseFloat(roundedAccuracy));
        }

    
    }, [totalGoodDataPoints, actualIntegrityData]);
    


    const markNotificationAsSeen = async (id: string, type: string) => {
        try {
            if(type === "modelCollection"){
                await DataService.updateModelCollection({
                    id: id,
                    hasUserSeenIncorrectPrediction: true,
                });
            }
            else if(type === "testCollection"){
                await DataService.updateTestCollection({
                    id: id,
                    hasUserSeenIncorrectPrediction: true,
                });
            }
          
        } catch (error) {
          console.error("Error updating model collection:", error);
        }
      };

    // set fullscreen or minimized according to props
    useEffect(() => {
        if(props.fullscreen || props.fullscreen === false){
            setFullScreenEnabled(props.fullscreen)
        }
    }, [props.fullscreen])
    
    useEffect(() => {
        if (props?.scenario === "isTestModelCollection" && (props?.testModelCollection ?? []).length > 0) {
  
          setActualIntegrityData(props?.testModelCollection?.[testCollectionIndex]);
        } else {
   
          setActualIntegrityData(props?.confidenceScoreBandsIntegrityData);
        }
      }, [props?.scenario, props?.testModelCollection,props?.confidenceScoreBandsIntegrityData]);
      useEffect(() => {     
          if(props.scenario === "autoAppearedTrainedNotification"){
              return;
          } 
        if (modelCollection?.model?.project) {
          const addResourceAndDatasetCollectionFileDownloadURLs = async () => {
            const resourceFileDownloadURL = await CloudStorageService.getResourceBucketFileDownloadURL({
                storageFileName: modelCollection?.resourcesGcsFileName,
                projectId: modelCollection?.model?.project?.toString()
            })
            modelCollection.resourceFileDownloadURL = resourceFileDownloadURL.data
      
            if (modelCollection?.defaultDataSetGcsFileName) {
                const defaultDataSetCollectionFileDownloadURL = await CloudStorageService.getResourceBucketFileDownloadURL({
                    storageFileName: modelCollection?.defaultDataSetGcsFileName,
                    projectId: modelCollection?.model?.project?.toString()
                });
                modelCollection.defaultDatasetCollectionFileDownloadURL = defaultDataSetCollectionFileDownloadURL.data;
            }
          };    
          addResourceAndDatasetCollectionFileDownloadURLs();

      }}, [modelCollection])
      
    useEffect(() => {
        let sum = 0;
        if(actualIntegrityData?.bands?.length){
            actualIntegrityData?.bands?.map(obj => {
                sum += obj.totalGoodDataPointsCount || 0
            })

            setTotalGoodDataPoints(sum)
        }
    }, [actualIntegrityData])

    //save accuracy value in local storage
    useEffect(() => {
        if (props.scenario === 'isTestModelCollection') {
                const accuracyValue = props.testModelIntegrityValues?.[testCollectionIndex]?.accuracyPercentage !== 'NA' ? (
                    actualIntegrityData?.model?.isSegmentationModelAnalytics === true ?
                        calculatedSystemAccuracy === -1 ?
                            0
                            : calculatedSystemAccuracy
                        : props?.testModelIntegrityValues?.[testCollectionIndex]?.accuracyPercentage
                )
                    : 0

                if(props.testModelCollection?.[testCollectionIndex]?._id){
                    saveCollectionAccuracyInLocalStorage(accuracyValue, testCollection?._id || "", props.testModelCollection?.[testCollectionIndex]?._id, iouThresholdValueForSegmentationAnalytics, confidenceScoreThresholdValue)
                }
        }
        else{
            saveCollectionAccuracyInLocalStorage(accuracy, testCollection?._id || "", props.modelCollection?._id || "", iouThresholdValueForSegmentationAnalytics, confidenceScoreThresholdValue)
        }
    }, [props.testModelIntegrityValues, actualIntegrityData, calculatedSystemAccuracy, testCollectionIndex])

    const parsedDate = (dateString: string): string => {
        const parsed = new Date(dateString)
        const options: any = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }
        const formattedDate = parsed.toLocaleDateString('en-US', options)

        return formattedDate
    }
   
    const confidenceScoreClick = (
        band: string,
        integrity: IIntegrity | undefined,
        goodConfidence = true,
      videoHandFilter?:string,
      clickScenario?: "wordErrorRateTranscriptsClick"
      ) => {
   
        if (!integrity) {
            return;
        } 
//         let modelCollectionIdsToCompareQuery;

// if (props?.scenerio === "isTestModelCollection") {
//   modelCollectionIdsToCompareQuery =JSON.stringify( props.testModelCollectionIds); 
// } else {
//     modelCollectionIdsToCompareQuery = integrity._id;
// }

// console.log(props?.testModelCollectionIds,"modelcollectionIds")
// console.log(modelCollectionIdsToCompareQuery,"IdsQuery")

       
        let query = new URLSearchParams(window.location.search);       
        const model = query.get("model") || "";
        const project = query.get("project") || "";
        const modelType = query.get("type") || "";
        const modelCollectionIdsToCompareQuery = integrity._id;
        const testCollectionId = query.get("testCollectionId") || "";
        query = new URLSearchParams();
        if (props?.scenario ==="isTestModelCollection" && props.selectedTestCollectionId) {
            query.set("testCollectionId",props.selectedTestCollectionId)
            }
        query.set("model", model);
        query.set("project", project);
        query.set("limit", "10");
        query.set("offset", "1");
        query.set("type", modelType);
        query.set("status", "dashboard");
        query.set("modelCollectionIdFilterSelected", integrity._id);
        query.set("modelCollectionIdsToCompare", modelCollectionIdsToCompareQuery);
        query.set("scenario", "integrity");
        query.set("accuracy", integrity.accuracy.value?.toString() || "0");
        query.set("sortField", SortField.PREDICTED_STATUS)
        query.set("sortOrder", goodConfidence ? SortOrder.ASC : SortOrder.DESC)

        if(clickScenario === 'wordErrorRateTranscriptsClick' && band !== 'total'){
            const bandLowerLimit = parseInt(band.split("-")[0].replace("%", ""));
            const bandUpperLimit = parseInt(band.split("-")[1].replace("%", ""));

            console.log()

            query.set("wordErrorRateMax", bandUpperLimit?.toString())
            query.set("wordErrorRateMin", bandLowerLimit?.toString())
        }
          
        const bands = band.split("-");
        if (bands[0].includes("99")) {
          band = "99% - 99.99%"
        } else if (bands[0].includes("100")) {
          band = "100%"
        } else {
          band = band.split("-").join(" - ")
        }
        if(videoHandFilter){
            query.set(videoHandFilter, "true");
            query.set("bandFilteredSelected","ALL");
        }
        else if(clickScenario === 'wordErrorRateTranscriptsClick'){
            query.set("bandFilterSelected", "ALL")
        }
        else {
            query.set("bandFilterSelected", band)
        }
        
        query.set(
          "sortByValueFilterSelected",
          goodConfidence ? "CORRECT FIRST" : "INCORRECT FIRST"
        );
      
        let iouThreshold;

        if (props.scenario === "addingNewModelForTrainingPod" && (props.purposeModel?.problemType === "Segmentation" || props.purposeModel?.problemType === "Detection")){
            iouThreshold = props.iouThresholdValueForSegmentationAnalytics || IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
            query.set("iouThreshold", iouThreshold.toString())
        }
        else if (integrity?.model?.isSegmentationModelAnalytics) {
            iouThreshold = (()=>{
            // const modelCollectionAdditionalDataIouThreshold = modelCollectionsAdditionalData.find(modelCollection=>modelCollection?.modelCollectionId===integrity._id)?.iouThreshold;
            const modelCollectionAdditionalDataIouThreshold = !isNullOrUndefined(props.iouThresholdValueForSegmentationAnalytics) ? props.iouThresholdValueForSegmentationAnalytics : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
            return modelCollectionAdditionalDataIouThreshold
          })();
          if (iouThreshold || iouThreshold === 0) {
            query.set("iouThreshold", String(iouThreshold))
          }
        }

        const basePath = window.location.pathname;
        if(scenario === "autoAppearedTrainedNotification"){
            window.open(
                `/admin/projects/${modelCollection?.projectName}/${modelCollection?.modelName}/collections/modelAnalytics/resources?model=${modelCollection?.model?._id}&project=${modelCollection?.model?.project}&limit=10&offset=1&type=${modelCollection?.model?.type}&status=dashboard&modelCollectionIdFilterSelected=${integrity._id}&modelCollectionIdsToCompare=${modelCollection?._id}&scenario=integrity&accuracy=${integrity.accuracy.value?.toString() || "0"}&sortField=${SortField.PREDICTED_STATUS}&sortOrder=${goodConfidence ? SortOrder.ASC : SortOrder.DESC}&bandFilterSelected=${band}&sortByValueFilterSelected=${goodConfidence ? "CORRECT FIRST" : "INCORRECT FIRST"}${(iouThreshold || iouThreshold === 0) ? ("&iouThreshold="+iouThreshold) : ""}`,
                "_blank"
            )
        }
        else if(props.scenario === "isTestModelCollection"){
            window.open(
                `/admin/projects/${modelCollection?.projectName}/${modelCollection?.modelName}/collections/modelAnalytics/resources?testCollectionId=${modelCollection?._id}&model=${modelCollection?.model?._id}&project=${modelCollection?.model?.project}&limit=10&offset=1&type=${modelCollection?.model?.type}&status=dashboard&modelCollectionIdFilterSelected=${integrity._id}&modelCollectionIdsToCompare=${modelCollection?.modelCollections[0]?.modelCollection}&scenario=integrity&accuracy=${integrity.accuracy.value?.toString() || "0"}&sortField=${SortField.PREDICTED_STATUS}&sortOrder=${goodConfidence ? SortOrder.ASC : SortOrder.DESC}&bandFilterSelected=${band}&sortByValueFilterSelected=${goodConfidence ? "CORRECT FIRST" : "INCORRECT FIRST"}${(iouThreshold || iouThreshold === 0) ? ("&iouThreshold="+iouThreshold) : ""}`,
                "_blank"
            )
        }
        else if(props.scenario === "addingNewModelForTrainingPod"){
            query.set("purposeModelId", props.purposeModel?._id || "")
            query.set("problemType", props.purposeModel?.problemType || "")
            window.open(
                basePath.replace("addNewModel/dataMapping/convertingDataFromModelFormatToTestingAnalytics", "collections/modelAnalytics/resources") + `?${query.toString()}`,
                "_blank"
            )
        }
        else{
            window.open(
            basePath.replace("collections/model", "collections/modelAnalytics/resources")+`?${query.toString()}`,
            "_blank"
        )          
        }

        // history.push({
        //   pathname: "../collections/modelAnalytics/resources",
        //   search: query.toString(),
        // });
      };    


    const renderDatasetCollections = () => {
        return (
            <div>
                {
                    modelCollection?.trainingDataDirectory?.map((set: string) => {
                        return <Chip label={set} style={{backgroundColor: '#ececec'}} />
                    })
                }
            </div>
        )
    }

    const updateModelCollectionFetchIntegrityAdditionalData = useCallback(() => {
        const additionalData = []
        if(scenario === 'isTestModelCollection' && testCollection?.modelCollections){
            for(let modelCollection of testCollection.modelCollections){
                additionalData.push({
                    modelCollectionId: modelCollection.modelCollection,
                    iouThreshold: !isNullOrUndefined(iouThresholdValueForSegmentationAnalytics) ? iouThresholdValueForSegmentationAnalytics : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS,
                    confidenceScoreThreshold: !isNullOrUndefined(confidenceScoreThresholdValue) ? confidenceScoreThresholdValue : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE
                })
            }
        }
        else if(modelCollection){
            additionalData.push({
                modelCollectionId: modelCollection._id,
                ...(isSegmentationModelAnalytics ? {iouThreshold: !isNullOrUndefined(iouThresholdValueForSegmentationAnalytics) ? iouThresholdValueForSegmentationAnalytics : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS} : {}),
                confidenceScoreThreshold: !isNullOrUndefined(confidenceScoreThresholdValue) ? confidenceScoreThresholdValue : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE
            })
        }

        //@ts-ignore
        setModelCollectionFetchIntegrityAdditionalData(additionalData)
    }, [])

    useEffect(() => {
        updateModelCollectionFetchIntegrityAdditionalData()
    }, [modelCollection, testCollection, scenario, isSegmentationModelAnalytics])

    const onChangeBandsTab = (event: any, newIndex: number) => {
        setSelectedBandsTabIndex(newIndex)
    }


    const isAudioTextTranscriptionTask = props.modelCollection?.model?.type === "audioTextTranscription"

        return (
        <Dialog fullScreen={props?.scenario === "AiMarketPlacetrainedModelPopup" ?false:fullScreenEnabled} aria-labelledby="simple-dialog-title" open={isOpen} classes={{ paper: classes.dialogPaper }}>
            <div className={classes.dialogRibbon}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '40px', display: 'flex', flexDirection: 'column' }}>
    <Typography style={{ marginBottom: '6px', color: 'secondary' }}>
        Accuracy
        {/* <OpenInNewIcon color='primary' /> */}
    </Typography>

    {props?.scenario === "isTestModelCollection" ? (
    <>
        <Chip
            style={{ display: 'flex', backgroundColor: '#ffc3c3' }}
            label={
                props?.testModelIntegrityValues?.[testCollectionIndex]?.accuracyPercentage !== 'NA' ? (
                  <>
                    <span style={{}}>
                      {/* {actualIntegrityData?.model?.isSegmentationModelAnalytics === true
                        ? calculatedSystemAccuracy === -1
                          ? 'NA'
                          : `${calculatedSystemAccuracy}${calculatedSystemAccuracy>=0 ? "%": ""}`
                        : `${props?.testModelIntegrityValues?.[testCollectionIndex]?.accuracyPercentage ? props.testModelIntegrityValues[testCollectionIndex].accuracyPercentage + '%' : 'NA'}` 
                      } */}
                      {
                        calculatedSystemAccuracy === -1 ? "NA"
                        : calculatedSystemAccuracy + '%'
                      }
                    </span>
                  </>
                ) : "NA"
              }
              
              
            // label={`${props?.testModelIntegrityValues?.[testCollectionIndex]?.accuracyPercentage}%`}
            variant='outlined'
        />
      </>
    ) : (
        <>   
            <Chip
                style={{
                    display: 'flex',
                    backgroundColor: '#ffc3c3',
                }}
                label={accuracy !== -1 ? `${accuracy}%` : "NA"}

                variant='outlined'
            />
        </>      
    )}
</div>              
                    <div style={{ marginRight: '40px' }}>
                        <Typography style={{ display: 'inline-flex', marginBottom: '6px', color: 'secondary' }}>
                            Integrity
                            {
                                props.scenario !== "addingNewModelForTrainingPod"
                                && props?.scenario !== "AiMarketPlacetrainedModelPopup" &&
                            <a
                                href={
                                    props.scenario === "isTestModelCollection" ? (
                                        modelCollection?.model?.isSegmentationModelAnalytics === true ? `/admin/projects/${modelCollection?.projectName}/${modelCollection?.modelName}/collections/modelAnalytics/integrity?model=${modelCollection?.model?._id}&type=${modelCollection?.model.type}&project=${modelCollection?.model?.project}&status=dashboard&modelCollectionIdsToCompare=${modelCollection?.modelCollections[0]?.modelCollection}&testCollectionId=${modelCollection?._id}`
                                            : `/admin/projects/${modelCollection?.projectName}/${modelCollection?.modelName}/collections/modelAnalytics/integrity?model=${modelCollection?.model?._id}&type=${modelCollection?.model.type}&project=${modelCollection?.model?.project}&status=dashboard&modelCollectionIdsToCompare=${modelCollection?.modelCollections[0]?.modelCollection}&testCollectionId=${modelCollection?._id}&modelCollectionsAdditionalData=[{"modelCollectionId":"${modelCollection?.modelCollections[0]?.modelCollection}","iouThreshold":${!isNullOrUndefined(props.iouThresholdValueForSegmentationAnalytics) ? props.iouThresholdValueForSegmentationAnalytics : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS}}]`
                                    )
                                    :
                                        (
                                            modelCollection?.model?.isSegmentationModelAnalytics === true ? `/admin/projects/${modelCollection?.projectName}/${modelCollection?.modelName}/collections/modelAnalytics/integrity?model=${modelCollection?.model?._id}&type=${modelCollection?.model.type}&project=${modelCollection?.model?.project}&status=dashboard&modelCollectionIdsToCompare=${modelCollection?._id}&modelCollectionsAdditionalData=[{"modelCollectionId":"${modelCollection?._id}","iouThreshold":${!isNullOrUndefined(props.iouThresholdValueForSegmentationAnalytics) ? props.iouThresholdValueForSegmentationAnalytics : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS}}]`
                                                : `/admin/projects/${modelCollection?.projectName}/${modelCollection?.modelName}/collections/modelAnalytics/integrity?model=${modelCollection?.model?._id}&type=${modelCollection?.model?.type}&project=${modelCollection?.model?.project}&status=dashboard&modelCollectionIdsToCompare=${modelCollection?._id}`
                                        )
                                }
                                target='_blank'
                                onClick={() => {
                                    if(props.autoTriggered){
                                        if(props.scenario === "autoAppearedTrainedNotification")
                                            markNotificationAsSeen(props.modelCollection?._id || '', "modelCollection")
                                        else if(props.scenario === "isTestModelCollection")
                                            markNotificationAsSeen(props.modelCollection?._id || '', "testCollection")
                                    }
                                }}
                            >
                                {
                                    (integrity !== -1 && integrity !== "N A") && <OpenInNewIcon style={{fontSize: 'medium', marginLeft: '6px'}} color='primary' />
                                }
                            </a>
                            }
                        </Typography>
                        <Tooltip title={isSegmentationModelAnalytics?`Integrity is calculated using iou threshold ${iouThresholdValueForSegmentationAnalytics} and confidence score threshold ${confidenceScoreThresholdValue} defined in Task Configuration`:""}>                   
                        {props?.scenario === "isTestModelCollection" ? (
                        <Chip
                       style={{ display: 'flex', backgroundColor: '#ffc3c3' }}
                        label={props?.testModelIntegrityValues?.[testCollectionIndex]?.integrityPercentage}
                        variant='outlined'
                        />
                        ) : (
                        <Chip
                      style={{ display: 'flex', backgroundColor: '#ffc3c3' }}
                      label={integrity !== -1 ? `${typeof(integrity) === 'number' ? (integrity + '%') : integrity}` : "NA"}
                      variant='outlined'
                      />
                     )}
                     </Tooltip>
                    </div>
                </div>
                {props?.scenario !== "AiMarketPlacetrainedModelPopup" &&<DialogTitle>
                    {
                        props.scenario === "isTestModelCollection" ? `Test Collection ${modelCollection?.version} is now ${modelCollection?.status}`
                            : props.scenario === "addingNewModelForTrainingPod" ? "" :
                                 `Model ${modelCollection?.version} is now ${modelCollection?.status}`
                    }
                </DialogTitle>}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        props.scenario !== "addingNewModelForTrainingPod" && props?.scenario !== "AiMarketPlacetrainedModelPopup"
                        &&
                    <div style={{ marginRight: '40px' }}>
                        <Typography style={{ marginBottom: '6px', color: 'secondary' }}>
                            Status
                        </Typography>
                        <Chip
                            style={{color: '#02BC77', backgroundColor: 'inherit', border: 'solid'}}
                            label={modelCollection?.status === "Live" ? `LIVED SUCCESSFULLY` : `${modelCollection?.status.toUpperCase()} SUCCESSFULLY`}
                        />
                    </div>
                    }
                    {props?.scenario !== "AiMarketPlacetrainedModelPopup" &&
                    <IconButton style={{ marginRight: '12px' }} onClick={() => setFullScreenEnabled(!fullScreenEnabled)}>
                        {
                            fullScreenEnabled ? 
                            <FullscreenExitIcon  /> : <FullscreenIcon />
                        }
                    </IconButton>
                    }
                    <IconButton onClick={() => {
                        onClose()
                        localStorage.removeItem('testCollectionData');
                        }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>        
                <div >

                    {

                        props?.scenario === "isTestModelCollection" && (
                            <div>
                            <p>
          ModelCollection Version : 
          <span style={{ fontWeight: 'bold' }}>
            {props?.testModelCollection?.[testCollectionIndex]?.version}
          </span>
        </p>    
                            </div>





                        )
                    }

                    {
                        isAudioTextTranscriptionTask
                        &&
                        <Tabs value={selectedBandsTabIndex} classes={{root: classes.tabsRoot}} onChange={onChangeBandsTab} >
                            <Tab label='Confidence Bands' classes={{root: classes.tabRoot}} disableFocusRipple disableRipple disableTouchRipple />
                            <Tab label='WER Bands' classes={{root: classes.tabRoot}} disableFocusRipple disableRipple disableTouchRipple />
                        </Tabs>
                    }
                    {
                        selectedBandsTabIndex === 0
                        &&
                    <table
                        className={css.integrityConfidenceBandsTable}
                    >
                        <thead>
                            <tr>
                                <th className={css.confidenceBandTableHeader}>Confidence Bands</th>
                                <th className={css.dataPointsTableHeader}>Test Data #</th>
                                <th className={css.goodConfidenceScoreTableHeader}>Correct %</th>
                                <th className={css.goodConfidenceScoreTableHeader}>Correct #</th>
                                <th className={css.badConfidenceScoreTableHeader}>Incorrect %</th>
                                <th className={css.badConfidenceScoreTableHeader}>Incorrect #</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.isConfidenceScoreBandsIntegrityDataBeingFetched &&
                                <tr>
                                    <td colSpan={4}>
                                        Loading...
                                    </td>
                                </tr>
                                // <div></div>
                            }
                            {
                                !props.isConfidenceScoreBandsIntegrityDataBeingFetched && actualIntegrityData?.bands?.map(confidenceScoreIntegrityBandData=>{
                                    const [minBand,maxBand] = confidenceScoreIntegrityBandData?.band?.split("-");

                                    return (
                                        <tr>
                                            <td className={css.confidenceBandTableCell}>
                                                {minBand}
                                                {
                                                    minBand === "100%" 
                                                    ?   ""
                                                    :   `- ${maxBand}`
                                                }
                                            </td>
                                            <td className={css.dataPointsTableCell}>{confidenceScoreIntegrityBandData.noOfDataPoints}</td>
                                            <td className={css.goodConfidenceScoreTableCell}
                                                onClick={()=>{
                                                    confidenceScoreClick(confidenceScoreIntegrityBandData.band,actualIntegrityData, true )
                                                    if(props.autoTriggered){
                                                        if(props.scenario === "autoAppearedTrainedNotification")
                                                            markNotificationAsSeen(props.modelCollection?._id || '', "modelCollection")
                                                        else if(props.scenario === "isTestModelCollection")
                                                            markNotificationAsSeen(props.modelCollection?._id || '', "testCollection")
                                                    }
                                                }}
                                            >
                                                {
                                                    isNullOrUndefined(confidenceScoreIntegrityBandData.goodConfidenceScore)
                                                    ? "NA"
                                                    :`${(confidenceScoreIntegrityBandData.goodConfidenceScore || 0)?.toFixed(2) || 0}%`
                                                }
                                                
                                            </td>
                                            <td className={css.goodConfidenceScoreTableCell}
                                                onClick={()=>{
                                                    confidenceScoreClick(confidenceScoreIntegrityBandData.band,actualIntegrityData, true )
                                                    if(props.autoTriggered){
                                                        if(props.scenario === "autoAppearedTrainedNotification")
                                                            markNotificationAsSeen(props.modelCollection?._id || '', "modelCollection")
                                                        else if(props.scenario === "isTestModelCollection")
                                                            markNotificationAsSeen(props.modelCollection?._id || '', "testCollection")
                                                    }
                                                }}
                                            >
                                                {
                                                    isNullOrUndefined(confidenceScoreIntegrityBandData.totalGoodDataPointsCount)
                                                    ? "NA"
                                                    :`${(confidenceScoreIntegrityBandData.totalGoodDataPointsCount || 0)}`
                                                }
                                                
                                            </td>
                                            <td className={css.badConfidenceScoreTableCell}
                                                onClick={()=>{                                        
                                                    confidenceScoreClick(confidenceScoreIntegrityBandData.band,actualIntegrityData, false )
                                                    if(props.autoTriggered){
                                                        if(props.scenario === "autoAppearedTrainedNotification")
                                                            markNotificationAsSeen(props.modelCollection?._id || '', "modelCollection")
                                                        else if(props.scenario === "isTestModelCollection")
                                                            markNotificationAsSeen(props.modelCollection?._id || '', "testCollection")
                                                    }
                                                }}
                                            >{
                                                isNullOrUndefined(confidenceScoreIntegrityBandData.goodConfidenceScore)
                                                ? "NA"
                                                : `${(100 - (confidenceScoreIntegrityBandData.goodConfidenceScore || 0))?.toFixed(2) || 0}%`
                                            }
                                            </td>
                                            <td className={css.badConfidenceScoreTableCell}
                                                onClick={()=>{
                                             
                                                    confidenceScoreClick(confidenceScoreIntegrityBandData.band,actualIntegrityData, false )
                                                    if(props.autoTriggered){
                                                        if(props.scenario === "autoAppearedTrainedNotification")
                                                            markNotificationAsSeen(props.modelCollection?._id || '', "modelCollection")
                                                        else if(props.scenario === "isTestModelCollection")
                                                            markNotificationAsSeen(props.modelCollection?._id || '', "testCollection")
                                                    }
                                                }}
                                            >
                                                {
                                                    (isNullOrUndefined(confidenceScoreIntegrityBandData.totalDataPointsCount) || isNullOrUndefined(confidenceScoreIntegrityBandData.totalGoodDataPointsCount))
                                                    && "NA"
                                                }
                                                {
                                                    (!isNullOrUndefined(confidenceScoreIntegrityBandData.totalDataPointsCount) && !isNullOrUndefined(confidenceScoreIntegrityBandData.totalGoodDataPointsCount))
                                                    && (
                                                        // @ts-ignore
                                                        `${(confidenceScoreIntegrityBandData.totalDataPointsCount - confidenceScoreIntegrityBandData?.totalGoodDataPointsCount)}`    
                                                    )
                                                }
                                                
                                            </td>                                            
                                        </tr>
                                    );
                                })
                            }
                            {
                                !props.isConfidenceScoreBandsIntegrityDataBeingFetched &&
                                <tr>
                                    <td className={css.confidenceBandTableCell}>
                                        Total
                                    </td>
                                    <td className={css.dataPointsTableCell}>
                                        {actualIntegrityData?.totalDataSetsCount}
                                    </td>
                                    <td></td>
                                    <td style={{textAlign: 'right', color: '#008D9C'}}>
                                        {totalGoodDataPoints}
                                    </td>
                                    <td></td>
                                    <td style={{textAlign: 'right', color: '#D6100F'}}>
                                        {actualIntegrityData?.totalDataSetsCount && (actualIntegrityData?.totalDataSetsCount - totalGoodDataPoints)}
                                    </td>
                                </tr>
                            }
                            { 
                                !props.isConfidenceScoreBandsIntegrityDataBeingFetched && !actualIntegrityData?.bands &&
                                <tr>
                                    <td colSpan={4}>
                                        No Data
                                    </td>
                                </tr>                        
                            }
                        </tbody>
                    </table>
                    }
                    {
                        selectedBandsTabIndex === 1
                        &&
                        <table className={css.integrityConfidenceBandsTable}>
                            <thead>
                                <tr>
                                    <th className={css.confidenceBandTableHeader}>Word Error Rate</th>
                                    <th className={css.dataPointsTableHeader}>Number of Transcripts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !props.isConfidenceScoreBandsIntegrityDataBeingFetched && actualIntegrityData?.wordErrorRateBands?.map(bandObj => {
                                        return (
                                            <tr key={`wer-band-${bandObj.band}`}>
                                                <td className={css.confidenceBandTableCell}>{bandObj.band}</td>
                                                <td className={css.dataPointsTableCell} onClick={() => confidenceScoreClick(bandObj.band, actualIntegrityData, false, "", 'wordErrorRateTranscriptsClick')} style={{cursor: 'pointer', textDecoration: 'underline'}} >{bandObj.totalDataPointsCount}</td>
                                            </tr>
                                        );
                                    })
                                }
                                    <tr key={`wer-band-total-datapoints`}>
                                        <td className={css.confidenceBandTableCell}>Total</td>
                                        <td className={css.dataPointsTableCell} onClick={() => confidenceScoreClick('total', actualIntegrityData, false, "", 'wordErrorRateTranscriptsClick')} style={{cursor: 'pointer', textDecoration: 'underline'}} >{ actualIntegrityData?.totalDataSetsCount }</td>
                                    </tr>
                            </tbody>
                        </table>
                    }


                    { props?.scenario == "isTestModelCollection" && props?.testModelCollection && props.testModelCollection.length > 1 &&(
                    
                    <div style={{ display: 'flex' ,justifyContent: 'center' }}>
            <Button
            onClick={handlePrevious}
            variant="contained"
            color="primary"
            style={{ marginRight: '10px' }}
            disabled={testCollectionIndex === 0}
          >
            Previous
          </Button>
          <Button
            onClick={handleNext}
            variant="contained"
            color="primary"
            disabled={testCollectionIndex === (props?.testModelCollection?.length || 0) - 1}
          >
            Next
          </Button>
                     </div>
                    )}
                </div>
                
                {
                    props.scenario !== "addingNewModelForTrainingPod"
                    && props?.scenario !== "AiMarketPlacetrainedModelPopup" &&
                <TableContainer style={{ width: '45%', paddingLeft: '20px' }}>

                {props.isHandsTrackingDataFetching && props.modelCollection?.model._id ===  "656f5e0e29b391c0646cc554" ? (
  <div>
    <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>Same Hands Data</p>
    <p>Fetching data...</p>
  </div>
) : (
  (props.hands_tracking_data || [])?.some(dataItem => Object.keys(dataItem || {})?.length > 0) ? (
    <div>
      {/* Render bold text only if there is at least one non-empty dataItem */}
     
      {(props.hands_tracking_data || [])
        .filter(dataItem => dataItem !== null)
        .map((dataItem, index) => (
          <div key={index} style={{ }}>
            {Object.keys(dataItem).length > 0 && dataItem.modelCollectionId === props.modelCollection?._id && (
              <p style={{ margin: '0', width: '100%' }}>

              <p style={{ fontWeight: 'bold',margin:"0" }}>Same Hands Data</p>
                <div className={classes.tableCell_key} style={{ paddingTop: 0, marginTop: 0 }}>
                  Feedback not yet given for videos: <span  onClick={()=>{
                    confidenceScoreClick("ALL",actualIntegrityData, false,"getSameHandFeedbackNotYetGiven" )
                   
                  }} style={{ color:" #008D9C",textDecoration:"underline" , cursor: 'pointer' }}>
                    {dataItem.userYetToMentionWhetherHeFoundSameHandHavingMultipleIdsOrNot
                      ? dataItem.userYetToMentionWhetherHeFoundSameHandHavingMultipleIdsOrNot
                      : 0}
                  </span> <br />
                  Videos having hand with multiple IDs: <span  onClick={()=>{
                    confidenceScoreClick("ALL",actualIntegrityData, false,"getSameHandWithMultipleIds" )
                    
                  }} style={{ color:" #008D9C",textDecoration:"underline" , cursor: 'pointer' }}>
                    {dataItem.noOfTimesUserFoundSameHandHavingMultipleId
                      ? dataItem.noOfTimesUserFoundSameHandHavingMultipleId
                      : 0}
                  </span> <br />
                  Videos having hand with no multiple IDs: <span onClick={()=>{
                     confidenceScoreClick("ALL",actualIntegrityData, false,"getSameHandWithNoMultipleIds" )
                    
                  }} style={{ color:" #008D9C",textDecoration:"underline" , cursor: 'pointer' }}>
                    {dataItem.noOfTimesUserFoundSameHandNotHavingMultipleId
                      ? dataItem.noOfTimesUserFoundSameHandNotHavingMultipleId
                      : 0}
                  </span> <br />
                </div>
              </p>
            )}
          </div>
        ))}
    </div>
  ) : <></>
)}                
                    <Table>                      
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCell_key}>
                                    <Typography >
                                        Project Name
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell_value}>
                                    <Typography>
                                        {modelCollection?.projectName}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell className={classes.tableCell_key}>
                                    <Typography >
                                        Tag
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell_value}>
                                    <Typography>
                                        todo...
                                    </Typography>
                                </TableCell>
                            </TableRow> */}

                        { props?.scenario !== "isTestModelCollection"  &&(
                            <TableRow>
                                <TableCell className={classes.tableCell_key}>
                                    <Typography >
                                        Train Data Set
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell_value}>
                                    <Typography>
                                        {renderDatasetCollections()}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                         { props?.scenario !== "isTestModelCollection"  && (
                            <TableRow>
                                <TableCell className={classes.tableCell_key}>
                                    <Typography >
                                        Hyper Parameter
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell_value}>
                                    <Typography>
                                        {modelCollection?.hyperParameter}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                         )}
                          { props?.scenario !== "isTestModelCollection"  && (
                            <TableRow>
                                <TableCell className={classes.tableCell_key}>
                                    <Typography >
                                        Model Architecture
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell_value}>
                                    <Typography>
                                        {modelCollection?.modelArchitecture}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                          )}
                                    {
                                        isSegmentationModelAnalytics && props.scenario !== "isTestModelCollection" && (
                                            <>
                                                {
                                                    isFetchingCollectionMetrics && !collectionMetrics?.find(obj => obj.modelCollectionId === modelCollection?._id)
                                                    &&
                                                    <>
                                                        {
                                                            new Array(4).fill("").map(obj => {
                                                                return (
                                                                    <TableRow >
                                                                        <TableCell className={classes.tableCell_key} style={{ marginLeft: '1rem', display: 'block' }}>
                                                                            <CustomSkeleton height={42} width={55} />
                                                                        </TableCell>
                                                                        <TableCell className={classes.tableCell_value}>
                                                                            <CustomSkeleton height={42} width={55} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                                {
                                                    collectionMetrics?.find(obj => obj.modelCollectionId === modelCollection?._id)
                                                    &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell className={classes.tableCell_key}>
                                                                <Typography>
                                                                    Average IoU
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell_value} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                                {
                                                                    <Typography>
                                                                        {collectionMetrics?.find(obj => obj.modelCollectionId === modelCollection?._id)?.['Average IoU']}
                                                                    </Typography>
                                                                }
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell className={classes.tableCell_key}>
                                                                <Typography>
                                                                    F1
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell_value} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                                {
                                                                    <Typography>
                                                                        {collectionMetrics?.find(obj => obj.modelCollectionId === modelCollection?._id)?.F1}
                                                                    </Typography>
                                                                }
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell className={classes.tableCell_key}>
                                                                <Typography>
                                                                    Mean Average Precision
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell_value} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                                {
                                                                    <Typography>
                                                                        {collectionMetrics?.find(obj => obj.modelCollectionId === modelCollection?._id)?.["Mean Average Precision"]}
                                                                    </Typography>
                                                                }
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell className={classes.tableCell_key}>
                                                                <Typography>
                                                                    Precision
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell_value} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                                {
                                                                    <Typography>
                                                                        {collectionMetrics?.find(obj => obj.modelCollectionId === modelCollection?._id)?.Precision}
                                                                    </Typography>
                                                                }
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell className={classes.tableCell_key}>
                                                                <Typography>
                                                                    Recall
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.tableCell_value} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                                {
                                                                    <Typography>
                                                                        {collectionMetrics?.find(obj => obj.modelCollectionId === modelCollection?._id)?.Recall}
                                                                    </Typography>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                            </>
                                        )
                                    }
                            <TableRow>
                            
                                <TableCell className={classes.tableCell_key}>
                                    <Typography >
                                        Resource File
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell_value}>
                                <Typography color='primary'>
                                {props.scenario !== "isTestModelCollection" ? (
                                <a href={modelCollection?.resourceFileDownloadURL} style={{ color: Theme.palette.primary.main }} target='_blank'>
                                {modelCollection?.resourcesFileName}&nbsp;&nbsp;<GetAppIcon style={{ fontSize: 'large' }} />
                                 </a>
                                ) : (
                                <a href={modelCollection?.resourcesFileUrl
                                } style={{ color: Theme.palette.primary.main }} target='_blank'>
                               {modelCollection?.resourcesFileName}&nbsp;&nbsp;<GetAppIcon style={{ fontSize: 'large' }} />
                                </a>
                               )}
                              </Typography>

                                </TableCell>
                            </TableRow>
                            {
                                props.scenario !== "isTestModelCollection" &&
                                <>
                                <TableRow>
                                    <TableCell className={classes.tableCell_key}>
                                        <Typography >
                                            Test Dataset
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell_value}>
                                        <Typography>
                                            {
                                                modelCollection?.defaultDataSetCollectionName ?
                                                <a href={modelCollection?.defaultDatasetCollectionFileDownloadURL} style={{ color: Theme.palette.primary.main }} target='_blank'>
                                                    {modelCollection?.defaultDataSetCollectionName}&nbsp;&nbsp;<GetAppIcon style={{fontSize: 'large'}}/>
                                                </a>
                                                :
                                                "NA"
                                            }
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCell_key}>
                                        <Typography >
                                            Description
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell_value}>
                                        <Typography>
                                            {modelCollection?.modelDescription}
                                        </Typography>
                                    </TableCell>
                                </TableRow>                                
                                </>
                            }
                            <TableRow>
                                <TableCell className={classes.tableCell_key}>
                                    <Typography >
                                        Created on
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell_value}>
                                    <Typography>
                                        {parsedDate(modelCollection?.created_at || '')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCell_key}>
                                    <Typography >
                                        Created by
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell_value}>
                                    <Typography>
                                        {modelCollection?.created_by}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCell_key}>
                                    <Typography >
                                        Last Modified
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell_value}>
                                    <Typography>
                                        {parsedDate(modelCollection?.updated_at || '')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {
                                props.scenario === "isTestModelCollection" &&
                                <>
                                <TableRow>
                                    <TableCell className={classes.tableCell_key}>
                                        <Typography >
                                            Data-Set
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell_value}>
                                        <Typography>
                                            <Chip 
                                                // @ts-ignore
                                                label={modelCollection?.dataSetCollection?.name}
                                                style={{
                                                    backgroundColor: "#f3f4f6"
                                                }}
                                            />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCell_key}>
                                        <Typography >
                                            Description
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell_value}>
                                        <Typography>
                                            {/* @ts-ignore */}
                                            {modelCollection?.description}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {
                                    // @ts-ignore
                                    !isSegmentationModelAnalytics && modelCollection?.populatedModelCollections?.map(modelcollection=>{
                                        return (
                                            <>
                                            <TableRow>
                                                <TableCell className={classes.tableCell_key} style={{paddingBottom: 0}}>
                                                    <Typography>Model</Typography>
                                                </TableCell>
                                                <TableCell className={classes.tableCell_value} style={{paddingBottom: 0}}>
                                                    <Typography>{modelcollection?.version}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableCell_key} style={{paddingTop: 0}}>
                                                    <Typography>Model Description</Typography>
                                                </TableCell>
                                                <TableCell className={classes.tableCell_value} style={{paddingTop: 0}}>
                                                    <Typography>{modelcollection?.modelDescription}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            </>
                                        )
                                    })
                                }

                                
                                    {
                                        props.scenario === 'isTestModelCollection' && isSegmentationModelAnalytics
                                        &&
                                        <>
                                            {
                                                // (isFetchingCollectionMetrics || ( collectionMetrics?.length && collectionMetrics.find(obj => obj.testCollectionId === testCollection?._id)))
                                                // &&
                                                // <TableRow>
                                                //     <TableCell className={classes.tableCell_key}>
                                                //         Model Collections:
                                                //     </TableCell>
                                                // </TableRow>
                                            }
                                            {
                                                isFetchingCollectionMetrics
                                                &&
                                                <>
                                                    {
                                                        new Array(4).fill("").map(obj => {
                                                            return (
                                                                <TableRow >
                                                                    <TableCell className={classes.tableCell_key} style={{ marginLeft: '1rem', display: 'block' }}>
                                                                        <CustomSkeleton height={42} width={55} />
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableCell_value}>
                                                                        <CustomSkeleton height={42} width={55} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                            {
                                                !isFetchingCollectionMetrics && collectionMetrics?.map(collection => {
                                                    if (collection.testCollectionId === testCollection?._id) {
                                                        return <>
                                                            <TableRow >
                                                                <TableCell className={classes.tableCell_key} style={{ marginLeft: '1rem', display: 'block' }}>
                                                                    Model:
                                                                </TableCell>
                                                                <TableCell className={classes.tableCell_value}>
                                                                    {testCollection?.populatedModelCollections?.find(obj => obj._id === collection.modelCollectionId)?.version}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow >
                                                                <TableCell className={classes.tableCell_key} style={{ marginLeft: '1rem', display: 'block' }}>
                                                                    Model Description:
                                                                </TableCell>
                                                                <TableCell className={classes.tableCell_value}>
                                                                    {testCollection?.populatedModelCollections?.find(obj => obj._id === collection.modelCollectionId)?.modelDescription}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell className={classes.tableCell_key} style={{ display: 'flex', alignItems: 'start', marginLeft: '1rem' }}>
                                                                    Analysis:
                                                                </TableCell>
                                                                <TableCell className={classes.tableCell_value}>
                                                                    <Accordion elevation={0} classes={{ root: classes.analysisAccordionRoot }}>
                                                                        <AccordionSummary style={{ backgroundColor: 'white', padding: 0 }} expandIcon={<ExpandMoreIcon />}>
                                                                            <Typography>
                                                                                Analysis Metrics
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails style={{ backgroundColor: 'white', padding: 0 }}>
                                                                            <Table style={{marginLeft: '12px'}}>
                                                                                {
                                                                                    Object.entries(collection).map(([key, value]) => {
                                                                                        if (key === 'modelCollectionId' || key === 'testCollectionId' || key === 'Labels') {
                                                                                            return null
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <TableRow key={key}>
                                                                                                    <TableCell className={classes.tableCell_key}>{key}</TableCell>
                                                                                                    <TableCell className={classes.tableCell_value}>{value}</TableCell>
                                                                                                </TableRow>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {
                                                                                    collection && collection.hasOwnProperty('Labels')
                                                                                    &&
                                                                                    <>
                                                                                        <TableRow>
                                                                                            <TableCell className={classes.tableCell_key}>
                                                                                                Labels
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                        {
                                                                                            Object.entries(collection.Labels).map(([key, value]) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <TableRow key={key} style={{ textIndent: 32 }}>
                                                                                                            <TableCell className={classes.tableCell_key}>{key}</TableCell>
                                                                                                        </TableRow>
                                                                                                        {
                                                                                                            Object.entries(value).map(([labelMetricKey, labelMetricValue]) => {
                                                                                                                return (
                                                                                                                    <TableRow style={{ textIndent: 48 }}>
                                                                                                                        <TableCell className={classes.tableCell_key}>{labelMetricKey}</TableCell>
                                                                                                                        <TableCell className={classes.tableCell_value}>{labelMetricValue}</TableCell>
                                                                                                                    </TableRow>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </Table>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    }

                                                })
                                            }
                                        </>
                                    }
                                
                                </>
                            }
                        </TableBody>
                    </Table>                 
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                         <a target='_blank' style={{ color: Theme.palette.primary.main, display: 'block', marginTop: '20px' }}
                        href={props.scenario === "isTestModelCollection"? `/admin/projects/${modelCollection?.projectName}/${modelCollection?.modelName}/collections/modelAnalytics/dashboard?model=${modelCollection?.model?._id}&type=${modelCollection?.model?.type}&project=${modelCollection?.model.project}&status=dashboard&modelCollectionIdsToCompare=${props.testModelCollectionIds}&testCollectionId=${props.selectedTestCollectionId}&versionSearchValue=${versionSearchValue}${isSegmentationModelAnalytics ? '&' + AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForIntegrity + '=' + JSON.stringify(modelCollectionFetchIntegrityAdditionalData) : ''}`
                        : 
                        `/admin/projects/${modelCollection?.projectName}/${modelCollection?.modelName}/collections/modelAnalytics/dashboard?model=${modelCollection?.model?._id}&type=${modelCollection?.model?.type}&project=${modelCollection?.model.project}&status=dashboard&modelCollectionIdsToCompare=${modelCollection?._id}&versionSearchValue=${versionSearchValue}&architectureSearchValue=${architectureSearchValue}&${AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForIntegrity + '=' + JSON.stringify(modelCollectionFetchIntegrityAdditionalData)}`}
                              onClick={() => {
                                if(props.autoTriggered){
                                    if(props.scenario === "autoAppearedTrainedNotification")
                                        markNotificationAsSeen(props.modelCollection?._id || '', "modelCollection")
                                    else if(props.scenario === "isTestModelCollection")
                                        markNotificationAsSeen(props.modelCollection?._id || '', "testCollection")
                                }
                            }}
                    >
                        GO TO MODEL ANALYTICS &nbsp; <OpenInNewIcon style={{fontSize: 'large'}} />
                    </a>
                    {(
                    <div
                     style={{
                    color: Theme.palette.primary.main,
                    marginTop: '20px',
                    cursor: 'pointer'
                    }}
                    onClick={() => {
                    setShowNotesDialog(true);
                    if(props.autoTriggered){
                        if(props.scenario === "autoAppearedTrainedNotification")
                            markNotificationAsSeen(props.modelCollection?._id || '', "modelCollection")
                        else if(props.scenario === "isTestModelCollection")
                            markNotificationAsSeen(props.modelCollection?._id || '', "testCollection")
                    }
                  }}
                    >
                   NOTES TO IMPROVE MODEL &nbsp; <FeatherEditIcon style={{ width: '16px', marginBottom: '4px' }} />
                </div>
                )}
                    </div>               
                </TableContainer>
                }
            </div>
            {props?.scenario !== "AiMarketPlacetrainedModelPopup" &&<NotesDialog
                open={showNotesDialog}
                setShowNotesDialog = {setShowNotesDialog}
                collection={modelCollection}
                collectionType={props.scenario === "isTestModelCollection" ? "testCollection" : "modelCollection" }
            />}
        </Dialog>
        )
    // }    
}

const NotesDialog = (props: any) => {

    const classes = useStyles();

    const [QnA, setQnA] = useState(MODEL_TRAINED_QNA_NOTIFICATION_DEFAULT_QNA)

    useEffect(() => {
        if(props.collectionType === "modelCollection" && props.collection?.trainedModelCollectionPopupQnA?.length){
            setQnA(props.collection?.trainedModelCollectionPopupQnA)
        }
        else if(props.collectionType === "testCollection" && props.collection?.testedCollectionPopupQnA?.length){
            setQnA(props.collection?.testedCollectionPopupQnA)
        }
    }, [props.collection, props.collectionType])
    

    const handleAnswerChange = (index: number, newAnswer: string) => {
        setQnA((prevData: any) => {
            const newData = [...prevData];
            newData[index].answer = newAnswer;
            return newData;
        });
    };

    const isResponseValid = () => {
        // Check if answers for both questions are provided and not whitespaces
        return QnA.every((qa: any) => qa.answer.trim() !== "");
    };

    const onSave = async () => {
        try {
            if(props.collectionType === "modelCollection"){
                await DataService.updateModelCollection({
                    id: props.collection?._id,
                    //@ts-ignore
                    trainedModelCollectionPopupQnA: QnA,
                });
            }
            else if(props.collectionType === "testCollection"){
                await DataService.updateTestCollection({
                    id: props.collection?._id,
                    //@ts-ignore
                    testedCollectionPopupQnA: QnA
                })
            }
            props.setShowNotesDialog(false)
        }
        catch (e) {
            alert(e);
        }
    };


    return (
        <Dialog
            open={props.open}
            onClose={() => props.setShowNotesDialog(false)} 
            classes={{paper: classes.NotesDialogPaper}}
        >
            {
                QnA.map((obj: any, index: number) => (
                    <div key={index}>
                        <DialogTitle
                            id="form-dialog-title"
                            style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                marginBottom: "0px",
                                padding: "0px",
                            }}
                        >
                            <p style={{ fontWeight: "bold" }}>
                                {" "}
                                Q{index + 1}: {obj.question}
                            </p>
                        </DialogTitle>
                        <DialogContent
                            style={{ height: "auto", overflow: "hidden", marginTop: "0px" }}
                        >
                            <TextField
                                margin="dense"
                                id={`answer-${index}`}
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                placeholder="Write your answers"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{
                                    height: "100%",
                                    overflowY: "auto",
                                }}
                                value={obj.answer}
                                onChange={(e) => handleAnswerChange(index, e.target.value)}
                            />
                        </DialogContent>
                    </div>
                ))
            }

            <DialogActions
                style={{
                    margin: 'auto'
                }}
            >
                <Button
                    onClick={() => onSave()}
                    color='primary'
                    variant='contained'
                    disabled={!isResponseValid()}
                >
                    Save notes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TrainedModelPopup