export const deleteButtonIconColor: string = "#EF8A8A";
export const tooltipTextForBacklogIcon: string = "Move it to Backlog tab";
export const tooltipTextForApprovedIcon: string = "Move it to Approved tab";
export const tooltipTextForDeleteIconWhenMovingToDeleteTab: string = "Move it to Deleted tab";
export const tooltipTextForDeleteIconWhenDeletingResource: string = "Delete the resource";
export const listViewOfDataOfModelsIconButton: string = "List View";
export const gridViewOfDataOfModelsIconButton: string = "Grid View";
export const tooltipTextForSetApprovalStatusButton: string = "Move to Approval tab";
export const tooltipTextForSetRejectedStatusButton: string = "Move to Rejected tab";
export const tooltipTextForEditResourceDataSetCollectionButton: string = "Manage resource data-set collections";
export const tooltipTextForAddResourceDataSetCollectionButton: string = "Add selected resources to data-set collections";
export const ModelResourcePredictedText="predicted";
export const copyDataSetToAnotherModelButtonTooltipText="Copy the collection to another task";
export const aiTestForDatasetCollection = "AI-Test for Dataset Collection";
export const projectsListDefaultLimit = 1000;
export const tasksListDefaultLimit = 1000;
export const shuffleDataSetToAnotherModelButtonTooltipText="Shuffle the collection";
export const splitDataSetToAnotherModelButtonTooltipText="Split the collection into train and test";
