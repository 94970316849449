import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TableContainer, makeStyles, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Grid, Tooltip, Box, Button, Chip, TablePagination, Popover, TableSortLabel, IconButton, Typography, TextField, MenuItem, Slider, Checkbox, CircularProgress,  } from '@material-ui/core';
import {
    tableContainerStyle,
    tablePaperStyle,
} from "../../../constants/tableStyles";
import { deleteButtonIconColor, tooltipTextForEditResourceDataSetCollectionButton } from '../../data/constants/constants';
import { AnalyticsResourceView, CustomSkeleton, IAnalyticsModelData, IAnalyticsTableFilterObject, ILabelConfidenceScoreObject, IModelAnalyticsTestStatisticalData, IModelType, IResourceAnalyticsCollection, IResourceLabels, ResourceImageAnnotation } from '../../../../../../../../../../../common';
import Audio from '../../../../../../../../../components/ModelData/Audio';
import LabelEditText from '../../data/components/LabelEditText';
import { NoTableData } from '../../data/constants';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
// import { TimeDateFormat } from '../../data/components/TimeDateFormat';
import { useHistory, useLocation } from 'react-router';
import AnalyticsDataViewDialog from './AnalyticsDataViewDialog';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Fade from '@material-ui/core/Fade';
import AddResourceToDataSetCollectionButton from '../../data/components/AddResourceToDataSetCollectionButton';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListIcon from '@material-ui/icons/FilterList';
// import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { InputFieldState } from '../../../../../../../../../../../common/constants/interfaces/inputFieldState';
import { TableColumnResetFilterButton } from '../../data/components/TableColumnResetFilterButton';
// import { useURLQuery } from '../../../../../../../../../../../hooks/useURLQuery';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { OneMB } from '../../data/components/TableContent';
import { AnalyticsResourceReferenceGuideDataType, OtherImageAnnotationPrediction, OtherModelDutchTranslationPrediction } from './ModelAnalyticsResources';
import ImageAnnotationSegmentationDialog from './ModelAnalyticsResourseComponent';

import { ModelService } from '../../../../../../../../../../../services';
import { getFileTypeFromFileName } from '../../../../../../../../../../../common/utilities';
import { IPurposeModel } from '../../../../../../../../../../../common/constants/interfaces/purposeModels';
import PurposeModelService from '../../../../../../../../../../../services/PurposeModelService';

// import { convertStringtoContainsExpressionInRegex, convertStringtoEndsWithExpressionInRegex, convertStringtoEqualsExpressionInRegex, convertStringtoStartsWithExpressionInRegex } from '../../../../../../../../../../../services/regexHelperService';
// import { isNullOrUndefined } from '../../../../../../../../../../../services/variableHelperService';

const tableCellCheckboxWidth = '25px';
const tableCellResourceContainerWidth = '50px';
const tableCellActionsWidth = '100px';
const tableCellActionButtonsWidth = '80px';
const tableCellVersionWidth = '120px';
const tableCellStatusWidth = '200px';
const tableCellPredictedStatusWidth = '100px';
const tableCellConfidenceScoreWidth = '100px';
const tableCellViewWidth = "120px";
const urlSearchParamsStrings = {
    PURPOSE_MODEL_ID: "purposeModelId"
}

export type ResourceLabelFilterOperator = 'contains' | 'equals' | 'starts with' | 'ends with';
export const SearchOperatorAvailableValues = [
    'contains', 'equals', 'starts with', 'ends with'
];

export enum SortField {
    LABEL = 'label',
    PREDICTED_STATUS = 'predicted_status',
    CONFIDENCE_SCORE = 'confidence_score',
    WORD_ERROR_RATE = 'wordErrorRate',
    LAST_MODIFIED = 'lastModified',
    IOU_SCORE="iou_score",
}

export enum SortOrder {
    ASC = 'ascending',
    DESC = 'descending'
}
/*
interface ILabelFilter {
    labelFilterOperator: ResourceLabelFilterOperator;
    labelFilterValue: string[];
}

interface IConfidenceScoreFilter {
    min: number;
    max: number;
}
*/
const useStyles = makeStyles((theme) => ({
    paper: tablePaperStyle(theme),
    table: {
        // minWidth: 750,
        tableLayout: 'fixed',
        "& .MuiTableCell-sizeSmall": {
            padding: "6px 0px 6px 6px"
          }
    },
    tableRow: {
        width: "100%",
        "& th": {
            color: 'rgb(152, 166, 179)',
            font: "14px/22px Nunito Sans"
        }
    },
    tableCellCheckbox: {
        width: `${tableCellCheckboxWidth} !important`
    },
    tableCellVersion: {
        width: tableCellVersionWidth,
    },
    centerTextAlign: {
        textAlign: 'center'
    },
    leftTextAlign: {
        textAlign: 'left'
    },
    tableCellStatus: {
        width: tableCellStatusWidth,
        textAlign: "left",
    },
    tableCellPredictedStatus: {
        width: tableCellPredictedStatusWidth,
        textAlign: "center",
        
        
    },
    tableCellLabels: {
        width: "auto",
        // width: `calc(38.3333333333% - (${tableCellTotalFixedWidth}))`
        textAlign: "left",
    },
    labelColumn: {
        width: '180px !important'
    },
    tableCellTraningDataDirectory: {
        width: "auto",
        maxWidth: "150px",
        // width: `calc(38.3333333333% - (${tableCellTotalFixedWidth}))`
        textAlign: "left",
        minWidth: "250px"
    },
    tableCellDescription: {
        width: "auto",
        textAlign: "left",
        // width: `calc(23.3333333333% - (${tableCellTotalFixedWidth}))`
    },
    tableCellActions: {
        width: tableCellActionsWidth,
        textAlign: "left",
    },
    tableCellActionsButtons: {
        width: tableCellActionButtonsWidth,
        textAlign: "left",
    },
    tableCellView: {
        width: tableCellViewWidth,
        textAlign: "center",
    },
    chip: {
        minWidth: "100px",
        marginRight: theme.spacing(1),
        background: '#F4F5F7'
    },
    deleteButton: {
        color: deleteButtonIconColor,
    },
    tableContainer: tableContainerStyle,
    root: {
        marginBottom: theme.spacing(2),
        position: "sticky",
        top: "0px",
        backgroundColor: "#f0f5fc",
        zIndex: 1,
    },
    indicator: {
        backgroundColor: "transparent",
    },
    delete: {
        color: deleteButtonIconColor,
    },
    progress: {
        padding: "10px",
    },
    tableCellAccuracy: {
        width: "150px",
        textAlign: "center",
    },
    accuracyChip: {
        backgroundColor: theme.palette.grey[50],
    },
    rowSelected: {
        backgroundColor: "#CCE8EB !important",
        "&:hover": {
            backgroundColor: "#CCE8EB !important",
            opacity: 1
        }
    },
    tableCellName: {
        width: "212px",
    },
    lastModifiedTime: {
        color: '#008D9C',
        fontSize: '16px',
    },
    lastModifiedDate: {
        color: '#212121',
        fontSize: '16px'
    },
    tableCellConfidenceScore: {
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: 'center',
        width: tableCellConfidenceScoreWidth
    },
    tableCellLabel: {
        // width: "180px"
        width: "200px",
        paddingRight: 0,
        paddingLeft: 0,
    },
    tableCellLabelForForcastingModel: {
        // width: "180px"
        maxWidth: "275px",
        minWidth: "250px !important",
        width: "auto",
        paddingRight: 0,
        paddingLeft: 0,
    },
    tableCellResourceContainer: {
        width: tableCellResourceContainerWidth
    },
    tableCellResourceContainerWhenModelTypeDLP: {
        width: "100px"
    },
    tableCellResourceContainerWhenModelTypeBookTranslation: {
        width: "86px"
    },
    image: {
        borderWidth: '2px',
        borderColor: '#fff',
        borderStyle: 'solid',
        boxShadow: theme.shadows[1],
    },
    incorrect: {
        background: '#EF8A8A'
    },
    correct: {
        background: '#F4F5F7'
    },
    grid: {
        midWidth: '220px',
    },
    sortLabelIcon: {
        display: 'none'
    },
    sortLabelRoot: {
        justifyContent: 'flex-end',
        "&:hover .MuiTableSortLabel-icon": {
            display: 'flex'
        }
    },
    sortLabelActive: {
        minWidth: '111px',
        color: '#98a6b3 !important',
        "& .MuiTableSortLabel-icon": {
            display: 'flex'
        }
    },
    columnOptionsIcon: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    tableColumnMenuHeading: {
        display: 'flex', justifyContent: 'space-between'
    },
    floating: {
        "& legend": {
            float: "unset"
        }
    },
    autoCompleteChipRoot: {
        maxWidth: "170px",
    },
    // tableColumnMenuHeading: {
    //     display: 'flex', justifyContent: 'space-between'
    // },
    selectAllDataText: {
        cursor: 'pointer',
        color: theme.palette.primary.main
    },
    clearDataSelectionText: {
        cursor: 'pointer',
        color: theme.palette.primary.main
    },
    nameColumnGrid:{
        width:'calc(100% + 70px)'
    },   
}));


// interface IAnalyticHeader {
//     key: string;
//     value: string;
// }

const showName = (name: string) =>{
    if (name.length > 15) {
        return "..." + name.substring(name.length - 10, name.length);
    }
    return name;
}

function VideoSection(props: {modelType: IModelType, videoUrl: string,  dataView:"grid-view" | "list-view", index: number, onVideoThumbnailClick: ()=>void, parentScrollContainerElementId: string}) {
    const index = props.index;
    const viewType = props.dataView;
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const [preventLoadImage, setPreventLoadImage] = useState<boolean>(true);
    const [isImageInViewport, setIsImageInViewport] = useState(props.parentScrollContainerElementId ? false : true);
    const imageRef = useRef<any>();

    useEffect(() => {
        if (!props.parentScrollContainerElementId) {
            return;
        }
        const observer = new IntersectionObserver(([entry])=>{
          if (entry.isIntersecting) {
            setIsImageInViewport(true)
              observer.disconnect();
          }
        }, {
            root: document.getElementById(props.parentScrollContainerElementId),
            rootMargin: viewType === "grid-view" ? "1000px" : "500px"            
        })
        if (imageRef.current) {
            observer.observe(imageRef.current)
        }
      
        return () => {
            if (observer?.disconnect) {
                observer.disconnect();
            }
        }
      }, [viewType, props.parentScrollContainerElementId])

      useEffect(()=>{
        if(isImageInViewport && preventLoadImage){
            setPreventLoadImage(false)
        }
    },[isImageInViewport, preventLoadImage])

    return (
        <div ref={imageRef}>
        {
            viewType === "grid-view"
            ?
            <video muted controls id={`video${index}`} style={{width: '220px',height: '200px', cursor: 'pointer'}} src={preventLoadImage ? "" : props.videoUrl} 
            onClick={()=>{
                props.onVideoThumbnailClick();
                // setPopoverOpen(true)
            }}
            />
            :
            !preventLoadImage ?
            <video id={`video${index}`} style={{width: '51px', cursor: 'pointer', borderRadius: "5px"}} src={props.videoUrl} 
            onClick={()=>{
                props.onVideoThumbnailClick();
                if (props.modelType === 'video') {
                    setPopoverOpen(true)
                }
            }}
            />
            :
            <Skeleton 
                variant="rect"
                style={{
                    margin: 'auto',
                }}
                width="30px"
                height="30px"
            />
        }
        
        <Popover
            open={popoverOpen}
            anchorEl={document.getElementById(`tableCellName${index}`)}
            onClose={()=>{setPopoverOpen(false)}}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
        >
            <video muted autoPlay style={{width: '100%', height: '256px'}} src={preventLoadImage ? "" : props.videoUrl} controls />
        </Popover>           
        </div>
    )
}
interface IAnalyticsTableProps {


    tableData?: Array<IResourceAnalyticsCollection>,
    setTableData: React.Dispatch<React.SetStateAction<IResourceAnalyticsCollection[]>>,
    modelInformation?: IAnalyticsModelData,
    uniquePredictedValues: string[];
    classFilterOptions: Array<string>,
    modelCollectionsToCompareWith: Array<IModelAnalyticsTestStatisticalData>;
    selectedModelCollectionId: string,
    bandFilterValue: string,
    isDefaultConfidenceScoreFilterValue: boolean,
    
    
    classFilterValue: string,
    isDefaultLabelFilter: boolean,
    tableFilters: IAnalyticsTableFilterObject;
    isSegmentationModelAnalyticsEnabled: boolean;
    isSpectrogramEnabled: boolean;
    isFetchingOtherModelTranslatedlanguageTextpredictions: boolean;
    otherModelTranslatedLanguageTextPredictions: OtherModelDutchTranslationPrediction[] ;
    isFetchingOtherImageAnnotationPredictions?: boolean;
    OtherImageAnnotationPredictions?: OtherImageAnnotationPrediction[];
    actions: {
        [name: string]: Function,
        tablePageChangeHandler: Function | any,
        tableRowsPerPageChangeHandler: Function,
        handleTableFiltersChange: Function
    },
    pagination: {
        currentPage: number,
        rowsPerPage: number;
        totalCount: number;
    }
    isDataFetching: boolean
    currentAnalyticsResourcesViewType: AnalyticsResourceView;
    modelCollectionIdToCompareConfidenceScoreWith?: string;
    isFetchingConfidenceScoresForModelCollectionResources: boolean;
    resourceIdsSelectedForAddingToDataSetCollection: string[];
    setResourceIdsSelectedForAddingToDataSetCollection: React.Dispatch<React.SetStateAction<string[]>>
    isAddResourcesToDataSetCollectionPopupShowing: boolean;
    setIsAddResourcesToDataSetCollectionPopupShowing: React.Dispatch<React.SetStateAction<boolean>>;
    tableResourceIdsSelected: string[];
    resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB: string[];
    setTableResourceIdsSelected: React.Dispatch<React.SetStateAction<string[]>>;
    areMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser: boolean;
    setAreMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser: React.Dispatch<React.SetStateAction<boolean>>;
    modelReferenceGuides: AnalyticsResourceReferenceGuideDataType[];
    shouldModalOpenedAfterFetchingData:boolean,
    setShouldModalOpenedAfterFetchingData:(newState:boolean)=>void
    purposeModelProblemType?: IPurposeModel["problemType"]
}

function ImageThumbnail(props: {
    classes?: { image: string | undefined; },
    resource: IResourceAnalyticsCollection,
    dataView: String,
    index: number,
    isSpectrogramAudioSenerio?: boolean
    onThumbnailClick: () => void
}) {
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const viewType = props.dataView;
    const defaultCanvasWidth = 220
    const defaultCanvasHeight = 220
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [canvasWidth, setCanvasWidth] = useState(defaultCanvasWidth);
    const [canvasHeight, setCanvasHeight] = useState(defaultCanvasHeight);
    const [shouldRenderOriginalImage, setShouldRenderOriginalImage] = useState<boolean>(false);

    useEffect(() => {
        let base_image = new Image();
        base_image.src = props.isSpectrogramAudioSenerio? (props.resource.spectrogram||"") : props.resource.resourceUrl;
    
        let file;
            fetch(props.isSpectrogramAudioSenerio? (props.resource.spectrogram||"") : props.resource.resourceUrl)
                .then(res => res.blob())
                .then(blob => {
                    file = new File([blob], 'dot.png', blob)
                   
                    const blobURL = URL.createObjectURL(file);
                  
                    const img = new Image();
                    img.src = blobURL;
                    img.onload = function () {
                        // URL.revokeObjectURL(this.src);
                        // const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                        let imageWidth = img.width;
                        let imageHeight = img.height;
                        let differenceBetweenCanvasAndImageToSet: number = 0;
                        // let canvasHeightToSet: number = canvasHeight;
                        let canvasHeightToSet: number = defaultCanvasHeight;
                        // let canvasWidthToSet: number = canvasWidth;
                        let canvasWidthToSet: number = defaultCanvasWidth;
                        let blobSizeInMB = blob.size/(1024*1024)
                
                        if (imageWidth >= imageHeight)
                            {
                                differenceBetweenCanvasAndImageToSet = imageWidth / defaultCanvasWidth;
                                canvasHeightToSet = imageHeight / differenceBetweenCanvasAndImageToSet ;
                                canvasWidthToSet = defaultCanvasWidth;
                            } 
                        else if (imageHeight >= imageWidth) {
                            differenceBetweenCanvasAndImageToSet = imageHeight / defaultCanvasHeight;
                            canvasWidthToSet = imageWidth / differenceBetweenCanvasAndImageToSet;
                            canvasHeightToSet = defaultCanvasHeight;
                        } 
                
                        if(canvasHeightToSet){
                            setCanvasHeight(canvasHeightToSet);
                        }
                        if(canvasWidthToSet){
                            setCanvasWidth(canvasWidthToSet);
                        }
    
                        if(blobSizeInMB > OneMB){
                            setShouldRenderOriginalImage(false);
                            let canvas = document.getElementById(`canvasImage${props.isSpectrogramAudioSenerio? (props.resource.spectrogram||"") : props.resource.resourceUrl}`) as HTMLCanvasElement;
                            let context;
                            if(canvas){
                                context = canvas.getContext("2d");
                            }
                            // canvas.width = 'grid-view' ? canvasWidthToSet : 40;
                            // canvas.height = 'grid-view' ? canvasHeightToSet : 40;
                            // const ctx = canvas.getContext("2d");
                            context &&
                            (viewType === 'grid-view' ? context.drawImage(img, 0 , 0, canvasWidthToSet, canvasHeightToSet)
                            : context.drawImage(img, 0 , 0, 37, 37))
                            canvas &&
                            canvas.toBlob(
                                (blob) => {
                                    // Handle the compressed image. es. upload or save in local state
                                    // displayInfo('Original file', file);
                                    // displayInfo('Compressed file', blob);
                                },
                                "image/jpeg",
                                0.7
                            );
                        }else{
                            setShouldRenderOriginalImage(true);
                        }
                        // document.getElementById("root").append(canvas);
                    };
                })      
    
    }, [props.resource.resourceUrl, viewType, props.resource.spectrogram])
    


    // base_image.onload = function () {
    //     let imageWidth = base_image.width;
    //     let imageHeight = base_image.height;
    //     let differenceBetweenCanvasAndImageToSet: number = 0;
    //     let canvasHeightToSet: number = canvasHeight;
    //     let canvasWidthToSet: number = canvasWidth;

    //     if (imageWidth > imageHeight) {
    //         differenceBetweenCanvasAndImageToSet = imageWidth / defaultCanvasWidth;
    //         canvasHeightToSet = imageHeight / differenceBetweenCanvasAndImageToSet;
    //         canvasWidthToSet = defaultCanvasWidth;
    //     }
    //     else if (imageHeight > imageWidth) {
    //         differenceBetweenCanvasAndImageToSet = imageHeight / defaultCanvasHeight;
    //         canvasWidthToSet = imageWidth / differenceBetweenCanvasAndImageToSet;
    //         canvasHeightToSet = defaultCanvasHeight;
    //     }

    //     if (canvasHeightToSet) {
    //         setCanvasHeight(canvasHeightToSet);
    //     }
    //     if (canvasWidthToSet) {
    //         setCanvasWidth(canvasWidthToSet);
    //     }
    // }

    return (
        <>
            {
                viewType === 'grid-view' ?
                <>
                    <div style={{
                        width: `${defaultCanvasWidth}px`,
                        height: `${defaultCanvasHeight}px`, display: 'flex', alignItems: 'center'
                    }}>
                        <canvas
                            id={`canvasImage${props.isSpectrogramAudioSenerio? (props.resource.spectrogram||"") : props.resource.resourceUrl}`}
                            ref={canvasRef} 
                            className={'abc-image'}
                            width= {canvasWidth} 
                            height= {canvasHeight}
                            
                            style ={{
                                backgroundImage: `url(${shouldRenderOriginalImage ? props.isSpectrogramAudioSenerio? (props.resource.spectrogram||"") : props.resource.resourceUrl : ""})`,
                                // width: `${canvasWidth}px`, 
                                // height: `${canvasHeight}px`,
                                backgroundSize : `${canvasWidth}px ${canvasHeight}px`
                            }}
                            
                        ></canvas>
                    </div>
                </> : 
                // <>
                //     <div style={{
                //         width: `${defaultCanvasWidth}px`,
                //         height: `${defaultCanvasHeight}px`, display: 'flex', alignItems: 'center'
                //     }}>
                //         <canvas
                //             ref={canvasRef}
                //             className={props.classes.image}

                //             style={{
                //                 backgroundImage: `url(${props.resource.resource})`,
                //                 width: `${canvasWidth}px`,
                //                 height: `${canvasHeight}px`,
                //                 backgroundSize: `${canvasWidth}px ${canvasHeight}px`
                //             }}

                //         ></canvas>
                //     </div>
                // </>
                // :
                <>
                {console.log("props.resource", props.resource)}
                {shouldRenderOriginalImage ? 
                    <Avatar variant="rounded" className={props.classes?.image} style={{ cursor: 'pointer' }} src={props.isSpectrogramAudioSenerio? (props.resource.spectrogram||"") : props.resource.resourceUrl}
                        onClick={() => {
                            setPopoverOpen(true);
                        }}
                    /> :
                    <canvas 
                        id={`canvasImage${props.isSpectrogramAudioSenerio? (props.resource.spectrogram||"") : (props.resource.resource || props.resource.resourceUrl)}`}
                        ref={canvasRef} 
                        className={'abc-image'}
                        width= {37} 
                        height= {37}
                        style={{cursor: 'pointer', borderRadius: '5px'}}
                        onClick={()=>{
                            props.onThumbnailClick();
                            setPopoverOpen(true);
                        }}
                    >
                    </canvas>}
                    <Popover
                        open={popoverOpen}
                        anchorEl={document.getElementById(`tableCellName${props.index}`)}
                            onClose={() => {
                                setPopoverOpen(false)
                            }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <img alt="image" className={props.classes?.image} style={{ width: '100%', height: '256px' }} src={props.isSpectrogramAudioSenerio? (props.resource.spectrogram||"") : props.resource.resourceUrl} />
                    </Popover>
                </>
            }
        </>
    );
}


function AnalyticsTable(props: IAnalyticsTableProps) {

    const [currentIndex,setCurrentIndex]=useState(-1)
    const classes = useStyles(props);
    let { tableData, isDataFetching, actions, pagination, currentAnalyticsResourcesViewType, tableFilters, bandFilterValue, classFilterValue, uniquePredictedValues } = props;
    const history = useHistory();
    const location = useLocation();
    // const urlSearchParams  = useURLQuery();

    const urlSearchParams = new URLSearchParams(window.location.search);

    const isPurposeModelProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics: boolean = (()=>{
        return PurposeModelService.isProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics(props.purposeModelProblemType);
    })();

   const IouMax= urlSearchParams.get("iouScoreMax");
   const IouMin= urlSearchParams.get("iouScoreMin");
    const iouFilterApplied=(IouMax==="1" && IouMin==="0")?false:true;
    const [viewResourceData, setViewResourceData] = useState(false);
    const [selectedModelResource, setSelectedModelResource] = useState<IResourceAnalyticsCollection | null>(null);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const [showLeftArrow, setShowLeftArrow] = useState(true);
    const [fetchedLabelConfidenceScores, setFetchedLabelConfidenceScores] = useState<ILabelConfidenceScoreObject>({});
    const [resourceLabels, setResourcesLabels] = useState < Array<IResourceLabels>>([]);
    // const [isDefaultIouScoreFilterValue, setIsDefaultIouScoreFilterValue] = useState(true);
    const [filterMenuOpen, setFilterMenuOpen] = useState('');

    const isSegmentationModelAnalyticsEnabled = props.isSegmentationModelAnalyticsEnabled;
   
    const areAllRowsWhichIsShowingInUISelectedByUser = useMemo(()=>{
        try {
            if (
                props.tableResourceIdsSelected?.length === props.tableData?.length &&
                props.tableData?.length>0
            ) {
                return true;
            }
        } catch (error) {
            console.error(error)
        }
        return false;
    },[props.tableData?.length, props.tableResourceIdsSelected?.length])
    useEffect(()=>{
        if(!isDataFetching && props.shouldModalOpenedAfterFetchingData){
            if(props.tableData && props.tableData.length > 0)
                onViewButtonClick(props.tableData[0],0);
        }
    },[isDataFetching,props.shouldModalOpenedAfterFetchingData,props.tableData?.length])
    useEffect(() => {
        if (viewResourceData) {
            if (tableData && tableData.length > 0 && selectedModelResource) {
                const selectedResourceIndex = tableData?.indexOf(selectedModelResource);
                if (selectedResourceIndex === 0) {
                    setShowLeftArrow(false);
                } else if (selectedResourceIndex === tableData.length - 1) {
                    setShowRightArrow(false)
                } else {
                    setShowLeftArrow(true);
                    setShowRightArrow(true)
                }
            }
        }
    }, [viewResourceData, tableData, selectedModelResource]);

    useEffect(() => {
        const selectedResource = resourceLabels.find(resourceLabel => resourceLabel.resourceId === selectedModelResource?._id);
        if (selectedResource) {
            setFetchedLabelConfidenceScores({...selectedResource.labels})
        } else {
            setFetchedLabelConfidenceScores
            ({})
        }
    }, [selectedModelResource])

    useEffect(() => {
        if (viewResourceData) {
            const selectedResource = resourceLabels.find(resourceLabel => resourceLabel.resourceId === selectedModelResource?._id);
            if (selectedResource) {
                const updatedResourceLabels = resourceLabels.map(resourceLabel => {
                    if (resourceLabel.resourceId === selectedResource.resourceId) {
                        return {...resourceLabel, labels: fetchedLabelConfidenceScores}
                    }
                    return resourceLabel
                })
                setResourcesLabels([...updatedResourceLabels]);
            } else {
                const updatedResourceLabels = [...resourceLabels, { resourceId: selectedModelResource?._id || '', labels: {} }]
                setFetchedLabelConfidenceScores({})
                setResourcesLabels([...updatedResourceLabels])
            }
        }
    }, [viewResourceData, fetchedLabelConfidenceScores])


    const handleDataView = () => {
        if(props.modelCollectionIdToCompareConfidenceScoreWith){
            const urlQuery = new URLSearchParams(location?.search);
            urlQuery.set("resourceToCompareWith", props.modelCollectionIdToCompareConfidenceScoreWith);
            location.search = urlQuery.toString();
            history.push(`${location?.pathname}?${urlQuery.toString()}`);
        }
        setViewResourceData(true)
    }

    const onSwitchResourceInDialog = (i: number) => {
        if (selectedModelResource && tableData?.length) {
            const selectedResourceIndex = tableData?.indexOf(selectedModelResource);
            if (selectedResourceIndex + i === 0) {
                setShowLeftArrow(false);
                setShowRightArrow(true);
            } else if (selectedResourceIndex + i === tableData.length - 1) {
                setShowRightArrow(false)
                setShowLeftArrow(true)
            } else {
                setShowLeftArrow(true);
                setShowRightArrow(true)
            }
            if (i > 0) {
                setSelectedModelResource(tableData[selectedResourceIndex + 1])
            } else if (i < 0) {
                setSelectedModelResource(tableData[selectedResourceIndex - 1])
            }
        }
    }


    const handleBack = () => {

        console.log("console")
        setCurrentIndex((prevIndex: number) => {
          const newIndex = prevIndex - 1;
          console.log("currentIndex", newIndex);
          return newIndex
        });
      };
      
      const handleFront = () => {
        
        setCurrentIndex((prevIndex: number) => {
          const newIndex = prevIndex + 1;
          return newIndex;
        });
      };
      
    const onViewButtonClick = useCallback((row,index) => {
        setCurrentIndex(index)
        setSelectedModelResource(row);
        handleDataView()
    }, [handleDataView]);

    const onAddResourceToDataSetCollectionButtonClick = useCallback((row_id) => {
        props.setResourceIdsSelectedForAddingToDataSetCollection(
            [row_id]
        )
        props.setIsAddResourcesToDataSetCollectionPopupShowing(true)
    },[props])

    const onCheckBoxSelectForOneResource = useCallback((row_id) =>{
        props.setTableResourceIdsSelected(oldResourceIds=>{
            let isResourceIdAlreadyPresent: boolean = oldResourceIds.indexOf(row_id) !== -1 ? true : false;

            if (!isResourceIdAlreadyPresent) {
                oldResourceIds.push(row_id)
            } else {
                const resourceIdIndex = oldResourceIds.indexOf(row_id);
                oldResourceIds.splice(resourceIdIndex, 1);
            }
            return [...oldResourceIds]
        })
    },[props])

    const mapStatus = (value?: string) => {
        if (value === 'onlyCorrect') {
            return 'CORRECT'
        } else if (value === 'onlyIncorrect') {
            return 'INCORRECT'
        } else {
            return ''
        }
    }

    const isBookTranslationModelType = props?.modelInformation?.modelType === "bookTranslation" ? true : false;

    const tableRowCellColSpan = (()=>{
        if (props.modelInformation?.modelType === "bookTranslation") {
            return 6
        }
        if(props.isSegmentationModelAnalyticsEnabled || (isPurposeModelProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics)) return 8;
        return 8
    })();

    return (
        <>
            <TableContainer className={classes.tableContainer}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                    id='analyticsTable'
                    stickyHeader
                >
                    <TableHead>
                        <TableRow classes={{ root: classes.tableRow }}>
                            <TableCell
                                classes={{root: classes.tableCellCheckbox}}
                            >
                                <Checkbox 
                                    indeterminate={props.tableResourceIdsSelected?.length > 0 && props.tableResourceIdsSelected.length < (props.tableData || [])?.length}
                                    checked={
                                        (props.tableData || [])?.length>0 &&

                                        /**
                                         * tableResourceIdsSelected.length can be greater than tableData.length when all resources
                                         * are selected which are not limited by pagination
                                         */
                                        props.tableResourceIdsSelected.length >= (props.tableData || [])?.length                                                     

                                    }
                                    onChange={(event)=>{
                                        if (event.target.checked) {
                                            props.setTableResourceIdsSelected([...(tableData || [])?.map((resource)=>resource._id)])
                                            return;
                                        }

                                        props.setTableResourceIdsSelected([]);
                                    }}
                                />
                            </TableCell>
                            <TableCell classes={{ 
                                root: `
                                    ${classes.tableCellResourceContainer}
                                    ${props.modelInformation?.modelType === "textAnnotation" || props.modelInformation?.modelType === "DLP" ? classes.tableCellResourceContainerWhenModelTypeDLP : ""}
                                    ${props.modelInformation?.modelType === "bookTranslation" ? classes.tableCellResourceContainerWhenModelTypeBookTranslation : ""}
                                `
                            }}
                            >
                                {
                                    props.modelInformation?.modelType === "bookTranslation"
                                    ? "Paragraph Number"
                                    : ""
                                }
                            </TableCell>
                            {
                                !isBookTranslationModelType && 
                                <TableCell classes={{ root: `${classes.tableCellVersion} ${classes.centerTextAlign} ${classes.labelColumn}` }}>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div></div>
                                        <TableSortLabel
                                            active={tableFilters.sortField === SortField.LABEL}
                                            direction={
                                                tableFilters.sortField === SortField.LABEL
                                                    ? tableFilters.sortOrder === SortOrder.ASC ? 'asc' : 'desc'
                                                    : 'asc'
                                            }
                                            onClick={(e) => {
                                                actions.handleTableFiltersChange({...tableFilters, sortOrder: tableFilters.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC, sortField: SortField.LABEL })
                                            }}
                                            classes={{
                                                icon: classes.sortLabelIcon,
                                                root: classes.sortLabelRoot,
                                                active: classes.sortLabelActive
                                            }}
                                        >
                                            Label
                                        </TableSortLabel>
                                        <Box>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                id="labelColumnOptions"
                                                classes={{
                                                    root: classes.columnOptionsIcon
                                                }}
                                                onClick={() => {
                                                    setFilterMenuOpen(SortField.LABEL)
                                                }}
                                            >
                                                {
                                                    (tableFilters.labelRegex?.length && !props.isDefaultLabelFilter) || tableFilters.predictedStatusRegex?.length
                                                        ? (
                                                            <Tooltip title="Filter applied on label">
                                                                <FilterListIcon />
                                                            </Tooltip>
                                                        )
                                                        : <MoreVertIcon />
                                                }

                                            </IconButton>
                                            {
                                                filterMenuOpen === SortField.LABEL &&
                                                <LabelColumnMenu
                                                    isSegmentationModelAnalyticsEnabled={urlSearchParams.get("purposeModelId") ? isPurposeModelProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics || false : props.isSegmentationModelAnalyticsEnabled }
                                                    open={filterMenuOpen === SortField.LABEL}
                                                    onClose={() => {
                                                        setFilterMenuOpen('')
                                                    }}
                                                    statusFilterOptions={uniquePredictedValues}
                                                    statusSearchOperator={tableFilters.statusFilterOperator || 'equals'}
                                                    statusSearchValue={tableFilters.predictedStatusRegex}
                                                    searchValue={tableFilters.labelRegex}
                                                    classFilterOptions={props.classFilterOptions}
                                                    resetFilters={() => {
                                                        actions.handleTableFiltersChange({ ...tableFilters, labelFilterOperator: 'equals', labelRegex: classFilterValue && classFilterValue !== 'CUSTOM' ? [classFilterValue] : [], statusFilterOperator: 'equals', predictedStatusRegex : []})
                                                        setFilterMenuOpen('')
                                                    }}
                                                    searchOperator={tableFilters.labelFilterOperator || 'equals'}
                                                    isFilterApplied={tableFilters.labelRegex?.length > 0 ? true : false}
                                                    onApplyFilterButtonClick={(labelOperator, labelValue, statusOperator, statusValue) => {
                                                        actions.handleTableFiltersChange({ ...tableFilters, labelFilterOperator: labelOperator, labelRegex: labelValue, statusFilterOperator: statusOperator, predictedStatusRegex: statusValue })
                                                        setFilterMenuOpen('')
                                                    }}
                                                />
                                            }

                                        </Box>
                                    </Box>
                                </TableCell>
                            }
                            {
                                !isBookTranslationModelType && 
                                <TableCell classes={{ root: `${classes.tableCellVersion} ${classes.centerTextAlign}` }} style={{width:"80px"}}>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div></div>
                                        <TableSortLabel
                                            active={tableFilters.sortField === SortField.PREDICTED_STATUS}
                                            direction={
                                                tableFilters.sortField === SortField.PREDICTED_STATUS
                                                    ? tableFilters.sortOrder === SortOrder.ASC ? 'asc' : 'desc'
                                                    : 'asc'
                                            }
                                            onClick={(e) => {
                                                actions.handleTableFiltersChange({ ...tableFilters, sortOrder: tableFilters.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC, sortField: SortField.PREDICTED_STATUS })
                                            }}
                                            classes={{
                                                icon: classes.sortLabelIcon,
                                                root: classes.sortLabelRoot,
                                                active: classes.sortLabelActive
                                            }}
                                        >
                                            Predicted Status
                                        </TableSortLabel>
                                        <Box>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                id="predictedStatusOptions"
                                                classes={{
                                                    root: classes.columnOptionsIcon
                                                }}
                                                onClick={() => {
                                                    setFilterMenuOpen(SortField.PREDICTED_STATUS)
                                                }}
                                            >
                                                {
                                                    tableFilters.predictionStatusFilter
                                                        ? (
                                                            <Tooltip title="Filter applied on Status">
                                                                <FilterListIcon />
                                                            </Tooltip>
                                                        )
                                                        : <MoreVertIcon />
                                                }

                                            </IconButton>
                                            {
                                                filterMenuOpen === SortField.PREDICTED_STATUS &&
                                                <PredictedStatusColumnMenu
                                                    open={filterMenuOpen === SortField.PREDICTED_STATUS}
                                                    onClose={() => {
                                                        setFilterMenuOpen('')
                                                    }}
                                                    statusOptions={['CORRECT', 'INCORRECT']}
                                                    statusFilterValue={mapStatus(tableFilters.predictionStatusFilter || '')}
                                                    resetFilters={() => {
                                                        // setPredictedStatusFilter('')
                                                        actions.handleTableFiltersChange({ ...tableFilters, predictionStatusFilter: ''})
                                                        setFilterMenuOpen('')
                                                    }}
                                                    isFilterApplied={tableFilters.predictionStatusFilter ? true : false}
                                                    onApplyFilterButtonClick={(filterValue) => {
                                                        // setPredictedStatusFilter(filterValue);
                                                        actions.handleTableFiltersChange({ 
                                                            ...tableFilters, 
                                                            predictionStatusFilter: 
                                                                filterValue === 'CORRECT' 
                                                                ? 'onlyCorrect' 
                                                                :  filterValue === "INCORRECT"
                                                                    ? 'onlyIncorrect'
                                                                    : ""
                                                        })
                                                        setFilterMenuOpen('')
                                                    }}
                                                />
                                            }

                                        </Box>
                                    </Box>
                                </TableCell>
                            }
                            {
                                true &&
                                <>
                                    <TableCell classes={{ root: `${classes.tableCellVersion} ${classes.centerTextAlign}` }} style={{width:"80px"}}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div></div>
                                            <TableSortLabel
                                                active={tableFilters.sortField === SortField.CONFIDENCE_SCORE}
                                                direction={
                                                    tableFilters.sortField === SortField.CONFIDENCE_SCORE
                                                        ? tableFilters.sortOrder === SortOrder.ASC ? 'asc' : 'desc'
                                                        : 'asc'
                                                }
                                                onClick={(e) => {
                                                    actions.handleTableFiltersChange({ ...tableFilters, sortOrder: tableFilters.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC, sortField: SortField.CONFIDENCE_SCORE })
                                                }}
                                                classes={{
                                                    icon: classes.sortLabelIcon,
                                                    root: classes.sortLabelRoot,
                                                    active: classes.sortLabelActive
                                                }}
                                            >
                                                Confidence Score
                                            </TableSortLabel>
                                            <Box>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    id="confidenceScoreColumnOptions"
                                                    classes={{
                                                        root: classes.columnOptionsIcon
                                                    }}
                                                    onClick={() => {
                                                        setFilterMenuOpen(SortField.CONFIDENCE_SCORE)
                                                    }}
                                                >
                                                    {
                                                        tableFilters.resourceConfidenceScoreMaxValue && !props.isDefaultConfidenceScoreFilterValue
                                                            ? (
                                                                <Tooltip title="Filter applied on confidence score">
                                                                    <FilterListIcon />
                                                                </Tooltip>
                                                            )
                                                            : <MoreVertIcon />
                                                    }

                                                </IconButton>
                                                {
                                                    filterMenuOpen === SortField.CONFIDENCE_SCORE &&
                                                    <ConfidenceScoreColumnMenu
                                                        open={filterMenuOpen === SortField.CONFIDENCE_SCORE}
                                                        onClose={() =>{
                                                            setFilterMenuOpen('')
                                                        }}
                                                        minValue={
                                                            parseInt(tableFilters.resourceConfidenceScoreMinValue) || 0
                                                        }
                                                        maxValue={
                                                            parseFloat(tableFilters.resourceConfidenceScoreMaxValue) || 100
                                                        }
                                                        resetFilters={() => {
                                                            if (bandFilterValue.includes('100')) {
                                                                actions.handleTableFiltersChange({ ...tableFilters, resourceConfidenceScoreMinValue: 100, resourceConfidenceScoreMaxValue:  100 })
                                                            } else if (bandFilterValue.includes('99.99')) {
                                                                actions.handleTableFiltersChange({ ...tableFilters, resourceConfidenceScoreMinValue: 99, resourceConfidenceScoreMaxValue: 100 })
                                                            } else {
                                                                const [min, max] = bandFilterValue.replaceAll('%', '').split(' - ');
                                                                actions.handleTableFiltersChange({ ...tableFilters, resourceConfidenceScoreMinValue: min ? parseInt(min) : '', resourceConfidenceScoreMaxValue: max ? parseInt(max) : ''
                                                            })
                                                            }
                                                            setFilterMenuOpen('')
                                                        }}
                                                        isFilterApplied={tableFilters.resourceConfidenceScoreMaxValue ? true : false}
                                                        onApplyFilterButtonClick={(min, max) => {
                                                           
                                                            actions.handleTableFiltersChange({ ...tableFilters, resourceConfidenceScoreMinValue: min, resourceConfidenceScoreMaxValue: max })
                                                            setFilterMenuOpen('')
                                                        }}
                                                    />
                                                }
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    {!props.isSegmentationModelAnalyticsEnabled && !props.modelInformation?.model?.isAnalyticsBasedOnWordErrorRateEnabled && (!isPurposeModelProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics) && <TableCell classes={{ root: `${classes.tableCellPredictedStatus}` }}
                                    >Compared Model Version Confidence Score</TableCell>}
                                </>
                            }
                            {
                                props.modelInformation?.model?.isAnalyticsBasedOnWordErrorRateEnabled &&
                                <TableCell classes={{ root: `${classes.tableCellVersion} ${classes.centerTextAlign}` }} style={{width:"80px"}}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div></div>
                                    <TableSortLabel
                                        active={tableFilters.sortField === SortField.WORD_ERROR_RATE}
                                        direction={
                                            tableFilters.sortField === SortField.WORD_ERROR_RATE
                                                ? tableFilters.sortOrder === SortOrder.ASC ? 'asc' : 'desc'
                                                : 'asc'
                                        }
                                        onClick={(e) => {
                                            actions.handleTableFiltersChange({ ...tableFilters, sortOrder: tableFilters.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC, sortField: SortField.WORD_ERROR_RATE })
                                        }}
                                        classes={{
                                            icon: classes.sortLabelIcon,
                                            root: classes.sortLabelRoot,
                                            active: classes.sortLabelActive
                                        }}
                                    >
                                        Word Error Rate
                                    </TableSortLabel>
                                        
                                    <Box>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            id="wordErrorRateColumnOptions"
                                            classes={{
                                                root: classes.columnOptionsIcon
                                            }}
                                            onClick={() => {
                                                setFilterMenuOpen(SortField.WORD_ERROR_RATE)
                                            }}
                                        >
                                            {
                                                // tableFilters.resourceWordErrorRateMaxValue && !props.isDefaultConfidenceScoreFilterValue
                                                tableFilters.resourceWordErrorRateMaxValue
                                                    ? (
                                                        <Tooltip title="Filter applied on word error rate">
                                                            <FilterListIcon />
                                                        </Tooltip>
                                                    )
                                                    : <MoreVertIcon />
                                            }

                                        </IconButton>
                                        {
                                            filterMenuOpen === SortField.WORD_ERROR_RATE &&
                                            <WordErrorRateColumnMenu
                                                open={filterMenuOpen === SortField.WORD_ERROR_RATE}
                                                onClose={() =>{setFilterMenuOpen('')}}
                                                minValue={ parseInt(tableFilters.resourceWordErrorRateMinValue || '') || 0 }
                                                maxValue={ parseFloat(tableFilters.resourceWordErrorRateMaxValue || '') || 100 }
                                                resetFilters={() => {
                                                    // if (bandFilterValue.includes('100')) {
                                                    //     actions.handleTableFiltersChange({ ...tableFilters, resourceWordErrorRateMinValue: 100, resourceWordErrorRateMaxValue:  100 })
                                                    // } else if (bandFilterValue.includes('99.99')) {
                                                    //     actions.handleTableFiltersChange({ ...tableFilters, resourceWordErrorRateMinValue: 99, resourceWordErrorRateMaxValue: 100 })
                                                    // } else {
                                                        // const [min, max] = bandFilterValue.replaceAll('%', '').split(' - ');
                                                        // actions.handleTableFiltersChange({ ...tableFilters, resourceWordErrorRateMinValue: min ? parseInt(min) : '', resourceWordErrorRateMaxValue: max ? parseInt(max) : ''
                                                        // })
                                                        // }
                                                    const updatedTableFilters = {...tableFilters }
                                                    delete updatedTableFilters.resourceWordErrorRateMinValue
                                                    delete updatedTableFilters.resourceWordErrorRateMaxValue
                                                    actions.handleTableFiltersChange(updatedTableFilters)
                                                    setFilterMenuOpen('')
                                                }}
                                                isFilterApplied={tableFilters.resourceWordErrorRateMaxValue ? true : false}
                                                onApplyFilterButtonClick={(min, max) => {
                                                    actions.handleTableFiltersChange({ ...tableFilters, resourceWordErrorRateMinValue: min, resourceWordErrorRateMaxValue: max })
                                                    setFilterMenuOpen('')
                                                }}
                                            />
                                        }
                                    </Box>
                                </Box>
                                </TableCell>                                
                            }
                            {
                                (isSegmentationModelAnalyticsEnabled || (urlSearchParams.get("purposeModelId") && isPurposeModelProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics)) &&
                                <>
                                    <TableCell classes={{ root: `${classes.tableCellVersion} ${classes.centerTextAlign}` }}>
                                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap:12 }}>
                                             <TableSortLabel
                                                active={tableFilters.sortField === SortField.IOU_SCORE}
                                                direction={
                                                    tableFilters.sortField === SortField.IOU_SCORE
                                                    
                                                        ? tableFilters.sortOrder === SortOrder.ASC ? 'asc' : 'desc'
                                                        : 'asc'
                                                }
                                                onClick={(e) => {
                                                    actions.handleTableFiltersChange({ ...tableFilters, sortOrder: tableFilters.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC, sortField: SortField.IOU_SCORE })
                                                }}
                                                classes={{
                                                    icon: classes.sortLabelIcon,
                                                    root: classes.sortLabelRoot,
                                                    active: classes.sortLabelActive
                                                }}
                                            >
                                                IOU Score
                                            </TableSortLabel>
                                            <Box>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    id="iouScoreColumnOptions"
                                                    classes={{
                                                        root: classes.columnOptionsIcon
                                                    }}
                                                    onClick={() => {
                                                        setFilterMenuOpen(SortField.IOU_SCORE)
                                                    }}
                                                >
                                                    {
                                                        tableFilters.resourceIouScoreMaxValue && iouFilterApplied
                                                            ? (
                                                                <Tooltip title="Filter applied on IOU score">
                                                                    <FilterListIcon />
                                                                </Tooltip>
                                                            )
                                                            : <MoreVertIcon />
                                                    }

                                                </IconButton>
                                                {
                                                    filterMenuOpen === SortField.IOU_SCORE &&
                                                    <IOUScoreColumnMenu
                                                        open={filterMenuOpen === SortField.IOU_SCORE}
                                                        onClose={() =>{
                                                            setFilterMenuOpen('')
                                                        }}
                                                        minValue={
                                                            parseFloat(tableFilters.resourceIouScoreMinValue) || 0
                                                        }
                                                        maxValue={
                                                            parseFloat(tableFilters.resourceIouScoreMaxValue) || 1
                                                        }
                                                        resetFilters={() => {
                                                        
                                                
                                                                actions.handleTableFiltersChange({ ...tableFilters, resourceIouScoreMinValue: 0, resourceIouScoreMaxValue: 1
                                                            })
                                                            
                                                            setFilterMenuOpen('')
                                                        }}
                                                   
                                                        isFilterApplied={tableFilters.resourceIouScoreMaxValue ? true : false}
                                                        onApplyFilterButtonClick={(min, max) => {
                                                           
                                                            actions.handleTableFiltersChange({ ...tableFilters, resourceIouScoreMinValue: min, resourceIouScoreMaxValue: max })
                                                            setFilterMenuOpen('')
                                                        }}
                                                    />
                                                }
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </>
                            }
                            
                            <TableCell style={{width: '40px'}} classes={{ root: classes.tableCellView }}
                            // colSpan={isSegmentationModelAnalyticsEnabled && !tableData?.length ? 5 : undefined}
                            >Actions</TableCell>
                            <TableCell classes={{ root: classes.tableCellView }} style={{width: '70px'}}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        currentAnalyticsResourcesViewType === 'grid-view' ?
                            <TableBody>
                                <Grid container spacing={4} style={{width: 'calc(100% + 85vmax)', 
                    paddingTop: '16px', paddingLeft: '16px', height: 'self.innerHeight', position:'relative'}} > 
                    {
                                isDataFetching && new Array(15).fill('').map((item, index) =>
                        <Grid alignItems="center"
                        key={index}
                        style={{margin: 0, maxWidth:'220px', padding: '4px'}}
                        item xs={6} sm={4} md={3} lg={2}
                        >
                            <Card className={classes.grid}>
                                <CardHeader
                                            // classes={{ root: classes.tableCell}} 
                                            padding="checkbox"
                                            style ={{display : "flex", flexDirection:"row-reverse", width:"auto !important", paddingBottom: "8px"}}
                                            action={
                                                    <Checkbox
                                                        style={{paddingRight: "8px"}}
                                                    />
                                            }
                                            subheader = {<Grid container alignItems="center" spacing={1} style={{margin: 0, paddingLeft:"8px", width : "auto"}}>
                                                <Tooltip title=".">
                                                    <Typography paragraph>
                                                        .
                                                    </Typography>
                                                </Tooltip>
                                                </Grid>
                                                }
                                        />
                                <CardMedia
                                    //
                                />
                                <CardContent>
                                    <CustomSkeleton width="100%">
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            .
                                        </Typography>
                                    </CustomSkeleton>
                                </CardContent>
                                <CardContent>
                                    <CustomSkeleton width="100%">
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            .
                                        </Typography>
                                    </CustomSkeleton>
                                </CardContent>
                                <CardActions disableSpacing>
                                        <CustomSkeleton width="100%">
                                            <Chip></Chip>
                                        </CustomSkeleton>                                    
                                </CardActions>
                                </Card>
                            </Grid>
                        )}
                        {!isDataFetching && tableData && tableData.length > 0 && tableData.map((row: IResourceAnalyticsCollection, index) => {
                            
                            return (
                                <Fade key={row._id} in={true} timeout={index * 250} mountOnEnter unmountOnExit>
                                    <Grid alignItems="center"
                                        style={{margin: 0,minWidth: '228px', maxWidth: '270px', padding: '4px'}}
                                        item xs={'auto'} sm={'auto'} md={4} lg={2}
                                    >
                                    <Card className={classes.grid}
                                        // hover
                                        role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row._id}
                                        // className={row._id}
                                        id={`tableRow${row._id}`}
                                        data-tablerowindex={index}
                                        classes={{ root: classes.tableRow}}
                                        // selected={isItemSelected || (selected === row._id)}
                                        // onClick={()=>{this.props.setSelectedData(row._id)}}
                                    >
                                        <CardHeader
                                            // classes={{ root: classes.tableCell}} 
                                            padding="checkbox"
                                            style ={{display : "flex", flexDirection:"row-reverse", width:"auto !important", paddingBottom: "8px", position:'absolute'}}
                                        />
                                        <CardMedia
                                            classes={{ root: classes.tableCellName}} 
                                            style = {{padding:0, margin: '10px 0px', width:"auto"}}
                                            id={`tableCellName${index}`}
                                        >
                                            
                                            <Grid container alignItems="center" wrap="nowrap" spacing={1}
                                            style = {{justifyContent:"center", position: 'relative'}}
                                            >
                                                <Grid item>
                                                    <Checkbox
                                                            style={{paddingRight: "8px", backgroundColor: '#ffffff', position: 'absolute', top:'10px', left:'10px'}}
                                                            onClick={(event)=>{
                                                                onCheckBoxSelectForOneResource(row._id)
                                                                // props.setTableResourceIdsSelected(oldResourceIds=>{
                                                                //     let isResourceIdAlreadyPresent: boolean = oldResourceIds.indexOf(row._id) !== -1 ? true : false;
        
                                                                //     if (!isResourceIdAlreadyPresent) {
                                                                //         oldResourceIds.push(row._id)
                                                                //     } else {
                                                                //         const resourceIdIndex = oldResourceIds.indexOf(row._id);
                                                                //         oldResourceIds.splice(resourceIdIndex, 1);
                                                                //     }
                                                                //     return [...oldResourceIds]
                                                                // })
                                                            }}
                                                            checked={props.tableResourceIdsSelected.indexOf(row._id) === -1 ? false : true}
                                                            // inputProps={{ 'aria-labelledby': labelId+'deepak' }}
                                                    />
                                                    {
                                                            props.modelInformation?.modelType === 'image' ||
                                                                props.modelInformation?.modelType === 'imageAnnotation' ||
                                                                props.modelInformation?.modelType === 'imageAnnotationGroup' || 
                                                                row.analyticsPurposeModelData?.model?.type === "image" ||
                                                                row.analyticsPurposeModelData?.model?.type === "imageAnnotation" ||
                                                                row.analyticsPurposeModelData?.model?.type === "imageAnnotationGroup"
                                                                ? (<ImageThumbnail index={index} classes={classes} resource={row} dataView='grid-view'
                                                                    onThumbnailClick={() => {
                                                                    }}
                                                                />)
                                                                : props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation"
                                                                    ? <TextResourceInGridViewColumn resourceText={row.resource || ""} />
                                                                    : props.modelInformation?.modelType === "bookTranslation"
                                                                      ? <div>{row.resource}</div>
                                                                      : props.modelInformation?.modelType === "video" ||
                                                                        row.analyticsPurposeModelData?.model?.type === "video"
                                                                      ? 
                                                                        <VideoSection 
                                                                            //@ts-ignore
                                                                            modelType={(urlSearchParams.get(urlSearchParamsStrings.PURPOSE_MODEL_ID) && selectedModelResource?.analyticsPurposeModelData?.model?.type) ? selectedModelResource?.analyticsPurposeModelData?.model?.type : props.modelInformation?.modelType} 
                                                                            videoUrl={row.resourceUrl} 
                                                                            dataView={'grid-view'} 
                                                                            index={index} 
                                                                            onVideoThumbnailClick={()=>{}} 
                                                                            parentScrollContainerElementId={''}                                                                        
                                                                        />
                                                                    : <Audio src={row.resourceUrl} startTimeInSeconds={row.startTime} endTimeInSeconds={row.endTime} />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </CardMedia>
                                        <CardContent classes={{ root: classes.tableCellLabel}} style={{width : "auto", textAlign: "center", padding:"8px 16px"}}>
                                            <Box
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <LabelEditText
                                                        readonly={
                                                            true
                                                        }
                                                        labelsAvailable={
                                                            []
                                                            // this.state.labelsAvailable
                                                        }
                                                        resource={row as unknown as any}
                                                        onEditTextFocus={() => {
                                                            // this.setShouldBlockDataTableKeyboardEvents(true)
                                                        }}
                                                        onEditTextBlurEvent={() => {
                                                            // this.setShouldBlockDataTableKeyboardEvents(false)
                                                        }}
                                                        placeWhereComponentBeingUsed="data-table"
                                                        scenario={
                                                            props.modelInformation?.model?.isAnalyticsBasedOnWordErrorRateEnabled
                                                            ?   row?.predictionStatus === "CORRECT"
                                                                ?   "correctPrediction"
                                                                :   "incorrectPrediction"
                                                            :   undefined
                                                        }
                                                    />
                                                </Box>
                                        </CardContent>
                                        <CardContent classes={{root: classes.tableCellConfidenceScore}} style={{width : "auto", padding: '0px 16px'}}>
                                            <Tooltip title={row.confidence_score + "%"}>
                                                <Chip style={{ width: "100%", marginRight: 0 }} label={row.confidence_score + "%"} classes={{ root: classes.chip }} />
                                            </Tooltip>
                                        </CardContent>
                                        <CardActions classes={{ root: `${classes.tableCellActions}` }}  style={{width : "auto", justifyContent: "center", margin: '8px 0px'}}>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    {
                                                        props.resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB.indexOf(row._id) === -1
                                                        ?
                                                            <Tooltip title={tooltipTextForEditResourceDataSetCollectionButton}>
                                                                <span>
                                                                    <AddResourceToDataSetCollectionButton
                                                                        onClick={() => {onAddResourceToDataSetCollectionButtonClick(row._id)} }
                                                                    /> 
                                                                </span>                                                
                                                            </Tooltip>
                                                        :
                                                        <CircularProgress color="primary" size={25} style={{marginLeft: "12px", marginTop:"12px"}} /> 
                                                    }
                                                    <Tooltip title= "View details" >
                                                        <IconButton
                                                            onClick={() => {onViewButtonClick(row,index)}}
                                                            style={{ textTransform: 'none' , border: 'none', outline: "none"}}
                                                        >
                                                            <FullscreenIcon color='primary' style={{ textTransform: 'none' }}/>
                                                        </IconButton>
                                                    </Tooltip>
                                            </div>
                                        </CardActions>
                                        </Card>
                                    </Grid>
                                </Fade>
                        );
                        })}
                        {!isDataFetching && tableData && !tableData.length && 
                            <div style={{height:'53px', width: '100%', padding: '15px'}}>
                                <Typography component="h5" variant="body1">
                                    No Data Found
                                </Typography>
                                <hr style={{marginLeft: '-15px', width:'100vmax'}}/>
                            </div>}
                        {/* <NoTableData colSpan={12} /> */}
                    </Grid>
                            </TableBody>
                    :
                    <TableBody>
                        {isDataFetching &&
                            new Array(10).fill("").map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className={`${classes.tableCellCheckbox}`}>
                                        <CustomSkeleton width={20} style={{marginLeft: "10px"}}>
                                            <Checkbox />
                                        </CustomSkeleton>
                                    </TableCell>
                                    <TableCell>
                                        <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                        <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                        <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                        <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                        <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                        <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                        <CustomSkeleton height={48} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        {
                            areAllRowsWhichIsShowingInUISelectedByUser &&
                            props.pagination.rowsPerPage < props.pagination.totalCount &&
                            (props.tableData?.length || 0) > 0 &&
                            <TableRow style={{background: "white"}}>
                                <TableCell 
                                    colSpan={tableRowCellColSpan}
                                    style={{
                                        top: '61px', 
                                        textAlign: 'center',
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        position: "sticky",
                                        backgroundColor: "white",
                                        zIndex: 1
                                    }}                                    
                                >
                                    {
                                        props.pagination.totalCount > props.tableResourceIdsSelected?.length && !props.areMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser &&
                                        <>
                                            <span>All <b>{props.pagination.rowsPerPage}</b> data on this page are selected. </span>
                                            <span
                                                className={`${classes.selectAllDataText}`}
                                                onClick={()=>{
                                                    props.setAreMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser(true);
                                                }}                                    
                                            >
                                                {`Select first ${props.pagination.totalCount < 50000 ? props.pagination.totalCount : 50000} data matching this table filter`}
                                            </span>  
                                        </>                                  
                                    }
                                    {
                                        (props.pagination.totalCount === props.tableResourceIdsSelected?.length ||
                                        props.areMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser) &&
                                        <>
                                            <span>
                                                {props.pagination.totalCount < 50000 ? `All ${props.pagination.totalCount} data are selected. ` : `First ${50000} data are selected. `}
                                            </span>
                                            <span
                                                className={`${classes.clearDataSelectionText}`}
                                                onClick={()=>{
                                                    props.setTableResourceIdsSelected([]);
                                                    props.setAreMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser(false);
                                                }}
                                            >
                                                Clear selection
                                            </span>                                            
                                        </>
                                    }
                                </TableCell>
                            </TableRow>
                        }
                        {!isDataFetching &&
                            tableData && tableData.length > 0 && tableData.map((row: IResourceAnalyticsCollection, index) => {

                                const isSegmentationModelAnalyticsEnabled = urlSearchParams.get("purposeModelId") ? isPurposeModelProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics || false : props.modelInformation?.model?.isSegmentationModelAnalytics;

                                const isPredictionStatusLogicThroughDeviationAllowed = props.modelInformation?.model?.predictionStatusLogic === "labelAndErrorDeviation";

                                const isCorrectPrediction: boolean = (()=>{
                                    if (props.modelInformation?.model?.isAnalyticsBasedOnWordErrorRateEnabled) {
                                        if (row?.predictionStatus === "CORRECT") {
                                            return true
                                        } else {
                                            return false
                                        }
                                    }   else if (isSegmentationModelAnalyticsEnabled) {
                                        if (row?.predictionStatus === "CORRECT") {
                                            return true
                                        }                                         
                                        return false;
                                    } else {
                                        if (isPredictionStatusLogicThroughDeviationAllowed &&!isNaN(Number(row?.label||""))&&!isNaN(Number(row?.prediction))&&row.prediction!=="predicted"){
                                            if(Math.abs(Number(row?.label)-Number(row?.prediction))<=Number(props.modelInformation?.model?.maximumMisPredictionDeviationAllowedInNumber||0)) {
                                                return true;
                                            }
                                            else {
                                                return false;
                                            }
                                        }
                                        return row.prediction === 'predicted' ? true : false
                                    }
                                })();

                                return (
                                    <>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row._id}
                                            className={row._id}
                                            id={`tableRow${row._id}`}
                                            data-tablerowindex={index}
                                            classes={{ root: classes.tableRow }}
                                            selected={currentIndex === index}
                                            onClick={() => {
                                               setCurrentIndex(index)
                                            }}
                                        >
                                            <TableCell classes={{ root: classes.tableCellCheckbox}}>
                                                <Checkbox
                                                    onClick={(event)=>{
                                                        onCheckBoxSelectForOneResource(row._id)
                                                        // props.setTableResourceIdsSelected(oldResourceIds=>{
                                                        //     let isResourceIdAlreadyPresent: boolean = oldResourceIds.indexOf(row._id) !== -1 ? true : false;

                                                        //     if (!isResourceIdAlreadyPresent) {
                                                        //         oldResourceIds.push(row._id)
                                                        //     } else {
                                                        //         const resourceIdIndex = oldResourceIds.indexOf(row._id);
                                                        //         oldResourceIds.splice(resourceIdIndex, 1);
                                                        //     }
                                                        //     return [...oldResourceIds]
                                                        // })
                                                    }}
                                                    checked={props.tableResourceIdsSelected.indexOf(row._id) === -1 ? false : true}
                                                    // onClick={(event) => this.onCheck(event, row._id)}
                                                    // checked={isItemChecboxSelected || this.state.allSelected}
                                                    // inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellName }} padding="none"
                                                id={`tableCellName${index}`}
                                                style={{ width: props.modelInformation?.modelType === "forecasting" ? "100px" : "312px" }}
                                            >
                                                <Grid container justify='center' alignItems="center" wrap="nowrap" spacing={1}
                                                    classes={{
                                                        root: classes.nameColumnGrid
                                                    }}
                                                >
                                                    <Grid item>
                                                        {
                                                            !urlSearchParams.get("purposeModelId")
                                                            &&
                                                            (
                                                            props.modelInformation?.modelType === 'image' ||
                                                                props.modelInformation?.modelType === 'imageAnnotation' ||
                                                                props.modelInformation?.modelType === 'imageAnnotationGroup'
                                                                ? (<ImageThumbnail index={index} classes={classes} resource={row} dataView='list-view'
                                                                    onThumbnailClick={() => {
                                                                    }}
                                                                />)
                                                                : props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation"
                                                                    ? 
                                                                        <TextResourceInListViewColumn 
                                                                            resourceText={row.resource || ""}
                                                                        />
                                                                    : props.modelInformation?.modelType === "bookTranslation"
                                                                        ? <div>{row.resource}</div>
                                                                        : props.modelInformation?.modelType === "video"
                                                                        ? <VideoSection 
                                                                                dataView='list-view'
                                                                                index={index}
                                                                                modelType={props.modelInformation?.modelType} videoUrl={row.resourceUrl} 
                                                                                onVideoThumbnailClick={()=>{}} 
                                                                                parentScrollContainerElementId={''}                                                                                
                                                                            />
                                                                    :
                                                                    props.isSpectrogramEnabled?
                                                                    <ImageThumbnail index={index} classes={classes} resource={row} dataView='list-view'
                                                                    isSpectrogramAudioSenerio={true}
                                                                    onThumbnailClick={() => {
                                                                    }}
                                                                />:
                                                                     <Audio src={row.resourceUrl} startTimeInSeconds={row.startTime} endTimeInSeconds={row.endTime} />
                                                            )
                                                        }
                                                        {
                                                            urlSearchParams.get("purposeModelId")
                                                            &&
                                                            (
                                                                getFileTypeFromFileName(row.fileName || "") === "image" ?
                                                                    <ImageThumbnail index={index} classes={classes} resource={row} dataView='list-view'
                                                                        onThumbnailClick={() => {
                                                                        }}
                                                                    />
                                                                    : getFileTypeFromFileName(row.fileName || "") === "text" ?
                                                                        <TextResourceInListViewColumn
                                                                            resourceText={row.resource || ""}
                                                                        />
                                                                        :
                                                                        getFileTypeFromFileName(row.fileName || "") === "video" ?
                                                                            <VideoSection
                                                                                dataView='list-view'
                                                                                index={index}
                                                                                //@ts-ignore
                                                                                modelType={props.modelInformation?.modelType} videoUrl={row.resourceUrl}
                                                                                onVideoThumbnailClick={() => { }}
                                                                                parentScrollContainerElementId={''}
                                                                            />
                                                                            : <Audio src={row.resourceUrl} startTimeInSeconds={row.startTime} endTimeInSeconds={row.endTime} />
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item xs zeroMinWidth wrap="nowrap">
                                                    <Tooltip title={row?.fileName??""}>
                                                        <Typography component="p" variant="subtitle2" noWrap>
                                                            {showName(row?.fileName ??"")}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                                </Grid>
                                            </TableCell>
                                            {
                                                !isBookTranslationModelType &&
                                                <TableCell classes={{ root: `${props.modelInformation?.modelType === "forecasting" ? classes.tableCellLabelForForcastingModel : classes.tableCellLabel} ${classes.labelColumn}` }}
                                                >
                                                    <Box
                                                        style={{
                                                            maxWidth: '224px',
                                                            margin: 'auto'
                                                        }}
                                                    >
                                                        {
                                                            isPredictionStatusLogicThroughDeviationAllowed?
                                                            <LabelEditText
                                                            readonly={
                                                                true
                                                            }
                                                            labelsAvailable={
                                                                []
                                                                // this.state.labelsAvailable
                                                            }
                                                            resource={row as unknown as any}
                                                            onEditTextFocus={() => {
                                                                // this.setShouldBlockDataTableKeyboardEvents(true)
                                                            }}
                                                            onEditTextBlurEvent={() => {
                                                                // this.setShouldBlockDataTableKeyboardEvents(false)
                                                            }}
                                                            placeWhereComponentBeingUsed="data-table"
                                                            scenario={
                                                                "labelAndErrorDeviation"
                                                            }
                                                            maximumMisPredictionDeviationAllowedInNumber={props.modelInformation?.model?.maximumMisPredictionDeviationAllowedInNumber}
                                                        />
                                                            :
                                                            <LabelEditText
                                                            readonly={
                                                                true
                                                            }
                                                            labelsAvailable={
                                                                []
                                                                // this.state.labelsAvailable
                                                            }
                                                            resource={row as unknown as any}
                                                            onEditTextFocus={() => {
                                                                // this.setShouldBlockDataTableKeyboardEvents(true)
                                                            }}
                                                            onEditTextBlurEvent={() => {
                                                                // this.setShouldBlockDataTableKeyboardEvents(false)
                                                            }}
                                                            placeWhereComponentBeingUsed="data-table"
                                                            scenario={
                                                                props.modelInformation?.model?.isAnalyticsBasedOnWordErrorRateEnabled
                                                                ?   isCorrectPrediction
                                                                    ?   "correctPrediction"
                                                                    :   "incorrectPrediction"
                                                                :   props.purposeModelProblemType
                                                                        ? isPurposeModelProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics
                                                                            ?  "modelAnalyticsSegmentationAnalyticsEnabled"
                                                                            : undefined
                                                                        : props.modelInformation?.model?.isSegmentationModelAnalytics
                                                                            ? "modelAnalyticsSegmentationAnalyticsEnabled"
                                                                            : undefined
                                                            }
                                                        />}
                                                    </Box>
                                                </TableCell>
                                            }
                                            {
                                                !isBookTranslationModelType &&
                                                <TableCell classes={{ root: `${classes.tableCellPredictedStatus}` }}
                                                >
                                                    <Box
                                                        style={{
                                                            margin: 'auto'
                                                        }}
                                                    >
                                                        <Chip label={
                                                            isCorrectPrediction ? 'CORRECT' : 'INCORRECT'
                                                        } style={{ width: "120px" }} classes={{ root: `${classes.chip} ${isCorrectPrediction ? classes.correct : classes.incorrect}` }} />
                                                    </Box>
                                                </TableCell>
                                            }
                                            {
                                                true &&
                                                <>
                                                    <TableCell classes={{ root: classes.tableCellConfidenceScore }}
                                                    >
                                                        <Tooltip title={row.confidence_score + "%"}>
                                                            <Chip style={{ width: "120px", marginRight: 0 }} label={row.confidence_score + "%"} classes={{ root: classes.chip }} />
                                                        </Tooltip>
                                                    </TableCell>

                                                    {!props.modelInformation?.model?.isAnalyticsBasedOnWordErrorRateEnabled  && !props.isSegmentationModelAnalyticsEnabled&&<TableCell classes={{ root: classes.tableCellConfidenceScore }}
                                                    >
                                                        {
                                                            props.isFetchingConfidenceScoresForModelCollectionResources
                                                            ? <CustomSkeleton height={40} />
                                                            : <Tooltip 
                                                                    title={
                                                                        row?.modelCollectionSpecificData && props?.modelCollectionIdToCompareConfidenceScoreWith &&
                                                                        row?.modelCollectionSpecificData[props?.modelCollectionIdToCompareConfidenceScoreWith]?.confidenceScore
                                                                        ? `${row?.modelCollectionSpecificData[props?.modelCollectionIdToCompareConfidenceScoreWith]?.confidenceScore}%`
                                                                        : `N A`                                                        
                                                                    }
                                                                >
                                                                    <Chip style={{ width: "120px", marginRight: 0 }} 
                                                                        label={
                                                                            row?.modelCollectionSpecificData && props?.modelCollectionIdToCompareConfidenceScoreWith &&
                                                                            row?.modelCollectionSpecificData[props?.modelCollectionIdToCompareConfidenceScoreWith]?.confidenceScore
                                                                            ? `${row?.modelCollectionSpecificData[props?.modelCollectionIdToCompareConfidenceScoreWith]?.confidenceScore}%`
                                                                            : `N A`
                                                                        } 
                                                                        classes={{ root: classes.chip }} 
                                                                    />
                                                                </Tooltip>
                                                        }

                                                    </TableCell>}
                                                </>
                                            }
                                            {
                                                props.modelInformation?.model?.isAnalyticsBasedOnWordErrorRateEnabled &&
                                                <TableCell classes={{ root: classes.tableCellConfidenceScore }}
                                                >
                                                    <Tooltip title={row.wordErrorRate + "%"}>
                                                        <Chip style={{ width: "120px", marginRight: 0 }} label={row.wordErrorRate + "%"} classes={{ root: classes.chip }} />
                                                    </Tooltip>
                                                </TableCell>                                                
                                            }
                                            {
                                                isSegmentationModelAnalyticsEnabled &&
                                                <>
                                                    <TableCell classes={{ root: classes.tableCellConfidenceScore }}
                                                    >
                                                        {row.iouScore && <Tooltip title={row.iouScore}>
                                                            <Chip style={{ width: "120px", marginRight: 0 }} label={row?.iouScore.toFixed(2)} classes={{ root: classes.chip }} />
                                                        </Tooltip>}
                                                    </TableCell>

                                                </>
                                            }

                                            {/* <TableCell classes={{
                                                // root: classes.tableCellDateForForecastingModel
                                            }}
                                            >
                                                <div style={{ maxWidth: '140px' }}>
                                                    <TimeDateFormat timeString={row.statusLastModifiedAt} timeStyleClass={classes.lastModifiedTime} textAlign={'left'} />
                                                </div>
                                            </TableCell> */}
                                            <TableCell
                                                classes={{ root: `${classes.tableCellActionsButtons}` }}
                                            >
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    {
                                                        props.resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB.indexOf(row._id) === -1
                                                            ?
                                                            <Tooltip title={tooltipTextForEditResourceDataSetCollectionButton}>
                                                                <span>
                                                                    <AddResourceToDataSetCollectionButton
                                                                        onClick={() => {onAddResourceToDataSetCollectionButtonClick(row._id)} }
                                                                    /> 
                                                                </span>                                                
                                                            </Tooltip>
                                                            :
                                                            <CircularProgress color="primary" size={25} style={{ marginLeft: "12px" }} />
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                classes={{ root: `${classes.tableCellView}` }}
                                            >
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        startIcon={<FullscreenIcon />}
                                                        onClick={() => {onViewButtonClick (row,index)}}
                                                        color='primary' variant='contained'
                                                        style={{ textTransform: 'none' }}>View</Button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            })}
                        {!isDataFetching &&
                            tableData && !tableData.length &&
                            <NoTableData colSpan={tableRowCellColSpan} />}
                    </TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={pagination.totalCount}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.currentPage}
                onChangePage={actions.tablePageChangeHandler}
                onChangeRowsPerPage={(event) => actions.tableRowsPerPageChangeHandler(event)}
            />
            {console.log("is true", viewResourceData && isSegmentationModelAnalyticsEnabled && props.modelInformation?.modelType === "imageAnnotation")}
            {
                viewResourceData && (isSegmentationModelAnalyticsEnabled) && (props.modelInformation?.modelType === "imageAnnotation" || props.modelInformation?.modelType === "imageAnnotationGroup" || selectedModelResource?.analyticsPurposeModelData?.model?.type === "imageAnnotation" || selectedModelResource?.analyticsPurposeModelData?.model?.type === "imageAnnotationGroup")
                ?
                  <ImageAnnotationSegmentationDialog 
                  shouldModalOpenedAfterFetchingData={props.shouldModalOpenedAfterFetchingData}
                  setShouldModalOpenedAfterFetchingData={props.setShouldModalOpenedAfterFetchingData}
                  pagination={pagination}
                  onPageChange={actions.tablePageChangeHandler}
                  resources={props.tableData}
                  selectedModelIndex={currentIndex}
                  modelInformation={props.modelInformation}
                  isFetchingOtherImageAnnotationPredictions={props.isFetchingOtherImageAnnotationPredictions ? true : false}
                  OtherImageAnnotationPredictions={props.OtherImageAnnotationPredictions}
                  selectedResource={props?.tableData?.[currentIndex] ?? null }
                  showLeftArrow={showLeftArrow}
                  showRightArrow={showRightArrow}
                  purposeModelProblemType={props.purposeModelProblemType}
                  front={handleFront}
                  back={handleBack}
                  availableLabelsData={resourceLabels.find(resourceLabel => resourceLabel.resourceId === selectedModelResource?._id)?.labels || null}
                  onClose={() => {
                      const urlQuery = new URLSearchParams(location?.search);
                      urlQuery.delete('resourceToCompareWith');
                      location.search = urlQuery.toString();
                      history.replace(`${location?.pathname}?${urlQuery.toString()}`);
                      setViewResourceData(false)
                      props.setShouldModalOpenedAfterFetchingData(false)
                  }}
                  modelCollectionsToCompareWithOptions={props.modelCollectionsToCompareWith} 
                  selectedModelCollectionId={props.selectedModelCollectionId} 
                  actions={
                      {
                          modelCollectionToCompareWithChangeHandler: props.actions.modelToCompareWithChangeHandler,
                          onArrowClickHanlder: onSwitchResourceInDialog,
                          updateLabelConfidenceScores: (e: any) => {
                              const selectedResourceLabels = resourceLabels.find(resourceLabel => resourceLabel.resourceId === selectedModelResource?._id);
                              setFetchedLabelConfidenceScores({ ...selectedResourceLabels?.labels, [e.modelCollectionId]: e.data.data })
                          }
                      }
                  } 
                  modelReferenceGuides={props.modelReferenceGuides}
                />
                : 
                viewResourceData && isSegmentationModelAnalyticsEnabled && props.isSpectrogramEnabled && props.modelInformation?.modelType === "audio"?
                <ImageAnnotationSegmentationDialog 
                  shouldModalOpenedAfterFetchingData={props.shouldModalOpenedAfterFetchingData}
                  setShouldModalOpenedAfterFetchingData={props.setShouldModalOpenedAfterFetchingData}
                  pagination={pagination}
                  onPageChange={actions.tablePageChangeHandler}
                  isSpectrogramAudioContext={true}
                  resources={props.tableData}
                  selectedModelIndex={currentIndex}
                  modelInformation={props.modelInformation}
                  isFetchingOtherImageAnnotationPredictions={props.isFetchingOtherImageAnnotationPredictions ? true : false}
                  OtherImageAnnotationPredictions={props.OtherImageAnnotationPredictions}
                  selectedResource={props?.tableData?.[currentIndex] ?? null }
                  showLeftArrow={showLeftArrow}
                  showRightArrow={showRightArrow}
                  purposeModelProblemType={props.purposeModelProblemType}
                  front={handleFront}
                  back={handleBack}
                  availableLabelsData={resourceLabels.find(resourceLabel => resourceLabel.resourceId === selectedModelResource?._id)?.labels || null}
                  onClose={() => {
                      const urlQuery = new URLSearchParams(location?.search);
                      urlQuery.delete('resourceToCompareWith');
                      location.search = urlQuery.toString();
                      history.replace(`${location?.pathname}?${urlQuery.toString()}`);
                      setViewResourceData(false)
                      props.setShouldModalOpenedAfterFetchingData(false)
                  }}
                  modelCollectionsToCompareWithOptions={props.modelCollectionsToCompareWith} 
                  selectedModelCollectionId={props.selectedModelCollectionId} 
                  actions={
                      {
                          modelCollectionToCompareWithChangeHandler: props.actions.modelToCompareWithChangeHandler,
                          onArrowClickHanlder: onSwitchResourceInDialog,
                          updateLabelConfidenceScores: (e: any) => {
                              const selectedResourceLabels = resourceLabels.find(resourceLabel => resourceLabel.resourceId === selectedModelResource?._id);
                              setFetchedLabelConfidenceScores({ ...selectedResourceLabels?.labels, [e.modelCollectionId]: e.data.data })
                          }
                      }
                  } 
                  modelReferenceGuides={props.modelReferenceGuides}
                />
                :viewResourceData
                  ?                 <AnalyticsDataViewDialog
                  open={viewResourceData}
                  modelInformation={props.modelInformation}
                  purposeModelInformation={selectedModelResource?.analyticsPurposeModelData}
                  fetchedLabelConfidenceScores={fetchedLabelConfidenceScores}
                  isFetchingOtherModelTranslatedlanguageTextpredictions={props.isFetchingOtherModelTranslatedlanguageTextpredictions}
                  otherModelTranslatedLanguageTextPredictions={props.otherModelTranslatedLanguageTextPredictions}
                  setTableData={props.setTableData}
                  selectedResource={selectedModelResource}
                  showLeftArrow={showLeftArrow}
                  showRightArrow={showRightArrow}
                  availableLabelsData={resourceLabels.find(resourceLabel => resourceLabel.resourceId === selectedModelResource?._id)?.labels || null}
                  onClose={() => {
                      const urlQuery = new URLSearchParams(location?.search);
                      urlQuery.delete('resourceToCompareWith');
                      location.search = urlQuery.toString();
                      history.push(`${location?.pathname}?${urlQuery.toString()}`);
                      setViewResourceData(false)
                  }}
                  modelCollectionsToCompareWithOptions={props.modelCollectionsToCompareWith} 
                  selectedModelCollectionId={props.selectedModelCollectionId} 
                  actions={
                      {
                          modelCollectionToCompareWithChangeHandler: props.actions.modelToCompareWithChangeHandler,
                          onArrowClickHanlder: onSwitchResourceInDialog,
                          updateLabelConfidenceScores: (e: any) => {
                              const selectedResourceLabels = resourceLabels.find(resourceLabel => resourceLabel.resourceId === selectedModelResource?._id);
                              setFetchedLabelConfidenceScores({ ...selectedResourceLabels?.labels, [e.modelCollectionId]: e.data.data })
                          }
                      }
                  } 
                  modelReferenceGuides={props.modelReferenceGuides}
                  />
                  : <></>

            }
        </>
    )
}

export function LabelColumnMenu(props: {
    isSegmentationModelAnalyticsEnabled: boolean
    open: boolean,
    searchValue: string[],
    classFilterOptions: Array<string>;
    statusFilterOptions: Array<string>;
    searchOperator: ResourceLabelFilterOperator,
    statusSearchOperator: ResourceLabelFilterOperator,
    statusSearchValue: string[]
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetFilters: () => void
    isFilterApplied: boolean
    onApplyFilterButtonClick: (operator: ResourceLabelFilterOperator, value: string[], statusOperator: ResourceLabelFilterOperator, statusValue: string[]) => void
}) {
    const classes = useStyles();
    const [searchOperatorState, setSearchOperatorState] = useState<InputFieldState<ResourceLabelFilterOperator>>({ value: props.searchOperator });
    const [statusOperatorState, setStatusOperatorState] = useState<InputFieldState<ResourceLabelFilterOperator>>({ value: props.statusSearchOperator });
    const [searchValueState, setSearchValueState] = useState<string[]>(props.searchValue);
    const [statusSearchValueState, setStatusSearchValueState] = useState<string[]>(props.statusSearchValue)

    return (
        <Popover
            id="name-column-menu"
            anchorEl={document.getElementById("labelColumnOptions")}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                style: {
                    width: "450px",
                },
            }}
        >
            {props.open && (
                <Box id="nameMenuItemContainer" style={{ padding: 15 }}>
                    <div className={`${classes.tableColumnMenuHeading}`}>
                        <Typography
                            variant="body1"
                            style={{ marginBottom: 20 }}
                            color="primary"
                        >
                            &#8288;Label Column Filter
                        </Typography>
                        <Tooltip title="Reset Filters">
                            <Box>
                                <TableColumnResetFilterButton
                                    onClick={() => props.resetFilters()}
                                    disabled={!props.isFilterApplied}
                                />
                            </Box>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            size="small"
                            style={{ width: '49%' }}
                            variant="outlined"
                            select
                            label="Search Operator"
                            className={`${classes.floating}`}
                            value={searchOperatorState.value}
                            onChange={(e) => {
                                setSearchOperatorState(oldState => { return { ...oldState, value: e.target.value as ResourceLabelFilterOperator, isDirty: true, isTouched: true } })
                            }}
                        >
                            {SearchOperatorAvailableValues.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div style={{ width: 'auto' }}></div>
                        <Autocomplete
                            multiple
                            id="label-search-filter-value"
                            freeSolo
                            style={{width: '50%'}}
                            options={props.classFilterOptions}
                            value={searchValueState}
                            onChange={(event, value) => {
                                setSearchValueState([...value])
                            }}
                            renderTags={(value, getTagProps) => {
                                return value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        className={`${classes.chip}`}
                                        classes={{ root: classes.autoCompleteChipRoot }}
                                        label={
                                            <Tooltip title={option}>
                                                <span>
                                                    {index + 1}:
                                                    {props.classFilterOptions.filter(
                                                        (classValue) =>
                                                            classValue.toUpperCase() === option.toUpperCase()
                                                    )[0] || option}
                                                </span>
                                            </Tooltip>
                                        }
                                        {...getTagProps({ index })}
                                    />
                                ));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className={classes.floating}
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label="Label Filter Value"
                                    placeholder="Add Label Filter"
                                    onChange={(e) => {
                                        if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                            setSearchValueState([...searchValueState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                            const val = e.target.value.replaceAll(',','');
                                            setSearchValueState([...searchValueState, val])
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>

                    {!props.isSegmentationModelAnalyticsEnabled && <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <TextField
                            size="small"
                            style={{ width: '49%' }}
                            variant="outlined"
                            select
                            label="Status Search Operator"
                            className={`${classes.floating}`}
                            value={statusOperatorState.value}
                            onChange={(e) => {
                                setStatusOperatorState(oldState => { return { ...oldState, value: e.target.value as ResourceLabelFilterOperator, isDirty: true, isTouched: true } })
                            }}
                        >
                            {SearchOperatorAvailableValues.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div style={{ width: 'auto' }}></div>
                        <Autocomplete
                            multiple
                            id="label-search-filter-value"
                            freeSolo
                            style={{ width: '50%' }}
                            options={props.statusFilterOptions}
                            value={statusSearchValueState}
                            onChange={(event, value) => {
                                setStatusSearchValueState([...value])
                            }}
                            renderTags={(value, getTagProps) => {
                                return value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        className={`${classes.chip}`}
                                        classes={{ root: classes.autoCompleteChipRoot }}
                                        label={
                                            <Tooltip title={option}>
                                                <span>
                                                    {index + 1}:
                                                    {props.statusFilterOptions.filter(
                                                        (classValue) =>
                                                            classValue === option
                                                    )[0] || option}
                                                </span>
                                            </Tooltip>
                                        }
                                        {...getTagProps({ index })}
                                    />
                                ));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className={classes.floating}
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label="Predicted Status Value"
                                    placeholder="Add Predicted Status Filter"
                                    onChange={(e) => {
                                        if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                            setStatusSearchValueState([...searchValueState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                            const val = e.target.value.replaceAll(',', '');
                                            setStatusSearchValueState([...searchValueState, val])
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
}
                    <div id="resetFilterButtonContainer"
                        style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}
                    >
                        <div></div>
                        <Button variant="contained" color="primary"
                            onClick={() => {
                                props.onApplyFilterButtonClick(searchOperatorState.value, searchValueState, statusOperatorState.value, statusSearchValueState)
                            }}
                        >
                            Apply Filters
                        </Button>
                    </div>
                </Box>
            )}
        </Popover>
    );
}

function ConfidenceScoreColumnMenu(props: {
    open: boolean,
    minValue: number,
    maxValue: number,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetFilters: () => void
    isFilterApplied: boolean
    onApplyFilterButtonClick: (min: number, max: number) => void
}) {
    const classes = useStyles();
    const [sliderFieldState, setSliderFieldState] = useState<InputFieldState<[number, number]>>({ value: [props.minValue, props.maxValue] })

    return (
        <Popover
            id="confidenceScore-column-menu"
            anchorEl={document.getElementById("confidenceScoreColumnOptions")}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                style: {
                    width: "450px",
                },
            }}
        >
            {props.open && (
                <Box id="confidenceScoreMenuItemContainer" style={{ padding: 15 }}>
                    <div className={`${classes.tableColumnMenuHeading}`}>
                        <Typography
                            variant="body1"
                            style={{ marginBottom: 20 }}
                            color="primary"
                        >
                            &#8288;Confidence Score Column Filter
                        </Typography>
                        <Tooltip title="Reset Filters">
                            <Box>
                                <TableColumnResetFilterButton
                                    onClick={() => props.resetFilters()}
                                    disabled={!props.isFilterApplied}
                                />
                            </Box>
                        </Tooltip>
                    </div>
                    <div id="rangeSection">
                        <div>Confidence score range</div>
                        <div
                            id="rangeInputFieldWrapper"
                            style={{
                                width: "calc(95% - 15px)",
                                marginLeft: "15px",
                            }}
                        >
                            <Slider
                                value={sliderFieldState.value}
                                min={0}
                                max={100}
                                color="primary"
                                step={1}
                                marks={[
                                    { value: 0, label: "0%" },
                                    { value: 50, label: "50%" },
                                    { value: 100, label: "100%" },
                                ]}

                                // @ts-ignore
                                onChange={(e, value: [number, number]) => {
                                    setSliderFieldState(oldState => {
                                        return {
                                            ...oldState, isTouched: true, isDirty: true,
                                            value: [value[0], value[1]]
                                        }
                                    })
                                }}

                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                            />
                        </div>
                    </div>

                    <div id="resetFilterButtonContainer"
                        style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}
                    >
                        <div></div>
                        <Button variant="contained" color="primary"
                            onClick={() => {
                                props.onApplyFilterButtonClick(sliderFieldState.value[0], sliderFieldState.value[1])
                                
                            }}
                            disabled={!sliderFieldState.isDirty}
                        >
                            Apply Filters
                        </Button>
                    </div>
                </Box>
            )}
        </Popover>
    );
}

function WordErrorRateColumnMenu(props: {
    open: boolean,
    minValue: number,
    maxValue: number,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetFilters: () => void
    isFilterApplied: boolean
    onApplyFilterButtonClick: (min: number, max: number) => void
}) {
    const classes = useStyles();
    const [sliderFieldState, setSliderFieldState] = useState<InputFieldState<[number, number]>>({ value: [props.minValue, props.maxValue] })

    return (
        <Popover
            id="wordErrorRate-column-menu"
            anchorEl={document.getElementById("wordErrorRateColumnOptions")}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                style: {
                    width: "450px",
                },
            }}
        >
            {props.open && (
                <Box id="wordErrorRateMenuItemContainer" style={{ padding: 15 }}>
                    <div className={`${classes.tableColumnMenuHeading}`}>
                        <Typography
                            variant="body1"
                            style={{ marginBottom: 20 }}
                            color="primary"
                        >
                            &#8288;Word Error Rate Column Filter
                        </Typography>
                        <Tooltip title="Reset Filters">
                            <Box>
                                <TableColumnResetFilterButton onClick={() => props.resetFilters()} disabled={!props.isFilterApplied}/>
                            </Box>
                        </Tooltip>
                    </div>
                    <div id="rangeSection">
                        <div>Word Error Rate range</div>
                        <div
                            id="rangeInputFieldWrapper"
                            style={{
                                width: "calc(95% - 15px)",
                                marginLeft: "15px",
                            }}
                        >
                            <Slider
                                value={sliderFieldState.value}
                                min={0}
                                max={100}
                                color="primary"
                                step={1}
                                marks={[
                                    { value: 0, label: "0%" },
                                    { value: 50, label: "50%" },
                                    { value: 100, label: "100%" },
                                ]}

                                // @ts-ignore
                                onChange={(e, value: [number, number]) => {
                                    setSliderFieldState(oldState => {
                                        return {
                                            ...oldState, isTouched: true, isDirty: true,
                                            value: [value[0], value[1]]
                                        }
                                    })
                                }}

                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                            />
                        </div>
                    </div>

                    <div id="resetFilterButtonContainer"
                        style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}
                    >
                        <div></div>
                        <Button variant="contained" color="primary"
                            onClick={() => {
                                props.onApplyFilterButtonClick(sliderFieldState.value[0], sliderFieldState.value[1])
                            }}
                            disabled={!sliderFieldState.isDirty}
                        >
                            Apply Filters
                        </Button>
                    </div>
                </Box>
            )}
        </Popover>
    );
}


function IOUScoreColumnMenu(props: {

    open: boolean,
    minValue: number,
    maxValue: number,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetFilters: () => void
    isFilterApplied: boolean
    onApplyFilterButtonClick: (min: number, max: number) => void
}) {
   console.log(props.minValue,props.maxValue,"minValue")
    const classes = useStyles();
    const [sliderFieldState, setSliderFieldState] = useState<InputFieldState<[number, number]>>({ value: [props.minValue, props.maxValue] })
   
    return (
        <Popover
            id="confidenceScore-column-menu"
            anchorEl={document.getElementById("iouScoreColumnOptions")}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                style: {
                    width: "450px",
                },
            }}
        >
            {props.open && (
                <Box id="confidenceScoreMenuItemContainer" style={{ padding: 15 }}>
                    <div className={`${classes.tableColumnMenuHeading}`}>
                        <Typography
                            variant="body1"
                            style={{ marginBottom: 20 }}
                            color="primary"
                        >
                            &#8288;IOU Score Column Filter
                        </Typography>
                        <Tooltip title="Reset Filters">
                            <Box>
                                <TableColumnResetFilterButton
                                    onClick={() => props.resetFilters()}
                                    disabled={!props.isFilterApplied}
                                />
                            </Box>
                        </Tooltip>
                    </div>
                    <div id="rangeSection">
                        <div>IOU score range</div>
                        <div
                            id="rangeInputFieldWrapper"
                            style={{
                                width: "calc(95% - 15px)",
                                marginLeft: "15px",
                            }}
                        >
                         <Slider
    value={sliderFieldState.value}
    min={0}
    max={1}
    color="primary"
    step={0.1}  // Adjust the step value for more granular control
    marks={[
        { value: 0, label: "0" },
        { value: 0.5, label: "0.5" },
        { value: 1, label: "1" },
    ]}
    onChange={(e, value: number | number[]) => {
        setSliderFieldState((oldState: InputFieldState<[number, number]>) => ({
            ...oldState,
            isTouched: true,
            isDirty: true,
            value: Array.isArray(value) ? [value[0], value[1]] : [value, value],
        }));
    }}
    valueLabelDisplay="auto"
    aria-labelledby="range-slider"
/>

                        </div>
                    </div>

                    <div id="resetFilterButtonContainer"
                        style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}
                    >
                        <div></div>
                        <Button variant="contained" color="primary"
                            onClick={() => {
                                props.onApplyFilterButtonClick(sliderFieldState.value[0], sliderFieldState.value[1])
                    
                            }}
                            disabled={!sliderFieldState.isDirty}
                        >
                            Apply Filters
                        </Button>
                    </div>
                </Box>
            )}
        </Popover>
    );
}
export function PredictedStatusColumnMenu(props: {
    open: boolean,
    statusFilterValue: string,
    statusOptions: Array<string>;
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetFilters: () => void
    isFilterApplied: boolean
    onApplyFilterButtonClick: (filterValue: string) => void
}) {
    const classes = useStyles();
    const [statusValueState, setStatusValueState] = useState<string>(props.statusFilterValue);

    return (
        <Popover
            id="name-column-menu"
            anchorEl={document.getElementById("predictedStatusOptions")}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                style: {
                    width: "330px",
                },
            }}
        >
            {props.open && (
                <Box id="nameMenuItemContainer" style={{ padding: 15 }}>
                    <div className={`${classes.tableColumnMenuHeading}`}>
                        <Typography
                            variant="body1"
                            style={{ marginBottom: 20 }}
                            color="primary"
                        >
                            &#8288;Predicted Status Column Filter
                        </Typography>
                        <Tooltip title="Reset Filters">
                            <Box>
                                <TableColumnResetFilterButton
                                    onClick={() => props.resetFilters()}
                                    disabled={!props.isFilterApplied}
                                />
                            </Box>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                            variant="body1"
                        >Status
                        </Typography>
                        <div style={{ width: 'auto' }}></div>
                        <Autocomplete
                            id="sort_by_field"
                            freeSolo
                            style={{ width: '75%' }}
                            options={props.statusOptions}
                            value={props.statusFilterValue}
                            onChange={(event, value) => {
                                setStatusValueState(value || '')
                            }}
                            renderTags={(value, getTagProps) => {
                                return value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        className={`${classes.chip}`}
                                        classes={{ root: classes.autoCompleteChipRoot }}
                                        label={<Tooltip title={option}>
                                            <span>
                                                {index + 1}:
                                                {props.statusOptions.filter(
                                                    (sortByFilter) => sortByFilter === option.toString()
                                                )[0]}
                                            </span>
                                        </Tooltip>}
                                        {...getTagProps({ index })} />
                                ));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    value={props.statusFilterValue}
                                    className={classes.floating}
                                />
                            )} />
                    </div>

                    <div id="resetFilterButtonContainer"
                        style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}
                    >
                        <div></div>
                        <Button variant="contained" color="primary"
                            onClick={() => {
                                props.onApplyFilterButtonClick(statusValueState)
                            }}
                        >
                            Apply Filters
                        </Button>
                    </div>
                </Box>
            )}
        </Popover>
    );
}

function TextResourceInListViewColumn(props: {
    resourceText: string
}) {
    const resourceText = props.resourceText;
    return (
        <div
            style={{
                // width: "139px",
                width: "100%",
                overflow: "auto",
                // height: "60px",
                height: "31px",
                wordBreak: "break-word"
            }}
        >{resourceText}</div>
    )
}
function TextResourceInGridViewColumn(props: {
    resourceText: string
}) {
    const resourceText = props.resourceText;
    return (
        <div
            style={{
                // width: "141px",
                width: "125px",
                overflow: "auto",
                height: "86px",
                wordBreak: "break-word"
            }}
        >{resourceText}</div>
    )
}


export default AnalyticsTable;
