import React, { useEffect, useState, useRef, useContext } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Chip,
    Tooltip,
    CircularProgress,
    Link,
} from "@material-ui/core";
import { ReactComponent as CLONESVG } from "../../../../../../../assets/svg/clone.svg";
import AiMarketPlaceCSS from '../aiMarketPlace.module.css';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { loaders } from "../../../../../../../common/styles/loaders";
import { IGenerateRedictionLinkForTestAndTrainDatasets, IGetModelCollectionResponse, IHyperParameterList, IHyperParameters } from "../aiMarketPlaceInterfaces/interfaces";
import BrowseCollectionsPopup from "../../models/features/model/features/collections/components/BrowseCollectionsPopup";
import BrowseCollectionsPopupContext from "../../models/browseCollectionsPopupContext/browseCollectionsPopup.context";

const formatDateTime = (timestamp: string) => {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} | ${hours}:${minutes}:${seconds} Local Time`;
}
const formatNumberOfParams = (numParams: string | number) => {
    let num = parseFloat(numParams.toString());
    if (isNaN(num)) {
        return 'NA';
    }

    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    loader: {
        ...loaders.centralLoader
    }
}));
const generateRedictionLinkForTestAndTrainDatasets = ({ projectName = "", modelName = "", projectId = "", modelId = "", modelType = "", dataSetName = "" }: IGenerateRedictionLinkForTestAndTrainDatasets) => {
    if (!projectName || !modelName || !projectId || !modelId || !modelType || !dataSetName) {
        return "NA";
    }
    return (
        `${window.location.origin}/admin/projects/${projectName}/${modelName}/data/data-set?resourceStatus=collections&resourceOffset=1&resourceLimit=10&project=${projectId}&model=${modelId}&type=${modelType}&nameSearchValue=${dataSetName}&nameSearchOperator=equals`
    )
}



const AIMarketPlaceViewModel = (props: any) => {
    const { isFetching, viewModelPageData,scenario } = props
    const classes = useStyles();
    const dataCartContext = useContext(BrowseCollectionsPopupContext)
    const originalSearchQueryRef = useRef(window.location.search)
    const [isDataCartOpen, setIsDataCartOpen] = useState<boolean>(false);
    const [hyperParametersList, setHyperParametersList] = useState<IHyperParameterList[]>([]);
    const [TagsList, setTagList] = useState([])
    const [modelCollectionDetailsData, setModelCollectionDetailsData] = useState<IGetModelCollectionResponse | undefined>()
    const [isFetchingDataCartState, setIsFetchingDataCartState] = useState<boolean>(false)


    useEffect(() => {
        // Update hyperParametersList when isFetching is true
        setModelCollectionDetailsData(viewModelPageData)
        if (isFetching && viewModelPageData?.purposeModel?.hyperParameters) {
            const updatedList = Object.keys(viewModelPageData?.purposeModel?.hyperParameters).map((key) => {
                const item = viewModelPageData.purposeModel.hyperParameters[key];
                return {
                    label: item.display_name,
                    value: item.value || "NA"
                };
            });
            setHyperParametersList(updatedList);

        }
        if (isFetching && viewModelPageData?.purposeModel?.tags) {
            setTagList(viewModelPageData?.purposeModel?.tags)
        }
    }, [isFetching, viewModelPageData, hyperParametersList]);


    const fetchInitialDataOfDataCart = async () => {
            setIsFetchingDataCartState(true);
            await dataCartContext.setAiMarketplaceDataCartInitialStateFromDB();
            setIsFetchingDataCartState(false)
    }

    useEffect(() => {
        if(!isFetchingDataCartState){
            fetchInitialDataOfDataCart();
        }
    }, [originalSearchQueryRef.current])

    const safeGetValue = (data: any, fallback: string) => (data ? data : fallback);
    const isGeneralPurposeModel = viewModelPageData?.purposeModel?.purposeType === "general" ? true : false
    const yearOfRelease = isGeneralPurposeModel ? `${viewModelPageData.purposeModel?.yearOfRelease || 'NA'}`
        : viewModelPageData?.created_at?.substring(0, 4) || 'NA';
    // const TagsList = viewModelPageData?.purposeModel.tags?.length ? viewModelPageData?.purposeModel.tags : []  
    console.log({ TagsList })
    const authorName = isGeneralPurposeModel ? viewModelPageData?.purposeModel?.createdByUser?.emailId || "NA" : viewModelPageData?.created_by || "NA";
    const testDataSetName = isGeneralPurposeModel ? viewModelPageData?.purposeModel?.testDataSet?.label : viewModelPageData?.checkpointFiles?.files?.analysis?.dataSetCollection?.name;
    const testDataSetLink = isGeneralPurposeModel ? viewModelPageData?.purposeModel?.testDataSet?.link : generateRedictionLinkForTestAndTrainDatasets(
        {
            projectName: viewModelPageData?.project?.name,
            modelName: viewModelPageData?.model?.name,
            projectId: viewModelPageData?.project?._id,
            modelId: viewModelPageData?.model?._id,
            modelType: viewModelPageData?.model?.type,
            dataSetName: testDataSetName
        }
    )
    const trainDataSetName = isGeneralPurposeModel ? "" : viewModelPageData?.trainingDataDirectory?.[0] || "NA"
    const trainDataSetLink = isGeneralPurposeModel ? "" :generateRedictionLinkForTestAndTrainDatasets(
        {
            projectName: viewModelPageData?.project?.name,
            modelName: viewModelPageData?.model?.name,
            projectId: viewModelPageData?.project?._id,
            modelId: viewModelPageData?.model?._id,
            modelType: viewModelPageData?.model?.type,
            dataSetName: viewModelPageData?.trainingDataDirectory?.[0]
        }
    )
    const modelInfoList = [
        {
            label: 'Name',
            value: safeGetValue(viewModelPageData?.purposeModel?.modelName, "NA")
        },
        {
            label: 'Year of Release',
            value: safeGetValue(yearOfRelease, "NA")
        },
        {
            label: 'Num Parameters',
            value: formatNumberOfParams(safeGetValue(viewModelPageData?.numberOfModelParams?.toString(), "NA"))
        },
        {
            label: 'Inference hardware',
            value: viewModelPageData && viewModelPageData?.purposeModel?.inferenceHardwareGPUCount && viewModelPageData?.purposeModel?.inferenceHardware
                ? `${viewModelPageData.purposeModel.inferenceHardwareGPUCount}x${viewModelPageData.purposeModel.inferenceHardware}`
                : "NA"
        },
        {
            label: 'Inference time (ms)',
            value: 'NA'
        },
        {
            label: 'References',
            value: viewModelPageData && viewModelPageData?.purposeModel?.references || []
        },
    ]


    const onClickTryOutButton = () => {
        setIsDataCartOpen(true);
    }


    return (
        <Box className={AiMarketPlaceCSS.viewModelPageContainer}>
            {isFetching ? <Box className={classes.loader}>
                <CircularProgress size={30} />
            </Box> :
                <Box display="flex" flexDirection="column">
                    <Box className={AiMarketPlaceCSS.columnFlex} style={{ gap: '12px' ,order:2}}>
                        <Box className={AiMarketPlaceCSS.rowFlex} style={{ gap: '12px' }}>
                            <Typography className={AiMarketPlaceCSS.viewModelSubHeading}>{safeGetValue(viewModelPageData?.purposeModel?.modelName, "NA")}</Typography>
                            {isGeneralPurposeModel ?
                                <Chip className={AiMarketPlaceCSS.generalPurposeType} label={"General"} />
                                :
                                <Chip className={AiMarketPlaceCSS.specificPurposeType} label={"Specific"} />
                            }
                        </Box>
                        <Box className={AiMarketPlaceCSS.rowFlex} style={{ gap: '8px' }}>
                            {TagsList.length > 0 && TagsList.map((item: { value: string }) => <Chip size="small" variant="default" label={item?.value || "NA"} className={AiMarketPlaceCSS.Customchip} />)}
                        </Box>
                        <Typography variant="subtitle2" style={{ color: "#838C95" }}>
                            Description : <span style={{ fontWeight: 'bold' }}>
                                {isGeneralPurposeModel ? safeGetValue(viewModelPageData?.purposeModel?.modelDescription, "NA") : safeGetValue(viewModelPageData?.modelDescription, "NA")}
                            </span>
                        </Typography>
                        <Typography variant="subtitle2" style={{ color: "#838C95" }}>Project : <span style={{ fontWeight: 'bold' }}>{safeGetValue(viewModelPageData?.project?.name, "NA")}</span></Typography>
                        <Typography variant="subtitle2" style={{ color: "#838C95" }}>Last Modified : <span style={{ fontWeight: 'bold' }}>
                            {isGeneralPurposeModel ? formatDateTime(safeGetValue(viewModelPageData?.purposeModel?.updated_at, "NA")) : formatDateTime(viewModelPageData?.updated_at)}
                        </span>
                        </Typography>
                        {authorName !== "NA" && <Typography variant="subtitle2" style={{ color: "#838C95" }}>Author : <span style={{ fontWeight: 'bold' }}>{authorName}</span></Typography>}
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={4} style={{ gap: '1rem', paddingBottom: '70px',flexDirection: scenario==="homepageChatAssistant"?'column':'row' ,order:3}}>
                        {/*Model Info */}
                        <Box >
                            <Typography variant="subtitle2" style={{ marginBottom: '8px', color: "#838C95" }}>Model Info</Typography>
                            <TableContainer component={Paper} style={{width:'fit-content'}}>
                                <Table size="small" aria-label="Info table">
                                    <TableBody>
                                        {modelInfoList.map((eachItem) =>
                                            <TableRow>
                                                <TableCell style={{
                                                    fontWeight: 600,
                                                    backgroundColor: "#F9FAFB",
                                                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                                    borderLeft: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '4px',
                                                    whiteSpace: "nowrap"
                                                }}>
                                                    {eachItem.label}
                                                </TableCell>
                                                <TableCell style={{
                                                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                                    whiteSpace: "nowrap"
                                                }}>
                                                    {eachItem.label === 'References' ? (
                                                        eachItem.value.length > 0 ? (
                                                            eachItem.value.map((ref: any, idx: number) => (
                                                                <a key={idx} href={ref.link} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', textDecoration: 'underline', color: "rgb(0, 141, 156)" }}>
                                                                    {ref.label}
                                                                </a>
                                                            ))
                                                        ) : 'NA'
                                                    ) : (
                                                        eachItem.value
                                                    )}
                                                </TableCell>
                                            </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        {/* hyperParameter */}
                        <Box>
                            <Typography variant="subtitle2" style={{ marginBottom: '8px', color: "#838C95" }}>
                                Hyper Parameters
                            </Typography>
                            <TableContainer component={Paper} style={{width:'fit-content'}}>
                                <Table size="small" aria-label="Hyper Parameters table">
                                    <TableBody>
                                        {hyperParametersList && hyperParametersList.map((eachItem: IHyperParameterList) =>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        color: "black",
                                                        fontWeight: 600,
                                                        backgroundColor: "#F9FAFB",
                                                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderLeft: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '4px', whiteSpace: 'nowrap'
                                                    }}
                                                >{eachItem.label}</TableCell>
                                                <TableCell
                                                    style={{
                                                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderRadius: '4px'
                                                    }}>{eachItem.value}</TableCell>
                                            </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        {/* datasetUsed */}
                        <Box>
                            <Typography variant="subtitle2" style={{ marginBottom: '8px', color: "#838C95" }}>Dataset Used</Typography>
                            {/* training datast only for specific  */}
                            {!isGeneralPurposeModel && <Box style={{
                                border: "1px solid #E5E7EB", backgroundColor: "#FFFFFF",
                                borderRadius: '8px', minWidth: '300px', boxShadow: "0px 2px 8px 0px #0000000D",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '1rem',
                                padding: '8px',
                                marginBottom: '0.5rem',
                            }}>
                                <Box style={{
                                    border: "1px solid #E5E7EB", backgroundColor: "#FFFFFF",
                                    borderRadius: '4px', padding: '10px', boxShadow: "0px 2px 8px 0px #0000000D",
                                    borderLeft: "3px solid #008D9C",
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    width: '100%',
                                    gap: '1.5rem'
                                }}>
                                    <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>Training Dataset</Typography>
                                    <Link href={trainDataSetLink} target="_blank" style={{ opacity: '0.7', fontSize: '12px', whiteSpace: 'nowrap', color: "rgb(0, 141, 156)", textDecoration: 'underline' }}>
                                        {trainDataSetName}
                                    </Link>
                                </Box>
                            </Box>}
                            {/* testing dataset */}
                            <Box style={{
                                border: "1px solid #E5E7EB", backgroundColor: "#FFFFFF",
                                borderRadius: '8px', minWidth: '300px', boxShadow: "0px 2px 8px 0px #0000000D",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '1rem',
                                padding: '8px',
                                width:'fit-content'
                            }}>
                                <Box style={{
                                    border: "1px solid #E5E7EB", backgroundColor: "#FFFFFF",
                                    borderRadius: '4px', padding: '10px', boxShadow: "0px 2px 8px 0px #0000000D",
                                    borderLeft: "3px solid #008D9C",
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    width: '100%',
                                    gap: '1.5rem'
                                }}>
                                    <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>Testing Dataset</Typography>
                                    <Link href={testDataSetLink} target="_blank" style={{ opacity: '0.7', fontSize: '12px', whiteSpace: 'nowrap', color: "rgb(0, 141, 156)", textDecoration: 'underline' }}>
                                        {testDataSetName}
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${AiMarketPlaceCSS.rowFlex} ${AiMarketPlaceCSS.viewModelFooter} ${scenario==="homepageChatAssistant"&& AiMarketPlaceCSS.homepageChatViewModelHeaderCss}`} style={{order:scenario==="homepageChatAssistant"?1:4}}>
                        <Box className={AiMarketPlaceCSS.rowFlex} style={{ gap: '10px' }}>
                            <BookmarkBorderOutlinedIcon style={{ color: "#4D4D4D" }} />
                            <Typography variant="subtitle2" style={{ color: "#4D4D4D" }}>Bookmark</Typography>
                        </Box>
                        <Box className={AiMarketPlaceCSS.rowFlex} style={{ gap: '10px' }}>
                            <ArrowOutwardOutlinedIcon style={{ color: "#4D4D4D" }} />
                            <Typography variant="subtitle2" style={{ color: "#4D4D4D" }}>Visit training pod</Typography>
                        </Box>
                        <Button variant="outlined" startIcon={<CLONESVG />} style={{ textTransform: 'capitalize' }}>
                            Clone pod
                        </Button>
                        <Button variant="contained" color="primary" startIcon={scenario==="homepageChatAssistant"?"":<AutorenewOutlinedIcon />} style={{ textTransform: 'capitalize' }} onClick={onClickTryOutButton} disabled={isFetchingDataCartState}>
                            {scenario==="homepageChatAssistant"?"Test Drive":"Try out"}
                        </Button>
                    </Box>
                    <BrowseCollectionsPopup
                        isOpen={isDataCartOpen}
                        setOpen={setIsDataCartOpen}
                        resourceDataSetCollectionIds={[]}
                        originalSearchQuery={originalSearchQueryRef.current}
                        setDataCartOpen={() => { }}
                        setSavedForLaterOpen={() => { }}
                        scenario='ai-marketplace-datacart'
                    />
                </Box>}
        </Box>
    );
};

export default AIMarketPlaceViewModel