import React, { Dispatch, SetStateAction, useState, useRef, useMemo, ChangeEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import { Paper, Card, Button, Grid , Tooltip, CardContent, CardActions, Chip, SvgIcon, CircularProgress, Typography, Backdrop, Switch, Dialog, DialogTitle, DialogContent, Slider, PaperProps, DialogContentText, DialogActions, IconButton, Box, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
// import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Color_Palatte from './Images/Color_Palatte.png';
import { useHistory, useLocation } from "react-router";
import { useEffect, useCallback } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { getResources, removeCoPilotResourceFromUI, updateCoPilotResource, updateSelectedCoPilotResource, setCoPilotResourceData, getResourceById, updateSelectedTab, updateCopilotResourceImageAnnotationsInDB, setCoPilotResourcesOnly, setCoPilotResourcesTotalCountOnly, updateCopilotResourceTrimmedTextsInUI } from '../../../../../../../store/actions/coPilotDetailPageActions';
import {  IReduxState, Status, convertDate, imageAnnotationReferenceVideo, textAnnotationReferenceVideo, ModelAnnotationLabelAvailable, IModelType, IReferenceDataState, ownerAndMinorStationCartMotionDetectionCopilotReferenceImage, AIHandObjectDetectHumanCopilotReferenceImage, OwensAndMinorRacksMotionVideoIndiaUS_1, OwensAndMinorRacksMotionVideoIndiaUS_2, owensAndMinorStationCartVideoMotionCopilotReferenceVideo_Useful, owensAndMinorStationCartVideoMotionCopilotReferenceVideo_Useless, avantorTrayDetectionCopilotReferenceImage, avantorTrayDetectionCopilotReference_TrayPresentPerfectlyAnnotated, avantorTrayDetectionCopilotReference_TrayPresentImPerfectlyAnnotated, avantorTrayDetectionCopilotReference_TrayNotPresentDetected, avantorTrayDetectionCopilotReference_TrayNotPresentNotDetected, avantorCassetteDetectionCopilotReference_LTCassette, avantorCassetteDetectionCopilotReference_RTCassette, avantorCassetteDetectionCopilotReference_Missing, avantorCassetteDetectionCopilotReference_PerfectVideo, avantorCassetteDetectionCopilotReference_MissingVideo, avantorCassetteDetectionCopilotReference_Cassette, owensAndMinorPutinPutoutIndiaVideoCopilotReference_Putin, owensAndMinorPutinPutoutIndiaVideoCopilotReference_Putout, fredrempShadowDefectDetectionCopilotReferenceImage, owensAndMinorStationCartSAMDetectionReferenceVideo, owensAndMinorRacksMeaningfulMotionDetection_useful, owensAndMinorRacksMeaningfulMotionDetection_useless, IReferenceGroupState } from '../../../../../../../common';
import { updateIsDataFetching } from '../../../../../../../store/actions/coPilotDetailPageActions';
import { ResourceList } from './components/resourceList/resourceList';
import ColorSelector from './components/ColorSelector';
import DrawerButton from '../components/DrawerButton';
import OnRejectModal from './components/Modal/OnRejectModal';
import { labelExistsInBreadcrumb } from '../../../../../../../common/utils/breadcrumb';
import { addBreadcrumbs, setTopActionButton } from '../../../../../../../store/actions';
import copilotService from '../../../../../../../services/copilotService';
import { ICoPilotResource, CoPilotResourceTimerEndAt, ITrimmedAudioAnnotation } from '../../../../../../../common/constants/interfaces/coPilotResource';
// import { ICoPilotDetailPageState } from '../../../../../../../common/constants/interfaces/coPilotDetailPage';
import { setSideDrawer } from '../../../../../../../store/actions/optionsDrawerActions';
import { convertURLQueryParamsToJSON } from '../../../../../../../services/urlQueryParamService';
import { InputFieldState } from '../../../../../../../common/constants/interfaces/inputFieldState';
import { AnnotationComponentAnnotationChangeData, AnnotationComponentSaveClickData, AnnotationOption, AnnotationOptionChildren, defaultAnnotationOptions, setResourceImageAnnotationsForSendingToAnnotationComponent, setResourceImageAnnotationsOfOriginalImageForCompressedImage, setResourceImageAnnotationsOfCompressedImageForOriginalImage } from '../../../../../../../services/imageAnnotationHelperService';
import { copyByValue, isNullOrUndefined } from '../../../../../../../services/variableHelperService';
import styles from "./components/resource/resource.module.scss";
// import ImageAspectRatioScaler from '../../../../../../../common/components/ImageAspectRatioScaler';
import ImageAspectRatioScaler from '../../models/features/model/features/data/components/imageAspectRatioScalerWithAlignment.component';
import clsx from 'clsx';
import { TwoDimensionalImage } from '../../../../../../../assets/components/react-annotation-tool/src/apps/two-dimensional-image';
import { getImageScaleFactorForImageWidth } from '../../../../../../../services/imageHelperService';
import { IData, ResourceImageAnnotation, TrimmedAudio } from '../../../../../../../common/constants/interfaces/data';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { useTimeInterval } from '../../../../../../../hooks/useTimeInterval';
import FileNameClipper from '../../../../../../../common/components/FileNameClipper';
import { Alert } from '@material-ui/lab';
// @ts-ignore
import { SOCKET_EVENT_CO_PILOT_RESOURCE_CREATED, SOCKET_EVENT_CO_PILOT_RESOURCE_CREATED_DATA, SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED, SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED_DATA } from '../../../../../../../common/constants/interfaces/socketEvents';
import { socket } from "../../../../../../../services/config";
import { ShowLessLink, ShowMoreLink } from './components/addRemark/addRemark'; 
import { SimpleModal } from './components/Modal/SimpleModal';
import Configuration from '../../models/features/model/features/configuration/Configuration';
import TextTrimUI from '../../models/features/model/features/data/components/TextTrimUI/textTrimUI';
import usePreviousValue from '../../../../../../../hooks/usePreviousValue';
import useImageOnLoad from '../../../../../../../hooks/useImageOnLoad';
import ImageService from '../../../../../../../services/ImageService';
import { CopilotService } from '../../../../../../../services';
import ErrorIcon from '@material-ui/icons/Error';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import css from "./CopilotDetail.module.css";
import { convertJsonToArrayOfKeyValuePair } from '../../../../../../../services/jsonHelperService';
import { Modal } from 'reactstrap';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { ImageRelatedModelReferenceData } from '../../../../../../../common/constants/interfaces/imageRelatedModelReferenceData';
import Draggable from 'react-draggable';
import CloseIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Cancel, CheckCircle, CheckCircleOutline, HighlightOffOutlined } from '@material-ui/icons';
import FullScreenCard from './FullScreenCard';
import {ReactComponent as IconZoomIn} from "../../../../../../../assets/svg/Icon-ZoomIn.svg"
import { SpectrogramAudioTwoDimensionalImage } from '../../../../../../../assets/components/react-annotation-tool/src/apps/spectrogram-audio-two-dimensional-image';
import { AudioTrimUI, TrimLabelWontGetSavedWarningAlert } from '../../models/features/model/features/data/components/AudioTrimUI/AudioTrimUI';
import { UniqueColorPicker } from '../../models/features/model/features/data/components/TextTrimUI/UniqueColorPicker';
import { convertMilliSecondsToSeconds } from '../../../../../../../services/timeHelperService';
// @ts-ignore
import WaveSurfer from "wavesurfer.js/dist/wavesurfer.min";
// @ts-ignore
import WaveSurferRegionPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";
// @ts-ignore
import WaveSurferTimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import axios from 'axios';

const socketConnection = socket.connection;

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: any;
//   value: any;
// }

type HandleSlideChangeFunctionType = (scenario: 'goToPreviousSlide' | 'goToNextSlide') => any

const AnnotatedImageWidthToSet = 450;
const MaxAnnotatedImageWidthAllowed = 448;
const MaxAnnotatedImageHeightAllowed = 370;

function EmptyDiv() {
  return (
    <div></div>
  )
}

const csvInitialMaxLengthLimit=10;

/*
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
*/

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textarea: {
    width: '100%',
    border:"1px solid #1094a2", 
    fontSize:'13.3px',
    borderRadius:'5px',
    ':focus' :{
      border:"2px solid #1094a2"
  }},
  detailsCardGridWhenCopilotTypeImageAnnotation: {
    maxHeight: "440px"
  },
  detailsCardGridWhenCopilotTypeTextAnnotation: {
    maxHeight: "415px"
  },
  details: {
    minWidth : '120px', 
    display:'inline-block', 
    marginBottom:'8px',
    fontSize: 'smaller'
  },
  tabs:{
    color:'#000000',
    '&:active':{
      color: '#0b8a96',
      outline: 'none'
    },
    '&:hover':{
      color: '#0b8a96',
      outline: 'none'
    },
    '&:focus':{
      color: '#0b8a96',
      outline: 'none'
    },
    '&:checked':{
      color: '#0b8a96',
      outline: 'none'
    },
    // '.MuiTab-textColorInherit':{
    //   color: '#000000'
    // }
  },
  itemCardCompRoot: {
    width: "100%"
  },
  itemCardCompRootWhenCopilotTypeImageAnnotationsAndEditAnnotationEnabled: {
    width: "115%"
  },
  itemCardPaperWhenCopilotTypeImage: {
    // width: "325px"
    // width: "400px"
    width: "350px",
    height: '61vh',
    minHeight: '446px',
    maxHeight: '530px'
  },
  actionsButtonRowWhenCopilotTypeImage: {
    marginTop: "10px"
  },  
  actionsButtonRowWhenCopilotTypeImageAnnotation: {
    maxWidth: "457px"
  },  
  itemCardPaperWhenCopilotTypeImageAndDetailsShowing: {
    // width: "590px"
    width: "655px",
    height: '61vh',
    maxHeight: '530px',
    minHeight: '446px'
  },
  itemCardGridWhenCopilotTypeImage: {
    // width: "320px"
    width: "100%"
  },  
  itemCardPaperWhenCopilotTypeImageAnnotation: {
    width: "510px",
  },
  itemCardPaperWhenCopilotTypeImageAnnotationAndDetailsShowing: {
    width: "815px"
  },
  itemCardPaperWhenCopilotTypeImageAnnotationAndEditAnnotationsEnabled: {
    // width: "940px"
    width: "1064px"
  },
  itemCardPaperWhenCopilotTypeTextAnnotationAndEditAnnotationsEnabled: {
    // width: "1000px"
    width: "1204px"
  },
  itemCardGridWhenCopilotTypeImageAnnotation: {
    width: "100%"
  },
  itemCardWhenCopilotTypeImage: {
    // backgroundColor:'#d4e8ea', 
    border: "none", 
    boxShadow: "none",
    // width: "277px",
    width: "100%",
    // height: "400px",
    // height: "100%",
    height: "100%",
    // padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px"    
  }, 
  itemCardWhenCopilotTypeVideo: {
    width: 500
  },
  itemCardWhenCopilotTypeImageAnnotation: {
    width: "100%",
    // height: "400px",
    // padding: "20px",
    display: "flex",
    flexDirection: "column",
    // gap: "15px",
    boxShadow: "none"    
  },
  cardLinksWhenCopilotTypeImageAnnotation: {
    marginBottom: "20px"
  },
  carouselContainerWhenCopilotTypeImage: {
    // height: "600px"
    // height: "483px"
    height: "100%"
  },
  imageAnnotationReferenceVideoWhenCardIsExpanded: {
    pointerEvents: "none"
  },
  imageAnnotationReferenceVideo: {
    width: "320px", 
    height: "350px", 
    marginTop: "-80px", 
    borderRadius: "10px"
  },
  textAnnotationReferenceVideo: {
    width: "320px",
    height: "255px",
    marginTop: "12px",
    borderRadius: "10px"
  },
  updateAlert: {
    left:"37%",
    top:"75%"
  },
  imageAnnotationUpdateAlert:{
    left:"46.5%",
    top:"77%"
  },
  cardDisabled: {
    pointerEvents: "none",
    opacity: 0.5
  },
  highlightedCard: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "5px 5px 20px #028D9A33",
    border: "0.5px solid #d8d8d8",
    borderRadius: "5px",
    opacity: 1
  },
  updatedHighlightedCard: {
    // background: "#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: "5px 5px 20px #028D9A33",
    // border: "0.5px solid #d8d8d8",
    borderRadius: "5px",
    opacity: 1
  },
  highlightedCardforVideo: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "5px 5px 20px #028D9A33",
    border: "0.5px solid #d8d8d8",
    borderRadius: "5px",
    opacity: 1,
    width: "fit-content"
  },
  remarkButton:{
    '&:active':{
      outline: 'none'
    },
    '&:hover':{
      outline: 'none'
    },
    '&:focus':{
      outline: 'none'
    },
    '&:checked':{
      outline: 'none'
    },
  },
  paperComponentWidth: {
    '@media(max-width: 1100px)': {
      width: '1100px'
    },
  },
  twoDimensionalImageWhenCopilotReadOnlyImageAnnotation: {
    display: "flex",
    justifyContent: "center"
  },
  referenceDataGutter: {
    display: 'flex',
    alignItems: 'center', 
    maxHeight: '65vh', 
    flexWrap: 'wrap', 
    overflowY: 'auto',
    [theme.breakpoints.up('xl')]: {
      maxHeight: '75vh'
    }
  },
  referenceDataItem: {
    margin: 'auto',
    marginTop: '8px',
    marginBottom: '8px',
    width: '80%',
    padding: '6px',
  },
  linearGradient: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "50px",
    background: "linear-gradient(180deg, #000000 0%, #00000000 100%) 0% 0%"
  },
  dialogCloseButton: {
    position: "absolute", 
    color: "#ffffff", 
    right: "0px", 
    cursor: "pointer", 
    padding: "10px",
    zIndex: 1000
  },
  dialogLeftArrowButton: {
    position: "absolute", 
    color: "#ffffff", 
    top: "45%",
    left: 0,
    cursor: "pointer", 
    padding: "10px",
    zIndex: 1000,
    background: "#0000004f"
  },
  dialogRightArrowButton: {
    position: "absolute", 
    color: "#ffffff", 
    top: "45%",
    right: 0,
    cursor: "pointer", 
    padding: "10px",
    zIndex: 1000,
    background: "#0000004f"
  },
  customControls: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    padding: 10
  },
  videoActionIcon: {
    color: "#ffffff"
  },
  dialogActionContainer: {
    background: "black",
    color: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0
  },
  timeLineSlider: {
    position: "absolute",
    width: "100%",
    marginLeft: 0,
    bottom: 25
  },
  track: {
    height: 4, // Adjust the height to make the track thicker
    borderRadius: 0, // Optional: Add border-radius for rounded corners
  },
  playPauseButtonOfOriginalAudio: {
    width: "40px",
    height: "40px",
    marginRight: "11px",
    cursor: "pointer"
  },
  trimmedAudiosTableHeadCell: {
    fontWeight: 600,
    // backgroundColor: "#f5f5f5"
  },
  trimmedAudiosTableCellCheckbox: {
    width: "20px"
  },
  trimmedAudiosTableCellAnnotationColor: {
    width: "158px"
  },
  trimmedAudiosTableCellStartTimeColor: {
    width: "72px"
  },
  trimmedAudiosTableCellEndTimeColor: {
    width: "67px"
  },
  trimmedAudiosTableCellActions: {
    width: "122px"
  },
  trimAudioTableRowDisabled: {
    opacity: 0.4,
    pointerEvents: "none"
  },
  trimmedAudiosTableDivContainer: {
    height: "calc(100% - 176px)"
  },
  trimmedAudiosTableDivContainerWhenUnsaveWaarningSignNotShowing: {
    height: "calc(100% - 124px)"
  }
}));

type urlSourceDataType = "image" | "video" | "text"

type SpectrogramAudioTrimUIProps = {
  resource: ICoPilotResource | IData;
  updateResourceInUI: (resourceDataToUpdate: ICoPilotResource) => any;
  copilotSpectrogramAudioAnnotation: TrimmedAudio [];
  copilotMaxAudioTrimAllowedInMilliSeconds: number; 
  selectedSpectrogramAnnotation: string;
  setSelectedSpectrogramAnnotation: React.Dispatch<React.SetStateAction<string>>;
  getSpectrogramCoordinatesFromAudioDuration: (newRegion: any, color: string) => Promise<void>
  setWidth: string
  elementId: number
 };

 type UITrimmedAudio = TrimmedAudio & {
  color: string
}

type WaveSurferRegionOptions = {
  id: string;
  start: number;
  end: number;
  color: string;
 };
 const trimAudioColors = ['rgba(0,255,81,1)', 'rgba(255,219,0,1)', 'rgba(255,0,0,1)', 'rgba(0,4,255,1)', 'rgba(227,0,255,1)', '#006600', '#d1e0e0', '#eea782', '#00ffff', '#800000', '#52527a'];

 const Annotation_Color_Opacity = 0.4;

export const SpectrogramAudioTrimUI = (props: SpectrogramAudioTrimUIProps) => {
  const classes = useStyles();
 const resource = props.resource;
 const projectId = new URLSearchParams(window.location.search).get("project") || "";
 const resourceTrimmedAudios = props.copilotSpectrogramAudioAnnotation || [];

 const wavesurferRef = useRef<any>(null);
 const arrBufferRef = useRef<any>(null);
 const audioBufferRef = useRef<any>(null);
 const updateResourceInUI = props.updateResourceInUI;
 const {copilotMaxAudioTrimAllowedInMilliSeconds} = props;
 const [trimmedAudios, setTrimmedAudios] = useState<UITrimmedAudio[]>([]);
 const [
  trimmedAudioIdsBeingDeletedFromDb,
  setTrimmedAudioIdsBeingDeletedFromDb,
 ] = useState<string[]>([]);
 const [trimmedAudioIdsBeingUpdatedInDb, setTrimmedAudioIdsBeingUpdatedInDb] =
  useState<string[]>([]);

 const [isResourceAudioPlaying, setIsResourceAudioPlaying] = useState(false);
 const [hasResourceIdChanged, setHasResourceIdChanged] = useState(false);
 const [originalAudioCurrentTime, setOriginalAudioCurrentTime] = useState(0);
 const [originalAudioTotalTime, setOriginalAudioTotalTime] = useState(0);
 const [trimAudioIdsSelectedInTable, setTrimAudioIdsSelectedInTable] = useState<string[]>([]);
 const [trimAudioIdsBeingClonedInDb, setTrimAudioIdsBeingClonedInDb] = useState<string[]>([]);
 const [modelIdSelectedToCloneInto, setModelIdSelectedToCloneInto] = useState("");
 const [isDeleteAllTrimAudiosProcessHappening, setIsDeleteAllTrimAudiosProcessHappening] = useState(false);
 const trimAudioColorPickerRef= useRef<UniqueColorPicker>(new UniqueColorPicker(trimAudioColors));

 const isWaveFormHTMLElementPresentInDom = useCallback(
  () => {
   const htmlElement = document.getElementById(`waveform${props.elementId}`);
   if (htmlElement) {
     return true;
   }
   return false;
  },
  [],
);

const setRegionTooltipTimeInDom = useCallback(
  (regionId: string, startTime: number, endTime: number) => {
    const regionElement = document.querySelector(`[data-id='${regionId}']`)
    if (regionElement) {
     //  @ts-ignore
     regionElement.title = `${(startTime||0).toFixed(2)}-${(endTime||0).toFixed(2)}`;
     //  @ts-ignore
      regionElement.style.opacity = 0.4;
    }
  },
  [],
); 

useEffect(() => {
  if (Array.isArray(trimmedAudios)) {
    for (const trimAudio of trimmedAudios) {
      setTimeout(() => {
        setRegionTooltipTimeInDom(trimAudio._id,trimAudio.startTimeInSeconds, trimAudio.endTimeInSeconds)
      }, 500);
    }
  }

}, [setRegionTooltipTimeInDom, trimmedAudios]);


const formatTimeCallback = (seconds: number, pxPerSec: number) => {
  seconds = Number(seconds);
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  // fill up seconds with zeroes
  let secondsStr = Math.round(seconds).toString();
  if (pxPerSec >= 25 * 10) {
      secondsStr = seconds.toFixed(2);
  } else if (pxPerSec >= 25 * 1) {
      secondsStr = seconds.toFixed(1);
  }

  if (minutes > 0) {
      if (seconds < 10) {
          secondsStr = '0' + secondsStr;
      }
      return `${minutes}:${secondsStr}`;
  }

  return secondsStr;
}

const timeInterval = (pxPerSec: number) => {
  let timeInterval = wavesurferRef.current.getDuration() * 0.02;
  // timeInterval = Number(timeInterval.toFixed(2));
  return timeInterval;
 }

 const readAudio = useCallback((file) => {
  return new Promise((resolve, reject) => {
   var reader = new FileReader();
   reader.readAsArrayBuffer(file);

   //Resolve if audio gets loaded
   reader.onload = function () {
    console.log("Audio Loaded");
    resolve(reader);
   };

   reader.onerror = function (error) {
    console.log("Error while reading audio");
    reject(error);
   };

   reader.onabort = function (abort) {
    console.log("Aborted");
    console.log(abort);
    reject(abort);
   };
  });
 }, []);

 const readAndDecodeAudio = useCallback(
  async (audioBlob) => {
   //Read the original Audio
   await readAudio(audioBlob)
    .then((results: any) => {
     arrBufferRef.current = results.result;
    })
    .catch((error: any) => {
     window.alert("Some Error occured");
     return;
    });

   //Decode the original Audio into audioBuffer
   await new AudioContext()
    .decodeAudioData(arrBufferRef.current)
    .then((res) => {
     audioBufferRef.current = res;
    })
    .catch((err) => {
     window.alert("Can't decode Audio");
     return;
    });
  },
  [readAudio]
 );

 const showSelectedResourceSection = useCallback(() => {
  // @ts-ignore
  document.getElementById("selectedResourceSection").style.display = "block";
 }, []);

 const handleTrimAudioUpdateInDB = useCallback(
  async (
   scenario: "createInDB" | "updateInDB",
   trimAudioId: string,
   trimAudioToUpdate: UITrimmedAudio
  ) => {

  },
  [resource, resourceTrimmedAudios, trimmedAudios, updateResourceInUI]
 );


 const createAudioRow = useCallback((arr) => {
  let tableRow = document.createElement("tr");
  tableRow.setAttribute("id", arr[0]);
  tableRow.setAttribute("class", "w3-hover-text-green");
  //tableRow.setAttribute("onmouseover", "highlightRegion('over','"+arr[0]+"')");
  //tableRow.setAttribute("onmouseleave", "highlightRegion('leave','"+arr[0]+"')");
  for (let i in arr) {
   let tableData;

   // @ts-ignore
   if (i === 0) {
    /** Not creating checkbox element as its not required */
    // tableData = document.createElement("input");
    // tableData.setAttribute("type", "checkbox");

    tableData = document.createElement("span");
    tableData.setAttribute("class", "w3-check w3-margin-left");
   } else {
    tableData = document.createElement("td");
    tableData.innerText = arr[i].toFixed(4);
   }
   tableData.setAttribute("id", arr[0] + i);
   tableRow.appendChild(tableData);
  }

  var actionsArray = [
   { action: "play", iconClass: "fa fa-play-circle-o" },
   // {"action":"download", "iconClass":"fa fa-download"},
   { action: "upload", iconClass: "fa fa-upload" },
   { action: "delete", iconClass: "fa fa-times" }
  ];
  for (let i = 0; i < actionsArray.length; i++) {
   var tableData = document.createElement("td");
   tableData.setAttribute("id", arr[0] + "-" + actionsArray[i].action);
   var dataIcon = document.createElement("button");
   dataIcon.setAttribute("title", actionsArray[i].action);
   dataIcon.setAttribute(
    "class",
    actionsArray[i].iconClass +
     " w3-button w3-white w3-border w3-border-light-green w3-round-large"
   );
   dataIcon.setAttribute("id", arr[0] + "-" + actionsArray[i].iconClass);
   dataIcon.setAttribute(
    "onClick",
    actionsArray[i].action + "Track('" + arr[0].toString() + "')"
   );
   tableData.appendChild(dataIcon);
   tableRow.appendChild(tableData);
  }
  return tableRow;
 }, []);

 const setPlayButton = useCallback(() => {
  // const icon: any = document.getElementById("play-pause-icon");
  // icon.className = "fa fa-play";
 }, []);

const loadAudio = useCallback(
  async (resource: ICoPilotResource | IData) => {

   setOriginalAudioCurrentTime(0.00);
   setOriginalAudioTotalTime(0.00);

   const audioDownloadURL = resource.resource;
 

   const audioBlobPromise = new Promise(async (resolve, reject) => {
    try {
     const apiResponse = await fetch(audioDownloadURL);
     const apiBlobResponse = apiResponse.blob();
     // resolve( new File([apiBlobResponse], resourceObj.filename));
     resolve(apiBlobResponse);
    } catch (error: any) {
     reject(new Error(error.message));
    }
   });

   const audioFile = await audioBlobPromise;


   if (wavesurferRef.current) {
    wavesurferRef.current.destroy();
   }

   if (!isWaveFormHTMLElementPresentInDom()) {
     return;
   }

   wavesurferRef.current = WaveSurfer.create({
    container: `#waveform${props.elementId}`,
    waveColor: "#d4e8e9",
    progressColor: "#008d9c",
    responsive: true,
    barWidth: 3,
    barRadius: 3,
    cursorWidth: 1,
    height: 100,
    barGap: 3,
    plugins: [
      WaveSurferRegionPlugin.create({}),
      WaveSurferTimelinePlugin.create({
        container: `#waveform-timeline${props.elementId}`,
        formatTimeCallback: formatTimeCallback,     // It is used for formatting the timeline notches
        timeInterval: timeInterval,                 // It is the gap between the secondaryLabelInterval (Note: To get the primaryLabelInterval multiple the timeInterval with 5)
        // primaryLabelInterval: 8,      // It tells after how much secondary bars primary bar is required
        secondaryLabelInterval: 10,   // No of secondary bars needed inbetween primary bars
      })
    ],
   });


   wavesurferRef.current.on("ready", function () {

    readAndDecodeAudio(audioFile);
    // preTrimUIChanges();
    const totalAudioDuration = wavesurferRef.current.getDuration();

    setOriginalAudioTotalTime(totalAudioDuration.toFixed(1));

    // wavesurferRef.current.enableDragSelection({
    //  maxLength: 0.5,
    // });

    const dragSelectionOptions: {
      maxLength?: number
    } = {
    }

    if (copilotMaxAudioTrimAllowedInMilliSeconds) {
      dragSelectionOptions.maxLength = convertMilliSecondsToSeconds(copilotMaxAudioTrimAllowedInMilliSeconds);
    }

    wavesurferRef.current.enableDragSelection(dragSelectionOptions);
    showSelectedResourceSection();

    const resourceTrimmedAudios = props.copilotSpectrogramAudioAnnotation;
    if (resourceTrimmedAudios && resourceTrimmedAudios?.length > 0) {
      const uiTrimmedAudios: UITrimmedAudio[] = [];
     for (const resourceTrimmedAudio of resourceTrimmedAudios) {
      //  const regionColor = getRandomElementFromArray(highContrastingColors)
      //  const regionColor = getRandomColor();
      const newRegionObject: WaveSurferRegionOptions = {
       id: resourceTrimmedAudio._id,
       start: resourceTrimmedAudio.startTimeInSeconds,
       end: resourceTrimmedAudio.endTimeInSeconds,
       color: resourceTrimmedAudio.color || "transparent"
      };


      wavesurferRef.current.addRegion(newRegionObject);
      uiTrimmedAudios.push({
        _id: resourceTrimmedAudio._id,
        startTimeInSeconds: resourceTrimmedAudio.startTimeInSeconds,
        endTimeInSeconds: resourceTrimmedAudio.endTimeInSeconds,
        color: resourceTrimmedAudio.color || "transparent" ,
        label: resourceTrimmedAudio.label,
        createdAt: resourceTrimmedAudio.createdAt
      })
     }

     setTrimmedAudios([...uiTrimmedAudios])
    }
   });
   //  wavesurferRef.current.on("finish", setPlayButton);
   wavesurferRef.current.on("finish", function () {
    setIsResourceAudioPlaying(false);
   });
   // wavesurfer.load(URL.createObjectURL(element.files[0]));
  //  wavesurferRef.current.load(audioDownloadURL);
   wavesurferRef.current.loadBlob(audioFile)
   wavesurferRef.current.on("audioprocess", function () {
    if (wavesurferRef.current.isPlaying()) {
     var currentTime = wavesurferRef.current.getCurrentTime();

     // @ts-ignore
    //  document.getElementById("time-current").innerText = currentTime.toFixed(1);
    setOriginalAudioCurrentTime(currentTime.toFixed(1));
    }
   });
   wavesurferRef.current.on("region-created", function (newRegion: any) {
    return;
   });
   wavesurferRef.current.on("region-update-end", function (newRegion: any) {

    if (copilotMaxAudioTrimAllowedInMilliSeconds) {
      const maximumLengthOfTrimAudioInSeconds = convertMilliSecondsToSeconds(copilotMaxAudioTrimAllowedInMilliSeconds);
      const lengthSelectedByUser = newRegion.end - newRegion.start;
      if (lengthSelectedByUser > maximumLengthOfTrimAudioInSeconds) {
        newRegion.end = newRegion.start + maximumLengthOfTrimAudioInSeconds;
      }
    }
    const colorToSetInRegion = trimAudioColorPickerRef.current.getLeastUsedColor();
    props.getSpectrogramCoordinatesFromAudioDuration(newRegion, colorToSetInRegion)

    setTrimmedAudios((oldState) => {
     const newState = [...oldState];
     const trimAudioMatchingRegionId = newState.find(ele => ele._id === newRegion.id);     
     if (trimAudioMatchingRegionId) {
      trimAudioMatchingRegionId.startTimeInSeconds = newRegion.start;
      trimAudioMatchingRegionId.endTimeInSeconds = newRegion.end;
      if (trimAudioMatchingRegionId.label) {
        handleTrimAudioUpdateInDB(
          "updateInDB",
          trimAudioMatchingRegionId._id,
          trimAudioMatchingRegionId
        )
      }
     } else {
      // const colorToSetInRegion = getRandomElementFromArray(highContrastingColors)
      // const colorToSetInRegion = getRandomColor();
      
      wavesurferRef.current.regions.list[newRegion.id].update({color: colorToSetInRegion});       
       newState.push({
        startTimeInSeconds: newRegion.start,
        endTimeInSeconds: newRegion.end,
        _id: newRegion.id,
        label: "",
        createdAt: "",
        color: colorToSetInRegion
       });
     }     

     return newState;
    });

    return;
   });
  },
  [
    props.copilotSpectrogramAudioAnnotation,
   resourceTrimmedAudios,
   createAudioRow,
   readAndDecodeAudio,
   setPlayButton,
   showSelectedResourceSection,
  ]
 );

 useEffect(() => {
  if (resource._id) {
    trimAudioColorPickerRef.current = new UniqueColorPicker(trimAudioColors);
   setHasResourceIdChanged(true);
  }
 }, [resource._id]);


useEffect(() => {
  if (props.resource && hasResourceIdChanged && props.copilotSpectrogramAudioAnnotation) {
   setTrimmedAudios([])
   setHasResourceIdChanged(false);
  }
  loadAudio(props.resource);
 }, [props.copilotSpectrogramAudioAnnotation, hasResourceIdChanged, props.resource, loadAudio]);

//  const playTrack = useCallback((regionId: string) => {
//   wavesurferRef.current.regions.list[regionId].play();
//  }, []);
  
  /**
   * To check if all the selected audio have labels
   * @returns boolean value
   */
  const checkSelectedTrimmedAudioLabel = () => {
    return trimAudioIdsSelectedInTable.every(id => {
      const trimmedAudioValue = trimmedAudios.find(audio => audio._id === id);
      return trimmedAudioValue?.label && trimmedAudioValue.label.length ? true : false;
    })
}

  const playTrimmedAudio = (annotationId:string) => {
    if(wavesurferRef?.current?.regions?.list[annotationId]) {
      wavesurferRef?.current?.regions?.list?.[annotationId].play() 
      props.setSelectedSpectrogramAnnotation("")
    } 
  }

  useEffect(() => {
      if(props.selectedSpectrogramAnnotation&&props.selectedSpectrogramAnnotation!==""&&wavesurferRef.current) {
        // console.log("props.selectedSpectrogramAnnotation", props.selectedSpectrogramAnnotation, wavesurferRef.current.regions.list)
        playTrimmedAudio(`${props.selectedSpectrogramAnnotation}-audio-annotation`)  
      }
  }, [props.selectedSpectrogramAnnotation])

  const [isAllTrimmedAudioHaveLabels, setIsAllTrimmedAudioHaveLabels] = useState<boolean>(true);
  useEffect(() => {
    const allLabelAvailable = trimmedAudios.every((audio) => {
      return audio?.label && audio.label.length ? true : false;
    })

    if (allLabelAvailable){
      setIsAllTrimmedAudioHaveLabels(true)
    }
    else{
      setIsAllTrimmedAudioHaveLabels(false)
    }
  },[trimmedAudios])

  const [playBackSpeedRate, setPlayBackSpeedRate] = useState<string>("1")

  function handleSpeedChange(e: ChangeEvent<{ value: unknown }>) {
    setPlayBackSpeedRate(e.target.value as string)
    wavesurferRef.current?.setPlaybackRate(parseFloat(e.target.value as string));
  }

  return (
    <>
     <div className="w3-container"
      style={{
        height: "fit-content",
        width: `${props.setWidth === "" ? "457px" : props.setWidth}`,
        paddingLeft: "10px"
      }}
     >
      <br />
      <div
       id={`waveform${props.elementId}`}
       className="w3-border w3-round-large"
       data-step="3"
       data-intro="Click and drag to select section"
      ></div>
      <div id={`waveform-timeline${props.elementId}`}></div>
      <br />
      <div id="selectedResourceSection"
        style={{
          height: "calc(100% - 135px)"
        }}
      >
       <div className="w3-row">
        <div style={{ display: "flex", flexDirection: "row", gap: 10}} className="w3-half w3-container w3-hide" id="audio-buttons">
         {!isResourceAudioPlaying ? (
          <Tooltip title="Play the audio">
            <PlayCircleFilledIcon
             color="primary"
             className={classes.playPauseButtonOfOriginalAudio}
             onClick={() => {
              setIsResourceAudioPlaying(true);
              wavesurferRef.current.play();
             }}
            />
          </Tooltip>
         ) : (
           <Tooltip title="Pause the audio">
             <PauseCircleFilledIcon
              color="primary"
              className={classes.playPauseButtonOfOriginalAudio}
              onClick={() => {
               setIsResourceAudioPlaying(false);
               wavesurferRef.current.pause();
              }}
             />
           </Tooltip>
         )}
         <b id="time-current">{originalAudioCurrentTime}</b> / <b id="time-total">{originalAudioTotalTime}</b>
              <div style={{display: "flex", flexDirection: "row", width: 300, alignItems: "center", gap: 10, justifyContent: "center"}}>
                  <InputLabel id="speed-label">Playback Speed</InputLabel>
                  <Select
                    labelId="speed-label"
                    id="speed"
                    value={playBackSpeedRate}
                    onChange={handleSpeedChange}
                  >
                    <MenuItem value="1">1x</MenuItem>
                    <MenuItem value="0.9">0.9x</MenuItem>
                    <MenuItem value="0.8">0.8x</MenuItem>
                    <MenuItem value="0.7">0.7x</MenuItem>
                    <MenuItem value="0.6">0.6x</MenuItem>
                    <MenuItem value="0.5">0.5x</MenuItem>
                    {/* Add more options as needed */}
                  </Select>
              </div>
        </div>
        <div className="w3-half w3-container"></div>
       </div>
       <br />
      </div>
     </div>
    </>
   );
}

function SpectrogramAudioItemCard(props:{
  resources: ICoPilotResource[],
  resource: ICoPilotResource, 
  isFocusMode: boolean,
  removeCoPilotResourceFromUI: (arg0: { resourceId: string; }) => void
  getResources: (arg0: { offset: string; limit: string; status: string; sortField: string; sortOrder: string; }) => void
  setCurrentPage: Dispatch<SetStateAction<number>>,
  isExtendTimeFeatureEnabled: boolean,
  timeByWhichCollectionTimeShouldBeExtended: number,
  currentPage:number,
  totalCount:number,
  annotationOptions?: AnnotationOption,
  annotationLabels: ModelAnnotationLabelAvailable[],
  handleSlideChange: HandleSlideChangeFunctionType,
  updateResourceImageAnnotationsInDB: typeof updateCopilotResourceImageAnnotationsInDB,
  setCoPilotResourcesOnly: typeof setCoPilotResourcesOnly
  setCoPilotResourcesTotalCountOnly: typeof setCoPilotResourcesTotalCountOnly,
  setCoPilotResourceData: typeof setCoPilotResourceData,
  onCardStateChange: (cardState: "expanded" | "collapsed") => void,
  updateCopilotResourceTrimmedTextsInUI?: (resourceData: any) => {
    type: string;
    payload: any;
  },
	referenceGroupsState: InputFieldState<IReferenceGroupState[]>
  isEditAnnotationsEnabledByUser: boolean,
  setIsEditAnnotationsEnabledByUser: React.Dispatch<React.SetStateAction<boolean>>
})
// :JSX.Element {
  
 
//    const [copilotImageFullScreenUrl, setCopilotImageFullScreenUrlFullScreenUrl] = useState<string>("")
//   const [copilotImageFullScreenCaption, setCopilotImageFullScreenCaption] = useState<string>("")
//   const [copilotFullScreenFileName, setCopilotFullScreenFileName] = useState<string>("")
//   const [coPilotImageFullScreenDialogIsOpen, setcoPilotImageFullScreenDialogIsOpen] = useState<boolean>(false)
//   const [copilotType, setCopilotType] = useState<urlSourceDataType | undefined>()
//   const handleReferenceImageFullScreenDialogOnClose = () => {
//     setcoPilotImageFullScreenDialogIsOpen(false)
//   }
//   const handleFullScreenShowLeftReferenceData = () => {

//   }

//   const handleFullScreenShowRightReferenceData = () => {

//   }

: JSX.Element{
  const [copilotImageFullScreenUrl, setCopilotImageFullScreenUrlFullScreenUrl] = useState<string>("")
  const [copilotImageFullScreenCaption, setCopilotImageFullScreenCaption] = useState<string>("")
  const [copilotFullScreenFileName, setCopilotFullScreenFileName] = useState<string>("")
  const [coPilotImageFullScreenDialogIsOpen, setcoPilotImageFullScreenDialogIsOpen] = useState<boolean>(false)
  const [copilotType, setCopilotType] = useState<urlSourceDataType | undefined>()
  const handleReferenceImageFullScreenDialogOnClose = () => {
    setcoPilotImageFullScreenDialogIsOpen(false)
  }
  const handleFullScreenShowLeftReferenceData = () => {

  }

  const handleFullScreenShowRightReferenceData = () => {

  }
  const [spectrogramAudioAnnotation, setSpectrogramAudioAnnotation] = useState<ITrimmedAudioAnnotation []>(props.resource.trimmedAudioAnnotation || [])
  const classes = useStyles();
  const history = useHistory();
  const {resources, resource, handleSlideChange} = props;
  const { resource500WUrl } = resource;
  const [detailsCard, setDetailsCard] = React.useState(false);

  const [showViewReferenceContextInformation, setShowViewReferenceContextInformation] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const [addTimeModal, setAddTimeModal] = React.useState(false);
  const [isModalOpenedOnce, setModalOpenedOnce] = React.useState(false);
  const [updateAlert, setUpdateAlert] = React.useState("");
  const [userTimeLeftInSecondsToTakeAction, setUserTimeLeftInSecondsToTakeAction] = useState(resource.userTimeLeftInSecondsToTakeAction);

  const resourceUrl = resource.resource || "";
  const coPilotType = resource.coPilot.type;
  const resourceImageAnnotations = resource.imageAnnotations || [];

  const [csvExpandedFormShowing, setCsvExpandedFormShowing] = useState<boolean>(false);

  // const [isEditAnnotationsEnabledByUser, setIsEditAnnotationsEnabledByUser] = useState(false);

  const {isEditAnnotationsEnabledByUser, setIsEditAnnotationsEnabledByUser} =  props;

  const [isUpdateApiBeingCalled, setisUpdateApiBeingCalled] = useState(false);

  const [selectedSpectrogramAnnotation, setSelectedSpectrogramAnnotation] = useState<string>("")

  const {image: originalImage, imageLoadStatus: originalImageLoadStatus} = useImageOnLoad(
    props.resource?.resourceMetaData?.width && props.resource?.resourceMetaData?.height && props.resource?.resource500WUrl && props.resource?.resource500WMetaData?.width && props.resource?.resource500WMetaData?.height
    ? props.resource?.resource || ""
    : ""
   );  

   const [selectedAudioAnnotationId, setSelectedAudioAnnotationId] = useState<string>("");
   
   const imageWidthToSetRef = useRef(MaxAnnotatedImageWidthAllowed);

   const imageHeightToSetRef = useRef(MaxAnnotatedImageHeightAllowed);

   const previousResourceId = usePreviousValue(resource?._id || "");

   const isResourceIdChanged = (()=>{
     if (props.resource?._id !== previousResourceId) {
       return true;
     }
     return false;
   })();  
 
   const isResourceIdChangedRef = useRef(isResourceIdChanged);
   isResourceIdChangedRef.current = isResourceIdChanged;  
 
   const propsResourceRef = useRef(props.resource);
   propsResourceRef.current = props.resource;  

   useEffect(() => {
    editedAnnotationsRef.current = undefined;
  }, [propsResourceRef.current?._id])   
 
   const editedAnnotationsRef = useRef<ResourceImageAnnotation[]>();     

   const isShowingCompressedImageBecauseOriginalImageIsDownloading = (()=>{
    if (originalImageLoadStatus === "loading") {
      return true;
    }
    return false;
  })();  

  const isShowingCompressedImageBecauseOriginalImageIsDownloadingRef = useRef(isShowingCompressedImageBecauseOriginalImageIsDownloading);
  isShowingCompressedImageBecauseOriginalImageIsDownloadingRef.current = isShowingCompressedImageBecauseOriginalImageIsDownloading;    

  useEffect(() => {
    editedAnnotationsRef.current = undefined;
  }, [propsResourceRef.current?._id])    

  useEffect(()=>{
    const executeFunction = async () => {
      if (coPilotType === 'imageAnnotation') {

        let isShowingCompressedImageBecauseOriginalImageIsDownloading = isShowingCompressedImageBecauseOriginalImageIsDownloadingRef.current;

        let image: {
          width: number,
          height: number
        } | undefined = undefined;

        if (isShowingCompressedImageBecauseOriginalImageIsDownloading  && propsResourceRef.current.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {
          image = {
            width: propsResourceRef.current?.resource500WMetaData?.width || 0,
            height: propsResourceRef.current?.resource500WMetaData?.height || 0,
          }          
        } else if (propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height) {
          image = {
            width: propsResourceRef.current.resourceMetaData.width,
            height: propsResourceRef.current.resourceMetaData.height,
          }          
        } else {
          image = await ImageService.getImageWidthHeightFromURL(resourceUrl || "");
          if (resource._id !== propsResourceRef.current._id) {
            /**
             * if selcted resource got changed, then no point of continuing
             */
            return;
          }          
        }

        setIsImageBeingDownloaded(true);

        let differenceBetweenCanvasAndImageToSet = 0;
        let canvasWidthToSet = 0;        
        let canvasHeightToSet = 0;

        if (image.width > image.height) {
          differenceBetweenCanvasAndImageToSet = image.width / MaxAnnotatedImageWidthAllowed ;
          canvasHeightToSet = image.height / differenceBetweenCanvasAndImageToSet ;          
          canvasWidthToSet = MaxAnnotatedImageWidthAllowed;          

          
        } else if (image.height > image.width) {
          differenceBetweenCanvasAndImageToSet = image.height / MaxAnnotatedImageHeightAllowed;
          canvasWidthToSet = image.width / differenceBetweenCanvasAndImageToSet;
          canvasHeightToSet = MaxAnnotatedImageHeightAllowed;          

          if(canvasWidthToSet > MaxAnnotatedImageWidthAllowed){
            differenceBetweenCanvasAndImageToSet = canvasWidthToSet / MaxAnnotatedImageWidthAllowed;
            canvasHeightToSet = canvasHeightToSet / differenceBetweenCanvasAndImageToSet;
            canvasWidthToSet = MaxAnnotatedImageWidthAllowed;
          }          
        }

        imageWidthToSetRef.current = canvasWidthToSet;
        imageHeightToSetRef.current = canvasHeightToSet;

        const imageScaleFactor = getImageScaleFactorForImageWidth(
          image.width,
          imageWidthToSetRef.current
        );

        let imageAnnotationsToSet = 
          coPilotType === "imageAnnotation"
            ? setResourceImageAnnotationsForSendingToAnnotationComponent(
                  copyByValue(propsResourceRef.current.imageAnnotations || []),
                  imageScaleFactor
              )
            : []     
            
        if (isShowingCompressedImageBecauseOriginalImageIsDownloading && propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height && propsResourceRef.current?.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {
          /**
           * Converting imageAnnotations vertices as per compressedImage width and height
           */

           imageAnnotationsToSet = setResourceImageAnnotationsOfOriginalImageForCompressedImage(
            imageAnnotationsToSet,
            propsResourceRef.current?.resourceMetaData?.width,
            propsResourceRef.current?.resourceMetaData?.height,
            propsResourceRef.current?.resource500WMetaData?.width,
            propsResourceRef.current?.resource500WMetaData?.height          
          )
        }
            
        setResourceImageAnnotationsForRendering([...imageAnnotationsToSet])
        setIsImageBeingDownloaded(false);
      }
    }

    executeFunction();
  }, [resourceUrl, coPilotType])

  useEffect(()=>{

    if (isResourceIdChangedRef.current) {
      return;
    }

    const executeFunction = async () => {
      if (coPilotType === 'imageAnnotation') {

        let image: {
          width: number,
          height: number
        } | undefined = undefined;

        if (isShowingCompressedImageBecauseOriginalImageIsDownloading  && propsResourceRef.current.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {
          image = {
            width: propsResourceRef.current?.resource500WMetaData?.width || 0,
            height: propsResourceRef.current?.resource500WMetaData?.height || 0,
          }          
        } else if (propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height) {
          image = {
            width: propsResourceRef.current.resourceMetaData.width,
            height: propsResourceRef.current.resourceMetaData.height,
          }          
        } else {
          image = await ImageService.getImageWidthHeightFromURL(resourceUrl || "");
          if (resource._id !== propsResourceRef.current._id) {
            /**
             * if selcted resource got changed, then no point of continuing
             */
            return;
          }          
        }

        setIsImageBeingDownloaded(true);

        let differenceBetweenCanvasAndImageToSet = 0;
        let canvasWidthToSet = 0;        
        let canvasHeightToSet = 0;

        if (image.width > image.height) {
          differenceBetweenCanvasAndImageToSet = image.width / MaxAnnotatedImageWidthAllowed ;
          canvasHeightToSet = image.height / differenceBetweenCanvasAndImageToSet ;          
          canvasWidthToSet = MaxAnnotatedImageWidthAllowed;          

          
        } else if (image.height > image.width) {
          differenceBetweenCanvasAndImageToSet = image.height / MaxAnnotatedImageHeightAllowed;
          canvasWidthToSet = image.width / differenceBetweenCanvasAndImageToSet;
          canvasHeightToSet = MaxAnnotatedImageHeightAllowed;          

          if(canvasWidthToSet > MaxAnnotatedImageWidthAllowed){
            differenceBetweenCanvasAndImageToSet = canvasWidthToSet / MaxAnnotatedImageWidthAllowed;
            canvasHeightToSet = canvasHeightToSet / differenceBetweenCanvasAndImageToSet;
            canvasWidthToSet = MaxAnnotatedImageWidthAllowed;
          }          
        }

        imageWidthToSetRef.current = canvasWidthToSet;

        const imageScaleFactor = getImageScaleFactorForImageWidth(
          image.width,
          imageWidthToSetRef.current
        );

        let imageAnnotationsToSet = 
          coPilotType === "imageAnnotation"
            ? setResourceImageAnnotationsForSendingToAnnotationComponent(
                  copyByValue((editedAnnotationsRef.current || propsResourceRef.current?.imageAnnotations) || []),
                  imageScaleFactor
              )
            : []     
            
        if (isShowingCompressedImageBecauseOriginalImageIsDownloading && propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height && propsResourceRef.current?.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {
          /**
           * Converting imageAnnotations vertices as per compressedImage width and height
           */

           imageAnnotationsToSet = setResourceImageAnnotationsOfOriginalImageForCompressedImage(
            imageAnnotationsToSet,
            propsResourceRef.current?.resourceMetaData?.width,
            propsResourceRef.current?.resourceMetaData?.height,
            propsResourceRef.current?.resource500WMetaData?.width,
            propsResourceRef.current?.resource500WMetaData?.height          
          )
        }
            
        setResourceImageAnnotationsForRendering([...imageAnnotationsToSet])
        setIsImageBeingDownloaded(false);
      }
    }

    executeFunction();
  }, [isShowingCompressedImageBecauseOriginalImageIsDownloading])  

  const handleImageAnnotationSAveClick = useCallback(async (data: AnnotationComponentSaveClickData)=>{
    // data = copyByValue(data);
    if (coPilotType === "imageAnnotation") {
      try {
        if (isShowingCompressedImageBecauseOriginalImageIsDownloading && data?.annotations?.length>0) {
          data.annotations = setResourceImageAnnotationsOfCompressedImageForOriginalImage(
            data.annotations,
            propsResourceRef.current?.resourceMetaData?.width || 0,
            propsResourceRef.current?.resourceMetaData?.height || 0,
            propsResourceRef.current?.resource500WMetaData?.width || 0,
            propsResourceRef.current?.resource500WMetaData?.height || 0
          )          
        }
        await props.updateResourceImageAnnotationsInDB({
          imageAnnotations: data.annotations,
          resourceId: propsResourceRef.current?._id.toString(),
          shouldNotFormNewRootState: true
        })
        setAlertMessge("Changes Saved !");
        setAlert("success");
        setTimeout(()=>{
          setAlert("");
        },2000);                        
      } catch (error) {
        setAlertMessge("Something went wrong !!");
        setAlert("failed");
        setTimeout(()=>{
          setAlert("");
        },2000);                        
      }
    }
  }, [coPilotType, isShowingCompressedImageBecauseOriginalImageIsDownloading])

  useTimeInterval(()=>{
    if (userTimeLeftInSecondsToTakeAction > 0) {
      setUserTimeLeftInSecondsToTakeAction(oldState => oldState - 1);
    }
  }, 1000)

  useEffect(()=>{
    const executeFunction = async () => {
      if (userTimeLeftInSecondsToTakeAction <= 0) {
        setIsFetchingResources(true);      

        await copilotService.updateResource({
          id: [resource._id],
          status: "expired"
        })        
        props.setCoPilotResourcesTotalCountOnly(0);
        props.setCoPilotResourcesOnly([])
        
        const urlQuery = new URLSearchParams(window.location.search);
        urlQuery.set("offset", "1")
        urlQuery.set("limit", "1")
        const json = convertURLQueryParamsToJSON(urlQuery.entries());
        delete json.project;
        await props.getResources(json);
        setIsFetchingResources(false);        
        setModalOpenedOnce(false)
      }
    }
    executeFunction();
  }, [userTimeLeftInSecondsToTakeAction])

  useEffect(()=>{
    const handleUpdateResourceSocketEvent = async (data: string) => {
      const resourceUpdateSocketEventData: SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED_DATA = JSON.parse(data);
      let resourceWhichIsShowing: ICoPilotResource = props.resource;
      if (resourceUpdateSocketEventData.resourceIds[0] === resourceWhichIsShowing?._id) {
        if (resourceUpdateSocketEventData.status && resourceUpdateSocketEventData.status !== "active") {
          setIsFetchingResources(true); 
          const urlQuery = new URLSearchParams(window.location.search);
          urlQuery.set("offset", "1")
          urlQuery.set("limit", "1")
          const json = convertURLQueryParamsToJSON(urlQuery.entries());
          delete json.project;
          await props.getResources(json);
          setIsFetchingResources(false); 
        }
      }
    };

    console.log("Icopilot", props.resource.coPilot);

    socketConnection.on(
      SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED, handleUpdateResourceSocketEvent
    );

    return () => {
      socketConnection.off(
        SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED, handleUpdateResourceSocketEvent
      );      
    }
  }, [props.resource])  

  useEffect(()=>{
    const handleUpdateResourceSocketEvent = async (data: string) => {
      const resourceUpdateSocketEventData: SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED_DATA = JSON.parse(data);
      let resourceWhichIsShowing: ICoPilotResource = props.resource;
      const urlQuery = new URLSearchParams(window.location.search);
      const json = convertURLQueryParamsToJSON(urlQuery.entries());
      // const projectId = urlQuery.get('project') || "";   
      const currentCopilot = urlQuery.get("coPilot")
      if (resourceUpdateSocketEventData.resourceIds[0] !== resourceWhichIsShowing?._id && currentCopilot === resourceUpdateSocketEventData.copilotId) {
        // console.log(":::: Old Copilot Resource Status Before Update is ::::::", resourceUpdateSocketEventData.oldCoPilotResourceStatusBeforeUpdate);
        // if (resourceUpdateSocketEventData.status && resourceUpdateSocketEventData.status !== "active") {
          if(resourceUpdateSocketEventData.oldCoPilotResourceStatusBeforeUpdate && resourceUpdateSocketEventData.oldCoPilotResourceStatusBeforeUpdate === "active" && resourceUpdateSocketEventData.status && resourceUpdateSocketEventData.status !== "active") {
            if(props.currentPage > 1){
              console.log("current page before updating: ", props.currentPage)
              props.setCurrentPage((oldState) => oldState - 1);
              console.log("current page AFTER updating: ", props.currentPage)
            }
            if(props.totalCount > 1){
              dispatch(props.setCoPilotResourcesTotalCountOnly(props.totalCount -1));
            }
          // }
            
        }
      }
      delete json.project;
    };

    socketConnection.on(
      SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED, handleUpdateResourceSocketEvent
    );

    return () => {
      socketConnection.off(
        SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED, handleUpdateResourceSocketEvent
      );      
    }
  }, [props.totalCount, props.currentPage, props.resource])  

  const [remarkTextState, setRemarkTextState] = React.useState<InputFieldState<string>>({value: ""});
  const remarkText = remarkTextState.value;
  let animationStyle = useMemo(() => {
    const animationProperties = {
      animationName: `CardBorderAnimation`,
      animationTimingFunction: "ease-in-out",
      animationDuration: `${props.resource?.resourceSecondsAllocatedDuringTimerSet}s`,
      animationDelay: `-${props.resource?.resourceSecondsAllocatedDuringTimerSet-userTimeLeftInSecondsToTakeAction}s`,
      animationIterationCount: 1,
      animationDirection: "normal",
      animationFillMode: "forwards",
      background: `linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%,transparent), linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%, transparent)`,
      backgroundSize: "99% 8px, 8px 99%, 99% 8px, 8px 99%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "0 0, 100% 0, 100% 100%, 0 100%",
      padding: "8px",
      borderRadius: "4px",
    }
    if (addTimeModal) {
      return {
        ...animationProperties,
        animationName: `CardBorderAnimation2`,
      }
    }
    return {
      ...animationProperties,
      animationName: `CardBorderAnimation`,
    }
  }, [resourceUrl, props.resource.userTimeLeftInSecondsToTakeAction, addTimeModal]);

  useEffect(() => {
    if (props.isExtendTimeFeatureEnabled && userTimeLeftInSecondsToTakeAction <= 4 && userTimeLeftInSecondsToTakeAction > 0 && !addTimeModal && resource.resourceTimerEndAtResetCount === 0 && (resource.coPilot.type === "imageAnnotation" || resource.coPilot.type === 'image' || resource.coPilot.type === 'video' || resource.coPilot.type === 'audio' || resource.coPilot.type === 'text' || resource.coPilot.type === 'textAnnotation' || resource.coPilot.type === 'DLP') && !isModalOpenedOnce) {
      setAddTimeModal(true)
      setModalOpenedOnce(true)
      activeResourceTimeExtendPopup()
    }
  }, [userTimeLeftInSecondsToTakeAction, addTimeModal, isModalOpenedOnce])

  async function activeResourceTimeExtendPopup() {
    const data = await copilotService.setActiveResourceExtendTime(resource._id, resource.resourceTimerEndAt, resource.coPilot._id);
    setUserTimeLeftInSecondsToTakeAction(data.data.userTimeLeftInSecondsToTakeAction)
    dispatch(setCoPilotResourceData({
      coPilotResource: {
        ...resource,
        userTimeLeftInSecondsToTakeAction: data.data.userTimeLeftInSecondsToTakeAction
      }
    }))
  }

  const extendResourceTime = () => {
    setAddTimeModal(false)
    extendResourceTimeBy60Seconds()
  }

  async function extendResourceTimeBy60Seconds() {
    const data = await copilotService.extendResourceTime(resource._id, resource.resourceTimerEndAt, resource.coPilot._id)
    setUserTimeLeftInSecondsToTakeAction(data.data.userTimeLeftInSecondsToTakeAction)
    dispatch(setCoPilotResourceData({
      coPilotResource: {
        ...resource,
        userTimeLeftInSecondsToTakeAction: data.data.userTimeLeftInSecondsToTakeAction,
        resourceTimerEndAtResetCount: 1,
      }
    }))
  }


  const [resourceImageAnnotationsForRendering, setResourceImageAnnotationsForRendering] = useState<ResourceImageAnnotation[]>([]);

  const [isImageBeingDownloaded, setIsImageBeingDownloaded] = useState(false);

  const resourceRemarkTextSavedInDB = resource.remarks?.text || "";

  const [alert, setAlert] = useState("none");
  const [alertMessage, setAlertMessge] = useState("none");
  
  const twoDimensionalImageAlertProp = useMemo(() => [alert, alertMessage], [alert, alertMessage])

  useEffect(()=>{
    if (detailsCard) {
      setIsEditAnnotationsEnabledByUser(false);
    }
  }, [detailsCard])

  useEffect(()=>{
    if (detailsCard || isEditAnnotationsEnabledByUser) {
      props.onCardStateChange("expanded")
    } else {
      props.onCardStateChange("collapsed")
    }
  }, [detailsCard, isEditAnnotationsEnabledByUser])


  useEffect(()=>{
    setRemarkTextState(oldState => ({
      ...oldState,
      isDirty: false,
      isTouched: false, 
      value: resourceRemarkTextSavedInDB
    }))
  }, [resourceRemarkTextSavedInDB])

  const location=useLocation();
  const showDetails = () => {
    setDetailsCard(!detailsCard)
  }
  const dispatch=useDispatch();

  const [isFetchingResources, setIsFetchingResources] = useState(false);

  async function updateRemark(){
    // const query = new URLSearchParams(location?.search);
    const data = {
        id: resources[0]._id,
        remarks:remarkText ,
      };
    try {
        await copilotService.rejectResources(data);
        // console.log("active remark edit res",res);
        // setResourceRemarksTextState("");
        // window.alert("Remark added");
        setUpdateAlert("success");
        setTimeout(() =>{setUpdateAlert("")},2000)
      } catch {
        // window.alert("error on reject");
        setUpdateAlert("error");
        setTimeout(() =>{setUpdateAlert("")},2000)
      }
}
const approveResource = async () => {
  const data: any = {
      id: [resource._id],
      status: Status.APPROVED,
      label: resource?.label
    }

    if (resource.onApproveSetLabel) {
      data["label"] = resource.onApproveSetLabel
    }

    // if(selectedColor.current){
    // }

    if (resource.coPilot?.type === "bestAnswer") {
      data["answerOptions"] = (() => {
        for (const ansOption of (resource.answerOptions || [])) {
          if (ansOption._id === selectedAnsOptionId) {
            ansOption.isSelected = true
          } else {
            ansOption.isSelected = false
          }
        }
        return resource.answerOptions
      })();
    }
    if (resource.coPilot?.type === "evaluateAnswer") {
      data["answerOptions"] = (() => {
        if (resource.answerOptions && resource.answerOptions?.length > 0) {
          resource.answerOptions[0].AIEvaluationScores = aiEvaluationScoresEditedByUser;
        }
        return resource.answerOptions
      })();
    }

    const urlQuery = new URLSearchParams(window.location.search);
    urlQuery.set("limit", "1");
    urlQuery.set("offset", "1");
    history.push(window.location.pathname + "?" + urlQuery.toString());
    const queryParamsJSON = convertURLQueryParamsToJSON(urlQuery.entries());
    data.getQueryParams = queryParamsJSON
  // }

  setisUpdateApiBeingCalled(true);
  await dispatch(updateCoPilotResource(data))

  await dispatch(getResources(queryParamsJSON))
  setisUpdateApiBeingCalled(false);
}


const rejectResource = async () => {
  const data: any = {
      id: [resource._id],
      status: Status.REJECTED,
      label: resource?.label
    }

    if (resource.onRejectSetLabel) {
      data["label"] = resource.onRejectSetLabel
    }

    // if(selectedColor.current){
    // }

    if (resource.coPilot?.type === "bestAnswer") {
      data["answerOptions"] = (() => {
        for (const ansOption of (resource.answerOptions || [])) {
          if (ansOption._id === selectedAnsOptionId) {
            ansOption.isSelected = true
          } else {
            ansOption.isSelected = false
          }
        }
        return resource.answerOptions
      })();
    }
    if (resource.coPilot?.type === "evaluateAnswer") {
      data["answerOptions"] = (() => {
        if (resource.answerOptions && resource.answerOptions?.length > 0) {
          resource.answerOptions[0].AIEvaluationScores = aiEvaluationScoresEditedByUser;
        }
        return resource.answerOptions
      })();
    }

    const urlQuery = new URLSearchParams(window.location.search);
    urlQuery.set("limit", "1");
    urlQuery.set("offset", "1");
    history.push(window.location.pathname + "?" + urlQuery.toString());
    const queryParamsJSON = convertURLQueryParamsToJSON(urlQuery.entries());
    data.getQueryParams = queryParamsJSON
  // }

  setisUpdateApiBeingCalled(true);
  await dispatch(updateCoPilotResource(data))

  await dispatch(getResources(queryParamsJSON))
  setisUpdateApiBeingCalled(false);
}

/*
function handleArrowPressInImageEditPopup(
  params: {
    scenario: "userWantsToGoToPreviousResource" | "userWantsToGoToNextResource"
  }
) {
  const { scenario } = params;

  handleSlideChange(
    scenario === 'userWantsToGoToNextResource'
    ? 'goToNextSlide'
    : 'goToPreviousSlide'
  )
  
}
*/

const handleExtendPopupTimeReachesZero = useCallback(()=>{
  setAddTimeModal(false);
  setUserTimeLeftInSecondsToTakeAction(0);
}, [])

// const handleOnAnnotationsChange = (data: AnnotationComponentAnnotationChangeData)=>{
//   if (isShowingCompressedImageBecauseOriginalImageIsDownloading) {
//     if (data.annotations?.length>0) {
//       data.annotations = setResourceImageAnnotationsOfCompressedImageForOriginalImage(
//         data.annotations,
//         props.resource?.resourceMetaData?.width || 0,
//         props.resource?.resourceMetaData?.height || 0,
//         props.resource?.resource500WMetaData?.width || 0,
//         props.resource?.resource500WMetaData?.height || 0
//       )
//     }
//   }
//   editedAnnotationsRef.current = data.annotations;
// }

const handleOnAnnotationsChange = useCallback(
  (data: AnnotationComponentAnnotationChangeData) => {
  if (isShowingCompressedImageBecauseOriginalImageIsDownloading) {
    if (data.annotations?.length>0) {
      data.annotations = setResourceImageAnnotationsOfCompressedImageForOriginalImage(
        data.annotations,
        props.resource?.resourceMetaData?.width || 0,
        props.resource?.resourceMetaData?.height || 0,
        props.resource?.resource500WMetaData?.width || 0,
        props.resource?.resource500WMetaData?.height || 0
      )
    }
  }
  editedAnnotationsRef.current = data.annotations;    
  },
  [isShowingCompressedImageBecauseOriginalImageIsDownloading, props.resource?.resource500WMetaData?.height, props.resource?.resource500WMetaData?.width, props.resource?.resourceMetaData?.height, props.resource?.resourceMetaData?.width],
)

 const [selectedAnsOptionId, setSelectedAnsOptionId] = useState("");
 useEffect(() => {
   const selectedAnsOptionId = (resource?.answerOptions || [])?.find(ansOption=>ansOption?.isSelected === true)?._id;
   if (selectedAnsOptionId) {
    setSelectedAnsOptionId(selectedAnsOptionId)
   }
 
 }, [resource._id])
 
 const [aiEvaluationScoresEditedByUser, setAiEvaluationScoresEditedByUser] = useState<Object>(resource?.answerOptions?.[0]?.AIEvaluationScores || {})

 useEffect(()=>{
  setAiEvaluationScoresEditedByUser(resource?.answerOptions?.[0]?.AIEvaluationScores || {})
}, [resource?._id])

const urlSearchParams = new URLSearchParams(window.location.search);
const copilotId = urlSearchParams.get("coPilot") || "";

  // const copilotsToHardcode = ["6555be0774b67808ca69bf1b", "654a1be8c2f2a5d7eb4b5218"];
  // const copilotsToHardcode = ["654a1be8c2f2a5d7eb4b5218"];

const resourcesLength = props.resources?.length;

const rejectCustomActionButtonOptions = resource?.activePageActions?.negativeButton
const approveCustomActionButtonOptions = resource?.activePageActions?.affirmationButton
const [isLabelDefined, setIsLabelDefined] = useState(true);
const isLabelDefinedinAnnotaions = (annotations?: ITrimmedAudioAnnotation[]) => {
  if(!annotations) return true;
  let isCondition = true;
  annotations.forEach((annotation) => {
    if(annotation.label === "" || annotation.label === "undefined") {
      isCondition = false;
    }
  })
  return isCondition;
}
const handleRejectButtonClick = useCallback(()=>{
  const isLabel = isLabelDefinedinAnnotaions(spectrogramAudioAnnotation);
  setIsLabelDefined(isLabel);
  if((rejectCustomActionButtonOptions? rejectCustomActionButtonOptions?.onClickBehaviour === "reject without reason": true) && isLabel) {
    rejectResource();
    return;
  }
  if ((rejectCustomActionButtonOptions? rejectCustomActionButtonOptions?.onClickBehaviour === "approve": true) && isLabel) {
    approveResource()
    return;
  }
  setOpenModal(true)
}, [approveResource, rejectCustomActionButtonOptions?.onClickBehaviour, resourcesLength, rejectResource, spectrogramAudioAnnotation, isLabelDefined])

const handleApproveButtonClick = useCallback(()=>{
  const isLabel = isLabelDefinedinAnnotaions(spectrogramAudioAnnotation);
  setIsLabelDefined(isLabel);
  // approveResource();
  // return;
  console.log("Approve clicked", approveCustomActionButtonOptions?.onClickBehaviour)
  if ((approveCustomActionButtonOptions?.onClickBehaviour? approveCustomActionButtonOptions?.onClickBehaviour === "approve": true) && isLabel) {

    approveResource()
    return;
  }
  //   if (rejectCustomActionButtonOptions?.onClickBehaviour === "approved") {
  //   return;
  // }  
}, [approveResource, approveCustomActionButtonOptions?.onClickBehaviour, spectrogramAudioAnnotation, isLabelDefined])

  const updateAnnotation = async (id: string) => {
    try {
      const isLabel = isLabelDefinedinAnnotaions(spectrogramAudioAnnotation);
      setIsLabelDefined(isLabel);
      console.log("updateAnnotation")
      const response
       = await CopilotService.updateResource(
        {
          id: [
            props.resource._id
          ],
          trimmedAudioAnnotation: spectrogramAudioAnnotation.map((annotation) => ({
            color: annotation.color,
            startTimeInSeconds: annotation.startTimeInSeconds,
            endTimeInSeconds: annotation.endTimeInSeconds,
            label: annotation.label,
            spectrogramCoordinates: annotation.spectrogramCoordinates,
            createdAt: annotation.createdAt
          }))
        }
      )
      console.log("updateAnnotation", response)
    } catch (err) {
      console.log("Error while deleting Annotation", err)
    }
  }

const [defaultSpectrogramImageAnnotation, setDefaultSpectrogramImageAnnotation] = useState<ResourceImageAnnotation []>([]);

const [defaultSpectrogramAudioAnnotation, setDefaultSpectrogramAudioAnnotation] = useState<TrimmedAudio []>([]);


const initialiseSpectrogramImageAnnotation = () => {
  if(!props.resource.trimmedAudioAnnotation) return;
  let currentImageAnnotation: ResourceImageAnnotation [] = spectrogramAudioAnnotation.map((annotation, index) => {
    let vertices = [
      {
        id: `${annotation._id}-vertice-${index}-1`,
        name: `${annotation._id}-vertice-${index}-1`,
        x: annotation.spectrogramCoordinates.x1,
        y: imageHeightToSetRef.current
      },
      {
        id: `${annotation._id}-vertice-${index}-2`,
        name: `${annotation._id}-vertice-${index}-2`,
        x: annotation.spectrogramCoordinates.x1,
        y: 0
      },
      {
        id: `${annotation._id}-vertice-${index}-3`,
        name: `${annotation._id}-vertice-${index}-3`,
        x: annotation.spectrogramCoordinates.x2,
        y: 0
      },
      {
        id: `${annotation._id}-vertice-${index}-4`,
        name: `${annotation._id}-vertice-${index}-4`,
        x: annotation.spectrogramCoordinates.x2,
        y: imageHeightToSetRef.current
      },
    ]
    let selectedOptions = [
      {
        id: `${annotation._id}-value`,
        value: annotation.label
      }
    ];
    return {
      id: annotation._id,
      vertices: vertices,
      selectedOptions: selectedOptions,
      created_at: new Date(annotation.createdAt),
      color: annotation.color,
      isClosed: true
    };
  })
  setDefaultSpectrogramImageAnnotation(currentImageAnnotation)
}

const initialiseSpectrogramAudioAnnotation = () => {
  if(!props.resource.trimmedAudioAnnotation) return;
  let currentAudioAnnotation: TrimmedAudio [] = spectrogramAudioAnnotation.map((annotation, index) => {
    return {
      _id: `${annotation._id}-audio-annotation`,
      startTimeInSeconds: annotation.startTimeInSeconds,
      endTimeInSeconds: annotation.endTimeInSeconds,
      label: annotation.label,
      createdAt: annotation.createdAt,
      color: annotation.color
    };
  })
  setDefaultSpectrogramAudioAnnotation(currentAudioAnnotation)
}

const getSpectrogramCoordinatesFromAudioDuration = async (newRegion: any, color: string) => {
  console.log("getSpectrogramCoordinatesFromAudioDuration", newRegion)
  try {
    setSpectrogramAudioAnnotation(prevState => {
      if(!newRegion.id) return [...prevState];
      return [...prevState, {
        _id: `${newRegion.id}`||"",
        label: `${newRegion.label}`||"",
        startTimeInSeconds: Number(newRegion.start||-1),
        endTimeInSeconds: Number(newRegion.end||-1),
        createdAt: Date.now().toString(),
        color: color,
        confidenceScore: 100,
        spectrogramCoordinates: {
          y1: 0,
          x1: -1,
          y2: imageHeightToSetRef.current,
          x2: -1,
        }
      }]
    })

    const response = await fetch(props.resource.resource||"");
    const blob = await response.blob();
    const reader = new FileReader();

    reader.onloadend = async () => {
      const result = reader.result;

      if (typeof result === 'string') {
        // Handling the case where result is a string
        const base64String = result.split(',')[1];
        const getDurationApiUrl = "https://time-extraction-exjsxe2nda-el.a.run.app/process_audio"
        const apiData = {
          audio: base64String, 
          start_time: newRegion.start, 
          end_time: newRegion.end,
          spectrogram_width: imageWidthToSetRef.current, 
          spectrogram_height: 500 
        }
        const apiResponse = await axios.post(getDurationApiUrl, apiData)
        setSpectrogramAudioAnnotation((prevState) => {
          if(!prevState) return [];
          const currentAnnotation: ITrimmedAudioAnnotation | undefined = prevState.find((annotation) => annotation._id===newRegion.id)
          if(!currentAnnotation) return []
          return [...prevState.filter((annotation) => annotation._id!==newRegion.id),
          {
            ...currentAnnotation,
            spectrogramCoordinates: {
              y1: 0,
              x1: Number(apiResponse.data.x_coords[0] || 0),
              y2: imageHeightToSetRef.current,
              x2: Number(apiResponse.data.x_coords[1] || 0),
            }
          }
        ]
        })
      } else if (result instanceof ArrayBuffer) {
        // Handling the case where result is an ArrayBuffer
        const uint8Array = new Uint8Array(result);
        const byteArray = Array.from(uint8Array);
        const base64String = btoa(String.fromCharCode(...byteArray));
        const getDurationApiUrl = "https://time-extraction-exjsxe2nda-el.a.run.app/process_audio"
        const apiData = {
          audio: base64String, 
          start_time: newRegion.start, 
          end_time: newRegion.end,
          spectrogram_width: imageWidthToSetRef.current, 
          spectrogram_height: 500 
        }
        const apiResponse = await axios.post(getDurationApiUrl, apiData)

        setSpectrogramAudioAnnotation((prevState) => {
          if(!prevState) return [];
          const currentAnnotation: ITrimmedAudioAnnotation | undefined = prevState.find((annotation) => annotation._id===newRegion.id)
          if(!currentAnnotation) return []
          return [...prevState.filter((annotation) => annotation._id!==newRegion.id),
          {
            ...currentAnnotation,
            spectrogramCoordinates: {
              y1: 0,
              x1: Number(apiResponse.data.x_coords[0] || 0),
              y2: imageHeightToSetRef.current,
              x2: Number(apiResponse.data.x_coords[1] || 0),
            }
          }
        ]
        })
      }
    }
    reader.readAsDataURL(blob);

  }catch (error) {

  }
}

const getAudioDurationFromSpectrogramCoordinates = async (x1: number,x2: number, imageHeight: number,id: string) => {
  try {

    const response = await fetch(props.resource.spectrogram||"");
    const blob = await response.blob();
    const reader = new FileReader();

    reader.onloadend = async () => {
      const result = reader.result;

      if (typeof result === 'string') {
        // Handling the case where result is a string
        const base64String = result.split(',')[1];
        const getDurationApiUrl = "http://35.247.8.148:8501/extract_time"
        const apiData = {
          coordinates: [
            {
              x1: x1, y1: 0, x2: x2, y2: 0, x3: x2, y3: imageHeight, x4: x1, y4: imageHeight
            }
          ],
          spectrogram: base64String
        }
        const apiResponse = await axios.post(getDurationApiUrl, apiData)
        console.log("apiResponse",apiResponse)
        setSpectrogramAudioAnnotation((prevState) => {
          if(!prevState) return [];
          const currentAnnotation: ITrimmedAudioAnnotation | undefined = prevState.find((annotation) => annotation._id===id)
          if(!currentAnnotation) return []
          return [...prevState.filter((annotation) => annotation._id!==id),
          {
            ...currentAnnotation,
            startTimeInSeconds: Number(apiResponse.data.times[0].start_time || 0),
            endTimeInSeconds: Number(apiResponse.data.times[0].end_time || 0)
          }
        ]
        })
      } else if (result instanceof ArrayBuffer) {
        // Handling the case where result is an ArrayBuffer
        const uint8Array = new Uint8Array(result);
        const byteArray = Array.from(uint8Array);
        const base64String = btoa(String.fromCharCode(...byteArray));
        const getDurationApiUrl = "http://34.123.139.61:8501/extract_time"
        const apiData = {
          coordinates: [
            {
              x1: x1, y1: 0, x2: x2, y2: 0, x3: x2, y3: imageHeight, x4: x1, y4: imageHeight
            }
          ],
          spectrogram: base64String
        }
        const apiResponse = await axios.post(getDurationApiUrl, apiData)

        setSpectrogramAudioAnnotation((prevState) => {
          if(!prevState) return [];
          const currentAnnotation: ITrimmedAudioAnnotation | undefined = prevState.find((annotation) => annotation._id===id)
          if(!currentAnnotation) return []
          return [...prevState.filter((annotation) => annotation._id!==id),
          {
            ...currentAnnotation,
            startTimeInSeconds: Number(apiResponse.data.times[0].end_time || 0),
            endTimeInSeconds: Number(apiResponse.data.times[0].end_time || 0)
          }
        ]
        })
      }
    }
    reader.readAsDataURL(blob);

    
  } catch (err) {
    console.log("Error in getting Duration", err)
  }
}

const discardChanges = () => {
  setSpectrogramAudioAnnotation(props.resource.trimmedAudioAnnotation || [])
}


useEffect(() => {
    initialiseSpectrogramImageAnnotation()
    initialiseSpectrogramAudioAnnotation()
}, [spectrogramAudioAnnotation])

  return(
    <>
    <div style={{ overflow: "auto", height: "fit-content" }} >
    {coPilotImageFullScreenDialogIsOpen && <FullScreenCard
        srcUrl={copilotImageFullScreenUrl}
        isOpen={coPilotImageFullScreenDialogIsOpen}
        />}
    <div 
      className={`
        ${classes.itemCardCompRoot}
        ${detailsCard ? css.detailsViewOpened : ""}
        ${(resource.coPilot.type==="imageAnnotation" || resource.coPilot.type==="textAnnotation" || resource.coPilot.type==="DLP") && isEditAnnotationsEnabledByUser ? classes.itemCardCompRootWhenCopilotTypeImageAnnotationsAndEditAnnotationEnabled : ""}
        ${isUpdateApiBeingCalled ? classes.cardDisabled : ""}
        ${showViewReferenceContextInformation ? css.showingReferenceContextInformation : ""}
        ${resource.coPilot.type === "bestAnswer" ? css.bestAnsCopilotType : ""}
        ${props.isFocusMode ? css.focusMode : ""}
        ${resource.coPilot.type === "evaluateAnswer" ? css.evaluateAnsCopilotType : ""}
        ${resource.coPilot.type === "image" ? css.imageCopilotType : ""}
        ${resource.coPilot.type === "video" ? css.videoCopilotType : ""}
        ${resource.coPilot.type === "audio" ? css.audioCopilotType : ""}
        ${resource.coPilot.type === "text" ? css.textCopilotType : ""}
      `}
      style={{height: "100%", display: 'flex', justifyContent: 'center', width:'auto', margin:'0px 0px'}}>
        {props.resources.length !== 0 && openModal && <OnRejectModal updatedLabel={resource.label || ''} open={openModal} setOpen={setOpenModal} resource={props.resources[0]} />}
        {props.isExtendTimeFeatureEnabled && props.resources.length !== 0 && (resource.coPilot.type === 'image' || resource.coPilot.type === 'video' || resource.coPilot.type === 'audio' || resource.coPilot.type === 'imageAnnotation' || resource.coPilot.type === 'bestAnswer' || resource.coPilot.type === 'evaluateAnswer' || resource.coPilot.type === "text" || resource.coPilot.type === "textAnnotation" || resource.coPilot.type === "DLP") && addTimeModal && <SimpleModal open={addTimeModal} onTimeReachesZero={handleExtendPopupTimeReachesZero} title={resource.coPilot.type === 'imageAnnotation' || resource.coPilot.type === 'textAnnotation' || resource.coPilot.type === 'DLP' ? "Edit Annotation" : resource.coPilot.type==="image" ? 'Color Detection' : resource.coPilot.type==="text" ? 'Text Detection' : resource.coPilot.type==="bestAnswer" ? "Best Answer" : resource.coPilot.type==="evaluateAnswer" ? "Evaluate Answer" : ""} subtitle={"Do you want to extend the time?"} setOpen={setAddTimeModal} progressTime={userTimeLeftInSecondsToTakeAction} btnClickHandler={extendResourceTime} timeByWhichCollectionTimeShouldBeExtended={props.timeByWhichCollectionTimeShouldBeExtended} />}
            <Paper variant="elevation" square={false}
            className={ resource.coPilot.type === 'video'? classes.highlightedCardforVideo: classes.highlightedCard}
              classes={{
                root: `${( resource.coPilot.type === 'image' || resource.coPilot.type === 'text' || resource.coPilot.type === 'video' || resource.coPilot.type === 'audio' ) && detailsCard
                  ? classes.itemCardPaperWhenCopilotTypeImageAndDetailsShowing
                  : (resource.coPilot.type === 'image' || resource.coPilot.type === 'video' || resource.coPilot.type === 'text' || resource.coPilot.type === 'audio')
                    ? classes.itemCardPaperWhenCopilotTypeImage
                    : (resource.coPilot.type === 'imageAnnotation' || resource.coPilot.type === "bestAnswer" || resource.coPilot.type === "evaluateAnswer" || resource.coPilot.type === 'textAnnotation' || resource?.coPilot?.type === "DLP") && detailsCard
                      ? classes.itemCardPaperWhenCopilotTypeImageAnnotationAndDetailsShowing
                      : (resource.coPilot.type === "imageAnnotation") && isEditAnnotationsEnabledByUser
                        ? classes.itemCardPaperWhenCopilotTypeImageAnnotationAndEditAnnotationsEnabled
                        : (resource.coPilot.type === "textAnnotation" || resource?.coPilot?.type === "DLP") && isEditAnnotationsEnabledByUser
                          ? classes.itemCardPaperWhenCopilotTypeTextAnnotationAndEditAnnotationsEnabled
                          : (resource.coPilot.type === 'imageAnnotation' || resource.coPilot.type === 'textAnnotation' || resource?.coPilot?.type === "DLP")
                            ? classes.itemCardPaperWhenCopilotTypeImageAnnotation
                            : resource.coPilot.type === "bestAnswer"
                              ? css.itemCardPaperWhenCopilotTypeBestAnswer
                              : resource.coPilot.type === "evaluateAnswer"
                              ? css.itemCardPaperWhenCopilotTypeEvaluateAnswer
                              : ""} ${css.itemCardPaper}`
              }}
              style={{
                padding:"20px 20px", 
                paddingBottom: "0px",
                // height:'calc(100% - 60px)', 
                display:'flex', 
                justifyContent: showViewReferenceContextInformation ? "normal" : 'center', 
                backgroundColor:"#ffffff",
                margin:"10px",
                zIndex: 1,
                height: "auto",
                }}
              >
              <Grid alignItems="center"
                classes={{
                  root: (resource.coPilot.type === "audio" || resource.coPilot.type === "image" || resource.coPilot.type === "text" || resource.coPilot.type === "video")
                    ? classes.itemCardGridWhenCopilotTypeImage
                    : (resource.coPilot.type === "imageAnnotation" || resource.coPilot.type === "bestAnswer" || resource.coPilot.type === "evaluateAnswer" || resource.coPilot.type === "textAnnotation" || resource?.coPilot?.type === "DLP")
                      ? classes.itemCardGridWhenCopilotTypeImageAnnotation
                      : ""
                }}
                        style={{margin: 0, height:'100%'}}
              >
            <div style={{height: "100%"}}>
              <div
                className={css.differentColorCardParent}
                style={{
                  display: "flex"
                }}
              >
              <Card 
              style={{
                width: resource.coPilot.type==="video"?"500px": "100%"
              }}
                classes={{
                  root: (resource.coPilot.type === "image" || resource.coPilot.type === "text" || resource.coPilot.type === "video" || resource.coPilot.type === "audio")
                    ? classes.itemCardWhenCopilotTypeImage
                    : (resource.coPilot.type === "imageAnnotation" || resource.coPilot.type === "bestAnswer" || resource.coPilot.type === "evaluateAnswer" || resource.coPilot.type === "textAnnotation" || resource?.coPilot?.type === "DLP")
                      ? classes.itemCardWhenCopilotTypeImageAnnotation
                      : ""
                }}
              >
                <div
                  className={css.timerAnimation}
                  style={{
                    ...((resource.coPilot.type==="image" || resource.coPilot.type==="video" || resource.coPilot.type==="audio" || resource.coPilot.type==="text") ? animationStyle : {})
                    ,
                    height: "calc(100% - 56px)",
                  }}
                >
                <div
                  className={`
                    ${(resource.coPilot.type === "image" || resource.coPilot.type === "audio" || resource.coPilot.type === "text" || resource.coPilot.type === "video") ? css.cardImageColumnContainerWhenCopilotTypeImage : ""}
                  `}
                  style={{
                    height: "100%",
                    textAlign: "center",
                  }}
                >

                
                {
                  (resource.coPilot.type === 'text') &&
                  <div style={{display:'flex',alignContent:'center',height:'200px'}}>
                    {/* {
                      resource.coPilot.type === 'image' &&
                      <ImageAspectRatioScaler
                      maxImageWidth={200}
                      maxImageHeight={200}
                      imagePlotterSuffix='CopilotDetails'
                      // skeletonHeight={150}
                      imageUrl={resource.resource}
                      />
                    }
                    {
                      resource.coPilot.type === 'text' && */}
                      <div
                        style={{
                          width: "253px",
                          height: "200px",
                          overflow: "auto",
                          whiteSpace: "break-spaces",
                          border: "1px solid rgb(118, 118, 118)",
                          borderRadius: "8px",
                          padding: "10px"
                        }}
                      >
                        {resource.resource}
                      </div>
                    {/* } */}
                  </div>
                }
                
                {
                          resource.question &&
                          <div style={{width: 450}}>
                          <h4 style={{ 
                            textAlign: 
                              "center", 
                            fontWeight: 'bold', marginTop: resource.coPilot.type === "bestAnswer" ? "20px" : "0px", marginLeft: 0 }}>{resource.question}</h4></div>
                        }
                   
                      {
                        (resource.coPilot.type === 'spectrogramAudio') &&
                        <div style={{ display: 'flex', alignItems: "flex-start", justifyContent: 'center', flexDirection: "column",
                        width: `${imageWidthToSetRef.current}`, 
                        height: 
                          "fit-content"
                          }}>
                          {
                            resource.coPilot.type === 'spectrogramAudio' && !isImageBeingDownloaded &&
                            // resource.coPilot.type === 'imageAnnotation' &&
                            <div style={{ width: "100%" }}>
                              <SpectrogramAudioTwoDimensionalImage
                                modelType={resource.coPilot.type}
                                options={props.annotationOptions}
                                isShowingCompressedImageBecauseOriginalImageIsDownloading={isShowingCompressedImageBecauseOriginalImageIsDownloading}
                                scenario={
                                  !isEditAnnotationsEnabledByUser
                                    ? "coPilotActivePageReadOnlyAnnotation"
                                    : "coPilotActivePageEditAnnotation"
                                }
                                className={
                                  !isEditAnnotationsEnabledByUser
                                    ? classes.twoDimensionalImageWhenCopilotReadOnlyImageAnnotation
                                    : ""
                                }
                                settingManuallyCanvasWidth={448}
                                canvasBorderTimerAnimationStyle={animationStyle}
                                isLabelOn={true}
                                // url={resource.resource}
                                url={
                                  props.resource.spectrogram
                                }
                                onAnnotationsChange={handleOnAnnotationsChange}
                                resourceId={props.resource._id}
                                isZoomFeatureEnabled={isEditAnnotationsEnabledByUser ? true : false}
                                isViewOnlyMode={isEditAnnotationsEnabledByUser ? false : true}
                                hasSaveButton={isEditAnnotationsEnabledByUser ? true : false}
                                isDynamicOptionsEnable
                                trimmedAudioAnnotation={spectrogramAudioAnnotation}
                                defaultAnnotations={defaultSpectrogramImageAnnotation}
                                setTrimmedAudioAnnotation={setSpectrogramAudioAnnotation}
                                alert={twoDimensionalImageAlertProp}
                                onSaveClick={handleImageAnnotationSAveClick}
                                imageWidth={imageWidthToSetRef.current}
                                copilotType={copilotType}
                                referenceGroupsState={props.referenceGroupsState}
                                copilotId={copilotId}
                                saveUpdatedAnnotationChanges={updateAnnotation}
                                getAudioDurationFromSpectrogramCoordinates={getAudioDurationFromSpectrogramCoordinates}
                                labelsAvailable={props.resource.coPilot.labelsAvailable}
                                allowAnyLabel={props.resource.coPilot.allowUserToSetAnyLabel}
                                setSelectedSpectrogramAnnotation={setSelectedSpectrogramAnnotation}
                                discardChanges={discardChanges}
                              />
                            </div>
                          }
                          <SpectrogramAudioTrimUI resource={props.resource} updateResourceInUI={function (resourceDataToUpdate: ICoPilotResource) {
                                      throw new Error('Function not implemented.');
                                    } } copilotSpectrogramAudioAnnotation={defaultSpectrogramAudioAnnotation} copilotMaxAudioTrimAllowedInMilliSeconds={1000} 
                                    setSelectedSpectrogramAnnotation={setSelectedSpectrogramAnnotation}
                                        selectedSpectrogramAnnotation={selectedSpectrogramAnnotation}
                                        getSpectrogramCoordinatesFromAudioDuration={getSpectrogramCoordinatesFromAudioDuration}
                                        setWidth=""
                                        elementId={-1}
                          />
                        </div>
                      }
                      {
                        (resource.coPilot.type === "bestAnswer" || resource.coPilot.type === 'evaluateAnswer') &&
                        <div className={css.bestAnswerCopilotResourceContent}>
                          {
                            resource.coPilot.type === "bestAnswer" &&
                            <div className={css.chooseBestSuitableAns}>Choose best suitable answer for question</div>
                          }
                          <div className={css.questionRow}>
                            <span style={{ fontWeight: "bold" }}>Question:</span> <span className={css.question}>{resource.question}</span>
                          </div>
                          <div className={css.answersContainer}>
                            {
                              resource?.answerOptions?.map((ansOption, index) => {
                                return (
                                  <div className={`${css.ansColumn}`}
                                    onClick={() => {
                                      setSelectedAnsOptionId(ansOption?._id)
                                    }}
                                  >
                                    <div className={`${css.ansSection}  ${ansOption?._id === selectedAnsOptionId ? css.isSelected : ""}`}>
                                      <div className={css.ansHeading}>Answer {index + 1}</div>
                                      <div className={css.ansText}>{ansOption.text}</div>
                                    </div>
                                    {
                                      resource.coPilot.type === "bestAnswer" &&
                                      <div className={`${css.scoresSection}  ${ansOption?._id === selectedAnsOptionId ? css.isSelected : ""}`}>
                                        <div className={css.scoresHeading}>Scores</div>
                                        <div className={css.scoresLabelAndValueContainer}>
                                          {convertJsonToArrayOfKeyValuePair(ansOption.AIEvaluationScores || {}).map(keyValuePair => {
                                            return (
                                              <div className={css.scoreLabelAndValue}>
                                                <div className={css.scoreLabel}>{keyValuePair.key}:</div>
                                                {
                                                  resource.coPilot.type === "bestAnswer" &&
                                                  <div className={css.scoreLabelValue}>{keyValuePair.keyValue}</div>
                                                }
                                                {
                                                  resource.coPilot.type === "evaluateAnswer" &&
                                                  <div
                                                    contentEditable
                                                    onInput={e => console.log('Text inside div', e.currentTarget.textContent)}
                                                    className={css.scoreLabelValue}><span>{keyValuePair.keyValue}</span></div>
                                                }
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                )
                              }
                              )
                            }
                            {
                              resource?.coPilot?.type === "evaluateAnswer" &&
                              <div className={`${css.scoresSection}  ${css.isSelected}`}>
                                <div className={css.scoresHeading}>Scores</div>
                                <div className={css.scoresLabelAndValueContainer}>
                                  {convertJsonToArrayOfKeyValuePair(aiEvaluationScoresEditedByUser).map(keyValuePair => {
                                    return (
                                      <div className={css.scoreLabelAndValue}>
                                        <div className={css.scoreLabel}>{keyValuePair.key}:</div>
                                        {
                                          resource.coPilot.type === "evaluateAnswer" &&
                                          <>
                                            {/* <div 
                                    contentEditable
                                    onInput={e => console.log('Text inside div', e.currentTarget.textContent)}
                                    className={css.scoreLabelValue}><span>{keyValuePair.keyValue}</span></div> */}
                                    <input 
                                      type="text"
                                      className={css.scoreInputField}
                                      value={keyValuePair.keyValue}
                                      onChange={(e)=>{
                                        setAiEvaluationScoresEditedByUser(oldState=>{
                                          return  {
                                            ...oldState,
                                            [keyValuePair.key]: e.target.value
                                          }
                                        })
                                      }}
                                    />
                                  </>
                                }
                              </div>
                            )
                          })}
                        </div>
                      </div>                        
                      }
                    </div>
                  </div>
                }
                {/* <div style={{display:'flex', justifyContent: 'center', paddingTop:'10px'}}>
                              <img src={resource.resource} style={{height:'120px', width:'150px', backgroundColor:'#ffffff'}} alt={resource.filename}></img>
                              </div>                                 */}

                      <div
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginBottom: "10px",
                          display: 'grid'
                        }}
                      >
                        {
                          (resource.coPilot.type === "image" || resource.coPilot.type === "video" || resource.coPilot.type === "text" || resource.coPilot.type === "audio") && resource?.showLabelDropdown !== false &&
                          <CardContent style={{ padding: "0px 16px 0px", marginTop: "10px" }}>
                            <ColorSelector
                              allowUserToSetAnyLabel={resource.coPilot.allowUserToSetAnyLabel}
                              selectedColor={resource.label}
                              resource={resource}
                              labelsAvailable={resource.coPilot.labelsAvailable || []}
                              colorChange={(color: string) => {
                                resource.label = color;
                                dispatch(setCoPilotResourceData({ coPilotResource: resource, shouldNotFormNewRootState: true }))
                              }}
                            />
                          </CardContent>
                        }
                        {/* {
                          (copilotsToHardcode.includes(copilotId)) &&
                          <span style={{ color: "#838C95", marginLeft: '30px', textAlign: 'start' }}>{userTimeLeftInSecondsToTakeAction} s</span>
                        } */}
                        {/* {
                          (resource.coPilot.type === "image" || resource.coPilot.type === "video" || resource.coPilot.type === "text" || resource.coPilot.type === "audio") && (copilotId === "6555be0774b67808ca69bf1b") &&
                          <h4 style={{ textAlign: 'center', paddingBottom: '18px', marginBottom: '18px', fontWeight: 'bold' }}>Is this a Useful Motion ?</h4>
                        } */}
                        {/* {
                          (resource.coPilot.type === "image" || resource.coPilot.type === "video" || resource.coPilot.type === "text" || resource.coPilot.type === "audio") && (copilotId === "654a1be8c2f2a5d7eb4b5218") &&
                          <h4 style={{ textAlign: 'center', paddingBottom: '18px', marginBottom: '18px', fontWeight: 'bold' }}>Is there an Item Getting Removed or Added on Table ?</h4>
                        } */}
                        {
                          resource.coPilot.type==="spectrogramAudio" &&
                          <div style={{ maxWidth: 457}}>
                          <div 
                          className={`
                            ${(resource.coPilot.type==="spectrogramAudio") ? classes.cardLinksWhenCopilotTypeImageAnnotation : ""}                  `}
                            style={{ display: "flex", justifyContent: "space-around" }}>
                            {
                              !isEditAnnotationsEnabledByUser &&
                              <div className={styles.detailsLink} onClick={() => { setDetailsCard(false); setIsEditAnnotationsEnabledByUser(true) }} >Edit Annotations</div>
                            }
                            {
                              (
                                isEditAnnotationsEnabledByUser ||
                                detailsCard ||
                                showViewReferenceContextInformation
                              )
                              &&
                              <div className={styles.detailsLink}
                                onClick={() => {
                                  setIsEditAnnotationsEnabledByUser(false)
                                  setDetailsCard(false)
                                  setShowViewReferenceContextInformation(false)
                                }}
                              >
                                Back
                              </div>
                            }
                            </div>
                          </div>
                        }
                        
                        {
                          (resource.coPilot.type === "image" || resource.coPilot.type === 'video' || resource.coPilot.type === 'audio' || resource.coPilot.type === "text") && resource?.showConfidenceScore !== false &&
                          <CardContent style={{ padding: "0px 16px 8px", marginTop: "10px" }}>
                            <Tooltip title='Confidence Score'>

                      <Chip style={{ width: "100%" }} className={styles.confidenceContainer} label={`${resource.confidenceScore}%`} />
                    </Tooltip>
                  </CardContent>
                }
                {
                  // resource.coPilot.type === "image" &&
                  <CardActions 
                    className={`
                      ${(resource.coPilot.type==="image" ||resource.coPilot.type === "video" || resource.coPilot.type==="text" || resource.coPilot.type==="audio") ? classes.actionsButtonRowWhenCopilotTypeImage :""}
                      ${(resource.coPilot.type==="imageAnnotation" || resource.coPilot.type==="textAnnotation" || resource?.coPilot?.type === "DLP") ? classes.actionsButtonRowWhenCopilotTypeImageAnnotation :""}
                      ${css.approveRejectCardActions}
                    `}
                    disableSpacing style={{ display: "flex", justifyContent: "space-around", position: 'relative' }}>
                    {/* <IconButton style={{color:'#ef8a8a'}}>
                                      <CancelOutlinedIcon />
                                    </IconButton> */}
                      {/* <ActionsRow /> */}
                      <>
    { 
    // (!copilotsToHardcode.includes(copilotId)) &&
      <div onClick={handleRejectButtonClick} title={!isLabelDefined ? "Label is required" : ""} style={{opacity: !isLabelDefined ? 0.5 : 1}}>
        <DrawerButton status={'Reject'} buttonText={resource?.activePageActions?.negativeButton?.buttonText || "Reject"} side="left" />
      </div>
    }
    {/* { (copilotsToHardcode.includes(copilotId)) &&
      <div onClick={() => { resource.label = "No"; approveResource()}}>
        <DrawerButton status='No' side="left" />
      </div>    
    } */}
  <div style={{ marginBottom: '10px' }}>
    {
      
        // !copilotsToHardcode.includes(copilotId)) &&
        <span style={{color: "#838C95"}}>{userTimeLeftInSecondsToTakeAction} s</span>
    }
    {/* {resource.userTimeLeftInSecondsToTakeAction} */}
  </div>
  { 
      <div onClick={handleApproveButtonClick} title={!isLabelDefined ? "disabled" : ""} style={{opacity: !isLabelDefined ? 0.5 : 1}}>
        <DrawerButton status={'Approve'} buttonText={resource?.activePageActions?.affirmationButton?.buttonText || "Approve"} side="right" />
      </div>    
  }
  </>                      
                    {/* <IconButton style={{color:'#1094a2'}}>
                                      <CheckCircleOutlinedIcon/>
                                    </IconButton> */}
                  </CardActions>
                }

                </div>

                </div>
                </div>

              </Card>
              {
                showViewReferenceContextInformation &&
                <Card style={{ display:"flex", flexWrap:"nowrap", gap:"3px", border: "none", boxShadow: "5px 5px 20px #028D9A33", height: props.isFocusMode ? "600px" : "400px", width: "600px", overflow: "auto"}}  className={clsx(styles.card)}>
                  <div className={css.InformationHeading}>Information</div>
                  <div className={css.referenceContextContainer}>
                    {resource?.referenceContexts?.map((context)=>{
                      return (
                        <div className={css.referenceContext}>{context?.text}</div>
                      )
                    })}
                  </div>
              </Card>
              }
              </div>
                            </div>
                                {/* <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "10px"}}>
                                  <div className={styles.detailsLink} onClick={showDetails}>View Details</div>
                                  {
                                    !isEditAnnotationsEnabledByUser && resource.coPilot.type == "imageAnnotation" && 
                                    <div className={styles.detailsLink} onClick={()=>setIsEditAnnotationsEnabledByUser(true)} >Edit Annotations</div>
                                  }
                                  {
                                    (
                                      isEditAnnotationsEnabledByUser ||
                                      detailsCard
                                    )
                                    &&
                                    <div className={styles.detailsLink} 
                                      onClick={()=>{
                                        setIsEditAnnotationsEnabledByUser(false)
                                        setDetailsCard(false)
                                      }} 
                                    >
                                      Back
                                    </div>
                                  }
                                </div> */}
                            </Grid>
                            {detailsCard &&
                            <Grid alignItems="center"
                              classes={{ 
                                root: `
                                  ${(resource?.coPilot?.type === "imageAnnotation" || resource?.coPilot?.type === "bestAnswer" || resource?.coPilot?.type === "evaluateAnswer") ? classes.detailsCardGridWhenCopilotTypeImageAnnotation : ""}
                                  ${(resource?.coPilot?.type === "textAnnotation" || resource?.coPilot?.type === "DLP") ? classes.detailsCardGridWhenCopilotTypeTextAnnotation : ""}
                                `
                            }}
                              style={{
                                margin: 0, 
                                width:'320px'
                              }}
                        // item xs={6} sm={6} md={4} lg={4}
                        >
                          <Card style={{ padding: '0px 10px 0px 25px', marginTop: (resource?.coPilot?.type === "imageAnnotation" || resource?.coPilot?.type === "textAnnotation" || resource?.coPilot?.type === "bestAnswer" || resource?.coPilot?.type === "evaluateAnswer" || resource?.coPilot?.type === "DLP") ? "35px" : "1px", display:"flex", flexWrap:"nowrap", gap:"3px", border: "none", boxShadow: "none", height: (resource?.coPilot?.type === "imageAnnotation" || resource?.coPilot?.type === "textAnnotation" || resource?.coPilot?.type === "DLP") ?"100%":"400px", width: "305px", overflow: "auto"}}  className={clsx(styles.card)}>
                            {
                              resource.coPilot.type !== "text" &&
                              resource.coPilot.type !== "textAnnotation" &&
                              resource.coPilot.type !== "DLP" &&
                              <div>
                                  <p className={classes.details} style={{ color:"#8e969e"}}>File Name : </p>
                                  <p className={classes.details}><FileNameClipper fileName={resource.filename} currentPage="viewDetails"/></p>
                              </div>
                            }
                              <div>
                                  <p className={classes.details} style={{ color:"#8e969e"}}>Tag : </p>
                                  <p className={classes.details}>{resource.tag}</p>
                              </div>
                              <div> {console.log("Created at and Modified at : ", convertDate(props.resource.created_at || ""), props.resource.created_at, convertDate(props.resource.statusLastModifiedAt || ""))}
                                  <p className={classes.details} style={{ color:"#8e969e"}}>Created On   : </p>
                                  <p className={classes.details} >{moment(resource.created_at || "").format('MMMM Do YYYY')}</p>
                              </div>
                              {
                                (resource.coPilot.type==="image" || resource.coPilot.type === "video" || resource.coPilot.type==="text"|| resource.coPilot.type==="audio") &&
                              <div>
                                  <p className={classes.details} style={{ color:"#8e969e"}}>Confidence Score : </p>
                                  <p className={classes.details} >{`${resource.confidenceScore}%`}</p>
                              </div>
                              }
                              <div>
                                  <p className={classes.details} style={{ color:"#8e969e"}}>Last Modified : </p>
                                  <p className={classes.details} >{convertDate(props.resource.statusLastModifiedAt || "")}</p>
                              </div>

                              <div
                                  style={{
                                      display: "flex"
                                  }}
                              >
                                  <p className={classes.details} style={{ color:"#8e969e"}}>CSV:</p>
                                  <p
                                      className={classes.details}
                                      style={{
                                          display: "flex",
                                          flexDirection: csvExpandedFormShowing ? "column" : "row",
                                          width: "auto",
                                          overflow: "hidden",
                                          whiteSpace: "normal",
                                          textOverflow: "ellipsis",
                                          wordWrap: "break-word"                            
                                      }}
                                  >
                                      {
                                          props.resource?.csv?.length || 0 > csvInitialMaxLengthLimit
                                          ? csvExpandedFormShowing
                                              ? <><ShowLessLink onClick={()=>setCsvExpandedFormShowing(false)} /> {" "} <span dangerouslySetInnerHTML={{__html: props.resource?.csv || ""}} /> {"  "} </>
                                              : <span dangerouslySetInnerHTML={{__html: props.resource?.csv?.substring(0, csvInitialMaxLengthLimit)+"..."}} />
                                          : <span dangerouslySetInnerHTML={{__html: props.resource?.csv || ""}} />
                                      }
                                      {
                                          props.resource?.csv?.length || 0 > csvInitialMaxLengthLimit
                                          ? csvExpandedFormShowing
                                              ?   (
                                                  <ShowLessLink onClick={()=>setCsvExpandedFormShowing(false)} />
                                              )
                                              :   (
                                                  <ShowMoreLink onClick={()=>setCsvExpandedFormShowing(true)} />
                                              )
                                          : null
                                      }                    
                                  </p>
                              </div>

                              <div>
                                  <p className={classes.details} style={{ color:"#8e969e"}}>Add Remark : </p>
                                <p style={{marginBottom:'8px'}}>

                                  <textarea value={remarkText} 
                                    onChange={(e)=>{
                                      setRemarkTextState(oldState=>({
                                        ...oldState,
                                        isDirty: true,
                                        value: e.target.value
                                      }))
                                    }}
                                    rows={3}
                                   className={classes.textarea}>{resource.remarks}</textarea>
                                </p>

                              </div>
                                <div style={{ display: "flex", justifyContent: "center", paddingBottom:'12px'}} >
                              <Button color='primary' variant="contained" className={classes.remarkButton} onClick={updateRemark}>Save Remark</Button>
                            </div>
                            {updateAlert === "success" &&
                            <div style={{display:'flex', justifyContent:'center', position:'absolute', zIndex: 99, height:'18px'}}
                            className={`
                            ${(resource.coPilot.type === "imageAnnotation" || resource.coPilot.type === "textAnnotation" || resource.coPilot.type === "DLP") ? classes.imageAnnotationUpdateAlert : classes.updateAlert}
                            `}>
                                {/* alert text   */}
                                <Alert  severity="success" >
                                  <p>Remarks Added</p>
                                  </Alert>
                            </div>}
                            {updateAlert === "error" &&
                            <div style={{display:'flex', justifyContent:'center', position:'absolute', zIndex: 99, height:'18px'}}
                            className={`
                            ${(resource.coPilot.type === "imageAnnotation" || resource.coPilot.type === "textAnnotation" || resource.coPilot.type === "DLP") ? classes.imageAnnotationUpdateAlert : classes.updateAlert}
                            `}>
                              <Alert  severity="error" >
                                    "error on reject"
                                    </Alert>
                            </div>}
                          </Card>
                            {/* <Card style={{}}>
                                <CardContent>
                                </CardContent>
                                <CardContent>
                                  
                                </CardContent>
                                <CardActions disableSpacing style={{ display: "flex", justifyContent: "space-around"}}>
                                  
                                </CardActions>
                                </Card> */}
                            </Grid>
                            }

                            {false && showViewReferenceContextInformation &&
                            <Grid alignItems="center"
                              classes={{ 
                                root: `
                                  ${(resource?.coPilot?.type === "imageAnnotation" || resource?.coPilot?.type === "bestAnswer") ? classes.detailsCardGridWhenCopilotTypeImageAnnotation : ""}
                                  ${(resource?.coPilot?.type === "textAnnotation" || resource?.coPilot?.type === "DLP") ? classes.detailsCardGridWhenCopilotTypeTextAnnotation : ""}
                                `
                            }}
                              style={{
                                margin: 0, 
                                width:'320px'
                              }}
                        // item xs={6} sm={6} md={4} lg={4}
                        >
                          <Card style={{ padding: '0px 10px 0px 25px', marginTop: (resource?.coPilot?.type === "imageAnnotation" || resource?.coPilot?.type === "textAnnotation" || resource?.coPilot?.type === "bestAnswer" || resource?.coPilot?.type === "DLP") ? "35px" : "1px", display:"flex", flexWrap:"nowrap", gap:"3px", border: "none", boxShadow: "none", height: (resource?.coPilot?.type === "imageAnnotation" || resource?.coPilot?.type === "textAnnotation" || resource?.coPilot?.type === "DLP") ?"100%":"400px", width: "305px", overflow: "auto"}}  className={clsx(styles.card)}>
                            {resource?.referenceContexts?.map((context)=>{
                              return (
                                <div className={css.referenceCotext}>{context?.text}</div>
                              )
                            })}
                          </Card>
                            {/* <Card style={{}}>
                                <CardContent>
                                </CardContent>
                                <CardContent>
                                  
                                </CardContent>
                                <CardActions disableSpacing style={{ display: "flex", justifyContent: "space-around"}}>
                                  
                                </CardActions>
                                </Card> */}
                            </Grid>
                            }

                  
                            
              </Paper>
          </div>
    </div>
    </>
  )
}

  
export default SpectrogramAudioItemCard