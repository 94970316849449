import { InfoOutlined } from '@material-ui/icons'
import { InputLabel } from '@mui/material'
import React from 'react'
import { Theme } from '../../../theme'
import CustomTooltip from '../tooltip/Tooltip';

interface IProps {
    label?: string;
    tooltipTitle?: string;
    children?: React.ReactNode;
    additionalChildAfterInfo?: React.ReactNode;  // can be used for example a loader after the info icon
}

const InputLabelWithInfo = ({ label, tooltipTitle, children, additionalChildAfterInfo }: IProps) => {
    return (
        <InputLabel style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
            {
                label ? label
                : children
            }
            {
                tooltipTitle
                &&
                <CustomTooltip title={tooltipTitle} placement='right'>
                    <InfoOutlined style={{ color: Theme.palette.grey[500], width: '1rem' }} />
                </CustomTooltip>
            }
            {
                additionalChildAfterInfo ?? null
            }
        </InputLabel>
    )
}

export default InputLabelWithInfo