import React, { useEffect, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useHistory, useLocation } from "react-router";
import { CustomSkeleton, IModel, IModelAnalyticsAccuracy, IModelAnalyticsTestDataSet, IModelAnalyticsTestStatisticalData, IModelCollectionsAdditionalData, modelCollectionAdditionalDataRequestPayloadForIntegrity } from '../../../../../../../../../../../../common';
import { ModelCollectionAccuracy } from '../ModelCollectionAccuracy';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Box, Button, IconButton, Popover, Slider, Switch, TableHead, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListIcon from '@material-ui/icons/FilterList';
import { InputFieldState } from '../../../../../../../../../../../../common/constants/interfaces/inputFieldState';
import useSetUrlSearchParamsOfCurrentRoute from '../../../../../../../../../../../../hooks/useSetUrlSearchParamsOfCurrentRoute';
import { ModelAnalyticsInterityApiResponseData } from '../../../../../../../../../../../../common/constants/interfaces/modelAnalyticsIndividualClassAccuracyApiResponse';
import { DashboardIntegrityFilter } from '../ModelAnalyticsDashboard';
import { IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, URLQueryParams } from '../integrity';
const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    integrity:{
        height: "154px",
    },
    tableCell:{
      // font: "normal normal normal 600 11px/15px Nunito Sans",
      // fontSize: "11px",
        textAlign:'center',
        color: "#141414",
        padding: "8px 4px",
        '@media (min-width:1500px)' : {
          font: "normal normal normal 600 13px/15px Nunito Sans",
        },
        '@media (max-width:1500px) and (min-width:1000px)' : {
          font: "normal normal normal 600 13px/15px Nunito Sans",
        },
        '@media (max-width:1000px)' : {
          font: "normal normal normal 600 12px/15px Nunito Sans",
        },
    },
    heading:{
      height: "40px",
      textAlign: "left",
        font: "normal normal 600 13px/15px Nunito Sans",
        letterSpacing: "0.32px",
        color: "#1A1A1A",
        opacity: 1,
      padding: "0px 0px 0px 12px",
      //  padding: "14px 0 8px 12px",
      margin: "0px",
      fontWeight: 600,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    learnMore: {
      color: "#008D9C",
      // font: "normal normal bold 11px/15px Nunito Sans",
      fontSize: "11px",
      cursor: "pointer",
      '@media (min-width:1500px)' : {
        font: "normal normal bold  13px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal bold 13px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal bold 12px/15px Nunito Sans",
      },

    },
    tableCellTitle : {
      textAlign: "left",
      // font: "normal normal normal 600 11px/15px Nunito Sans",
      color: "#838C95",
      opacity: 1,
      padding: "0px 4px 0px 12px",
      maxWidth: "72px",
      '@media (min-width:1500px)' : {
        font: "normal normal normal 600 12px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal normal 600 13px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal normal 600 12px/15px Nunito Sans",
      },
    },
    tableContainer: {
      position: "relative",
      height: "calc(100% - 47.5px)"
    },
    table: {
     // height: "100%"
     paddingTop: "4px"
    },
    tr: {
      height: "28px"
    },
    popOverTableCell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center", 
      gap: 20
    },
    accuracyModelClass: {
      height: "24px",
    width: "50px",
    margin: "0 auto",
    fontSize: "9px",
    color: "#191919",
  
    '& div': {
      // fontSize: "9px",
      // font: "normal normal 600 9px/12px Nunito Sans", 
      color: "#191919",
      opacity:"unset",
      '@media (min-width:1500px)' : {
        font: "normal normal 600 12px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 600 11px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 600 10px/15px Nunito Sans",
      },
      }
    },
    liveAccuracyModelClass : {
      height: "24px",
    width: "50px",
    margin: "0 auto",
    fontSize: "9px",
    color: "#191919",
  
    '& div': {
      // fontSize: "9px",
      // font: "normal normal 600 9px/12px Nunito Sans", 
      // color: "#191919",
      opacity:"unset",
      '@media (min-width:1500px)' : {
        font: "normal normal 600 12px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 600 11px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 600 10px/15px Nunito Sans",
      },
      },
    }
  })
);

type Props = {
  modelAnalyticsIntegrity : IModelAnalyticsTestStatisticalData[], 
  modelDetails: IModel | undefined;

  // modelAnalyticsAccuracy: IModelAnalyticsAccuracy[] | any;
  // modelAnalyticsTestDataSet: IModelAnalyticsTestDataSet[];
  // modelVersionsToShow: string[];
  // modelAnalyticsTestStatisticalData: IModelAnalyticsTestStatisticalData[];

  // integrityData: ModelAnalyticsInterityApiResponseData;
  isFetchingIntegrityData: boolean;
  // isIntergrityFilterApplied: boolean;
  // integrityFilter: DashboardIntegrityFilter;
  // setIntegrityFilter: React.Dispatch<React.SetStateAction<DashboardIntegrityFilter>>;
  // modelCollectionsAdditionalDataQueryParamValueForIntegrity: modelCollectionAdditionalDataRequestPayloadForIntegrity[]
  
  // FetchModelAnalyticsTestStatisticalDataForIntegrityOfModel: (additionalData: IModelCollectionsAdditionalData[]) => Promise<void>, 
  
  // isFetchingModelAnalyticsTestStatisticalData: boolean, 
  // isTestCollectionPresent: boolean
};

interface IIntegritySliderData {
  modelCollectionId: string,
  confidenceScoreThreshold: number,
  sliderIsEnable: boolean
}

function Integrity( props: Props) {
  const history = useHistory();
  const location = useLocation();
    const classes = useStyles();
    const [isLiveModelCollectionExist, setIsLiveModelCollectionExist] = useState<boolean>(false);
    const [highestAccuracyValue, setHighestAccuracyValue] = useState<Number>(0);
    const [modelIntegrityPopOverOpen, setModelIntegrityPopOverOpen] = useState<boolean>(false);

    const handleCloseModelIntegrityPopOver = () => {
      setModelIntegrityPopOverOpen(false);
    }

    const [thresholdApplied, setThresholdApplied] = useState<IModelCollectionsAdditionalData []>()
    const setUrlSearchParamsOfCurrentRoute =
      useSetUrlSearchParamsOfCurrentRoute();
  
    const setUrlSearchParamsOfCurrentRouteRef = useRef(setUrlSearchParamsOfCurrentRoute);
    setUrlSearchParamsOfCurrentRouteRef.current=setUrlSearchParamsOfCurrentRoute;

    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
    const searchParams = new URLSearchParams(location.search);


    const IntegrityPopOver = (props: { 
      open: boolean,
      minValue: number,
      maxValue: number,
      onClose: () => void
      resetFilters: () => void
      isFilterApplied: boolean,
      onApplyFilterButtonClick: () => void,
      modelAnalyticsIntegrity : IModelAnalyticsTestStatisticalData[], 
      isFetchingIntegrityData: boolean,
      // setFilterMinMaxValue: (value: [number, number]) => Promise<void>
  }) => {

    let modelCollectionsAdditionalData = searchParams.get('modelCollectionsAdditionalDataForModelIntegrity');

  
    console.log("thresoldValue", thresholdApplied)
    
    const [sliderFieldState, setSliderFieldState] = useState< IIntegritySliderData []>(
      props.modelAnalyticsIntegrity.map((state) => {
        let thresholdValue = thresholdApplied?.find((prevState) => prevState.modelCollectionId===state.modelCollectionId)?.confidenceScoreThreshold
        if(thresholdValue===undefined){
          return {
            modelCollectionId: state.modelCollectionId,
            confidenceScoreThreshold: 90,
            sliderIsEnable: false
          }
        }
        return {
          modelCollectionId: state.modelCollectionId,
          confidenceScoreThreshold: thresholdValue,
          sliderIsEnable: true
        }
      })
    ); 

      return (
        <Popover
          id="integrity-model-popover-menu"
          anchorEl={document.getElementById("modelIntegrityOptions")}
          keepMounted
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
          }}
          transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}        
          open={props.open}
          onClose={props.onClose}
          PaperProps={{
            style: {
              width: "450px",
            },
          }}
        >
          {props.open && (
            <Box id="modelIntegrityMenuItemContainer" style={{ padding: 15 }}>
              <div
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>
                          Model
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          Confidence Score threshold
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!props.isFetchingIntegrityData &&
                    props.modelAnalyticsIntegrity?.map((cell, index: number) => {
                      return (
                        <TableRow>
                          <TableCell>{cell.version}</TableCell>
                          <TableCell className={classes.popOverTableCell}>
                            <Switch
                              checked={sliderFieldState.find(thresold => thresold.modelCollectionId === cell.modelCollectionId)?.sliderIsEnable}
                              onChange={() => {
                                setSliderFieldState((oldState) => {
                                  const currentSliderIsEnable = oldState.find(thresold => thresold.modelCollectionId === cell.modelCollectionId)?.sliderIsEnable
                                  return [...oldState.filter(thresold => thresold.modelCollectionId !== cell.modelCollectionId), {
                                    modelCollectionId: cell.modelCollectionId,
                                    confidenceScoreThreshold: 90,
                                    sliderIsEnable: !currentSliderIsEnable
                                  }];
                                })
                               }}
                              name="checkedA"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Slider
                              value={sliderFieldState.find((state)=> state.modelCollectionId===props.modelAnalyticsIntegrity[index].modelCollectionId)?.confidenceScoreThreshold}
                              min={0}
                              disabled={!sliderFieldState.find(thresold => thresold.modelCollectionId === cell.modelCollectionId)?.sliderIsEnable}
                              max={100}
                              color="primary"
                              step={1}
                              marks={[
                                { value: 0, label: "0%" },
                                { value: 50, label: "50%" },
                                { value: 100, label: "100%" },
                              ]}

                              // @ts-ignore
                              onChange={(e, value: number) => {
                                setSliderFieldState((oldState) => {
                                  return [...oldState.filter(thresold => thresold.modelCollectionId!==cell.modelCollectionId), {
                                    modelCollectionId: cell.modelCollectionId,
                                    confidenceScoreThreshold: value,
                                    sliderIsEnable: true
                                  }];
                                })
                              }}

                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </Table>
              </div>                    
                        
              <div id="resetFilterButtonContainer"
                  style={{marginTop: '40px', display: 'flex', justifyContent: 'flex-end', gap: 20}}
              >
                  <div></div>
                  <Button variant="outlined" color="primary"
                      onClick={()=>{
                        props.onClose()
                      }}
                      // disabled={!sliderFieldState.isDirty}
                  >
                      Cancel
                  </Button>
                  <Button variant="contained" color="primary"
                      onClick={()=>{
                        if(modelCollectionsAdditionalData===JSON.stringify(sliderFieldState)){
                          props.onClose()
                          return;
                        }
                        let urlParams=sliderFieldState
                        .filter((state) => state.sliderIsEnable)
                        .map((state) => {
                          return {
                            modelCollectionId: state.modelCollectionId,
                            confidenceScoreThreshold: state.confidenceScoreThreshold
                          };
                        })
                        if(urlParams.length===0)
                        {
                          setUrlSearchParamsOfCurrentRouteRef.current({
                            "modelCollectionsAdditionalDataForIntegrity": ""
                          }, "historyPush");
                        }
                        else{
                        setUrlSearchParamsOfCurrentRouteRef.current({
                          "modelCollectionsAdditionalDataForIntegrity": JSON.stringify(urlParams)
                        }, "historyPush")
                      }
                        props.onClose()
                      }}
                  >
                      Apply
                  </Button>
              </div>            
            </Box>
          )}
        </Popover>
      );
  }

  useEffect(() => {    
      

    let modelCollectionsAdditionalData = searchParams.get('modelCollectionsAdditionalDataForIntegrity');
          
    if(!modelCollectionsAdditionalData){
      setIsFilterApplied(false)
      setThresholdApplied([])
      return;
    } 
    if(modelCollectionsAdditionalData!=="")
    setIsFilterApplied(true)

    setThresholdApplied(Object.values(JSON.parse(modelCollectionsAdditionalData)))
    //props.FetchModelAnalyticsTestStatisticalDataForIntegrityOfModel(JSON.parse(JSON.stringify(modelCollectionsAdditionalData)))
      
  }, [location.search]); 


    useEffect(() => {
      let highestAccuracy = 0
      props.modelAnalyticsIntegrity.forEach((x: IModelAnalyticsTestStatisticalData) => {
        if(x.status === "Live"){
          setIsLiveModelCollectionExist(true)
        }
        if(x?.integrityFrequencyAccuracy?.value && x?.integrityFrequencyAccuracy?.value > highestAccuracy){
          highestAccuracy = x?.integrityFrequencyAccuracy?.value
        }
      })
      setHighestAccuracyValue(highestAccuracy)
      },[props.modelAnalyticsIntegrity])
    // let sortedModelAnalyticsIntegrity: IModelAnalyticsTestStatisticalData[]= props.modelAnalyticsIntegrity.sort((a, b) => (a.integrityFrequencyAccuracy?.value && b.integrityFrequencyAccuracy?.value && a.integrityFrequencyAccuracy?.value >= b.integrityFrequencyAccuracy?.value) ? -1 : 1);
    // console.log("sorted model analytics integrity is  :;; ",sortedModelAnalyticsIntegrity)

  return (
    <div className={classes.integrity}>
        <Paper className={classes.integrity}>
            <div className={classes.heading}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
              INTEGRITY
              {!props.isFetchingIntegrityData && !props.modelDetails?.isSegmentationModelAnalytics &&<Box
              >
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              id="modelIntegrityOptions"                          
              onClick={() => {
                setModelIntegrityPopOverOpen(true)
              }}
            >
              {
                isFilterApplied
                  ? (
                    <Tooltip title="Confidence score threshold Applied to Analyse the Top Performing Model">
                      <FilterListIcon />
                    </Tooltip>
                  )
                  : <MoreVertIcon />
              }
            </IconButton>
            {
              <IntegrityPopOver open={modelIntegrityPopOverOpen} 
              resetFilters={() => { } } 
              isFilterApplied={false}
              onApplyFilterButtonClick={() => { } } 
              minValue={0} 
              maxValue={0} 
              onClose={handleCloseModelIntegrityPopOver} //  setFilterMinMaxValue={() => {}} 
              modelAnalyticsIntegrity={props.modelAnalyticsIntegrity} 
              isFetchingIntegrityData={props.isFetchingIntegrityData}               />
            }
          </Box>}
              </div>
              {
                // !props.modelDetails?.isSegmentationModelAnalytics &&
                <div className={classes.learnMore} onClick={() => {
                      const query = new URLSearchParams(location.search);
                      const modelCollectionIds = query.get('modelCollectionIdsToCompare');
                      const testCollectionId = query.get("testCollectionId");
                      const versionSearchValue = query.get("versionSearchValue");
                      const architectureSearchValue = query.get("architectureSearchValue")
                      const modelCollectionAdditionalData = (()=>{
                        if (!props.modelDetails?.isSegmentationModelAnalytics) {
                          return null;
                        }
                        return [
                          // @ts-ignore
                          ...modelCollectionIds?.split(",")?.map(modelCollectionId=>{
                            return {
                              modelCollectionId: modelCollectionId,
                              // confidenceScoreThreshold: CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS,
                              iouThreshold: IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
                            }
                          })
                        ]
                      })();
                      console.log('modelCollectionAdditionalData ~ modelCollectionAdditionalData:', modelCollectionAdditionalData)
                      const testCollectionUrl = location.pathname.replace("/modelAnalytics/dashboard", "") + "/testModel";
                      const modelCollectionUrl = location.pathname.replace("/modelAnalytics/dashboard", "") + "/model";
                      // history.push(`integrity?model=${query.get("model")}&type=${query.get("type")}&project=${query.get("project")}&status=dashboard&modelCollectionIdsToCompare=${modelCollectionIds}${testCollectionId ? `&testCollectionId=${testCollectionId}` : ""}${modelCollectionAdditionalData && modelCollectionAdditionalData?.length>0 ? `&${URLQueryParams.MODEL_COLLECTIONS_ADDITIONAL_DATA}=${JSON.stringify(modelCollectionAdditionalData)}` : ""}`);
                      if(testCollectionId) {
                        history.push(`${testCollectionUrl}?model=${query.get("model")}&type=${query.get("type")}&project=${query.get("project")}&status=dashboard&modelCollectionIdsToCompare=${modelCollectionIds}${testCollectionId ? `&testCollectionId=${testCollectionId}` : ""}${modelCollectionAdditionalData && modelCollectionAdditionalData?.length>0 ? `&${URLQueryParams.MODEL_COLLECTIONS_ADDITIONAL_DATA}=${JSON.stringify(modelCollectionAdditionalData)}` : ""}&versionSearchValue=${versionSearchValue}&viewIntegrity=true`);
                      } else {
                        history.push(`${modelCollectionUrl}?status=approved&offset=1&limit=10&model=${query.get("model")}&type=${query.get("type")}&project=${query.get("project")}&modelCollectionIdsToCompare=${modelCollectionIds}${modelCollectionAdditionalData && modelCollectionAdditionalData?.length>0 ? `&${URLQueryParams.MODEL_COLLECTIONS_ADDITIONAL_DATA}=${JSON.stringify(modelCollectionAdditionalData)}` : ""}&versionSearchValue=${versionSearchValue}&architectureSearchValue=${architectureSearchValue}&viewIntegrity=true`);
                      }
                
                }}>
                Learn More <span><KeyboardArrowRightIcon style={{fontSize: "16px", marginLeft:"2px"}} /> </span>
                </div>
              }

            </div>

            <hr style={{margin:"0px"}}/>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader style={{borderBottom: "none"}} className={classes.table}>
                    <TableBody>
                        <TableRow  className={classes.tr}>
                            <TableCell className={classes.tableCellTitle}>
                                MODELS
                            </TableCell>
                            {
                              (props.isFetchingIntegrityData) &&
                              new Array(5).fill("").map((item, index) => (
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                              ))
                            }
                            {
                              (!props.isFetchingIntegrityData) &&
                              props.modelAnalyticsIntegrity?.map((cell, index) => {
                                return (
                                <TableCell className={classes.tableCell} key={cell.modelCollectionId}>
                                  {cell?.version}
                                </TableCell>)
                              })
                            }
                        </TableRow>
                        <TableRow  className={classes.tr}>
                            <TableCell className={classes.tableCellTitle}>
                                INTEGRITY FREQUENCY
                            </TableCell>
                            {
                              (props.isFetchingIntegrityData) &&
                              new Array(5).fill("").map((item, index) => (
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                              ))
                            }
                            {
                              (!props.isFetchingIntegrityData) &&
                              props.modelAnalyticsIntegrity?.map((cell, index) => {
                                return (
                                <TableCell className={classes.tableCell} key={cell.modelCollectionId}  style={{color: '#575757'}}>
                                  {cell.integrityFrequency}
                                </TableCell>
                                )
                              })
                            }
                        </TableRow>
                        <TableRow  className={classes.tr}>
                            <TableCell className={classes.tableCellTitle}>
                                TOTAL ACCURACY
                            </TableCell>
                            {
                              (props.isFetchingIntegrityData) &&
                              new Array(5).fill("").map((item, index) => (
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                              ))
                            }
                            {
                              (!props.isFetchingIntegrityData) &&
                              props.modelAnalyticsIntegrity?.map((cell, index) => {
                                return (
                                <TableCell className={classes.tableCell} key={cell.modelCollectionId}>
                                  <ModelCollectionAccuracy 
                                  className={(cell.status === "Live" || (cell?.integrityFrequencyAccuracy && (cell?.integrityFrequencyAccuracy?.value <=0)))? classes.liveAccuracyModelClass : classes.accuracyModelClass}
                                  isLiveModelCollection={cell.status === "Live"}
                                  liveCollectionAccuracy={0}
                                  accuracyStatus ={( index === 0 || cell?.integrityFrequencyAccuracy?.value === highestAccuracyValue) && ((cell?.integrityFrequencyAccuracy?.type === "noLiveExists") || !isLiveModelCollectionExist) ? "customHeighestNoLiveExists" : !isLiveModelCollectionExist ? "customNoLiveExists" : cell?.integrityFrequencyAccuracy?.type}
                                  accuracyValue={cell?.integrityFrequencyAccuracy?.value as any || 0} 
                                  jsonFileData = {undefined}
                                  // modelCollectionFiles={[]} 
                                />       
                                </TableCell>)
                              })
                            }
                        </TableRow>
                    </TableBody>
                </Table>

            </TableContainer>
        </Paper>
    </div>
  )
}

export default Integrity