import React, {useCallback, useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useHistory, useLocation } from "react-router";
import { CustomSkeleton, ICollectionMetrics, IModel, IModelAnalyticsAccuracy, IModelAnalyticsTestDataSet, IModelAnalyticsTestStatisticalData, IModelCollection, modelCollectionAdditionalDataRequestPayloadForIndividualClassAccuracy, useQuery } from '../../../../../../../../../../../../common';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ModelAnalyticsIndividualClassAccuracyApiResponseData, ModelAnalyticsIndividualClassAccuracyApiResponseDataClass } from '../../../../../../../../../../../../common/constants/interfaces/modelAnalyticsIndividualClassAccuracyApiResponse';
import { DashboardIndividualClassAccuracyFilter } from '../ModelAnalyticsDashboard';
import { DashboardIndividualClassAccuracyFilterPopover } from './dashbaordIndividualClassAccuracyFilterPopover/dashbaordIndividualClassAccuracyFilterPopover';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import css from "./IndividualClassAccuracy.module.css";
import { CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE, IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS } from '../integrity';
import { DataService } from '../../../../../../../../../../../../services';
import { collectionMetricsFromAiModelTestResultCSVApiResponse } from '../../../../../../../../../../../../services/DataService';
import { isNullOrUndefined } from '../../../../../../../../../../../../services/variableHelperService';

const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    componentRoot: {
      '@media (min-width:1150px)' : {
        height: "100%",
      }
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    individualClassAccuracy:{
        // height: "300px",
        // height:  "424px",
        height: "100%",
        // '@media (min-width:1150px)' : {
        // }        
    },
    tableCell:{
        padding: "8px 4px",
        textAlign: "center",
        width:"109px"
    },
    heading:{
      height: "40px",
      textAlign: "left",
        font: "normal normal 600 13px/15px Nunito Sans",
        letterSpacing: "0.32px",
        color: "#1A1A1A",
        opacity: 1,
        padding: "16px 13.29px 8px 12px",
        margin: "0px",
      // padding: "14px 0 8px 12px",
      //  margin: "0px",
      fontWeight: 600,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    learnMore: {
      color: "#008D9C",
      // font: "normal normal bold 11px/15px Nunito Sans",
      fontSize: "11px",
      cursor: "pointer",
      '@media (min-width:1500px)' : {
        font: "normal normal bold  13px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal bold 13px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal bold 12px/15px Nunito Sans",
      },
    },
    tableContainer: {
        position: "relative",
        maxHeight: "calc(100% - 47.5px)",
        width: "100%",
       //  margin:"0 auto",
       padding: "0 10px"
      },
      table: {
        // height: "100%"
        // paddingTop: "16px",
        '& .MuiTableCell-root': {
          // font: "normal normal 600 11px/15px Nunito Sans",
          letterSpacing:"0.28px",
          '@media (min-width:1500px)' : {
            font: "normal normal 600  13px/15px Nunito Sans",
          },
          '@media (max-width:1500px) and (min-width:1000px)' : {
            font: "normal normal 600 13px/15px Nunito Sans",
          },
          '@media (max-width:1000px)' : {
            font: "normal normal 600 12px/15px Nunito Sans",
          },
        },
        '& MuiTableRow-head': {
          color: '#575757'
        },
        '& .MuiTableCell-body':  {
          color: '#575757'
        }
       },
       tr: {
         height: "46px"
       },
       theadRow: {
        // height: "36px"
       },
      gray: {
        color: "#838C95 !important",
        // font: "normal normal normal 13px/15px Nunito Sans !important",
        '@media (min-width:1500px)' : {
          font: "normal normal 13px/15px Nunito Sans !important",
        },
        '@media (max-width:1500px) and (min-width:1000px)' : {
          font: "normal normal 15px/15px Nunito Sans !important",
        },
        '@media (max-width:1000px)' : {
          font: "normal normal 14px/15px Nunito Sans !important",
        },
      },
      modelVersionGray: {
        color: "#000000 !important",
        // font: "normal normal normal 13px/15px Nunito Sans !important",
        '@media (min-width:1500px)' : {
          font: "normal normal 600 13px/15px Nunito Sans !important",
        },
        '@media (max-width:1500px) and (min-width:1000px)' : {
          font: "normal normal 600 15px/15px Nunito Sans !important",
        },
        '@media (max-width:1000px)' : {
          font: "normal normal 600 14px/15px Nunito Sans !important",
        },
      },
      verticalPad: {
        paddingTop: 0,
        paddingBottom: 0
      },
      pt0: {
        paddingTop: 0,
      },
      pb10 : {
        paddingBottom: "10px"
      },
      modelVersionCol: {
        color: "#191919",
      },
      totalTrainedDatasetCountCol:  {
        color: "#838C95"
      },
      highestAccuracy: {
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        color: "#02BC77",
        opacity: 1,
        // font: "normal normal bold 11px/15px Nunito Sans;",
        letterSpacing: "0.22px",
        borderRadius: "10px",
          width: "80px",
        height:"32px",
        margin: "0 auto",
        transition: "all .3s ease-out",
        '&:hover' : {
        font: "normal normal bold 16px/22px Nunito Sans;",
          background:" #D4E8EA 0% 0% no-repeat padding-box",
          transform: "scale(1.0)",
        },
        '@media (min-width:1500px)' : {
          font: "normal normal bold  14px/15px Nunito Sans",
        },
        '@media (max-width:1500px) and (min-width:1000px)' : {
          font: "normal normal bold 13px/15px Nunito Sans",
        },
        '@media (max-width:1000px)' : {
          font: "normal normal bold 12px/15px Nunito Sans",
        },
      }
      
  }),
);

type Props = {
  modelDetails: IModel | undefined;
  modelAnalyticsAccuracy: IModelAnalyticsAccuracy[] | any;
  modelAnalyticsTestDataSet: IModelAnalyticsTestDataSet[];
  // isFetchingModelAnalyticsTestDataSet: boolean;
  // isFetchingModelAnalyticsAccuracy: boolean;
  modelVersionsToShow: string[];
  modelAnalyticsTestStatisticalData: IModelAnalyticsTestStatisticalData[];
  // isFetchingModelAnalyticsTestStatisticalData: boolean;
  
  individualClassAccuracyData: ModelAnalyticsIndividualClassAccuracyApiResponseData;
  isFetchingIndividualClassAccuracyData: boolean;
  isIndividualClassAccuracyFilterApplied: boolean;
  individualClassAccuracyFilter: DashboardIndividualClassAccuracyFilter;
  setIndividualClassAccuracyFilter: React.Dispatch<React.SetStateAction<DashboardIndividualClassAccuracyFilter>>;
  modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy: modelCollectionAdditionalDataRequestPayloadForIndividualClassAccuracy[]
};


const containsClass = (classToCheck: string, classes: {class: string}[]): boolean => {
  if (classes?.length>0) {
    for (const label of classes) {
      if (label.class === classToCheck) {
        return true
      }
    }
  }
  return false
}



function IndividualClassAccuracy(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useQuery()

  const testCollectionId = urlSearchParams.get('testCollectionId')
  const modelId = urlSearchParams.get('model')
  const modelCollectionIdsToCompareParam = urlSearchParams.get('modelCollectionIdsToCompare');
  let modelCollectionIdsToCompare: string[] = [];
  if (modelCollectionIdsToCompareParam) {
      if (Array.isArray(modelCollectionIdsToCompareParam)) {
          modelCollectionIdsToCompare = modelCollectionIdsToCompareParam;
      } else {
          modelCollectionIdsToCompare = modelCollectionIdsToCompareParam.split(',');
      }
  }

  const [modelAnalyticsAccuracy, setmodelAnalyticsAccuracy] = useState<any>([])
  const [collectionMetricsFromAiModelTestResult, setCollectionMetricsFromAiModelTestResult] = useState<ICollectionMetrics[]>([])
  const [isFetchingCollectionMetrics, setIsFetchingCollectionMetrics] = useState<boolean>(false)

  const classes = useStyles();

  const isSegmentationModelAnalyticsEnabled = props.modelDetails?.isSegmentationModelAnalytics;

  const uniqueClasses = (() => {
    const uniqueClasses: ModelAnalyticsIndividualClassAccuracyApiResponseDataClass[] = [];
    for (const modelCollection of props.individualClassAccuracyData) {
      for (let classIndex = 0; classIndex < modelCollection.classes.length; classIndex++) {
        const modelCollectionClass = modelCollection.classes[classIndex];
        if (!containsClass(modelCollectionClass.class, uniqueClasses)) {
          uniqueClasses.push(modelCollectionClass)
        }
      }
    }
    return uniqueClasses;
  })();
  
  // useEffect(() => {
  //   const modelAnalyticsAccuracyToShow = props.modelAnalyticsAccuracy.filter((data:any)=> {
  //     return props?.modelVersionsToShow.every((version) => {
  //       return version === data?.modelVersion
  //     })
  //   })
  //   setmodelAnalyticsAccuracy(modelAnalyticsAccuracyToShow)
  // },[])
  useEffect(() => { 
    const modelVersionsToShow = props.modelAnalyticsAccuracy.filter((model : any) => {
      return props.modelVersionsToShow.some(version => {
        return model.modelVersion === version
      })
    })
    // console.log(" model versions to show::::::::::::::::::::::::: ", modelVersionsToShow)
    if(props.modelAnalyticsAccuracy && props.modelAnalyticsTestDataSet) {
      const label: any  = props.modelAnalyticsTestDataSet
      const data: any = JSON.parse(JSON.stringify(modelVersionsToShow))
      label.forEach((x: any) => {
        let highestValue = 0
        let highestIndex  =  0
        data.forEach((y: any, j: number) => {
          if(j === 0)  {
          highestValue = (y?.accuracy?.accuracyJsonData?.[x?.label] || y?.accuracy?.accuracyJsonData?.[x?.label]?.Accuracy) || 0
          highestIndex = j
          } else {
            if (highestValue < (y?.accuracy?.accuracyJsonData?.[x?.label] || y?.accuracy?.accuracyJsonData?.[x?.label]?.Accuracy)) {
               highestValue = (y?.accuracy?.accuracyJsonData?.[x?.label] || y?.accuracy?.accuracyJsonData?.[x?.label]?.Accuracy)
               highestIndex = j
            }
          }
        })
        if(data[highestIndex]?.accuracy?.accuracyJsonData) {
          data[highestIndex].accuracy.accuracyJsonData[x.label+'highest'] = (highestValue ? `${x.label}_true` : `${x.label}_false)`  || `${x.label}_false`)
        }
  
      })  
      setmodelAnalyticsAccuracy(data)  
      // const accuracySortedData = data.filter((data:any)=> {
      //   return props.modelAnalyticsTestStatisticalData.every((modelCollection) => {
      //     return data.modelVersion === modelCollection.version
      //   })
      // })
      // console.log(":::: ",accuracySortedData)
      // const accuracyData = accuracySortedData.sort((a : any,b : any) => props.modelAnalyticsTestStatisticalData.indexOf(a) - props.modelAnalyticsTestStatisticalData.indexOf(b))
      // console.log(":::: ",accuracyData)
      // setmodelAnalyticsAccuracy(accuracyData)
    }
   
  },  [props.modelAnalyticsAccuracy, props.modelAnalyticsTestDataSet, props.modelAnalyticsTestStatisticalData, props.modelVersionsToShow])

  const updateCollectionMetricsState = useCallback((modelCollectionId: string, testCollectionId: string, metrics: collectionMetricsFromAiModelTestResultCSVApiResponse, modelCollectionVersion) => {
    // if testCollectionId is passed, it will updata state based on testCollectionId
    setCollectionMetricsFromAiModelTestResult(prevState => {
        const newState = [...prevState]

        let objForCurrentId = testCollectionId ? newState.find(obj => obj.modelCollectionId === modelCollectionId && obj.testCollectionId === testCollectionId) : newState.find(obj => obj.modelCollectionId === modelCollectionId)

        if (objForCurrentId) {
            objForCurrentId = {
                ...objForCurrentId,
                ...metrics
            }
        }
        else newState.push({
            ...metrics,
            modelCollectionId: modelCollectionId,
            ...(testCollectionId ? {testCollectionId: testCollectionId} : {}),
            modelCollectionVersion: modelCollectionVersion
        })

        return newState
    })
}, [])

  // fetch metrics for each model collection
  const fetchCollectionMetrics = async () => {
    if (modelCollectionIdsToCompare && modelCollectionIdsToCompare.length > 0) {
        if (isSegmentationModelAnalyticsEnabled) {
            setIsFetchingCollectionMetrics(true)
            const apiResponse = await DataService.fetchModelCollectionsFromDB({
                model: modelId || "",
                ids: modelCollectionIdsToCompare,
                jsonFileData: "true"
            })
            const promises = apiResponse?.data?.collections?.map((modelCollection: IModelCollection) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const response = await DataService.getCollectionMetricsFromAiModelTestResultCSV({
                            modelCollectionId: modelCollection._id,
                            testCollectionId: testCollectionId || "",
                            //@ts-ignore
                            confidenceScoreThreshold: !isNullOrUndefined(props.modelDetails?.confidenceScoreThresholdForIntegrity) ? props.modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE,
                            //@ts-ignore
                            iouThreshold: !isNullOrUndefined(props.modelDetails?.iouThresholdValue) ? props.modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
                        })

                        // if(response.data){
                            updateCollectionMetricsState(modelCollection._id, testCollectionId || "", response.data || {}, modelCollection.version)
                        // }

                        resolve(response.data)
                    } catch (error) {
                        reject(error);
                    }
                })
            })

            await Promise.all(promises)
        }
        setIsFetchingCollectionMetrics(false)
    }
}

useEffect(() => {
    if(!isFetchingCollectionMetrics && !modelCollectionIdsToCompare.every(id => collectionMetricsFromAiModelTestResult.find(obj => obj.modelCollectionId === id))){
        fetchCollectionMetrics()
    }
}, [modelCollectionIdsToCompare, isFetchingCollectionMetrics])

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleLabelAccuracyOrIntegrityClick = (labelOfAccuracyOrIntegrity: string, modelCollectionId: string) => {

    let query = new URLSearchParams(window.location.search);

    const model = query.get('model') || '';
    const project = query.get('project') || '';
    const modelType = query.get('type') || '';
    const modelCollectionIdToCompareQuery = query.get('modelCollectionIdsToCompare') || '';
    const testCollectionId = query.get("testCollectionId") || "";    

    query = new URLSearchParams();   

    const modelCollection = props.individualClassAccuracyData?.find(modelCollection=>modelCollection._id === modelCollectionId);
    
    if (testCollectionId) {
      query.set("testCollectionId", testCollectionId);
    }

    query.set('sortByClassFilterSelected', labelOfAccuracyOrIntegrity);
    query.set('model', model);
    query.set('limit', '10');
    query.set('offset', '1')
    query.set('project', project);
    query.set('type', modelType);
    query.set('status', 'dashboard')
    query.set('modelCollectionIdFilterSelected', modelCollectionId);
    query.set('modelCollectionIdsToCompare', modelCollectionIdToCompareQuery)
    query.set('scenario', 'classAccuracy')
    query.set('accuracy', modelCollection?.accuracy?.toString() || "")
    if (isSegmentationModelAnalyticsEnabled) {
      query.set("iouThreshold", String(IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS))
    }

    // query.set('sortByValueFilterSelected', goodConfidence ? 'CORRECT FIRST' : 'INCORRECT FIRST');

    // query.set("sortField", SortField.PREDICTED_STATUS)
    // query.set("sortOrder", goodConfidence ? SortOrder.ASC : SortOrder.DESC)

    history.push({ pathname: '../modelAnalytics/resources', search: query.toString() })    
  }

  return (
    <div 
      className={`
        ${classes.componentRoot}
        ${isSegmentationModelAnalyticsEnabled ? css.segmentationModelAnalytics : ""}
      `}
    >
      <Paper className={classes.individualClassAccuracy}>
            <div className={classes.heading}>
            <div
              style={{
                display: 'flex',
              }}
            >
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              {
                isSegmentationModelAnalyticsEnabled
                ? "INDIVIDUAL CLASS INTEGRITY"   // to be changed back to "INDIVIDUAL CLASS ACCURACY AND INTEGRITY after fetch metrics API completion
                : "INDIVIDUAL CLASS ACCURACY"
              }
            </div>
            {
              !props.isFetchingIndividualClassAccuracyData &&
              !isSegmentationModelAnalyticsEnabled &&
              <Box>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                id="dashboardIndividualClassAccuracyModelOptions"                          
                onClick={() => {
                  setIsFilterOpen(true)
                }}
              >
                {
                  props.isIndividualClassAccuracyFilterApplied
                    ? (
                      <Tooltip title="Confidence score threshold Applied to Analyse the Individual Class Accuracies">
                        <FilterListIcon />
                      </Tooltip>
                    )
                    : <MoreVertIcon />
                }
              </IconButton>
            </Box> 
            }

              </div>
              {
                !isSegmentationModelAnalyticsEnabled &&
                <div className={classes.learnMore} onClick={() => {
                      const query = new URLSearchParams(location.search);
                      const modelCollectionIdsToCompare = query.get('modelCollectionIdsToCompare');
                      const testCollectionId = query.get("testCollectionId");
                      history.push(`IndividualClassAccuracy?model=${query.get("model")}&type=${query.get("type")}&project=${query.get("project")}&status=dashboard&modelCollectionIdsToCompare=${modelCollectionIdsToCompare}${testCollectionId ? `&testCollectionId=${testCollectionId}` : ""}`);
                }}>
                Learn More <span><KeyboardArrowRightIcon style={{fontSize: "16px", marginLeft:"2px"}} /> </span>
                </div>
              }
              </div>

            <hr style={{margin:"0px"}}/>
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead style={{backgroundColor: "white", position: "sticky", top: "0"}}>
                        <TableRow className={`${classes.tr} ${classes.theadRow}`} style={{height: "22px"}}>

                            <TableCell className={`${classes.tableCell} ${classes.verticalPad} ${classes.modelVersionCol}`} style={{position:'sticky', top:'0px', height: '38px', paddingTop: "16px"}} colSpan={2}>
                              <div className={`${classes.gray}`}  style={{color: "#838C95", textAlign: 'left', marginLeft: "16px"}}>MODELS</div>
                              <div className={`${classes.gray} ${css.totalDataSet}`} style={{color: "#838C95", marginTop: "8px", textAlign: 'left', marginLeft: "16px"}}>TOTAL DATASET</div>
                            </TableCell>
                            {
                              ((props.isFetchingIndividualClassAccuracyData && !isSegmentationModelAnalyticsEnabled) || (isSegmentationModelAnalyticsEnabled && isFetchingCollectionMetrics)) &&
                              new Array(5).fill("").map((item, index) => (
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                              ))
                            }
                            {/* {
                              (!props.isFetchingModelAnalyticsAccuracy&& !props.isFetchingModelAnalyticsTestStatisticalData && !props.isFetchingModelAnalyticsTestDataSet) &&
                              props.modelAnalyticsTestStatisticalData.map((data) => {
                                return (
                                  modelAnalyticsAccuracy?.map((cell: any) => {
                                  return(
                                    data.modelCollectionId === cell.modelCollectionId &&
                                      <TableCell key={cell.modelCollectionId} className={`${classes.tableCell} ${classes.verticalPad} ${classes.modelVersionCol}`} style={{position:'sticky', top:'0px', height: '38px', paddingTop: "16px"}}>
                                          <div className={`${classes.modelVersionGray}`} style={{color: "#000000 !important"}}>{cell.modelVersion}</div>
                                          <div className={`${classes.gray}`} style={{color: "#838C95", marginTop: "8px"}}>{cell?.totalTrainedDataSetsCount}</div>
                                      </TableCell>
                                  )
                              })
                              )
                              })
                                
                            } */}
                            {
                              (!props.isFetchingIndividualClassAccuracyData && !isSegmentationModelAnalyticsEnabled) &&
                              props.individualClassAccuracyData?.map((data) => {
                                  return(
                                    // data.modelCollectionId === cell.modelCollectionId &&
                                      <TableCell key={data._id} className={`${classes.tableCell} ${classes.verticalPad} ${classes.modelVersionCol}`} style={{position:'sticky', top:'0px', height: '38px', paddingTop: "16px"}}>
                                          <div className={`${classes.modelVersionGray}`} style={{color: "#000000 !important"}}>{data.version}</div>
                                          <div className={`${classes.gray} ${css.totalDataSetValue}`} style={{color: "#838C95", marginTop: "8px"}}>{data?.totalDataSetsCount}</div>
                                      </TableCell>
                                  )
                              })
                                
                            }
                            {
                                isSegmentationModelAnalyticsEnabled && !isFetchingCollectionMetrics
                                &&
                                collectionMetricsFromAiModelTestResult.map(obj => {
                                    return (
                                        <TableCell className={`${classes.tableCell} ${classes.verticalPad} ${classes.modelVersionCol}`} style={{position:'sticky', top:'0px', height: '38px', paddingTop: "16px"}}>
                                            <div className={`${classes.modelVersionGray}`} style={{color: "#000000 !important"}}>{obj.modelCollectionVersion}</div>
                                            <div className={`${classes.gray} ${css.totalDataSetValue}`} style={{color: "#838C95", marginTop: "8px"}}>""</div>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                        <TableRow key={"row.label"} className={`${classes.tr}`}>
                                        <TableCell className={`${classes.tableCell}  ${classes.gray}`} style={{fontSize: "13px !important;", textAlign: "left", paddingLeft: "20px"}}>
                                            CLASS
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.gray} ${css.dataTableColumnHeading}`} style={{textAlign: "left", paddingLeft: "0px"}}>
                                            DATA
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.verticalPad}`}>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.verticalPad}`}>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.verticalPad}`}>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.verticalPad}`}>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.verticalPad}`}>
                                        </TableCell>
                          </TableRow>
                        {/* <div style={{marginTop: "10px"}}> </div> */}
                        {/* <TableRow className={`${classes.tr} ${classes.theadRow}`} style={{height: "50px"}}>
                            <TableCell className={`${classes.tableCell} ${classes.pt0}`}>
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.pt0}`}>
                            </TableCell>
                            {
                              (props.isFetchingModelAnalyticsAccuracy || props.isFetchingModelAnalyticsTestStatisticalData || props.isFetchingModelAnalyticsTestDataSet) &&
                              new Array(5).fill("").map((item, index) => (
                                <TableCell>
                                  <CustomSkeleton height={42} />
                                </TableCell>
                              ))
                            }
                            
                        </TableRow> */}
                    </TableHead>
                    <TableBody>
                            {
                              ((!isSegmentationModelAnalyticsEnabled && props.isFetchingIndividualClassAccuracyData) || (isSegmentationModelAnalyticsEnabled && isFetchingCollectionMetrics)) &&
                              new Array(10).fill("").map((item, index) => (
                                <TableRow>
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                                <TableCell>
                                  <CustomSkeleton height={48} />
                                </TableCell>
                                </TableRow>
                              ))
                            }
                        {/* {
                          (!props.isFetchingModelAnalyticsAccuracy && !props.isFetchingModelAnalyticsTestDataSet) &&
                              (props.modelAnalyticsTestDataSet.map((row, index) => {
                                return(
                                    <TableRow key={row.label} className={`${classes.tr}`}>
                                        <TableCell className={`${classes.tableCell}  ${classes.modelVersionGray}`} style={{fontSize: "13px !important;", textAlign: "left", paddingLeft: "21px"}}>
                                            {row.label}
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.gray}`} style={{textAlign: "left"}}>
                                            {row.totalLabelCount}
                                        </TableCell>
                                        {
                                          props.modelAnalyticsTestStatisticalData.map((data) => {
                                            return modelAnalyticsAccuracy?.map((cell: any) => {
                                              const rowLabel = row.label;
                                                return (
                                                  data.modelCollectionId === cell.modelCollectionId &&
                                                    <TableCell key={cell.modelCollectionId} className={classes.tableCell}>
                                                            { (cell?.accuracy?.accuracyJsonData && (row.label in cell?.accuracy?.accuracyJsonData)) ?
                                                              <div className={`${cell?.accuracy?.accuracyJsonData[rowLabel+'highest'] &&  classes.highestAccuracy}`}>
                                                            {(typeof(cell?.accuracy?.accuracyJsonData[rowLabel]) === "object" ? cell?.accuracy?.accuracyJsonData[rowLabel]?.accuracy : cell?.accuracy?.accuracyJsonData[rowLabel]).toString()+"%" || "NA"}
                                                            </div>
                                                            :
                                                            <div>
                                                              {"NA"}
                                                            </div>
                                                            }
                                                    </TableCell>
                                                )
                                            })
                                          })
                                        }
                                    </TableRow>
                                )
                            }))
                        } */}
                        {
                          !isSegmentationModelAnalyticsEnabled && (!props.isFetchingIndividualClassAccuracyData) &&
                              // (props.individualClassAccuracyData?.length > 0 && props.individualClassAccuracyData[0].classes.map((row, index) => {
                              (uniqueClasses?.length > 0 && uniqueClasses.map((row, index) => {
                                return(
                                    <TableRow key={row.class} className={`${classes.tr}`}>
                                        <TableCell className={`${classes.tableCell}  ${classes.modelVersionGray}`} style={{fontSize: "13px !important;", textAlign: "left", paddingLeft: "21px"}}>
                                            {row.class}
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.gray} ${css.labelDataSetValue}`} style={{textAlign: "left"}}>
                                            {row.totalTestDataSets}
                                        </TableCell>
                                        {
                                          // props.modelAnalyticsTestStatisticalData.map((data) => {
                                          props.individualClassAccuracyData.map((data) => {
                                            function getHighestLabelAccuracyInAllModelCollections(rowLabel: string, individualClassAccuracyData: ModelAnalyticsIndividualClassAccuracyApiResponseData) {
                                              let highestAccuracy = 0;
                                              for (const modelCollection of individualClassAccuracyData) {
                                                for (const label of modelCollection.classes) {
                                                  if (label.class===rowLabel && label.goodConfidenceScore > highestAccuracy) {
                                                    highestAccuracy = label.goodConfidenceScore;
                                                  }
                                                }
                                              }
                                              return highestAccuracy;
                                            }

                                            // return modelAnalyticsAccuracy?.map((cell: any) => {
                                            return props.individualClassAccuracyData?.map((cell) => {
                                              const rowLabel = row.class;

                                              const labelAccuracy = (cell.classes?.find(classed=>classed.class===rowLabel)?.goodConfidenceScore);

                                              const labelIntegrityAccuracy = (cell.classes?.find(classed=>classed.class===rowLabel)?.IntegrityAccuracy);

                                              const highestLabelAccuracyInAllModelCollections = getHighestLabelAccuracyInAllModelCollections(rowLabel, props.individualClassAccuracyData)

                                              const isHighestAccuracy = labelAccuracy === highestLabelAccuracyInAllModelCollections ? true : false;

                                                return (
                                                  data._id === cell._id &&
                                                    <TableCell key={cell._id} className={`${classes.tableCell} ${css.labelAccuracyTableCell}`}>
                                                            {/* { (cell?.accuracy?.accuracyJsonData && (row.class in cell?.accuracy?.accuracyJsonData)) ? */}
                                                            { (containsClass(rowLabel, cell.classes)) ?
                                                              // <div className={`${cell?.accuracy?.accuracyJsonData[rowLabel+'highest'] &&  classes.highestAccuracy}`}>
                                                              <div 
                                                                className={`
                                                                  ${isHighestAccuracy ? classes.highestAccuracy: ""}
                                                                  ${css.labelAccuracyAndIntegrityContainer}
                                                                `}
                                                                onClick={()=>handleLabelAccuracyOrIntegrityClick(rowLabel, data._id)}
                                                              >
                                                            {/* {(typeof(cell?.accuracy?.accuracyJsonData[rowLabel]) === "object" ? cell?.accuracy?.accuracyJsonData[rowLabel]?.accuracy : cell?.accuracy?.accuracyJsonData[rowLabel]).toString()+"%" || "NA"} */}
                                                            {/* {(cell.classes.find((class)=>class.class === rowLabel)?.goodConfidenceScore).toString()+"%" || "NA"} */}
                                                            <div className={css.labelAccuracyContainer}>
                                                              {isSegmentationModelAnalyticsEnabled ? <Tooltip title="Accuracy"><span>A:&nbsp;</span></Tooltip> : ""}{`${`${labelAccuracy?.toFixed(2).toString()}%` || "NA"}`}
                                                            </div>
                                                            {
                                                              isSegmentationModelAnalyticsEnabled &&
                                                              <div className={css.labelIntegrityContainer}><Tooltip title="Integrity"><span>I:&nbsp;</span></Tooltip> {`${`${labelIntegrityAccuracy?.toFixed(2).toString()}%` || "NA"}`}</div>
                                                            }
                                                            </div>
                                                            :
                                                            <div>
                                                              {"NA"}
                                                            </div>
                                                            }
                                                    </TableCell>
                                                )
                                            })
                                          })
                                        }
                                    </TableRow>
                                )
                            }))
                        }

                          {
                              isSegmentationModelAnalyticsEnabled && !isFetchingCollectionMetrics
                              &&
                              <>
                                  {
                                      collectionMetricsFromAiModelTestResult?.length && collectionMetricsFromAiModelTestResult[0]?.Labels
                                      &&
                                      Object.entries(collectionMetricsFromAiModelTestResult[0].Labels).map(([label, labelObj]) => {
                                          return (
                                              <TableRow>
                                                  <TableCell className={`${classes.tableCell}  ${classes.modelVersionGray}`} style={{ fontSize: "13px !important;", textAlign: "left", paddingLeft: "21px" }}>
                                                      {label}
                                                  </TableCell>
                                                  <TableCell className={`${classes.tableCell} ${classes.gray} ${css.labelDataSetValue}`} style={{ textAlign: "left" }}>
                                                      {/* {row.totalTestDataSets} */}
                                                  </TableCell>
                                                  {
                                                      collectionMetricsFromAiModelTestResult.map((collectionMetrics, index) => {
                                                          return (
                                                              collectionMetrics.Labels?.hasOwnProperty(label)
                                                              &&
                                                              <TableCell className={`${classes.tableCell} ${css.labelAccuracyTableCell}`}>
                                                                  {/* add this classname below: ${isHighestAccuracy ? classes.highestAccuracy : ""} */}
                                                                  <div
                                                                      className={`${css.labelAccuracyAndIntegrityContainer} ${classes.highestAccuracy}`}
                                                                      onClick={() => handleLabelAccuracyOrIntegrityClick(label, collectionMetrics.modelCollectionId || "")}
                                                                  >
                                                                      {/* <div className={css.labelAccuracyContainer}>
                                                                        {isSegmentationModelAnalyticsEnabled ? <Tooltip title="Accuracy"><span>A:&nbsp;</span></Tooltip> : ""}{`${`${labelAccuracy?.toFixed(2).toString()}%` || "NA"}`}
                                                                    </div> */}
                                                                      {
                                                                          <div className={css.labelIntegrityContainer}>
                                                                              <Tooltip title="Integrity"><span>I:&nbsp;</span></Tooltip> {`${`${labelObj.IntegrityAccuracy?.toFixed(2).toString()}%` || "NA"}`}
                                                                          </div>
                                                                      }
                                                                  </div>

                                                              </TableCell>
                                                          )
                                                      })
                                                  }
                                              </TableRow>
                                          )
                                      })
                                  }
                              </>
                          }
                    </TableBody>
                </Table>

            </TableContainer>
      </Paper>

      {
        isFilterOpen &&
        <DashboardIndividualClassAccuracyFilterPopover 
          open={true} 
          onClose={()=>{setIsFilterOpen(false)}} 
          isFilterApplied={props.isIndividualClassAccuracyFilterApplied} 
          isFetchingIndividualClassAccuracyData={props.isFetchingIndividualClassAccuracyData} 
          individualClassAccuracyData={props.individualClassAccuracyData} 
          individualClassAccuracyFilter={props.individualClassAccuracyFilter} 
          setIndividualClassAccuracyFilter={props.setIndividualClassAccuracyFilter} 
          modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy={props.modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy} />
      }
    </div>
  )
}

export default IndividualClassAccuracy