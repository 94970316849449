type TabObject = {
  tab: "/aboutModel" | "/problemType" | '/modelPurpose' |'/trainingModelOnSingleEpoch'| '/convertingDataFromModelFormatToTestingAnalytics'| "/modelDetailsTab" | '/dataFormat' | '/hyperParameters'| "/convertingFromRlefToModel" | '/modelDetails' | '/runtimeCode' | '/deployment' | '/dataMapping';
}

export const tabsCreatorHasSeen: TabObject[] = [
  { tab: "/aboutModel" },
  { tab: "/problemType" },
  { tab: "/modelDetailsTab" },
  { tab: "/convertingFromRlefToModel" }
];

export interface tabsCreatorHasSeenPayload {
  purposeModelId: string;
  tabDetail: TabObject;
}

export const RedAsterisk: React.FC = () => {
  return <span style={{ color: 'red' }}>*</span>;
};