import { makeStyles, Theme, createStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
// import { FaGalacticSenate } from "react-icons/fa";
import { IModelAnalyticsTestStatisticalData, accuracyValueInLocalStorage } from "../../../../../../../../../../../../common";
// import { CollectionAccuracy } from "../CollectionAccuracy";
import { ModelCollectionAccuracy } from "../ModelCollectionAccuracy";
import { DataService } from "../../../../../../../../../../../../services";
import { CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE, IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS } from "../integrity";
import { getCollectionAccuracyFromLocalStorage, keyToSaveCollectionAccuracyInLocalStorage, saveCollectionAccuracyInLocalStorage } from "../../TrainedModelPopup";
import { isNullOrUndefined } from "../../../../../../../../../../../../services/variableHelperService";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            height: 140,
            width: 100,
        },
        flex: {
            display: 'flex',
        },
        alignCenter: {
            alignItems: 'center'
        },
        chip: {
            margin: theme.spacing(0.5),
            maxWidth: "30px",
        },
        autoCompleteChipRoot: {
            maxWidth: "170px",
        },
        floating: {
            "& legend": {
                float: "unset",
            },
        },
        accuracyChip: {
            position: 'absolute',
            right: '35px',
            font: 'normal normal bold 12px/16px Nunito Sans',
            maxHeight: '24px',
            minWidth: '64px'
        },
        outlineInput: {
            marginTop: '12px',
            "& input": {
                fontSize: '12px',
                font: 'normal normal 600 14px/19px Nunito Sans'
            },
            "& fieldset": {
                borderColor: '#008D9C'
            }
        },
        labels: {
            fontSize: '12px'
        }
    }),
);
interface IComponentProps {
    modelsList: IModelAnalyticsTestStatisticalData[],
    selectedModel?: IModelAnalyticsTestStatisticalData,
    marginTop?: string;
    updateSelectedModel: Function;
    doNotShowAccuracy?: boolean;
    isSegmentationModelAnalytics?: boolean;
    iouThreshold: number;
    confidenceScoreThreshold?: number;
}

const DropdownWithAccuracyComponent = (props: IComponentProps) => {
    
    const classes = useStyles();
    const [isLiveModelCollectionExist, setIsLiveModelCollectionExist] = useState<boolean>(false);
    const [accuracy, setAccuracy] = useState<any>(-1)

    const urlParams = new URLSearchParams(window.location.search)

    useEffect(() => {
        props.modelsList.forEach((x: IModelAnalyticsTestStatisticalData) => {
          if(x.status === "Live"){
            setIsLiveModelCollectionExist(true)
          }
        })
        },[props.modelsList])
    // const fetchModelListValue = () => {
    //     return(
    //         props.modelsList?.filter(model => model.status === "Live")[0]?.testAccuracy?.value || 0
    //     )
    // }



    return (
        <>
            {
                props.selectedModel?.version &&
                <Autocomplete
                    id="modelDropdown"
                    size="small"
                    style={{ marginTop: props.marginTop || "20px", minWidth: '75%', position: 'relative' }}
                    options={props.modelsList}
                    closeIcon={() => { <></> }}
                    value={props.selectedModel}
                    classes={{ root: `${classes.flex} ${classes.alignCenter}` }}
                    getOptionLabel={option => option.version}
                    renderOption={option => {
                        return (
                            <li className={`${classes.labels}`}><div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <div style={{ paddingRight: '15px' }}>
                                    {option.version}
                                </div>
                                {
                                    !props.doNotShowAccuracy &&
                                    <div>
                                        <ModelCollectionAccuracy
                                            className={classes.accuracyChip}
                                            isLiveModelCollection={option.status === "Live"}
                                            liveCollectionAccuracy={
                                                props.modelsList?.filter(model => model.status === "Live")[0]?.testAccuracy?.value || 0
                                            }
                                            // accuracyStatus ={props?.modelsList?.filter(model => model.status === "noLiveExists")[0] && ((option?.testAccuracy?.type === "noLiveExists") || !isLiveModelCollectionExist) ? "customHeighestNoLiveExists" : !isLiveModelCollectionExist ? "customNoLiveExists" : option?.testAccuracy?.type}
                                            accuracyStatus={option.testAccuracy.type}
                                            accuracyValue={option.testAccuracy.value || 0}
                                            jsonFileData={undefined}
                                        />
                                    </div>
                                }
                            </div>
                            </li>
                        )
                    }}
                    onChange={(event, value) => {
                        props.updateSelectedModel(value)
                    }}
                    renderInput={(params) => {
                        return (
                            <>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    className={classes.floating}
                                    classes={{ root: classes.outlineInput }}
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    placeholder="Select Model" />
                                    {
                                        !props.doNotShowAccuracy &&
                                        <div style={{position: 'absolute', right: '4px', bottom: '39px'}}>
                                            <ModelCollectionAccuracy
                                                className={classes.accuracyChip}
                                                isLiveModelCollection={props.selectedModel?.status === "Live"}
                                                liveCollectionAccuracy={
                                                    props.modelsList?.filter(model => model.status === "Live")[0]?.testAccuracy?.value || 0
                                                }
                                                // accuracyStatus ={props?.modelsList[0] && ((props.selectedModel?.testAccuracy?.type === "noLiveExists") || !isLiveModelCollectionExist) ? "customHeighestNoLiveExists" : !isLiveModelCollectionExist ? "customNoLiveExists" : props.selectedModel?.testAccuracy?.type}
                                                accuracyStatus={props.selectedModel?.testAccuracy.type}
                                                accuracyValue={props.selectedModel?.testAccuracy?.value || 0}
                                                jsonFileData={undefined}
                                            />
                                        </div>
                                    }
                            </>
                        )
                    }}
                />
            }
        </>
    )
};

export default DropdownWithAccuracyComponent;