import ImageService from "./ImageService";

/**
 * @param originalImageWidth
 * @param imageWidthWhichWeWantToSet
 * @returns image scale factor
 */
export function getImageScaleFactorForImageWidth(
  originalImageWidth: number,
  imageWidthWhichWeWantToSet: number
): number {
  const imageScaleFactor = imageWidthWhichWeWantToSet / originalImageWidth;
  return imageScaleFactor;
}

export default class ImageScalarService {
  static getImageHeight(imageWidth: number, imageAspectRatio: number) {
    return imageWidth / imageAspectRatio;
  }
  static getImageWidth(imageHeight: number, imageAspectRatio: number) {
    return imageHeight * imageAspectRatio;
  }
}

export function getMaxImageWidthHeightWhichWeCanSet({
  originalImageWidth,
  originalImageHeight,
  maxImageWidthAllowed,
  maxImageHeightAllowed
}: {
  originalImageWidth: number,
  originalImageHeight: number,
  maxImageWidthAllowed: number,
  maxImageHeightAllowed: number
}): {
  imageWidthToSet: number,
  imageHeightToSet: number
} {
  let imageWidthToSet = 0;
  let imageHeightToSet = 0;

  const imageAspectRatio = originalImageWidth / originalImageHeight;  
  
  if (originalImageWidth >= originalImageHeight)
  {
    imageWidthToSet = maxImageWidthAllowed;
    imageHeightToSet = ImageService.getImageHeight(
      imageWidthToSet,
      imageAspectRatio
    )

    if (imageHeightToSet > maxImageHeightAllowed) {
      imageHeightToSet = maxImageHeightAllowed;
      imageWidthToSet = ImageService.getImageWidth(
        imageHeightToSet,
        imageAspectRatio
      );
    }         
  } 
  else if (originalImageHeight >= originalImageWidth) {
    imageHeightToSet = maxImageHeightAllowed;
    imageWidthToSet = ImageService.getImageWidth(
      imageHeightToSet,
      imageAspectRatio
    );

    if (imageWidthToSet > maxImageWidthAllowed) {
      imageWidthToSet = maxImageWidthAllowed;
      imageHeightToSet = ImageService.getImageHeight(
        imageWidthToSet,
        imageAspectRatio
      )
    }
  } 
  
  return {
    imageWidthToSet,
    imageHeightToSet
  }
}

export async function downloadImage(imageUrl: string){
  const image = new Image();
  image.src = imageUrl;
  image.onload = function() {
    return image
  }
  image.onerror = function() {
    // @ts-ignore
    alert(error.message);
    return;
  }
}
