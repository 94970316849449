import React, {useEffect , useState} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
// import { useHistory, useLocation } from "react-router"; 
// import { DataService } from '../../../../../../../../../../../../services';
import { CustomSkeleton, IModel, IModelAnalyticsAccuracy, IModelAnalyticsTestStatisticalData, accuracyValueInLocalStorage} from '../../../../../../../../../../../../common';
import { ModelCollectionAccuracy } from '../ModelCollectionAccuracy';
import ModelStatus from '../ModelStatus';
import { TimeDateFormat } from '../../../data/components/TimeDateFormat';
import css from "./ModelComparision.module.css";
import { DataService } from '../../../../../../../../../../../../services';
import { CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE, IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS } from '../integrity';
import { getCollectionAccuracyFromLocalStorage, keyToSaveCollectionAccuracyInLocalStorage, saveCollectionAccuracyInLocalStorage } from '../../TrainedModelPopup';
import { isNullOrUndefined } from '../../../../../../../../../../../../services/variableHelperService';

const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    dotWhenModelComparisonScenario: {
      margin: 'auto 11px auto 0'
    },
    modelComparision:{
        // width: "100%",
       // height: "330px"
       height: "437px",
       '@media (min-width:1150px)' : {
        height: "100%",
    }        
    },
    tableCell:{
        padding: "8px 4px",
        textAlign:"center",
        // font: "normal normal 600 11px/15px Nunito Sans",
        // fontSize: "11px"
       // height: "60px",
       '@media (min-width:1500px)' : {
        font: "normal normal  600 13px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal  600 13px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal  600 12px/15px Nunito Sans",
      },
    },
    heading:{
        height: "40px",
        textAlign: "left",
          font: "normal normal 600 13px/15px Nunito Sans",
          letterSpacing: "0.32px",
          color: "#1A1A1A",
          opacity: 1,
        padding: "14px 0 8px 12px",
        margin: "0px",
        fontWeight: 600,
      },
    tableContainer: {
        width: "100%",
       //  margin:"0 auto",
        // marginTop: "35px",
        padding: "0 10px",
        position: "relative",
        maxHeight: "calc(100% - 47.5px)"
    },
    table: {
        // height: "100%"
        paddingTop: "10px"
       },
       headertr: {
        height: "60px"
       },
       tr: {
         height: "64px"
       },
    tableHeadercell: {
        color: "#141414",
        textAlign:"center",
        // font: "normal normal normal 11px/15px Nunito Sans",
        '@media (min-width:1500px)' : {
          font: "normal normal normal 13px/15px Nunito Sans",
        },
        '@media (max-width:1500px) and (min-width:1000px)' : {
          font: "normal normal normal 13px/15px Nunito Sans",
        },
        '@media (max-width:1000px)' : {
          font: "normal normal normal 12px/15px Nunito Sans",
        },
    },
    lastModifiedTime: {
        color: '#008D9C',
        fontSize: '16px',
    },
    accuracyModelClass: {
        height: "24px",
      width: "50px",
      margin: "0 auto",
      fontSize: "9px",
      '& div': {
        // fontSize: "9px",
        // font: "normal normal 600 9px/12px Nunito Sans", 
        // color: "#191919",
      opacity:"unset",
      '@media (min-width:1500px)' : {
        font: "normal normal 600 12px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 600 11px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 600 10px/15px Nunito Sans",
      },
        }
      },
      liveAccuracyModelClass: {
        height: "24px",
      width: "50px",
      margin: "0 auto",
      fontSize: "9px",
      '& div': {
        // fontSize: "9px",
        // font: "normal normal 600 9px/12px Nunito Sans", 
        // color: "#191919",
      opacity:"unset",
      '@media (min-width:1500px)' : {
        font: "normal normal 600 12px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 600 11px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 600 10px/15px Nunito Sans",
      },
        }
      },
      modelClass: {
        height: "25px",
      // width: "70px",
      margin: "0 auto",
      fontSize: "10px",
      // font: "normal normal 600 9px/12px Nunito Sans"
      '@media (min-width:1500px)' : {
        font: "normal normal 600 12px/15px Nunito Sans",
        width: "85px",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 600 11px/15px Nunito Sans",
        width: "78px",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 600 10px/15px Nunito Sans",
        width: "70px",
      },
      },
      timeDateFormatClass: {
        margin: "0 auto",
        width: "fit-content",

        '& :nth-child(1)' : {
            // fontSize: "11px",
            // font: "normal normal 600 12px/15px Nunito Sans !important",
            '@media (min-width:1500px)' : {
              font: "normal normal 600 13px/15px Nunito Sans !important",
            },
            '@media (max-width:1500px) and (min-width:1000px)' : {
              font: "normal normal 600 13px/15px Nunito Sans !important",
            },
            '@media (max-width:1000px)' : {
              font: "normal normal 600 12px/15px Nunito Sans !important",
            },
        },
        '& :nth-child(2)' : {
            // fontSize: "11px",
            // font: "normal normal 600 12px/15px Nunito Sans !important",
            '@media (min-width:1500px)' : {
              font: "normal normal 600 13px/15px Nunito Sans !important",
            },
            '@media (max-width:1500px) and (min-width:1000px)' : {
              font: "normal normal 600 13px/15px Nunito Sans !important",
            },
            '@media (max-width:1000px)' : {
              font: "normal normal 600 12px/15px Nunito Sans !important",
            },
            color: "#212121"
        }
      }
  }),
);

function ModelComparision(props : {modelAnalyticsAccuracy: IModelAnalyticsAccuracy[], isFetchingModelAnalyticsAccuracy:boolean, modelVersionsToShow :string[], modelAnalyticsTestStatisticalData: IModelAnalyticsTestStatisticalData[], isFetchingModelAnalyticsTestStatisticalData:boolean, modelDetails: IModel | undefined}) {
    const classes = useStyles();
    const [isLiveModelCollectionExist, setIsLiveModelCollectionExist] = useState<boolean>(false);
    const [highestAccuracyValue, setHighestAccuracyValue] = useState<Number>(0);
    const [modelCollectionAccuracies, setModelCollectionAccuracies] = useState<{modelCollectionId: string, accuracyValue: number}[]>([]);
    // const [modelAnalyticsAccuracy, setmodelAnalyticsAccuracy] = useState<IModelAnalyticsAccuracy[]>([])
    console.log("model analytics model comparison and model analytics top performing model in model comparision : ", props.modelAnalyticsAccuracy)
    // const [updatingModelComparisonData, setUpdatingModelComparisonData ] = useState<boolean>(false);

    const urlSearhParams = new URLSearchParams(window.location.search)

    const testCollectionId = urlSearhParams.get('testCollectionId') || ""

    useEffect(() => {

      const requiredModelCollections = props.modelAnalyticsAccuracy.filter(model => {
        return props.modelVersionsToShow.some(version => {
          return model.modelVersion === version
        })
      })
      // console.log(requiredModelCollections, requiredModelCollections.length)
      let highestAccuracy = 0
      requiredModelCollections.forEach((x: IModelAnalyticsAccuracy) => {
        if(x.status === "Live"){
          setIsLiveModelCollectionExist(true)
        }
        if(x?.accuracy ){
          if(x?.accuracy?.value > highestAccuracy){
            highestAccuracy = x.accuracy.value
          }
        }
      })
      setHighestAccuracyValue(highestAccuracy)
      },[props.modelAnalyticsAccuracy, props.modelVersionsToShow])


  return (
    <div className={`${classes.modelComparision} ${props?.modelDetails?.isSegmentationModelAnalytics ? css.segmentationModelAnalytics : ""}`}>
        <Paper style={{height: "100%"}}>
            <div className={classes.heading}>MODEL COMPARISION</div>

            <hr style={{margin:"0px"}}/>
            <TableContainer className={classes.tableContainer}>
                <Table >
                    <TableHead style={{backgroundColor: "white", position: "sticky", top: "0"}}>
                        <TableRow  className={classes.headertr}>
                            <TableCell className={`${classes.tableCell} ${classes.tableHeadercell}`}>
                                MODEL
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHeadercell}`}>
                                STATUS
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHeadercell}`}>
                                <div>
                                ACCURACY
                                </div>
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHeadercell} ${css.totalDataSetsTableHeaderCell}`}>
                                <div>TOTAL </div> 
                                <div>DATA SETS</div>
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableHeadercell}`}>
                                <div>TRAINING</div> <div>TIME AND DATE</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                                (props.isFetchingModelAnalyticsAccuracy || props.isFetchingModelAnalyticsTestStatisticalData) &&
                                new Array(5).fill("").map((item, index) => (
                                    <TableRow>
                                    <TableCell>
                                    <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                    <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                    <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                    <CustomSkeleton height={48} />
                                    </TableCell>
                                    <TableCell>
                                    <CustomSkeleton height={48} />
                                    </TableCell>
                                    </TableRow>
                                ))
                        }
                        {   !props.isFetchingModelAnalyticsAccuracy && !props.isFetchingModelAnalyticsTestStatisticalData &&
                            props.modelAnalyticsTestStatisticalData.map((data, order) => {
                                return (props.modelAnalyticsAccuracy?.map((row, index) => {
                                return (
                                    data.modelCollectionId === row.modelCollectionId &&
                                    <TableRow key={row.modelCollectionId}  className={classes.tr}>
                                        <TableCell className={classes.tableCell}>
                                            {row?.modelVersion}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {/* {props.modelAnalyticsTopPerformingModel?.map((cell, index) => {
                                                return(
                                                    row?.modelVersion === cell.modelVersion &&
                                                    (
                                                        <ModelCollectionAccuracy 
                                                            isLiveModelCollection={row?.status === "Live" ? true : false}
                                                            liveCollectionAccuracy={
                                                            row?.filter((collection: IModelCollection)=>collection.status === "Live")[0]?.accuracy || 0
                                                            }
                                                            accuracyValue={cell.accuracy || 0} 
                                                            modelCollectionFiles={row?.files || []} 
                                                        />  
                                                    )
                                                )
                                            })}
                                            <ModelCollectionAccuracy 
                                                isLiveModelCollection={row?.status === "Live" ? true : false}
                                                liveCollectionAccuracy={
                                                row?.filter((collection: IModelCollection)=>collection.status === "Live")[0]?.accuracy || 0
                                                }
                                                accuracyValue={row.status || 0} 
                                                modelCollectionFiles={row?.files || []} 
                                            />   */}

                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <ModelStatus className={classes.modelClass} dotClassName={classes.dotWhenModelComparisonScenario} status={row.status as any} />
                                            </div>
                                        </TableCell>
                                        {
                                            <TableCell className={classes.tableCell}>
                                                
                                            {/* {props.modelAnalyticsTopPerformingModel?.map((cell, index) => {
                                                return( */}
                                                    {/* row?.modelVersion === cell.modelVersion && */}
                                                        <ModelCollectionAccuracy 
                                                        isSegmentationModelAnalytics={props.modelDetails?.isSegmentationModelAnalytics}
                                                        // segmentationModelAnalyticsAccuracyMetric={props.modelDetails?.segmentationModelAnalyticsAccuracyMetric}
                                                        className={(row?.status === "Live" || (row?.accuracy && row?.accuracy?.value === 0)) ?classes.liveAccuracyModelClass : classes.accuracyModelClass}
                                                    isLiveModelCollection={row?.status === "Live"}
                                                    liveCollectionAccuracy={0}
                                                    accuracyStatus ={ (order === 0 || row?.accuracy?.value === highestAccuracyValue) && ((row?.accuracy?.type === "noLiveExists") || !isLiveModelCollectionExist) ? "customHeighestNoLiveExists" : !isLiveModelCollectionExist ? "customNoLiveExists" : row?.accuracy?.type}
                                                    accuracyValue={row?.accuracy?.value || row?.accuracy?.accuracyJsonData?.accuracy  as any  || 0} 
                                                    jsonFileData ={row?.accuracy?.accuracyJsonData}
                                                    // modelCollectionFiles={[]} 
                                                  />       
                                                    
                                                {/* )
                                            })} */}
                                                {/* <div>
                                                {row?.accuracy?.value || row?.accuracy?.accuracyJsonData?.accuracy}
                                                </div> */}
                                            </TableCell>
                                        }
                                        <TableCell className={`${classes.tableCell} ${css.totalDataSetsValueTableCell}`}>
                                            {row?.totalTrainedDataSetsCount}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <TimeDateFormat className={classes.timeDateFormatClass} timeString={row.created_at as any} timeStyleClass={classes.lastModifiedTime} textAlign={'left'}/>
                                        </TableCell>
                                    </TableRow>
                                )
                            }))
                            })
                        }
                        
                    </TableBody>
                </Table>

            </TableContainer>
        </Paper>
    </div>
  )
}

export default ModelComparision