import axios from 'axios';
import { AI_MARKET_PLACE_API_URLS, BASE_URL, DATA_CART_BASE_URL, STREAM_RESPONSE_POST } from './aiMarketPlaceAPIStrings';
import { AiTaskQueryParams, getModelCollectionsOfAiTaskDetailResponse, GetUniqueAiTasksAndProblemTypesResponse, IDataCartDetails, ILastestModelQueryParams, ILatestModelResponse, IPopularModelQueryParams, IPopularModelResponse } from './aiMarketPlaceInterfaces/interfaces';
import { v4 as uuidv4 } from 'uuid';

export const getUniqueAiTasksAndProblemTypes = async (): Promise<GetUniqueAiTasksAndProblemTypesResponse[] | null> => {
    try {
        const response = await axios.get<GetUniqueAiTasksAndProblemTypesResponse[]>(AI_MARKET_PLACE_API_URLS.GET_LIST_OF_AI_TASKS, { withCredentials: true });
        return response.data;
    } catch (error) {
        return null;
    }
}

// const createQueryString = (params: AiTaskQueryParams) => {
//     const query = new URLSearchParams();
//     query.append('aiTask', params.aiTask);
//     query.append('problemType', params.problemType);

//     if (params.limit !== undefined) query.append('limit', params.limit.toString());
//     if (params.offset !== undefined) query.append('offset', params.offset.toString());
//     if (params.sortField) query.append('sortField', params.sortField);
//     if (params.sortOrder) query.append('sortOrder', params.sortOrder);
//     if (params.maxIntegrity !== undefined) query.append('maxIntegrity', params.maxIntegrity.toString());
//     if (params.minIntegrity !== undefined) query.append('minIntegrity', params.minIntegrity.toString());
//     if (params.minAccuracy !== undefined) query.append('minAccuracy', params.minAccuracy.toString());
//     if (params.bestModelFilter !== undefined) query.append('bestModelFilter', params.bestModelFilter.toString());

//     return query.toString();
// };

export const getModelCollectionsOfAiTaskDetail = async (params: AiTaskQueryParams): Promise<getModelCollectionsOfAiTaskDetailResponse | null> => {
    try {
        // const queryString = createQueryString(params);
        console.log({params})
        const url = AI_MARKET_PLACE_API_URLS.GET_TASK_DETAILS(params);
        const response = await axios.get<getModelCollectionsOfAiTaskDetailResponse>(url, { withCredentials: true });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch AI Task Details", error);
        return null;
    }
};


export const getDataCartDetails = async (data: {aiTask: string, problemType: string, userId: string}): Promise<IDataCartDetails | null> => {
    try {
        const url = `${DATA_CART_BASE_URL}?aiTask=${data.aiTask}&problemType=${data.problemType}&userId=${data.userId}`
        const response = await axios.get<IDataCartDetails>(url, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        console.error("Failed to fetch Data Cart", error);
        return null;
    }
}

export const createDataCart = async (data: {aiTask: string, problemType: string, userId: string}): Promise<any> => {
    try {
        const url = `${DATA_CART_BASE_URL}`
        await axios.post<any>(url, data, { withCredentials: true });
    }
    catch (error) {
        console.error("Failed to create Data Cart", error);
    }
}

export const updateDataCart = async (data: {_id: string, rlefResourceIds?: Array<string>, rlefDataSetIds?: Array<string> }): Promise<any> => {
    try {
        const url = `${DATA_CART_BASE_URL}`

        const payload = {
            _id: data._id,
            rlefResourceIds: JSON.stringify(data.rlefResourceIds),
            rlefDataSetIds: JSON.stringify(data.rlefDataSetIds),
        }

        await axios.put<any>(url, payload, { withCredentials: true });
    }
    catch (error) {
        console.error("Failed to update Data Cart", error);
    }
}

export const uploadALocalResourceInDataCart = async (data: { file: File, dataCartId: string }): Promise<any> => {
    try {
        const url = `${DATA_CART_BASE_URL}/uploadLocalResource`
        const formData = new FormData();
        formData.append('_id', data.dataCartId);
        formData.append('file', data.file);

        await axios.put<any>(url, formData, { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } });
    }
    catch (error) {
        console.error("Failed to upload resource", error);
    }
}

export const deleteLocalResourcesFromDataCart = async (data: { resourceIds: string[], dataCartId: string }) => {
    try {
        const url = `${DATA_CART_BASE_URL}/deleteLocalResources`
        const payload = {
            resourceIds: JSON.stringify(data.resourceIds),
            dataCartId: data.dataCartId
        }

        await axios.delete<any>(url, { withCredentials: true, data: payload });
    }
    catch (error) {
        console.error("Failed to delete resources", error);
    }
}

export const getPopularModels = async (params:IPopularModelQueryParams) => {
    try {
        const url = AI_MARKET_PLACE_API_URLS.GET_POPULAR_MODELS(params);
        const response = await axios.get<IPopularModelResponse>(url, { withCredentials: true });
        return response.data;
    }
    catch(error){
        console.log("Failed to fetch popular models",error)
    }
}

export const getLatestModels = async (params:ILastestModelQueryParams) => {
    try {
        const url = AI_MARKET_PLACE_API_URLS.GET_LATEST_MODELS(params);
        const response = await axios.get<ILatestModelResponse>(url, { withCredentials: true });
        return response.data;
    }
    catch(error){
        console.log("Failed to fetch popular models",error)
    }
}


export const streamResponsePost = async (prompt:string) => {
    const requestId = `requestId-${uuidv4()}`;
    const body = {
        name: 'wowdemo4',
        organizationName: 'techolution',
        aiModel: 'bestai',
        prompt,
        userName: 'Hartej',
        userEmailId: 'hartej.singh@techolution.com',
        userId: '13d70acf-c0b9-470d-8606-11b6c94f8bfc',
        requestId,
    };

    const headers = {
        accept: 'application/json, text/plain, */*',
        'accept-language': 'en-US,en;q=0.9',
        'content-type': 'application/json',
    };

    try {
        const response = await axios.post(STREAM_RESPONSE_POST, body, { headers });
        return response.data;  
    } catch (error) {
        console.error('Error in streamResponsePost:', error);
        throw error;
    }
};

export const streamResponseGet = async (requestId: string) => {
    const params = {
        name: 'wowdemo4',
        organizationName: 'techolution',
        aiModel: 'bestai',
        userName: 'Hartej',
        userEmailId: 'hartej.singh@techolution.com',
        userId: '13d70acf-c0b9-470d-8606-11b6c94f8bfc',
        requestId, 
    };

    try {
        const response = await axios.get(STREAM_RESPONSE_POST, { params });
        return response.data;  
    } catch (error) {
        console.error('Error in streamResponseGet:', error);
        throw error;
    }
};

export const getResultsForUserPrompt = async (prompt: string): Promise<getModelCollectionsOfAiTaskDetailResponse | null> => {
    try {
        const payload = {
            prompt
        }
        const response = await axios.post<getModelCollectionsOfAiTaskDetailResponse>(BASE_URL + '/prompt', payload );
        return response.data;
    }
    catch(error) {
        console.log("Failed to fetch results",error)
        return null;
    }
}